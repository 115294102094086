import { Box, Stack, Typography } from '@mui/material';
import { ChangeEvent, DragEvent, FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'shared/ui';

interface Props {
  setLogo: (logo: string) => void;
}

export const UploadPhoto: FC<Props> = ({ setLogo }) => {
  const { t } = useTranslation();
  const [, setDrag] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const imgRef = useRef<HTMLImageElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (!inputRef.current) return;
    inputRef.current.click();
  };
  const dragStartHandler = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDrag(true);
  };
  const dragLeaveHandler = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDrag(false);
  };

  const handleBase64Img = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      if (!reader.result || !imgRef.current) {
        console.error('Error with uploading image.');
        return;
      }

      setLogo(reader.result as string);
      imgRef.current.src = reader.result as string;
    };
    setLoaded(true);
  };
  const dropHandler = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleBase64Img(file);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target || !e.target.files) return;

    const file = e.target.files[0];

    if (!file) {
      console.error('No uploaded files.');
      return;
    }

    handleBase64Img(file);
  };

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ cursor: 'pointer', position: 'relative' }}
      onDragStart={(e) => dragStartHandler(e)}
      onDragOver={(e) => dragStartHandler(e)}
      onDragLeave={(e) => dragLeaveHandler(e)}
      onDrop={(e) => dropHandler(e)}
      onClick={handleClick}
    >
      <Box
        component="input"
        type="file"
        accept="image/*,.png,.jpg,.gif,.web"
        ref={inputRef}
        onChange={handleChange}
        sx={{
          opacity: 0,
          height: 0,
          width: 0,
          padding: 0,
          margin: 0,
        }}
      />
      <Box component="img" ref={imgRef} src="" sx={{ width: loaded ? '100px' : 0 }} />

      {!loaded && <Icon path={'org-placeholder'} alt="" sx={{ width: 100, height: 100, borderRadius: '6px' }} />}

      <Typography
        sx={{
          position: 'absolute',
          bottom: '-20px',
          textTransform: 'capitalize',
          color: '#667085',
          fontFamily: 'Roboto',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '130%',
          letterSpacing: '0.14px',
        }}
      >
        {t('upload photo')}
      </Typography>
    </Stack>
  );
};
