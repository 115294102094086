import { ChangeEvent, useEffect, useState } from 'react';

export const useLoginValues = () => {
  const [loginData, setLoginData] = useState({ username: '', password: '' });

  const [isNoChangeName, setIsNoChangeName] = useState(true);
  const [isNoChangePass, setIsNoChangePass] = useState(true);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const onLoginDataChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'username') setIsNoChangeName(false);
    if (e.target.name === 'password') setIsNoChangePass(false);
    setLoginData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const checkIsValid = () => {
    setUsernameError(false);
    setPasswordError(false);

    let isValid = true;

    if (!loginData.username) {
      setUsernameError(true);
      isValid = false;
    }
    if (!loginData.password) {
      setPasswordError(true);
      isValid = false;
    }

    return isValid;
  };

  useEffect(() => {
    const remembered = localStorage.getItem('rememberedUser');
    if (!remembered) return;

    const savedLoginData = JSON.parse(decodeURIComponent(window.atob(remembered))) as {
      username: string;
      password: string;
    };

    savedLoginData && setLoginData(savedLoginData);
  }, []);

  useEffect(() => {
    if (!isNoChangeName && !isNoChangePass) {
      checkIsValid();
    }
  }, [loginData]);

  return {
    loginData,
    onLoginDataChange,
    checkIsValid,
    usernameError,
    passwordError,
  };
};
