import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector, useCatalogSearchParams } from '../../../shared/hooks';
import { axiosBase } from 'shared/axios';
import { BaseResponse, LanguageData, TableProductInfo } from '../../../shared/models';
import {
  selectCatalogLanguage,
  selectCatalogLanguages,
  selectCatalogTypes,
  selectCurrentCatalog,
  selectOpenedCatalogs,
  setLanguage,
  setLanguages,
} from 'shared/slices';
import { requestWrapper } from 'shared/lib';
import { CatalogService } from 'shared/services';
import { CATALOG_TYPE } from 'shared/constants';

export const useTranslations = () => {
  const dispatch = useAppDispatch();
  const catalogTypes = useAppSelector(selectCatalogTypes);
  const currentCatalog = useAppSelector(selectCurrentCatalog);
  const languages = useAppSelector(selectCatalogLanguages);
  const language = useAppSelector(selectCatalogLanguage);
  const openedCatalogs = useAppSelector(selectOpenedCatalogs);

  const { type, onChangeManageType } = useCatalogSearchParams();

  const [isLanguagesLoading, setIsLanguagesLoading] = useState(false);
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [isProductsReady, setIsProductsReady] = useState(true);

  const [group, setGroup] = useState('');
  const [selected, setSelected] = useState('');

  const [products, setProducts] = useState<TableProductInfo[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<TableProductInfo[]>([]);

  const groupItems = useMemo(
    () => currentCatalog?.groups?.filter((g) => g.type === type) ?? [],
    [currentCatalog, type]
  );

  const currentOpenedCatalog = useMemo(
    () => openedCatalogs.find((oc) => oc.catalogId === currentCatalog.id),
    [currentCatalog, openedCatalogs]
  );

  const catalogItems = useMemo(() => {
    return currentOpenedCatalog?.visibleItems ?? [];
  }, [currentOpenedCatalog, type, group]);

  const onLanguageChange = (langId: string) => {
    dispatch(setLanguage(langId));
  };

  const onGroupChange = (groupId: string) => {
    setGroup(groupId);

    const filteredProductsByGroup = products.filter((p) => p.groupId === groupId);

    setFilteredProducts(filteredProductsByGroup);
  };

  const onSelect = (id: string) => setSelected(id);

  const onTypeChange = async (type: string) => {
    setIsProductsReady(false);

    onChangeManageType(type);

    const fetchedProducts = await fetchProducts(currentCatalog.id, type);

    if (!fetchedProducts) return;

    setProducts(fetchedProducts);

    const filteredProductsByGroup = fetchedProducts.filter((p) => groupItems[0] && p.groupId === groupItems[0].id);

    setFilteredProducts(filteredProductsByGroup);
  };

  const onArrowKeyPress = (
    e: React.KeyboardEvent<HTMLDivElement | HTMLButtonElement>,
    boxType: '' | 'type' | 'group' | 'translation'
  ) => {
    if (e.key !== 'ArrowDown' && e.key !== 'ArrowUp') return;
    e.preventDefault();

    const items = (() => {
      switch (boxType) {
        case 'group':
          return groupItems.map((item) => item.id);
        case 'translation':
          return filteredProducts.map((item) => item.id);
        case 'type':
          return catalogTypes.filter((item) => item !== CATALOG_TYPE.DISTRIBUTION_CURVE);
        default:
          return [];
      }
    })();

    const currentItemIndex = items.findIndex((item) => {
      if (boxType === 'group') {
        return item === group;
      }
      if (boxType === 'translation') {
        return item === selected;
      }
      if (boxType === 'type') {
        return item === type;
      }
    });

    if (currentItemIndex === -1) return;

    if (e.key === 'ArrowDown' && currentItemIndex < items.length - 1) {
      const newItem = items[currentItemIndex + 1];

      if (boxType === 'group') {
        onGroupChange(newItem);
      } else if (boxType === 'translation') {
        setSelected(newItem);
      } else if (boxType === 'type') {
        onChangeManageType(newItem);
      }
    } else if (e.key === 'ArrowUp' && currentItemIndex > 0) {
      const newItem = items[currentItemIndex - 1];

      if (boxType === 'group') {
        onGroupChange(newItem);
      } else if (boxType === 'translation') {
        setSelected(newItem);
      } else if (boxType === 'type') {
        onChangeManageType(newItem);
      }
    }
  };

  useEffect(() => {
    if (filteredProducts) {
      setIsProductsReady(true);
    }
    // }, [...filteredProducts]);
  }, [filteredProducts.length]);

  useEffect(() => {
    const setDefaultGroupValue = async () => {
      setIsProductsReady(false);

      if (!groupItems.length) {
        setIsProductsReady(true);

        return;
      }

      setGroup(groupItems[0].id);

      const fetchedProducts = await fetchProducts(currentCatalog.id, type);

      if (!fetchedProducts) return;

      setProducts(fetchedProducts);

      const filteredProductsByGroup = fetchedProducts.filter((p) => p.groupId === groupItems[0].id);

      setFilteredProducts(filteredProductsByGroup);
    };

    setDefaultGroupValue();
  }, [groupItems]);

  useEffect(() => {
    const fetchLanguages = async () => {
      setIsLanguagesLoading(true);

      const { data } = await requestWrapper(axiosBase.get<BaseResponse<LanguageData[]>>(`/Language`));
      if (data) {
        dispatch(setLanguages(data));
        dispatch(setLanguage(data[0].id));
      }
      setIsLanguagesLoading(false);
    };

    if (!languages.length) {
      fetchLanguages();
    }
  }, []);

  const fetchProducts = async (catalogId: string, type: string) => {
    setIsProductsLoading(true);

    const products = (await CatalogService.getProducts(catalogId, type)) ?? [];

    setIsProductsReady(true);

    setIsProductsLoading(false);
    if (products) return products ?? [];
  };

  const updateFilteredProduct = (updatedComponent: TableProductInfo) => {
    const newArray = filteredProducts.map((item) => {
      return item.id === updatedComponent.id ? updatedComponent : item;
    });

    setFilteredProducts(newArray);
  };

  return {
    groupItems,
    languages,
    isProductsReady,
    isLanguagesLoading,
    group,
    language,
    onLanguageChange,
    onGroupChange,
    selected,
    onSelect,
    catalogTypes,
    type,
    onTypeChange,
    catalogItems,
    isProductsLoading,
    filteredProducts,
    updateFilteredProduct,
    onArrowKeyPress,
  };
};
