import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import {
  openCatalog,
  selectCatalogTypes,
  selectGroups,
  selectPermissions,
  setCatalogsLoading,
  setCatalogTypes,
  setCurrentCatalog,
  setGroups,
} from 'shared/slices';
import { BaseLayout } from 'shared/ui';
import { CatalogManager } from 'widgets';
import { TabBar } from 'widgets/TabBar';
import { CatalogInfo } from 'features';
import { CATALOG_TYPE } from 'shared/constants';
import { CatalogService } from 'shared/services';
import { useEffect } from 'react';

export const CatalogManagementPage = () => {
  const dispatch = useAppDispatch();
  const { id: catalogId } = useParams();

  const permissions = useAppSelector(selectPermissions);
  const types = useAppSelector(selectCatalogTypes);
  const groups = useAppSelector(selectGroups);
  const isNoTypes = !types?.length;
  const isNoGroups = !groups?.[catalogId as string];

  useEffect(() => {
    const abortController = new AbortController();

    const getData = async () => {
      if (!catalogId) return;

      dispatch(setCatalogsLoading(true));

      try {
        const promiseArray: Promise<any>[] = [];

        if (isNoGroups) {
          const currentCatalogPromise = CatalogService.getCurrentCatalog(catalogId, abortController.signal);
          promiseArray.push(currentCatalogPromise);
        }

        if (isNoTypes) {
          const catalogTypesPromise = CatalogService.getTypes(abortController.signal);
          promiseArray.push(catalogTypesPromise);
        }

        const [currentCatalog, catalogTypes] = await Promise.all(promiseArray);

        if (currentCatalog) {
          dispatch(setCurrentCatalog(currentCatalog));
          dispatch(setGroups({ catalogId, groups: currentCatalog?.groups ?? [] }));
        }

        if (catalogTypes) {
          dispatch(
            setCatalogTypes([
              ...catalogTypes.filter((t: string) => t !== 'General'),
              CATALOG_TYPE.DISTRIBUTION_CURVE,
            ])
          );
        }

        dispatch(openCatalog(catalogId));
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setCatalogsLoading(false));
      }
    };

    getData();

    return () => abortController.abort();
  }, [catalogId]);

  const isListPermissionAble = Object.values(permissions).some((p) => p.catalog && p.catalog.list);

  return (
    <BaseLayout tabBar={<TabBar />}>
      <Stack sx={{ flexGrow: 1, overflow: 'hidden', height: '100%' }}>
        {isListPermissionAble && (
          <>
            <CatalogInfo />
            <CatalogManager />
          </>
        )}
      </Stack>
    </BaseLayout>
  );
};
