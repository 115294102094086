import { FC } from 'react';
import { Box, TableContainer, Table as MuiTable, useTheme, BoxProps } from '@mui/material';

export const Table: FC<BoxProps> = ({ children, sx, height, width }) => {
  const { palette } = useTheme();

  return (
    <Box sx={{ position: 'relative', ...sx }}>
      <TableContainer
        sx={{
          pb: '10px',
          height: height ?? '100%',
          width: width ?? '100%',

          overflowX: 'auto',
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: palette.primary.main,
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
            backgroundColor: palette.info.dark,
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: palette.info.dark,
          },
          '&::-webkit-scrollbar-corner': {
            backgroundColor: palette.info.dark,
            background: palette.info.dark,
          },
        }}
      >
        <MuiTable stickyHeader sx={{ pb: '20px' }}>
          {children}
        </MuiTable>
      </TableContainer>
    </Box>
  );
};
