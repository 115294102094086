import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const DotIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M0 12C0 18.6173 5.38272 24 12 24C18.6173 24 24 18.6173 24 12C24 5.38272 18.6173 0 12 0C5.38272 0 0 5.38272 0 12ZM13.92 17.76C13.9258 18.0157 13.8804 18.2699 13.7866 18.5079C13.6928 18.7458 13.5523 18.9625 13.3736 19.1454C13.1948 19.3283 12.9813 19.4737 12.7456 19.5729C12.5099 19.6722 12.2567 19.7233 12.001 19.7234C11.7452 19.7235 11.492 19.6724 11.2563 19.5733C11.0205 19.4742 10.8069 19.3289 10.6281 19.1461C10.4492 18.9633 10.3087 18.7466 10.2147 18.5088C10.1208 18.2709 10.0753 18.0166 10.081 17.761C10.0921 17.2593 10.2991 16.7819 10.6578 16.431C11.0164 16.0801 11.4982 15.8835 12 15.8834C12.5018 15.8833 12.9837 16.0796 13.3425 16.4303C13.7014 16.7811 13.9087 17.2583 13.92 17.76ZM13.92 12C13.9258 12.2557 13.8804 12.51 13.7866 12.7479C13.6928 12.9858 13.5523 13.2025 13.3736 13.3854C13.1948 13.5683 12.9813 13.7137 12.7456 13.8129C12.5099 13.9122 12.2567 13.9633 12.001 13.9634C11.7452 13.9635 11.492 13.9124 11.2563 13.8133C11.0205 13.7142 10.8069 13.5689 10.6281 13.3861C10.4492 13.2033 10.3087 12.9866 10.2147 12.7488C10.1208 12.5109 10.0753 12.2566 10.081 12.001C10.0921 11.4993 10.2991 11.0219 10.6578 10.671C11.0164 10.3201 11.4982 10.1235 12 10.1234C12.5018 10.1233 12.9837 10.3196 13.3425 10.6703C13.7014 11.0211 13.9087 11.4983 13.92 12ZM13.92 6.24C13.9258 6.49569 13.8804 6.74995 13.7866 6.98786C13.6928 7.22578 13.5523 7.44255 13.3736 7.62545C13.1948 7.80834 12.9813 7.95369 12.7456 8.05293C12.5099 8.15218 12.2567 8.20334 12.001 8.20341C11.7452 8.20347 11.492 8.15244 11.2563 8.05331C11.0205 7.95417 10.8069 7.80894 10.6281 7.62613C10.4492 7.44333 10.3087 7.22662 10.2147 6.98876C10.1208 6.75089 10.0753 6.49665 10.081 6.24096C10.0921 5.7393 10.2991 5.26192 10.6578 4.91101C11.0164 4.56009 11.4982 4.36353 12 4.36341C12.5018 4.36328 12.9837 4.5596 13.3425 4.91033C13.7014 5.26107 13.9087 5.73835 13.92 6.24Z"
      fill={props.fill}
    />
  </SvgIcon>
);
