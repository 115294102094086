import { Organization } from '../../models';

export const findOrganizationAdmin = (organizations: Organization[], orgId: number): string => {
  const currentOrg = organizations.find((org) => org.id === orgId);

  if (currentOrg && currentOrg.admins && currentOrg.admins.length > 0) {
    return currentOrg.admins[0];
  }

  if (currentOrg && currentOrg.fatherOrganizationId !== null) {
    return findOrganizationAdmin(organizations, currentOrg.fatherOrganizationId);
  }

  return 'Unknown';
};
