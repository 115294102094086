import { BoxProps, Stack } from '@mui/material';
import { FC } from 'react';
import { Button } from '../Buttons';
import { useTranslation } from 'react-i18next';

interface Props extends BoxProps {
  onSave?: () => void;
  onCancel?: () => void;
  isSaveDisabled?: boolean;
  isLoading?: boolean;
  small?: boolean;
  isSubmit?: boolean;
  btnTexts?: string[];
  isSaveExist?: boolean;
}

export const SaveControl: FC<Props> = ({
  onSave,
  onCancel,
  isLoading,
  small,
  isSubmit,
  isSaveDisabled,
  btnTexts,
  sx,
  isSaveExist = true,
}) => {
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      spacing={small ? '10px' : { xs: '15px', md: '24px' }}
      alignItems="center"
      sx={{ ...sx }}
    >
      <Button
        onClick={onCancel}
        disabled={isLoading}
        sx={{
          width: 120,
          height: 48,
          color: '#242731',
          textAlign: 'center',
          fontFamily: 'Roboto',
          fontSize: '18px',
          fontWeight: 600,
          lineHeight: '130%',
          letterSpacing: '0.18px',
        }}
      >
        {btnTexts?.[1] ? btnTexts[1] : t('cancel')}
      </Button>
      {isSaveExist && (
        <Button
          type={isSubmit ? 'submit' : 'button'}
          onClick={onSave}
          filled
          disabled={isSaveDisabled}
          loading={isLoading}
          sx={{
            minWidth: 120,
            height: 48,
            color: '#FFF',
            textAlign: 'center',
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '130%',
            letterSpacing: '0.18px',
          }}
        >
          {btnTexts?.[0] ? btnTexts[0] : t('save')}
        </Button>
      )}
    </Stack>
  );
};
