import { FormControlLabel, Switch } from '@mui/material';
import React, { FC } from 'react';

interface Props {
  label?: string;
  isOn: boolean;
  toggle: () => void;
}

export const AuthSwitcher: FC<Props> = ({ isOn, toggle, label }) => {
  return (
    <FormControlLabel
      label={label}
      sx={{
        ml: '0px',
        color: '#000',
        '& .MuiTypography-root': {
          fontSize: '12px',
          ml: '8px',
        },
      }}
      control={
        <Switch
          checked={isOn}
          onChange={toggle}
          sx={{
            boxSizing: 'border-box',
            padding: '0px !important',
            height: '20px !important',
            width: '40px !important',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: isOn ? 'primary.light' : 'info.light',
            borderRadius: '30px',
            backgroundColor: isOn ? 'primary.light' : 'info.light',

            '& .MuiSwitch-track': {
              backgroundColor: isOn ? 'primary.light' : 'info.light',
              opacity: '0 !important ',
            },
            '& .MuiSwitch-input': {
              left: '-200%',
              width: '200%',
              height: '200%',
            },
            '& .MuiButtonBase-root': {
              p: '1px',
            },
            '& .MuiSwitch-thumb': {
              height: '16px',
              width: '16px',
              // boxShadow: 'none',
              bgcolor: isOn ? 'primary.main' : 'info.main',
            },
            '& .Mui-checked': {
              transform: 'translateX(20px)',
            },
          }}
        />
      }
    />
  );
};
