import { selectCatalogs, selectCurrentCatalog, selectFlattedOrg, selectPermissions } from 'shared/slices';
import { useAppSelector } from './useAppSelector';
import { CATALOG_OWNER_TYPE } from 'shared/constants';
import { useParams } from 'react-router-dom';
import { localStorageService } from 'shared/services';

export const useCatalogUserPermissions = () => {
  const permissions = useAppSelector(selectPermissions);
  const catalogs = useAppSelector(selectCatalogs);
  const flattedOrgs = useAppSelector(selectFlattedOrg);
  const currentCatalog = useAppSelector(selectCurrentCatalog);
  const { id: catalogId } = useParams();

  const userId = localStorageService.userId;
  const ownerInfo = catalogs?.find((c) => c.id === catalogId)?.owner;
  const ownerOrgId = flattedOrgs.find((o) => o.organizationId === ownerInfo?.id)?.id;

  const isCatalogOwner = ownerInfo?.id === userId;
  const isOrgCatalog = ownerInfo?.type === CATALOG_OWNER_TYPE.ORG;

  const isHasCatalogManageOrgPermission = ownerOrgId ? permissions[ownerOrgId].catalog['manage-org'] : false;
  // const isHasCatalogManagePrivatePermission = Object.values(permissions).some((p) => p.catalog['manage-private']);
  const isHasEditorAccess = !!currentCatalog?.sharings
    ?.find((s) => s.sharedWithId === userId)
    ?.permission?.includes('Write');

  const isCanEditPrivateCatalog = isCatalogOwner || /* isHasCatalogManagePrivatePermission || */ isHasEditorAccess;
  const isCanEditCatalog = isOrgCatalog ? isHasCatalogManageOrgPermission : isCanEditPrivateCatalog;

  return {
    isCanEditPrivateCatalog,
    isCanEditCatalog,
  };
};
