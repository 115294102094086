import {
  DataGridPremium,
  ElementSize,
  GRID_CHECKBOX_SELECTION_FIELD,
  GridCellCoordinates,
  GridColumnResizeParams,
  GridRenderCellParams,
  GridRowId,
} from '@mui/x-data-grid-premium';
import { useAppDispatch } from '../../shared/hooks';
import { Checkbox } from '@mui/material';
import { CatalogSelectionArrow, TableArrowScrolling } from '../../shared/ui';
import { Brand, CellType, Group, TableProductInfo } from 'shared/models';
import {
  changeProductItemByArrayCell,
  clearCellErrors,
  pasteCopiedCells,
  removeCellError,
  selectCells,
  undoProductAction,
} from 'shared/slices';
import {
  CATALOG_SPEC,
  DISTRIBUTION_CURVE_COLUMNS,
  EMITTER_COLUMNS,
  GENERAL_COLUMNS,
  INTEGRAL_COLUMNS,
  PIPE_COLUMNS,
  PRODUCT_KEY,
} from 'shared/constants';
import {
  checkIsCurveCellDisabled,
  copyCellsToClipboard,
  getCellsFromClipboard,
  getCurrentViewCells,
  getDividedByRegexpText,
  getRegexpMatching,
} from 'shared/lib';
import { ContextMenuWrapper, RenderCell } from './components';
import { forwardRef, memo, MutableRefObject, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { localStorageService } from 'shared/services';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { usePersistTableScroll } from '../../shared/hooks/usePersistTableScroll';
import { ExtendedGridColDef } from './GridContainer';

const ROW_HEIGHT = 30;

const searchableColumns: string[] = [PRODUCT_KEY.SKU, PRODUCT_KEY.DESC, PRODUCT_KEY.SUBTYPE, PRODUCT_KEY.GROUP];

type Props = {
  columns: ExtendedGridColDef[];
  specification: number;
  items: TableProductInfo[];
  isEditing: boolean;
  changedItems: { [id: string]: TableProductInfo };
  deletedItemIDs: { [id: string]: boolean };
  newItemIDs: { [id: string]: boolean };
  tableSearch: { search: string; currentIdx: number; cells: CellType[] };
  isValidating: boolean;
  onToggleValidating: () => void;
  cellErrors: { [id: string]: { [key: string]: boolean } };
  isDistributionCurveType: boolean;
  catalogTypeGroups: Group[];
  catalogBrands: Brand[];
  tableRef: MutableRefObject<GridApiPremium>;
};

export const MemoGrid = memo(
  ({
    tableRef: apiRef,
    items,
    isEditing,
    specification,
    columns,
    newItemIDs,
    changedItems,
    deletedItemIDs,
    tableSearch,
    cellErrors,
    isValidating,
    isDistributionCurveType,
    catalogTypeGroups,
    onToggleValidating,
  }: Props) => {
    const dispatch = useAppDispatch();
    const { id: catalogId } = useParams();

    usePersistTableScroll(apiRef, catalogId);

    const [, setIsSelectionVisible] = useState(false);
    const [isSelectedFullRow, setIsSelectedFullRow] = useState(false);
    const editCellCoord = useRef<GridCellCoordinates | null>(null);

    const [menuPosition, setMenuPosition] = useState(0);
    const [menuHeight, setMenuHeight] = useState<string | number>('auto');

    const isEditingRef = useRef(isEditing);

    // const distributionCurveTopComponentIDs = isDistributionCurveType ? getCurveTopComponentIDs(items) : [];

    useEffect(() => {
      isEditingRef.current = isEditing;
    }, [isEditing]);

    useEffect(() => {
      if (isValidating) {
        const errorIDs = Object.keys(cellErrors);
        if (!errorIDs.length) return;

        const firstErrorID = errorIDs[0];
        const firstErrorField = Object.keys(cellErrors[firstErrorID])[0];

        const colIndex = apiRef.current.getColumnIndex(firstErrorField);
        const rowIndex = apiRef.current.getAllRowIds().findIndex((id) => id === firstErrorID);

        const dimensions = apiRef.current.getRootDimensions()?.viewportInnerSize as ElementSize;

        // let left = 0;
        // if (colIndex > 2) {
        //   const colWidths = apiRef.current.getAllColumns().map((c) => c.width as number);
        //   const pinnedWidth = colWidths.slice(0, 3).reduce((sum, num) => sum + num, 0);
        //   const halfWidth = (dimensions.width - pinnedWidth) / 2;
        //   left = halfWidth > pinnedWidth ? halfWidth : pinnedWidth;
        // }
        const top = ROW_HEIGHT * (rowIndex - 1) - dimensions?.height / 2;
        apiRef.current.scrollToIndexes({ rowIndex, colIndex });
        apiRef.current.scroll({ top });

        const errorCell = { id: firstErrorID, field: firstErrorField, value: '' };

        handleOpenCellEditMode(errorCell);
        onToggleValidating();
      }
    }, [isValidating]);

    useEffect(() => {
      if (isEditing) {
        const width = apiRef.current.getColumn(PRODUCT_KEY.DESC)?.width;
        if (!width) return;

        const widths = localStorageService.catalogsColumnWidths;
        const currentTableWidths = widths?.[catalogId as string] ?? {};

        currentTableWidths[PRODUCT_KEY.DESC] = width;
        localStorageService.catalogsColumnWidths = { ...widths, [catalogId as string]: currentTableWidths };
      }
    }, [isEditing]);

    useEffect(() => {
      if (!isEditing) {
        const errors = Object.entries(cellErrors ?? {});
        if (!errors.length) return;

        errors.forEach(([id, fields]) => {
          for (const field in fields) {
            const mode = apiRef.current.getCellMode(id as GridRowId, field);
            const cellType = apiRef.current.getColumn(field).type;

            if (mode === 'edit') {
              const product = items.find((item) => item.id === id);

              let value = product ? product[field as keyof TableProductInfo] : '';
              value = cellType == 'number' && !value ? '' : value;
              apiRef.current.setEditCellValue({ id: id as GridRowId, field, value });
              apiRef.current.stopCellEditMode({ id: id as GridRowId, field });
            }
          }
        });

        catalogId && dispatch(clearCellErrors(catalogId));
      }
    }, [isEditing]);

    useEffect(() => {
      const FIRST_SECTION_COL_IDX = 0;
      const FIRST_UNPINNED_COL_IDX = 3;
      const PLACE_ONE_COL_IDX = 6;
      const PLACE_26_COL_IDX = 32;

      let field = '';
      if (specification === CATALOG_SPEC.GENERAL) {
        field = GENERAL_COLUMNS[FIRST_UNPINNED_COL_IDX].field;
      }
      if (specification === CATALOG_SPEC.PIPE) {
        field = PIPE_COLUMNS[FIRST_SECTION_COL_IDX].field;
      }
      if (specification === CATALOG_SPEC.EMITTER) {
        field = EMITTER_COLUMNS[FIRST_SECTION_COL_IDX].field;
      }
      if (specification === CATALOG_SPEC.INTEGRAL) {
        field = INTEGRAL_COLUMNS[FIRST_SECTION_COL_IDX].field;
      }
      if (specification === CATALOG_SPEC.PLACE_0_26) {
        field = DISTRIBUTION_CURVE_COLUMNS[PLACE_ONE_COL_IDX].field;
      }
      if (specification === CATALOG_SPEC.PLACE_26_52) {
        field = DISTRIBUTION_CURVE_COLUMNS[PLACE_26_COL_IDX].field;
      }

      const columns = apiRef.current.getAllColumns();
      const colIndex = columns.findIndex((c) => c.field === field);
      const left = columns
        .map((c) => c.width as number)
        .slice(3, colIndex)
        .reduce((sum, num) => sum + num, 0);

      setTimeout(() => apiRef.current?.scroll({ left }), 0);
    }, [specification]);

    useEffect(() => {
      if (!newItemIDs) return;
      const lastId = Object.keys(newItemIDs).at(-1);
      if (!isDistributionCurveType && lastId) {
        apiRef.current.startCellEditMode({ id: lastId as GridRowId, field: PRODUCT_KEY.SKU });
      }
      if (isDistributionCurveType && lastId) {
        // this will cause scroll to a new row but will not open a drop-down
        apiRef.current.startCellEditMode({ id: lastId as GridRowId, field: PRODUCT_KEY.DESC });

        setTimeout(() => {
          const mode = apiRef.current.getCellMode(lastId as GridRowId, PRODUCT_KEY.DESC);
          if (mode === 'edit') {
            try {
              apiRef.current.stopCellEditMode({ id: lastId as GridRowId, field: PRODUCT_KEY.DESC });
            } catch (error) {
              console.log(error);
            }
          }
        }, 0);
      }
    }, [newItemIDs]);

    useEffect(() => {
      if (tableSearch?.cells?.length) {
        const cell = tableSearch.cells[tableSearch.currentIdx];
        const rowIndex = apiRef.current.getAllRowIds().findIndex((id) => id === cell.id);
        const colIndex = apiRef.current.getColumnIndex(cell.field);

        apiRef.current.scrollToIndexes({ colIndex, rowIndex });
      }
    }, [tableSearch]);

    useEffect(() => {
      window.addEventListener('keydown', handleTabKeyDown);
      window.addEventListener('keydown', handleHotKeyDown);
      window.addEventListener('keydown', handleInputMinus);

      return () => {
        window.removeEventListener('keydown', handleTabKeyDown);
        window.removeEventListener('keydown', handleHotKeyDown);
        window.removeEventListener('keydown', handleInputMinus);
      };
    }, []);

    useEffect(() => {
      if (editCellCoord.current) {
        window.removeEventListener('keydown', handleHotKeyDown);
      } else {
        window.addEventListener('keydown', handleHotKeyDown);
      }
    }, [editCellCoord.current]);

    const handleSaveSelectedCells = () => {
      const selectedRowIDs = Array.from(apiRef.current.getSelectedRows().keys());

      let cellCoords: GridCellCoordinates[];

      if (selectedRowIDs.length) {
        const columns = apiRef.current
          .getAllColumns()
          .map((c) => c.field)
          .filter((c) => c !== GRID_CHECKBOX_SELECTION_FIELD);

        const coords: GridCellCoordinates[] = [];
        for (let i = 0; i < selectedRowIDs.length; i++) {
          const id = selectedRowIDs[i];
          for (let y = 0; y < columns.length; y++) {
            const field = columns[y];
            const coord: GridCellCoordinates = { id, field };
            coords.push(coord);
          }
        }
        cellCoords = coords;
      } else {
        cellCoords = apiRef.current.unstable_getSelectedCellsAsArray();
      }

      const cells = cellCoords.map(({ id, field }) => {
        const value = apiRef.current.getCellValue(id, field);

        const cell: CellType = { id: id.toString(), field, value };

        return cell;
      });
      const currentCells = getCurrentViewCells(cells, items, changedItems);

      if (currentCells.length) {
        dispatch(selectCells(currentCells));
      }

      return currentCells;
    };

    const handleOpenCellEditMode = (c: CellType) => {
      const params = apiRef.current.getCellParams(c.id as GridRowId, c.field);
      const isEditable = params.isEditable;
      const isViewMode = params.cellMode === 'view';

      if (isEditing && isEditable && isViewMode) {
        apiRef.current.startCellEditMode({ id: c.id as GridRowId, field: c.field });
      }
    };

    const handleHorizontalScroll = (side: 'right' | 'left') => {
      let left = apiRef.current.getScrollPosition().left;
      if (side === 'left') {
        left -= 50;
      }
      if (side === 'right') {
        left += 50;
      }
      apiRef.current.scroll({ left });
    };

    const handleTabKeyDown = (e: KeyboardEvent) => {
      if (e.code == 'Tab') {
        e.preventDefault();
        const selectedRowIDs = [...apiRef.current.getSelectedRows().keys()];
        let targetCell: GridCellCoordinates | null = null;

        if (selectedRowIDs[0]) {
          targetCell = { id: selectedRowIDs[0], field: GRID_CHECKBOX_SELECTION_FIELD };
        } else {
          const selectedCells = apiRef.current.unstable_getSelectedCellsAsArray();
          if (!selectedCells.length) return;

          const firstSelectedRowID = selectedCells[0].id;
          targetCell = selectedCells.filter((c) => c.id === firstSelectedRowID).at(-1) as GridCellCoordinates;
        }

        if (targetCell) {
          const columnFields = apiRef.current.getAllColumns().map((c) => c.field);
          const colIdx = columnFields.findIndex((f) => f === targetCell?.field);
          let nextIdx = colIdx + 1;
          nextIdx = nextIdx > columnFields.length - 1 ? 1 : nextIdx;

          const nextCellField = columnFields[nextIdx];

          const model = { [targetCell.id]: { [nextCellField]: true } };
          apiRef.current.unstable_setCellSelectionModel(model);
          apiRef.current.setCellFocus(targetCell.id, nextCellField);
          apiRef.current.scrollToIndexes({ colIndex: nextIdx });
        }
      }
    };

    const handleInputMinus = (e: KeyboardEvent) => {
      const forbiddenSymbols = ['Minus', 'KeyE'];

      if (forbiddenSymbols.includes(e.code)) {
        const isCellEditing =
          apiRef.current.getCellMode(
            editCellCoord.current?.id as GridRowId,
            editCellCoord.current?.field as string
          ) === 'edit';
        const isNumberType = apiRef.current.getColumn(editCellCoord.current?.field as string)?.type === 'number';

        if (isCellEditing && isNumberType) {
          e.preventDefault();
        }
      }
    };

    const handleHotKeyDown = async (e: KeyboardEvent) => {
      if (!catalogId || !isEditingRef.current) return;

      if (e.code == 'KeyC' && (e.ctrlKey || e.metaKey)) {
        const cells = handleSaveSelectedCells();
        await copyCellsToClipboard(cells);
        setIsSelectionVisible(false);
      }

      if (e.code == 'KeyV' && (e.ctrlKey || e.metaKey)) {
        // trick for correct paste
        e.preventDefault();

        handleSaveSelectedCells();
        const cells = await getCellsFromClipboard();

        catalogId && dispatch(pasteCopiedCells({ catalogId, cells }));

        // trick for correct paste
        apiRef.current.setEditCellValue({
          id: editCellCoord.current?.id || '',
          field: editCellCoord.current?.field || '',
          value: cells[0].value?.toString(),
        });

        setIsSelectionVisible(false);
      }

      if (e.code == 'KeyZ' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        catalogId && dispatch(undoProductAction(catalogId));
        setIsSelectionVisible(false);
      }
    };

    return (
      <>
        <ContextMenuWrapper isSelectedFullRow={isSelectedFullRow} openEditMode={handleOpenCellEditMode}>
          <DataGridPremium
            apiRef={apiRef}
            initialState={{
              pinnedColumns: { left: [GRID_CHECKBOX_SELECTION_FIELD, PRODUCT_KEY.SKU, PRODUCT_KEY.DESC] },
            }}
            rowHeight={ROW_HEIGHT}
            columnHeaderHeight={35}
            hideFooter
            columns={columns}
            rows={items}
            unstable_cellSelection={true}
            checkboxSelection={true}
            rowSelection={true}
            disableRowSelectionOnClick
            experimentalFeatures={{ clipboardPaste: true }}
            disableClipboardPaste
            slotProps={{
              cell: {
                onContextMenu: handleSaveSelectedCells,
              },
              baseSelect: {
                MenuProps: {
                  id: 'singleSelectMenu',
                  PaperProps: {
                    sx: {
                      maxHeight: menuHeight,
                      top: `${menuPosition}px !important`,
                    },
                  },
                },
              },
            }}
            slots={{
              baseCheckbox: forwardRef((props, ref) => (
                <Checkbox
                  {...props}
                  ref={ref}
                  disableFocusRipple
                  disableTouchRipple
                  disableRipple
                  checkedIcon={<CatalogSelectionArrow sx={{ width: 10, color: 'darksome.main', ml: -1 }} />}
                  icon={<CatalogSelectionArrow sx={{ opacity: 0 }} />}
                />
              )),
              cell: (params: GridRenderCellParams) => {
                const { field, value } = params;

                const id = (params as unknown as { rowId: string }).rowId;
                const isChanged = changedItems[id] ? params.field in changedItems[id] : false;

                const { search, currentIdx, cells } = tableSearch;

                const isHightLight =
                  searchableColumns.includes(field) && search && !!getRegexpMatching(search, value ?? '');

                const highLightedTextParts: string[] | null = isHightLight
                  ? getDividedByRegexpText(search, value ?? '')
                  : null;
                const isHighlightedOnRed =
                  !!cells.length && cells[currentIdx].id === id && cells[currentIdx].field === params.field;

                return (
                  <RenderCell
                    {...params}
                    isChangedCell={isChanged}
                    highLightedTextParts={highLightedTextParts}
                    isHighlightedOnRed={isHighlightedOnRed}
                  />
                );
              },
            }}
            getRowClassName={(params) => {
              const id = params.id.toString();
              if (deletedItemIDs[id]) return 'deleted_row';
              if (newItemIDs[id]) return 'new_row';
              return '';
            }}
            getCellClassName={(params) => {
              const id = params.id.toString();
              const field = params.field;

              if (isDistributionCurveType && checkIsCurveCellDisabled(field)) {
                return isEditing
                  ? field === PRODUCT_KEY.DESC || field === PRODUCT_KEY.GROUP || field === PRODUCT_KEY.SUBTYPE
                    ? 'no_padding'
                    : 'grey_cell'
                  : '';
              }

              if (deletedItemIDs[id]) return '';

              const error = cellErrors?.[id]?.[field];
              if (error) return 'error_cell';

              return '';
            }}
            onCellEditStart={(params) => {
              const { id, field } = params;
              editCellCoord.current = { id, field };
              const model = { [id]: { [field]: true } };
              apiRef.current.unstable_setCellSelectionModel(model);
            }}
            onCellEditStop={(params) => {
              const id = params.id.toString();
              const { field, value, colDef } = params;

              editCellCoord.current = null;

              if (colDef.type === 'singleSelect') {
                const newValue = (apiRef.current.unstable_getEditCellMeta(id, field) as { value: string }).value;
                if (newValue === value) return;

                const nameCell: CellType = { id, field, value: newValue };
                const cells = [nameCell];

                cellErrors[id]?.[field] && catalogId && dispatch(removeCellError({ catalogId, cell: nameCell }));

                if (field === PRODUCT_KEY.GROUP) {
                  const group = catalogTypeGroups.find((g) => g.name === newValue);
                  const subtypeCell: CellType = {
                    id,
                    field: PRODUCT_KEY.SUBTYPE,
                    value: group?.subtype?.name ?? '',
                  };

                  cells.push(subtypeCell);
                }

                if (field === PRODUCT_KEY.SUBTYPE) {
                  const descriptionCell: CellType = {
                    id,
                    field: PRODUCT_KEY.DESC,
                    value: '',
                  };
                  const groupCell: CellType = {
                    id,
                    field: PRODUCT_KEY.GROUP,
                    value: '',
                  };

                  cells.push(descriptionCell, groupCell);
                }

                catalogId && dispatch(changeProductItemByArrayCell({ catalogId, cells }));
              }
            }}
            isCellEditable={(params) => {
              const id = params.id.toString();

              if (deletedItemIDs[id]) return false;
              const error = cellErrors?.[id]?.[params.field];
              if (error) return true;

              if (isDistributionCurveType && isEditing) {
                if (
                  newItemIDs[id] &&
                  (params.field === PRODUCT_KEY.DESC ||
                    params.field === PRODUCT_KEY.GROUP ||
                    params.field === PRODUCT_KEY.SUBTYPE)
                ) {
                  return true;
                }

                return !checkIsCurveCellDisabled(params.field);
              }

              return isEditing;
            }}
            unstable_onCellSelectionModelChange={() => {
              setIsSelectionVisible(true);
              setIsSelectedFullRow(false);

              const IDs = apiRef.current.getAllRowIds();
              apiRef.current.selectRows(IDs, false, false);

              const arrowCells = apiRef.current
                .unstable_getSelectedCellsAsArray()
                .filter((c) => c.field === GRID_CHECKBOX_SELECTION_FIELD);

              if (arrowCells.length) {
                const IDs = arrowCells.map((c) => c.id);
                apiRef.current.selectRows(IDs, true, false);
                setIsSelectedFullRow(true);
              }
            }}
            onRowSelectionModelChange={() => {
              const selectedRowIDs = [...apiRef.current.getSelectedRows().keys()];
              if (selectedRowIDs.length) {
                setIsSelectedFullRow(true);
              } else {
                setIsSelectedFullRow(false);
              }
            }}
            onCellClick={(params, e) => {
              const isCtrlPressed = e.ctrlKey;
              const isShiftPressed = e.shiftKey;

              const { field, id, colDef } = params;
              setIsSelectionVisible(true);
              if (isEditing) {
                editCellCoord.current = { id, field };
              }

              if (field === GRID_CHECKBOX_SELECTION_FIELD) {
                const selectedRowIDs = [...apiRef.current.getSelectedRows().keys()];
                apiRef.current.unstable_setCellSelectionModel({});

                if (isCtrlPressed) {
                  apiRef.current.selectRows([...selectedRowIDs, id], true, true);
                } else if (isShiftPressed) {
                  if (!selectedRowIDs.length) {
                    apiRef.current.selectRows([id], false, true);
                  } else {
                    const topRowId = selectedRowIDs[0];
                    const bottomRowId = selectedRowIDs[selectedRowIDs.length - 1];
                    const rowIDs = apiRef.current.getAllRowIds();

                    const topIdx = rowIDs.findIndex((rId) => rId === topRowId);
                    const bottomIdx = rowIDs.findIndex((rId) => rId === bottomRowId);
                    const selectedIdx = rowIDs.findIndex((rId) => rId === id);

                    let firstIdx: number, lastIdx: number;

                    if (selectedIdx <= topIdx) firstIdx = selectedIdx;
                    if (selectedIdx >= bottomIdx) lastIdx = selectedIdx;
                    if (selectedIdx > topIdx && selectedIdx < bottomIdx) {
                      (lastIdx = selectedIdx), (firstIdx = topIdx);
                    }

                    const needToSelectIDs = rowIDs.filter((_, i) => i >= firstIdx && i <= lastIdx);
                    apiRef.current.selectRows(needToSelectIDs, true, false);
                  }
                } else {
                  apiRef.current.selectRows([id], false, true);
                }
              } else {
                const isViewMode = apiRef.current.getCellMode(id, field) === 'view';
                isViewMode && handleOpenCellEditMode({ id: id as string, field, value: '' });
              }

              if (colDef.type === 'singleSelect') {
                const valueOptions = columns.find((column) => column.field === field)?.valueOptions;

                const options = Array.isArray(valueOptions) ? valueOptions : [];

                const MENU_ITEM_HEIGHT = 36;
                const PADDING_HEIGHT = options.length >= 5 ? 8 : 16;
                const VISIBLE_ITEMS_COUNT = options.length >= 5 ? 5 : options.length;

                const minRequiredMenuHeight = PADDING_HEIGHT + MENU_ITEM_HEIGHT * VISIBLE_ITEMS_COUNT;

                const rect = e.currentTarget.getBoundingClientRect();
                const cellTop = rect.top;
                const cellBottom = cellTop + 32;
                const pageHeight = window.innerHeight;

                if (pageHeight - cellBottom < minRequiredMenuHeight) {
                  setMenuPosition(cellTop - minRequiredMenuHeight - 4);
                  setMenuHeight(minRequiredMenuHeight);
                } else {
                  setMenuPosition(cellBottom);
                  setMenuHeight(`calc(100vh - ${cellBottom}px)`);
                }
              }
            }}
            onColumnWidthChange={(params: GridColumnResizeParams) => {
              const field = params.colDef.field;
              const width = +params.width.toFixed(0);

              const widths = localStorageService.catalogsColumnWidths;

              let currentTableWidths = widths?.[catalogId as string];

              if (currentTableWidths) {
                currentTableWidths[field] = width;
              } else {
                currentTableWidths = { [field]: width };
              }
              localStorageService.catalogsColumnWidths = {
                ...widths,
                [catalogId as string]: currentTableWidths,
              };
            }}
          />
        </ContextMenuWrapper>

        <TableArrowScrolling
          onScrollToLeft={() => handleHorizontalScroll('left')}
          onScrollToRight={() => handleHorizontalScroll('right')}
        />
      </>
    );
  }
);
