import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const SearchIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M10.9 17.3C14.4346 17.3 17.3 14.4346 17.3 10.9C17.3 7.36538 14.4346 4.5 10.9 4.5C7.36538 4.5 4.5 7.36538 4.5 10.9C4.5 14.4346 7.36538 17.3 10.9 17.3Z"
      stroke={props.stroke ?? '#4D4E4C'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M15.7 15.7002L20.5 20.5002"
      stroke={props.stroke ?? '#4D4E4C'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
