import { Box, Stack, Typography, styled } from '@mui/material';
import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { Button, HoverToolTip, Icon } from 'shared/ui';
import { toggleOrgDetails } from 'shared/slices';

const WhiteBox = styled(Box)(() => ({
  display: 'flex',
  width: '360px',
  height: '88px',
  padding: '24px',
  borderRadius: '8px',
  background: '#FFF',
  alignItems: 'center',
  gap: '12px',
  flexShrink: 0,
}));

const Text = styled(Typography)(() => ({
  fontFamily: 'Roboto',
  fontSize: '24px',
  lineHeight: '31.2px',
  letterSpacing: '0.24px',
}));

export const OrganizationInfo: FC = () => {
  const dispatch = useAppDispatch();

  const { selectedOrg } = useAppSelector((st) => st.orgs);
  const { permissions } = useAppSelector((st) => st.permissions);

  const isEditOrganizationAble =
    selectedOrg && Object.values(permissions[selectedOrg.id]?.grant_role ?? {}).some((r) => r);

  const onOpenOrgDetails = () => dispatch(toggleOrgDetails());

  return (
    <Box sx={{ px: '40px', pt: '40px' }}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <WhiteBox>
          <Icon
            isLogo
            path={selectedOrg?.logo ?? '/images/org-placeholder.svg'}
            alt="placeholder"
            sx={{ borderRadius: '6px', width: '48px', height: '48px' }}
          />
          <HoverToolTip title={selectedOrg?.organizationName}>
            <Text
              noWrap
              sx={{
                width: '250px',
                fontWeight: 600,
              }}
            >
              {selectedOrg?.organizationName}
            </Text>
          </HoverToolTip>
        </WhiteBox>

        {isEditOrganizationAble && (
          <WhiteBox>
            <Button
              onClick={onOpenOrgDetails}
              fullWidth
              sx={{
                display: 'flex',
                gap: '12px',
                border: 'none',
                bgcolor: 'info.main',
                color: 'darksome.main',
                '&:hover': { bgcolor: 'info.main' },
                px: 0,
              }}
            >
              <Icon path={'org-open-edit-green'} />

              <Text sx={{ fontSize: 18 }}>Edit Organization Details</Text>
            </Button>
          </WhiteBox>
        )}
      </Stack>
    </Box>
  );
};
