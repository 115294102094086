import React, { useLayoutEffect, useState } from 'react';
import { Badge, Box, Divider, IconButton, List, ListItemButton, Popover, Stack, Typography } from '@mui/material';
import { Icon, UserIcon } from 'shared/ui';
import { useAnchorElement, useAppDispatch, useAppNavigator } from 'shared/hooks';
import { Contrast, Logout } from '@mui/icons-material';
import {
  resetAuth,
  resetCatalogs,
  resetOrganizations,
  resetPermissions,
  resetTabs,
  resetUsers,
} from 'shared/slices';
import { useThemeProvider } from 'app/providers/MUIThemeProvider';

export const AccountInfo: React.FC = () => {
  const dispatch = useAppDispatch();
  const { navigateToLogin } = useAppNavigator();

  const [userInfo, setUserInfo] = useState({ email: '', userName: '' });

  const toggleColorMode = useThemeProvider();

  const { open, anchorEl, onOpenMenu, onCloseMenu } = useAnchorElement();

  useLayoutEffect(() => {
    const email = localStorage.getItem('email') ?? '';
    const userName = localStorage.getItem('name') ?? '';

    if (!email) {
      console.error('Email from LS not found.');
    }

    if (!userName) {
      console.error('Username from LS not found.');
    }

    setUserInfo({ email, userName });
  }, []);

  const handleLogout = () => {
    dispatch(resetAuth());
    dispatch(resetCatalogs());
    // dispatch(resetGroups());
    // dispatch(resetOpenedCatalogs());
    dispatch(resetOrganizations());
    dispatch(resetPermissions());
    dispatch(resetTabs());
    dispatch(resetUsers());
    localStorage.clear();
    navigateToLogin();
  };

  return (
    <Box>
      <Stack direction="row" alignItems="center" sx={{ height: '44px', alignItems: 'center', px: '10px' }}>
        <Icon path={'white-user_avatar'} />
        <Typography sx={{ fontSize: '14px', ml: '12px', color: 'info.main' }}>
          {userInfo.email ?? 'admin@admin.com'}
        </Typography>

        <Box sx={{ width: '30px' }}>
          <IconButton onClick={onOpenMenu}>
            <Icon path={'white-down-arrow'} />
          </IconButton>
        </Box>

        <Box sx={{ width: '1px', height: '26px', bgcolor: 'info.main', mx: '10px' }} />

        <Box sx={{ width: '30px' }}>
          <IconButton>
            <Badge color="secondary" anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
              <Icon path={'bell_notifications'} />
            </Badge>
          </IconButton>
        </Box>
      </Stack>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onCloseMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List disablePadding>
          <ListItemButton>
            <UserIcon sx={{ mr: '15px' }} />
            <Typography sx={{ color: 'secondary.light' }}>{userInfo.email ?? ''}</Typography>
          </ListItemButton>

          <ListItemButton onClick={toggleColorMode}>
            <Contrast sx={{ mr: '15px' }} />
            <Typography>Theme</Typography>
          </ListItemButton>
          <Divider />
          <ListItemButton onClick={handleLogout}>
            <Logout sx={{ mr: '15px' }} />
            <Typography> Log out</Typography>
          </ListItemButton>
        </List>
      </Popover>
    </Box>
  );
};
