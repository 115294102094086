import { getAllSubOrgIds } from './getAllSubOrgIds';
import { Organization } from '../../models';
import { flatOrgs } from './organizationHelpers';

export const getFatherOrganizations = (
  selectedOrg: Organization,
  organizations: Organization[]
): Organization[] => {
  const subOrgIds = getAllSubOrgIds([selectedOrg]);
  const flatOrgsArray = flatOrgs(organizations);

  return flatOrgsArray.filter((org) => org.id !== selectedOrg.id && !subOrgIds.includes(org.id));
};
