import { Box, Typography } from '@mui/material';
import ReactPhoneInput from 'react-phone-input-2';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  phoneErrorText: string;
  value: string;
  onChange: (phone: string) => void;
  onBlur: () => void;
  countryCode: string | undefined;
}

export const PhoneInput: FC<Props> = ({ countryCode, onChange, phoneErrorText, onBlur, value }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography
        noWrap
        sx={{
          color: '#242731',
          fontFamily: 'Roboto',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '130%',
          letterSpacing: '0.18px',
          marginBottom: '8px',
        }}
      >
        {t('Phone')}
      </Typography>
      <Box sx={{ position: 'relative' }}>
        <Box
          sx={{
            height: '44px',
            width: '1px',
            background: phoneErrorText ? 'rgba(236, 97, 97, 1)' : 'rgba(177, 177, 177, 1)',
            position: 'absolute',
            top: 0,
            left: 52,
            zIndex: 999,
          }}
        />
        <Box
          sx={{
            height: '44px',
            width: '1px',
            background: phoneErrorText ? 'rgba(236, 97, 97, 1)' : 'rgba(177, 177, 177, 1)',
            position: 'absolute',
            top: 0,
            left: 56,
            zIndex: 999,
          }}
        />
        <ReactPhoneInput
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          autoFormat={false}
          enableSearch
          searchPlaceholder={'Search'}
          country={countryCode}
          buttonStyle={{ borderRight: `5px #fff solid` }}
          placeholder={''}
          specialLabel={''}
          searchStyle={{
            width: '250px',
          }}
          inputStyle={{
            width: '100%',
            borderRadius: '8px',
            height: '44px',
            borderColor: phoneErrorText ? 'rgba(236, 97, 97, 1)' : 'rgba(177, 177, 177, 1)',
          }}
        />
        {!!phoneErrorText && (
          <Typography
            sx={{
              color: 'rgba(236, 97, 97, 1)',
              fontFamily: 'Myriad Pro',
              fontWeight: 400,
              fontSize: '0.75rem',
              lineHeight: 1,
              textAlign: 'left',
              marginTop: '3px',
              marginRight: '14px',
              marginBottom: 0,
              marginLeft: '14px',
            }}
          >
            {phoneErrorText}
          </Typography>
        )}
      </Box>
    </Box>
  );
};
