import { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { User } from 'shared/models';
import { Box, Button, DialogTitle, Stack, Typography, styled, useTheme } from '@mui/material';
import { DialogModal, Loader, SaveControl } from 'shared/ui';
import { HeadCell } from 'widgets/OrganizationsList/components/HeadCell';
import { useTranslation } from 'react-i18next';
import { STATUS } from 'shared/constants';
import { UserOrganizationRow } from './UserOrganizationRow';
import { selectAllRoles, selectPermissions, selectUsers, setUsers } from 'shared/slices';
import { UserService } from 'shared/services';

const TitleText = styled(Typography)(() => ({
  textAlign: 'center',
  color: '#242731',
  fontFamily: 'Roboto',
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '130%',
  letterSpacing: '0.24px',
}));

export type UserOrganizationRole = {
  roles: { id: string; status: number; name: string }[];
  organizationId: number;
  organizationName: string;
  userId: string;
  status: string;
};

interface Props {
  open: boolean;
  onClose: () => void;
  user: User;
}

export const UserOrganizationsModal: FC<Props> = ({ open, onClose, user }) => {
  const { name, userId } = user;
  const { palette } = useTheme();
  const dispatch = useAppDispatch();
  const users = useAppSelector(selectUsers);
  const { t } = useTranslation();
  const permissions = useAppSelector(selectPermissions);
  const allRoles = useAppSelector(selectAllRoles);

  const [isLoading, setIsLoading] = useState(true);
  const [userOrgs, setUserOrgs] = useState<UserOrganizationRole[]>([]);

  const [openDeleteUser, setOpenDeleteUser] = useState(false);

  const getUserOrgs = async () => {
    setIsLoading(true);

    const userOrgRoles = await UserService.getUserOrganizations(userId);
    if (userOrgRoles) {
      const organizationIds = [...new Set(userOrgRoles.map(({ organizationId }) => organizationId))];

      const userOrgs = organizationIds
        .map((orgId) => {
          const details = userOrgRoles.find((uor) => uor.organizationId === orgId);

          if (!details) return null;

          const entities = userOrgRoles.filter((uor) => uor.organizationId === orgId);

          const roles = entities.map((en) => ({
            id: en.role,
            status: en.status,
            name: allRoles.find((item) => item.id === en.role)?.name ?? 'NOT EXIST IN PERMIT IO',
          }));

          return {
            roles,
            status: !roles.length ? t('not_active') : user.status === STATUS.ACTIVE ? t('active') : t('pending'),
            organizationId: details.organizationId,
            organizationName: details.organizationName,
            userId: details.userId,
          };
        })
        .filter(
          (u): u is UserOrganizationRole => u !== null /* && u.roles.some((r) => r.status === STATUS.ACTIVE) */
        );

      setUserOrgs(userOrgs);
    }
    setIsLoading(false);
  };

  const handleDeleteFromUserOrgs = (organizationId: number) => {
    setUserOrgs((prev) => [...prev.filter((uor) => uor.organizationId !== organizationId)]);
  };

  const handleDeleteUser = async () => {
    if (!user.userId) return;

    setIsLoading(true);

    const isDeleted = await UserService.deleteUser(user.userId);
    if (isDeleted) {
      dispatch(setUsers(users.filter((u) => u.userId !== user.userId)));
      setOpenDeleteUser(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!userId || !open) return;

    getUserOrgs();
  }, [userId, open]);

  const isListPermissionAble = Object.values(permissions).some((p) => p.user && p.user.list);

  return (
    <DialogModal
      padding={'32px 0'}
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: '775px',
        },
      }}
    >
      <DialogTitle>
        <Stack direction="row" flexWrap={'wrap'} justifyContent="center" sx={{ mb: '22px' }}>
          <TitleText noWrap>{name}</TitleText>
          <TitleText noWrap sx={{ ml: 1 }}>{` organizations list`}</TitleText>
        </Stack>
      </DialogTitle>
      <Box sx={{ height: '400px', px: '20px' }}>
        {isLoading ? (
          <Box height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Loader />
          </Box>
        ) : (
          isListPermissionAble && (
            <>
              {userOrgs.length ? (
                <>
                  <Stack direction="row">
                    <Box sx={{ width: '20%' }}>
                      <HeadCell>{t('Org ID')}</HeadCell>
                    </Box>
                    <Box sx={{ width: '25%', minWidth: '100px' }}>
                      <HeadCell>{t('Org. Name')}</HeadCell>
                    </Box>
                    <Box sx={{ width: '30%' }}>
                      <HeadCell>{t('Permissions')}</HeadCell>
                    </Box>
                    <Box sx={{ width: '15%', minWidth: '100px' }}>
                      <HeadCell>{t('status')}</HeadCell>
                    </Box>
                    <Stack sx={{ width: '10%' }}>
                      <HeadCell>{t('actions')}</HeadCell>
                    </Stack>
                  </Stack>
                  <Box
                    sx={{
                      height: 'calc(100% - 60px)',
                      overflowX: 'hidden',
                      overflowY: 'auto',
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: palette.primary.main,
                        borderRadius: '4px',
                      },
                      '&::-webkit-scrollbar': {
                        width: '8px',
                        backgroundColor: palette.info.dark,
                        borderRadius: '4px',
                      },
                    }}
                  >
                    {userOrgs.map((uor) => (
                      <UserOrganizationRow
                        key={uor.organizationId}
                        uor={uor}
                        user={user}
                        handleDeleteFromUserOrgs={handleDeleteFromUserOrgs}
                        onCloseParentModal={onClose}
                      />
                    ))}
                  </Box>
                </>
              ) : (
                <Stack onClick={() => setOpenDeleteUser(true)} alignItems="center">
                  <Button
                    sx={{
                      borderRadius: '8px',
                      border: '1px solid #D4D4D4',
                      borderColor: '#D4D4D4',
                      background: '#D0D5DD',
                      padding: '8px 16px',
                      height: '40px',
                      width: '100%',
                      color: 'black',
                      fontWeight: 700,
                      '&:hover': {
                        background: '#A0A5AD',
                      },
                    }}
                  >
                    DELETE
                  </Button>
                </Stack>
              )}
            </>
          )
        )}
      </Box>

      <DialogModal open={openDeleteUser} onClose={() => setOpenDeleteUser(false)}>
        <Typography sx={{ textAlign: 'center', fontWeight: 600, fontSize: 18 }}>
          {t(`Are you sure you want to delete ${user.name} completely from the platform ?`)}
        </Typography>
        <Typography sx={{ textAlign: 'center', fontSize: 18 }}>{t('This action cannot be undone.')}</Typography>

        <Stack alignItems="center" sx={{ mt: '30px' }}>
          <SaveControl
            onSave={handleDeleteUser}
            onCancel={() => setOpenDeleteUser(false)}
            isLoading={isLoading}
            btnTexts={[t('Delete user')]}
          />
        </Stack>
      </DialogModal>
    </DialogModal>
  );
};
