import { Box, Stack, styled } from '@mui/material';
import { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { Icon } from 'shared/ui';
import { TabRoute } from './TabRoute';
import { closeCatalog, selectOpenedCatalogs, selectTabs, setTabs } from 'shared/slices';
import { AccountInfo } from './AccountInfo';

import { useParams } from 'react-router-dom';
import { useTabState } from './hooks';
import { localStorageService } from 'shared/services';
import { TabItem } from 'shared/models';

interface HomeProps {
  isHome?: boolean;
}

const HomeTab = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isHome',
})<HomeProps>(({ theme, isHome }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '55px',
  height: '100%',
  cursor: 'pointer',
  borderRight: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: isHome ? theme.palette.primary.main : theme.palette.primary.dark,
}));

export const TabBar: FC = () => {
  const dispatch = useAppDispatch();
  const { id: currentID } = useParams();
  const tabs = useAppSelector(selectTabs);
  const openedCatalogs = useAppSelector(selectOpenedCatalogs);

  const { onClickHomeTab, isTabRoute } = useTabState();

  useEffect(() => {
    const tabs = localStorageService.tabs;
    dispatch(setTabs(tabs));
  }, [currentID]);

  useEffect(() => {
    const catalogIDs = openedCatalogs.map((c) => c.catalogId);
    const tabIDs = tabs?.map((t) => t.id);
    catalogIDs.forEach((id) => {
      if (!tabIDs?.includes(id)) {
        dispatch(closeCatalog(id));
      }
    });
  }, [currentID]);

  return (
    <Stack direction="row" justifyContent="space-between" sx={{ bgcolor: 'primary.dark', height: '44px' }}>
      <HomeTab onClick={onClickHomeTab} isHome={!isTabRoute}>
        <Icon path="house" />
      </HomeTab>

      <Stack
        direction="row"
        sx={{
          width: '100%',
          overflowX: 'auto',
          overflowY: 'hidden',
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'info.main',
            border: `none`,
          },
          '&::-webkit-scrollbar': {
            height: '5px',
            backgroundColor: 'primary.dark',
          },
        }}
      >
        {tabs?.map((tab: TabItem) => <TabRoute key={tab.id} {...tab} />)}
      </Stack>

      <AccountInfo />
    </Stack>
  );
};
