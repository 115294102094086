import { Organization, Permissions } from 'shared/models';

export function mapPermissions(orgArray: Organization[]): Permissions {
  const result: Permissions = {};

  for (const org of orgArray) {
    // Map the current object's ID to its permissions
    result[org.id] = org.permissions;

    // Recursively process sub-organizations
    const subOrgPermissions = mapPermissions(org.subOrganizations);
    for (const key of Object.keys(subOrgPermissions)) {
      result[key] = subOrgPermissions[key];
    }
  }

  return result;
}
