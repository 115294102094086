import { IconButton, Stack, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { Icon } from 'shared/ui';
import { Close } from '@mui/icons-material';
import { useAppDispatch, useAppNavigator, useAppSelector } from 'shared/hooks';
import {
  closeCatalog,
  openCatalog,
  removeCatalogGroups,
  removeTab,
  selectOpenedCatalogs,
  setCurrentCatalog,
} from 'shared/slices';
import { TabItem } from 'shared/models';
import { ConfirmCloseCatalogModal } from 'features';
import { useParams } from 'react-router-dom';
import { CatalogService, localStorageService } from 'shared/services';

export const TabRoute: FC<TabItem> = ({ kind, title, id, route }) => {
  const { id: currentID } = useParams();
  const dispatch = useAppDispatch();
  const { navigate, navigateToCatalogs } = useAppNavigator();

  const openedCatalogs = useAppSelector(selectOpenedCatalogs);

  const [visible, setVisible] = useState(false);
  const onMouseEnter = () => setVisible(true);
  const onMouseLeave = () => setVisible(false);

  const [isConfirm, setIsConfirm] = useState(false);
  const onToggleConfirm = () => setIsConfirm((c) => !c);

  const handleOpenTab = async () => {
    // TODO: temporary fix, need to think about better solution. The problem is the catalogs is without groups inside store
    // dispatch(setCurrentCatalogById(id));
    const currentCatalog = await CatalogService.getCurrentCatalog(id);

    if (!currentCatalog) {
      console.log('No catalog found!');
      return;
    }

    dispatch(openCatalog(id));
    dispatch(setCurrentCatalog(currentCatalog));
    navigate(route);
  };

  const handleRemoveTabFromLS = (id: string) => {
    const tabs: TabItem[] = localStorageService.tabs;
    localStorageService.tabs = tabs.filter((t) => t.id !== id);

    const widths = localStorageService.catalogsColumnWidths;
    const currentTableWidths = widths?.[id as string];
    if (currentTableWidths) {
      delete widths[id as string];
      localStorageService.catalogsColumnWidths = widths;
    }
  };

  const handleDeleteTab = () => {
    if (id === currentID) {
      navigateToCatalogs();
    }
    dispatch(removeTab(id));
    dispatch(closeCatalog(id));
    dispatch(removeCatalogGroups(id));
    handleRemoveTabFromLS(id);
  };

  const onClickClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    const catalog = openedCatalogs.find((c) => c.catalogId === id);

    if (catalog) {
      if (catalog.actionHistory.length > 0) {
        onToggleConfirm();
        return;
      }
    }
    handleDeleteTab();
  };

  return (
    <>
      <Stack
        direction="row"
        sx={{
          bgcolor: id === currentID ? 'primary.main' : 'primary.dark',
          borderRight: '1px solid white',
          borderColor: 'primary.main',
          alignItems: 'center',
          py: '15px',
          pl: '20px',
          pr: '28px',
          position: 'relative',
          // opacity: router.route === route ? 1 : 0.5,
          width: '170px',
          cursor: 'pointer',
        }}
        onClick={handleOpenTab}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Stack sx={{ width: '22px' }}>
          <Icon path={kind === 'catalog' ? 'menuIcons/products' : 'menuIcons/design'} />
        </Stack>
        <Typography noWrap sx={{ fontSize: '14px', lineHeight: '16px', ml: '7px', color: 'info.main' }}>
          {title}
        </Typography>
        {visible && (
          <IconButton onClick={onClickClose} sx={{ position: 'absolute', right: '0' }}>
            <Close color="info" sx={{ fontSize: '14px' }} />
          </IconButton>
        )}
      </Stack>

      <ConfirmCloseCatalogModal open={isConfirm} onClose={onToggleConfirm} onConfirm={handleDeleteTab} />
    </>
  );
};
