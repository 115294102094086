import { Tabs } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { USER_CATALOG_SHARING_TYPE } from 'shared/constants';
import { TAB } from 'shared/ui';

const tabs = [USER_CATALOG_SHARING_TYPE.ALL, USER_CATALOG_SHARING_TYPE.MY, USER_CATALOG_SHARING_TYPE.SHARED];

interface Props {
  filter: USER_CATALOG_SHARING_TYPE;
  onChange: (tab: USER_CATALOG_SHARING_TYPE) => void;
}

export const CatalogSharingTypeFilter: FC<Props> = ({ filter, onChange }) => {
  const { t } = useTranslation();

  const handleChangeTab = (_: React.SyntheticEvent, tab: USER_CATALOG_SHARING_TYPE) => onChange(tab);

  return (
    <Tabs value={filter} onChange={handleChangeTab} sx={{ minHeight: '0px', mb: '3px', mr: 3 }}>
      {tabs.map((value) => (
        <TAB
          key={value}
          label={t(value)}
          value={value}
          sx={{ p: '5px 10px', minWidth: '30px', fontSize: 18, fontWeight: 500 }}
        />
      ))}
    </Tabs>
  );
};
