import React from 'react';
import ReactDOM from 'react-dom/client';
import { LicenseInfo } from '@mui/x-license-pro';
import App from 'app';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './shared/assets/fonts/fonts.css';
import './shared/i18n';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-input-2/lib/material.css';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

serviceWorkerRegistration.register();
