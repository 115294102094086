import { combineSlices, configureStore } from '@reduxjs/toolkit';
import {
  authSlice,
  catalogLanguagesSlice,
  catalogsSlice,
  modalSlice,
  openedCatalogsSlice,
  orgDataSlice,
  permissionsSlice,
  tabSlice,
  usersSlice,
} from 'shared/slices';

const rootReducer = combineSlices(
  authSlice,
  tabSlice,
  orgDataSlice,
  permissionsSlice,
  catalogLanguagesSlice,
  modalSlice,
  usersSlice,
  catalogsSlice,
  openedCatalogsSlice
);

export const makeStore = () =>
  configureStore({
    reducer: rootReducer,
  });

const store = makeStore();

type Store = ReturnType<typeof makeStore>;

export type AppDispatch = Store['dispatch'];
export type RootState = ReturnType<Store['getState']>;

export default store;
