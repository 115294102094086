import { axiosBase } from 'shared/axios';
import { requestWrapper } from 'shared/lib';
import { AvailabilityItem, BaseResponse } from 'shared/models';

export class AvailabilityService {
  static async getAvailabilities(catalogId: string) {
    const { data } = await requestWrapper(axiosBase.get<BaseResponse<AvailabilityItem[]>>(`Availability`));
    if (data) {
      const availabilities: AvailabilityItem[] = data.filter(
        (a) => a.sourceAvailability.sourceCatalogId === catalogId
      );
      return availabilities;
    }
  }

  static async getOrgAvailability(catalogId: string, orgId: string) {
    const { data } = await requestWrapper(
      axiosBase.get<BaseResponse<AvailabilityItem>>(`Availability/${catalogId}/${orgId}`)
    );

    return data;
  }

  static async createAvailability(
    orgId: string,
    catalogId: string,
    whiteList: { groupId: string; componentsId: string[] }[]
  ) {
    const body = { orgId, catalogId, whiteList };
    const { data } = await requestWrapper(axiosBase.post<BaseResponse<{ id: string }>>(`Availability`, body));
    if (!data) return null;

    const { data: availability } = await requestWrapper(
      axiosBase.get<BaseResponse<AvailabilityItem>>(`Availability/${data.id}`)
    );
    return availability;
  }

  static async changeAvailability(id: string, whiteList: { groupId: string; componentsId: string[] }[]) {
    const { error } = await requestWrapper(axiosBase.put(`Availability/${id}`, whiteList));
    if (error) return null;

    const { data } = await requestWrapper(axiosBase.get<BaseResponse<AvailabilityItem>>(`Availability/${id}`));
    return data;
  }

  static async deleteAvailability(id: string) {
    const { error } = await requestWrapper(axiosBase.delete(`/Availability/${id}`));
    if (!error) return true;
  }
}
