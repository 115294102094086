import React, { FC } from 'react';
import { IconButton, InputAdornment, TextField, TextFieldProps, useTheme } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

type Props = TextFieldProps & {
  password?: boolean;
};

export const autoFillColor = {
  '&:-webkit-autofill': {
    WebkitBoxShadow: '0 0 0 30px white inset !important',
    WebkitTextFillColor: 'black !important',
  },
  '&:-webkit-autofill:hover': {
    WebkitBoxShadow: '0 0 0 30px white inset !important',
    WebkitTextFillColor: 'black !important',
  },
  '&:-webkit-autofill:focus': {
    WebkitBoxShadow: '0 0 0 30px white inset !important',
    WebkitTextFillColor: 'black !important',
  },
  '&:-webkit-autofill:active': {
    WebkitBoxShadow: '0 0 0 30px white inset !important',
    WebkitTextFillColor: 'black !important',
  },
};

export const AuthInput: FC<Props> = ({ password, disabled, error, helperText, ...props }) => {
  const theme = useTheme();
  const [isShow, setIsShow] = React.useState(false);

  const handleShow = () => setIsShow(!isShow);

  return (
    <TextField
      {...props}
      disabled={disabled}
      type={password ? (isShow ? 'text' : 'password') : 'text'}
      error={error}
      helperText={error ? helperText : ''}
      sx={{
        width: '100%',
        height: '48px',
        fontFamily: 'Myriad Pro',
        '& .MuiInputBase-root': {
          borderRadius: '6px',
          border: '1px solid white',
          borderColor: error ? 'red' : 'info.dark',
          px: '10px',
          bgcolor: disabled ? 'info.light' : 'info.main',
        },
        '& .MuiFormHelperText-root': {
          lineHeight: 1,
        },
        '& .Mui-focused': {
          borderWidth: '1px !important',
        },
        '& .Mui-disabled': {
          WebkitTextFillColor: `${theme.palette.info.dark} !important`,
        },
        input: {
          ...autoFillColor,
          color: disabled ? 'info.dark' : 'black',
          fontFamily: 'Myriad Pro',
          fontSize: '14px',
          lineHeight: '20px',
          p: 0,
          height: '48px',

          '&::placeholder': {
            opacity: 1,
            color: 'info.dark',
            lineHeight: '16px',
            fontFamily: 'Myriad Pro',
          },
        },
        fieldset: {
          border: 'none !important',
        },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {password && (
              <IconButton onClick={handleShow}>
                {isShow ? (
                  <Visibility sx={{ color: 'darksome.main', fontSize: '18px' }} />
                ) : (
                  <VisibilityOff sx={{ color: 'darksome.main', fontSize: '18px' }} />
                )}
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};
