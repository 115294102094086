import { AuthLayout } from 'shared/ui';
import { Activation } from 'widgets/Activation';

export const ActivationPage = () => {
  return (
    <AuthLayout>
      <Activation />;
    </AuthLayout>
  );
};
