import { ERROR_CANCELED } from '../../constants';
import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { ApiError, BaseResponse } from '../../models';

const canceledMessage = 'canceled';

export const handleError = (err: unknown, callback?: (message: string) => void) => {
  console.error('####: error', err);

  let errorMessage = 'Some error occurred';

  if (axios.isAxiosError(err)) {
    const error = err as ApiError;

    if (callback && error.response?.data.error.message) {
      callback(error.response?.data.error.message);
      return;
    }

    errorMessage = error.response?.data.error?.message || error?.message || errorMessage;
  } else if (err instanceof Error) {
    errorMessage = err.message;
  } else {
    errorMessage = JSON.stringify(err);
  }

  if (errorMessage === ERROR_CANCELED || errorMessage === canceledMessage) return;

  toast.error(errorMessage);
};

export const requestWrapper = <T>(promise: Promise<AxiosResponse<BaseResponse<T>>>) =>
  promise
    .then(({ data: { data } }) => ({ data, error: null }))
    .catch((error) => {
      handleError(error);
      return { error, data: null };
    });
