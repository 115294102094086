import { useEffect, useState } from 'react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { Button } from 'shared/ui';
import { useSearchParams } from 'react-router-dom';
import { useAppNavigator } from 'shared/hooks';
import { UserService } from 'shared/services';

export const Activation = () => {
  const [isActivate, setIsActivate] = useState(false);
  const [counter, setCounter] = useState(5);
  const [searchParams] = useSearchParams();
  const { userId } = Object.fromEntries([...searchParams]);

  const { navigateToLogin } = useAppNavigator();

  const handleActivate = async () => {
    const isActivated = await UserService.activateUser(userId);
    if (isActivated) {
      setIsActivate(true);
      setInterval(() => setCounter((counter) => counter - 1), 1000);
      setTimeout(navigateToLogin, 4000);
    }
  };

  useEffect(() => {
    if (!userId) return;
    handleActivate();
  }, [userId]);

  return (
    <>
      {isActivate ? (
        <Box>
          <Typography
            sx={{
              color: 'primary.main',
              fontSize: '20px',
              fontWeight: 600,
              mb: '15px',
              textAlign: 'center',
            }}
          >
            Your mail was activated
          </Typography>
          <Typography
            sx={{
              color: 'primary.main',
              fontSize: '20px',
              fontWeight: 600,
              mb: '15px',
              textAlign: 'center',
            }}
          >
            In {counter} seconds you will redirect to Login
          </Typography>
          <Stack alignItems="center">
            <CircularProgress size={30} />
          </Stack>

          <Box sx={{ height: '40px', my: '23px' }}>
            <Button filled fullWidth onClick={navigateToLogin}>
              <Typography sx={{ fontWeight: 700 }}>Redirect</Typography>
            </Button>
          </Box>
        </Box>
      ) : (
        <Stack alignItems="center">
          <CircularProgress size={60} />
        </Stack>
      )}
    </>
  );
};
