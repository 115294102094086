import { Box, Stack } from '@mui/material';
import { FC, PropsWithChildren, ReactNode } from 'react';

interface Props extends PropsWithChildren {
  tabBar: ReactNode;
  sideMenu?: ReactNode;
}

export const BaseLayout: FC<Props> = ({ children, sideMenu, tabBar }) => {
  return (
    <Box sx={{ height: '100vh', width: '100%', maxWidth: '100vw', maxHeight: '100vh', overflow: 'hidden' }}>
      {tabBar}
      <Stack direction="row" sx={{ display: 'flex', height: 'calc(100% - 44px)' }}>
        {sideMenu}
        <Stack
          sx={{
            height: '100%',
            bgcolor: 'secondary.contrastText',
            flexGrow: 1,
            overflow: 'hidden',
          }}
        >
          {children}
        </Stack>
      </Stack>
    </Box>
  );
};
