import React from 'react';
import { IconButton, InputAdornment, InputProps, TextField, useTheme } from '@mui/material';
import { Icon } from '../Icon';
import CloseIcon from '@mui/icons-material/Close';

interface Props extends InputProps {
  icon?: string;
  onClearClick: () => void;
}

export const SearchInput: React.FC<Props> = ({ name, type, icon, value, onChange, onClearClick, sx }) => {
  const { palette } = useTheme();

  return (
    <TextField
      value={value}
      onChange={onChange}
      color={'info'}
      placeholder={name ? `${name} ` : ''}
      type={type}
      sx={{
        display: 'flex',
        height: '41px',
        flexGrow: 1,
        fontFamily: 'Myriad Pro',
        '& .MuiInputBase-root': {
          borderRadius: '10px',
          border: `1px solid ${palette.secondary.light}`,
          bgcolor: 'info.main',
        },
        '&  .Mui-focused': {
          borderWidth: '1px !important',
        },
        input: {
          color: 'black',
          fontFamily: 'Myriad Pro',
          fontSize: '18px',
          lineHeight: '16px',

          py: 0,
          height: '41px',
          '&::placeholder': {
            opacity: 1,
            color: 'info.dark',

            lineHeight: '16px',
            fontFamily: 'Myriad Pro',
          },
        },
        fieldset: {
          border: 'none !important',
        },
        ...sx,
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon path={icon ?? ''} />
          </InputAdornment>
        ),
        endAdornment: value ? (
          <>
            <InputAdornment position="start">
              <IconButton size={'small'} onClick={onClearClick}>
                <CloseIcon sx={{ color: 'secondary.light' }} />
              </IconButton>
            </InputAdornment>
          </>
        ) : null,
      }}
    />
  );
};
