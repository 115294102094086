export function separateByUpperCase(inputString: string) {
  const regex = /[A-Z]/g;

  const resultArray = inputString
    .split('')
    .map((s) => (regex.test(s) ? ` ` + s : s))
    .join('');

  return resultArray;
}
