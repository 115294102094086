import { FC } from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import ReactFlagsSelect from 'react-flags-select';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  country: string;
  setCountry: (code: string) => void;
  variant?: 'outlined' | 'underlined' | 'noSelectable';
  disabled?: boolean;
  label?: string;
  placeholder?: string;
}

export const CountrySelect: FC<Props> = ({ country, setCountry, variant, disabled, label, placeholder }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const OutlinedStyles = {
    button: {
      color: theme.palette.secondary.light,
      borderColor: theme.palette.info.dark,
      borderRadius: '10px',
      height: '44px',
      margin: 0,
      padding: '0 8px 0 8px',

      span: {
        fontSize: '18px',
        'svg ': {
          width: '24px',
          height: '18px',
          fill: 'red',
        },
      },

      '&::after,  &[aria-expanded="true"]::after ': {
        content:
          theme.palette.mode === 'dark'
            ? `url('/images/white-down-arrow.svg')`
            : `url('/images/black-down-arrow.svg')`,
        border: 'none',
        margin: '15px 20px 35px 0',
      },
      '&[aria-expanded="true"]::after': {
        transform: 'rotate(180deg)',
        margin: '35px 5px 15px 0px',
      },
    },
    ul: {
      color: theme.palette.secondary.light,
      backgroundColor: theme.palette.secondary.dark,
      borderRadius: '8px',
      border: 'none',
      boxShadow: '0px 0px 5px rgb(0 0 0 / 25%)',

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.primary.main,
        border: `3px solid ${theme.palette.secondary.dark}`,
        borderRadius: '30px',
      },
      '&::-webkit-scrollbar': {
        width: '15px',
      },
      'li:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
      div: {
        backgroundColor: theme.palette.secondary.dark,
        input: {
          backgroundColor: theme.palette.secondary.dark,
          color: theme.palette.secondary.light,
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '4px',
          marginRight: '2px',
          width: 'calc(100% - 12px)',
        },
      },
    },
  };
  const UnderlinedStyles = {
    button: {
      color: 'secondary.light',
      pb: '12px',
      fontSize: '18px',
      lineHeight: '22px',
      fontFamily: 'Myriad Pro',
      px: '0px',
      border: 'none',
      borderBottom: '1px solid white',
      borderColor: 'info.dark',
      borderRadius: 0,

      span: {
        fontSize: '18px',
        m: 0,
        px: '10px',
        ml: '-10px',
        'svg ': {
          width: '34px',
          height: '28px',
        },
      },

      '&:disabled': {
        backgroundColor: 'secondary.contrastText',
      },
      '&::after,  &[aria-expanded="true"]::after ': {
        content: `url('/images/down-arrow.svg')`,
        border: 'none',
        margin: '15px 20px 35px 0',
        display: 'none',
      },
      '&[aria-expanded="true"]::after': {
        transform: 'rotate(180deg)',
        margin: '35px 5px 15px 0px',
        display: 'none',
      },
    },
    ul: {
      color: 'secondary.light',
      backgroundColor: 'secondary.dark',
      borderRadius: '8px',
      border: 'none',
      boxShadow: '0px 0px 5px rgb(0 0 0 / 25%)',

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'primary.main',
        border: `3px solid white`,
        borderColor: `secondary.dark`,
        borderRadius: '30px',
      },
      '&::-webkit-scrollbar': {
        width: '15px',
      },
      'li:hover': {
        backgroundColor: 'secondary.main',
      },
      div: {
        backgroundColor: 'secondary.dark',
        input: {
          backgroundColor: 'secondary.dark',
          color: 'secondary.light',
          borderColor: 'primary.main',
          borderWidth: 'thin',
          borderRadius: '4px',
          marginRight: '2px',
          width: 'calc(100% - 12px)',
        },
      },
    },
  };
  const noSelectableStyles = {
    button: {
      color: 'grey',
      fontSize: '18px',
      lineHeight: '22px',
      fontFamily: 'Myriad Pro',
      px: '0px',
      border: 'none',
      borderColor: 'info.dark',
      borderRadius: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',

      span: {
        fontSize: '18px',
        m: 0,
        px: '10px',
        ml: '-10px',
        'svg ': {
          width: '34px',
          height: '28px',
        },
      },

      '&:disabled': {
        backgroundColor: 'transparent',
      },
      '&::after,  &[aria-expanded="true"]::after ': {
        content: `url('/images/down-arrow.svg')`,
        border: 'none',
        margin: '15px 20px 35px 0',
        display: 'none',
      },
      '&[aria-expanded="true"]::after': {
        transform: 'rotate(180deg)',
        margin: '35px 5px 15px 0px',
        display: 'none',
      },
    },
    ul: {
      color: 'secondary.light',
      backgroundColor: 'secondary.dark',
      borderRadius: '8px',
      border: 'none',
      boxShadow: '0px 0px 5px rgb(0 0 0 / 25%)',

      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'primary.main',
        border: `3px solid white`,
        borderColor: `secondary.dark`,
        borderRadius: '30px',
      },
      '&::-webkit-scrollbar': {
        width: '15px',
      },
      'li:hover': {
        backgroundColor: 'secondary.main',
      },
      div: {
        backgroundColor: 'secondary.dark',
        input: {
          backgroundColor: 'secondary.dark',
          color: 'secondary.light',
          borderColor: 'primary.main',
          borderWidth: 'thin',
          borderRadius: '4px',
          marginRight: '2px',
          width: 'calc(100% - 12px)',
        },
      },
    },
  };

  const onClearClick = () => {
    setCountry('');
  };

  return (
    <Box
      sx={{
        width: '100%',
        '.country-select': {
          ...(variant === 'outlined' && OutlinedStyles),
          ...(variant === 'underlined' && UnderlinedStyles),
          ...(variant === 'noSelectable' && noSelectableStyles),
        },
      }}
    >
      {label && (
        <Typography
          noWrap
          sx={{
            color: '#242731',
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '130%',
            letterSpacing: '0.18px',
            marginBottom: '8px',
          }}
        >
          {label}
        </Typography>
      )}
      <Box sx={{ position: 'relative' }}>
        {!disabled && country && (
          <IconButton
            size={'small'}
            onClick={onClearClick}
            sx={{ position: 'absolute', top: '6px', right: '35px', zIndex: 10 }}
          >
            <CloseIcon sx={{ color: 'secondary.light' }} />
          </IconButton>
        )}
        <ReactFlagsSelect
          selected={country}
          onSelect={setCountry}
          disabled={disabled}
          searchable
          className="country-select"
          placeholder={placeholder ?? t('search_a_country')}
          searchPlaceholder={t('search')}
        />
      </Box>
    </Box>
  );
};
