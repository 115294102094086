import { User } from '../../shared/models';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppType } from '../../shared/enum';
import { IconButton, List, ListItemButton, ListItemIcon, Popover, Stack, Typography } from '@mui/material';
import { STATUS } from '../../shared/constants';
import { DotIcon } from '../../shared/ui/icons/DotIcon';
import { DialogModal, Icon, SaveControl } from '../../shared/ui';
import { styled } from '@mui/material/styles';
import { UserOrganizationRole } from './index';
import { useAppDispatch, useAppNavigator, useAppSelector } from '../../shared/hooks';
import { setSelectedOrg, setSelectedUser, setUsers, toggleUserDetails } from '../../shared/slices';
import { UserService, localStorageService } from 'shared/services';

interface Props {
  uor: UserOrganizationRole;
  user: User;
  handleDeleteFromUserOrgs: (organizationId: number) => void;
  onCloseParentModal?: () => void;
}

const ListButton = styled(ListItemButton)(({ theme }) => ({
  backgroundColor: theme.palette.info.dark,
  height: 40,
  maxHeight: 40,
  border: 'unset',
}));

const Cell = styled(Stack)(({ theme }) => ({
  paddingLeft: '30px',
  paddingBlock: '20px',
  fontSize: '18px',
  lineHeight: '16px',
  fontWeight: 600,
  border: 'none',
  color: theme.palette.darksome.main,
  userSelect: 'none',
}));

export const UserOrganizationRow: FC<Props> = ({ user, uor, handleDeleteFromUserOrgs, onCloseParentModal }) => {
  const dispatch = useAppDispatch();
  const { users } = useAppSelector((state) => state.users);
  const { flattedOrgs } = useAppSelector((state) => state.orgs);
  const { t } = useTranslation();
  const { navigateToCurrentOrganization } = useAppNavigator();

  const [actionMenuOpen, setActionMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openRemoveUserFromOrg, setOpenRemoveUserFromOrg] = useState(false);
  const [openDeleteUser, setOpenDeleteUser] = useState(false);
  const [isSentInvitation, setIsSentInvitation] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const disableDeleting = user.userId === localStorageService.userId;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionMenuOpen((prev) => !prev);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setActionMenuOpen((prev) => !prev);
  };

  const handleResendInvitationEmail = async () => {
    if (!user.userId) return;

    setIsLoading(true);

    const isResent = await UserService.resendInvitationEmail(user.userId, AppType.Platform);
    setIsSentInvitation(!!isResent);
    setIsLoading(false);
  };

  const handleRemoveUserFromOrg = async () => {
    if (!uor.organizationId) return;

    const body = {
      id: user.userId,
      organizationId: uor.organizationId,
    };

    setIsLoading(true);

    const isDeleted = await UserService.deactivateUserFromOrg(body);
    if (isDeleted) {
      handleDeleteFromUserOrgs(uor.organizationId);
      setOpenRemoveUserFromOrg(false);
      onCloseParentModal?.();
    }
    setIsLoading(false);
  };

  const handleDeleteUser = async () => {
    if (!user.userId) return;

    setIsLoading(true);

    const isDeleted = await UserService.deleteUser(user.userId);
    if (isDeleted) {
      dispatch(setUsers(users.filter((u) => u.userId !== user.userId)));

      setOpenDeleteUser(false);
      onCloseParentModal?.();
    }
    setIsLoading(false);
  };

  const handleOpenUserDetails = () => {
    const org = flattedOrgs.find((o) => o.id === uor.organizationId);
    org && dispatch(setSelectedOrg(org));
    dispatch(setSelectedUser(user));

    setTimeout(() => dispatch(toggleUserDetails()), 0);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        position: 'relative',
        bgcolor: anchorEl ? 'rgba(219, 236, 225, 1)' : 'info.main',
      }}
    >
      <Cell sx={{ width: '20%', pl: 0 }}>
        <Typography noWrap sx={{ fontSize: '18px', lineHeight: '16px', fontWeight: 600 }}>
          {uor.organizationId}
        </Typography>
      </Cell>
      <Cell
        onDoubleClick={() => navigateToCurrentOrganization(uor.organizationId)}
        sx={{ width: '25%', pl: 0, cursor: 'pointer' }}
      >
        <Typography noWrap sx={{ fontSize: '18px', lineHeight: '16px', fontWeight: 600 }}>
          {uor.organizationName}
        </Typography>
      </Cell>

      <Cell onDoubleClick={handleOpenUserDetails} sx={{ width: '30%', pl: 0, cursor: 'pointer' }}>
        {uor.roles
          .filter((r) => r.status === STATUS.ACTIVE)
          .map((item) => item.name)
          .join(', ')}
      </Cell>
      <Cell sx={{ width: '15%', pl: 0 }}>{uor.status}</Cell>
      <Cell sx={{ width: '10%', p: 0, pl: 1 }}>
        <Stack direction="row">
          {!disableDeleting && (
            <IconButton onClick={handleClick}>
              <DotIcon
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill={actionMenuOpen ? '#B8B8B8' : '#242731'}
                sx={{
                  ...(actionMenuOpen && { transform: 'rotate(90deg)', transition: 'transform 0.5s ease-in-out;' }),
                }}
              />
            </IconButton>
          )}

          <Popover
            slotProps={{ paper: { sx: { borderRadius: '8px' } } }}
            open={actionMenuOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
            <List sx={{ padding: 0, height: 'auto', width: 190 }}>
              {user.status !== STATUS.ACTIVE && (
                <ListButton
                  onClick={handleResendInvitationEmail}
                  sx={{
                    borderRadius: '8px',
                    borderBottomRightRadius: '0px',
                    borderBottomLeftRadius: '0px',
                    border: '1px solid #D4D4D4',
                    borderColor: '#D4D4D4',
                    background: '#D0D5DD',
                    padding: '8px 16px',
                    height: '40px',
                    width: '100%',
                    '&:hover': { background: '#A0A5AD' },
                  }}
                >
                  <ListItemIcon sx={{ minWidth: 'unset', mr: 1 }}>
                    <Icon
                      path={`products-view/${isSentInvitation ? 'menu-email-already-sent' : 'menu-resend-email'}`}
                    />
                  </ListItemIcon>
                  <Typography noWrap sx={{ color: 'darksome.main' }}>
                    {isSentInvitation ? t('Invitation Sent') : t('Resend invitation')}
                  </Typography>
                </ListButton>
              )}

              <ListButton
                onClick={() => setOpenRemoveUserFromOrg(true)}
                sx={{
                  border: '1px solid #D4D4D4',
                  borderColor: '#D4D4D4',
                  background: '#D0D5DD',
                  padding: '8px 16px',
                  height: '40px',
                  width: '100%',
                  '&:hover': {
                    background: '#A0A5AD',
                  },
                }}
              >
                <ListItemIcon sx={{ minWidth: 'unset', mr: 1 }}>
                  <Icon path="products-view/menu-remove-user" />
                </ListItemIcon>
                <Typography noWrap sx={{ color: 'darksome.main' }}>
                  Remove user
                </Typography>
              </ListButton>

              <ListButton
                onClick={() => setOpenDeleteUser(true)}
                sx={{
                  borderRadius: '8px',
                  borderTopRightRadius: '0px',
                  borderTopLeftRadius: '0px',
                  border: '1px solid #D4D4D4',
                  borderColor: '#D4D4D4',
                  background: '#D0D5DD',
                  padding: '8px 16px',
                  height: '40px',
                  width: '100%',
                  '&:hover': {
                    background: '#A0A5AD',
                  },
                }}
              >
                <ListItemIcon sx={{ minWidth: 'unset', mr: 1 }}>
                  <Icon path="products-view/menu-delete-user" />
                </ListItemIcon>
                <Typography noWrap sx={{ color: 'darksome.main' }}>
                  Delete user
                </Typography>
              </ListButton>
            </List>
          </Popover>
        </Stack>
      </Cell>

      <DialogModal open={openRemoveUserFromOrg} onClose={() => setOpenRemoveUserFromOrg(false)}>
        <Typography sx={{ textAlign: 'center', fontWeight: 600, fontSize: 18 }}>
          {t(`Are you sure you want to remove ${user.name} from ${uor.organizationName}?`)}
        </Typography>
        <Typography sx={{ textAlign: 'center', fontSize: 18 }}>{t('This action cannot be undone.')}</Typography>

        <Stack alignItems="center" sx={{ mt: '30px' }}>
          <SaveControl
            onSave={handleRemoveUserFromOrg}
            onCancel={() => setOpenRemoveUserFromOrg(false)}
            isLoading={isLoading}
            btnTexts={[t('Remove user')]}
          />
        </Stack>
      </DialogModal>

      <DialogModal open={openDeleteUser} onClose={() => setOpenDeleteUser(false)}>
        <Typography sx={{ textAlign: 'center', fontWeight: 600, fontSize: 18 }}>
          {t(`Are you sure you want to delete ${user.name} completely from the platform ?`)}
        </Typography>
        <Typography sx={{ textAlign: 'center', fontSize: 18 }}>{t('This action cannot be undone.')}</Typography>

        <Stack alignItems="center" sx={{ mt: '30px' }}>
          <SaveControl
            onSave={handleDeleteUser}
            onCancel={() => setOpenDeleteUser(false)}
            isLoading={isLoading}
            btnTexts={[t('Delete user')]}
          />
        </Stack>
      </DialogModal>
    </Stack>
  );
};
