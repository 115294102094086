import React, { PropsWithChildren } from 'react';
import { Stack } from '@mui/material';

export const HeadPanel: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Stack
      direction="row"
      sx={{
        width: '100%',
        height: '71px',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px 33px',
        borderBottom: '1px solid white',
        borderColor: 'info.dark',
      }}
    >
      {children}
    </Stack>
  );
};
