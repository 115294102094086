import { Organization } from '../../models';

export const getDefaultFatherOrgId = (
  selectedOrg: Organization | null,
  filteredOrganizations: Organization[]
): number | string => {
  const isOrganizationExist =
    selectedOrg && !!filteredOrganizations.find((item) => item.id === selectedOrg.fatherOrganizationId);

  return isOrganizationExist ? selectedOrg.fatherOrganizationId : '';
};
