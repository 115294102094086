import React, { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { DialogModal, Icon, Input, SaveControl } from 'shared/ui';
import { useRenameCatalogValues } from './hooks';
import { useTranslation } from 'react-i18next';
import { NewCatalogIcon } from '../../shared/ui/icons/NewCatalogIcon';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { selectFlattedOrg, selectUsers, updateCatalog } from 'shared/slices';
import { Catalog } from 'shared/models';
import { CATALOG_OWNER_TYPE } from 'shared/constants';
import { CatalogService } from 'shared/services';

interface Props {
  open: boolean;
  onClose: () => void;
  catalog: Catalog;
}

export const CatalogRenameModal: FC<Props> = ({ open, onClose, catalog }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const flattedOrgs = useAppSelector(selectFlattedOrg);
  const users = useAppSelector(selectUsers);

  const [isLoading, setIsLoading] = React.useState(false);

  const { newCatalogData, onNewCatalogDataChange, nameHelpText } = useRenameCatalogValues(
    open,
    catalog.title,
    catalog.description
  );

  const isOrgType = catalog.owner.type === CATALOG_OWNER_TYPE.ORG;
  const ownerId = catalog.owner.id;
  const ownerName: string =
    (isOrgType
      ? flattedOrgs?.find((o) => o.organizationId === ownerId)?.organizationName ?? 'Unknown'
      : users?.find((u) => u.userId === ownerId)?.name) ?? 'Unknown';

  const onSubmit = async () => {
    setIsLoading(true);

    const dto = { title: newCatalogData.name, description: newCatalogData.description };

    const isRenamed = await CatalogService.renameCatalog(catalog.id, dto);
    if (isRenamed) {
      dispatch(updateCatalog({ catalogId: catalog.id, ...dto }));
      onClose();
    }
    setIsLoading(false);
  };

  return (
    <DialogModal
      open={open}
      withCloseButton={false}
      onClose={onClose}
      padding={'48px'}
      sx={{
        '& .MuiBackdrop-root': {
          top: '44px',
        },
        '& .MuiPaper-root': {
          width: '613px',
        },
      }}
    >
      <Stack sx={{ overflow: 'hidden' }}>
        <Stack direction={'row'} gap={'8px'} alignItems={'center'} mb={'12px'}>
          <NewCatalogIcon width="20" height="19" viewBox="0 0 20 19" />
          <Typography
            noWrap
            sx={{
              color: '#4D4E4C',
              fontFamily: 'Roboto',
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '130%',
              letterSpacing: '0.18px',
            }}
          >
            {t('Rename Catalog')}
          </Typography>
        </Stack>
        <Stack mb={'24px'} direction={'row'} justifyContent={'space-between'}>
          <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
            <Typography
              sx={{
                color: '#4D4E4C',
                fontFamily: 'Roboto',
                fontSize: '18px',
                fontWeight: 600,
                lineHeight: '130%',
                letterSpacing: '0.18px',
              }}
            >
              {t(catalog.title)}
            </Typography>
          </Stack>
          <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
            <Icon path={isOrgType ? 'organization-catalog-icon' : 'private-catalog-icon'} />

            <Typography
              sx={{
                color: '#1D8742',
                fontFamily: 'Roboto',
                fontSize: '18px',
                lineHeight: '130%',
                letterSpacing: '0.18px',
              }}
            >
              {isOrgType ? t('Organization') : t('Private')}
            </Typography>
          </Stack>
        </Stack>
        <Box mb={'16px'}>
          <Input
            value={newCatalogData.name}
            onChange={onNewCatalogDataChange}
            label={t('catalog_name')}
            name="name"
            placeholder={catalog.title}
            error={!!nameHelpText}
            helperText={nameHelpText}
          />
        </Box>
        <Box mb={'16px'}>
          <Input value={ownerName ?? ''} label={t('owner')} disabled />
        </Box>
        <Box mb={'48px'}>
          <Input
            value={newCatalogData.description}
            onChange={onNewCatalogDataChange}
            label={t('catalog_description')}
            name="description"
            placeholder={catalog.description}
          />
        </Box>
        <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
          <SaveControl onSave={onSubmit} onCancel={onClose} isLoading={isLoading} />
        </Stack>
      </Stack>
    </DialogModal>
  );
};
