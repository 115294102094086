import { PRODUCT_KEY } from './enums';

const commonColumns = [
  PRODUCT_KEY.SKU,
  PRODUCT_KEY.DESC,
  PRODUCT_KEY.BRAND,
  PRODUCT_KEY.SUBTYPE,
  PRODUCT_KEY.GROUP,
];

const generalColumns = [
  PRODUCT_KEY.INLET,
  PRODUCT_KEY.OUTLET,
  PRODUCT_KEY.OPT_FACTOR1,
  PRODUCT_KEY.OPT_FACTOR2,
];

export const emitterColumnsOrder = [
  ...commonColumns,
  PRODUCT_KEY.NOZZLE,
  PRODUCT_KEY.SWIVEL,
  ...generalColumns,
  PRODUCT_KEY.Q_NOM,
  PRODUCT_KEY.P_MIN,
  PRODUCT_KEY.P_MAX,
  PRODUCT_KEY.PRESSURE_NOMINAL_M,
  PRODUCT_KEY.KD,
  PRODUCT_KEY.EMITTER_QA,
  PRODUCT_KEY.EMITTER_QB,
];
