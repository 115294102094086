import { CatalogDotsIcon } from 'shared/ui/icons/CatalogDotsIcon';
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import { useMemo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseGreenIcon } from 'shared/ui/icons/CloseGreenIcon';
import { OpenIcon } from 'shared/ui/icons/OpenIcon';
import { RenameIcon } from 'shared/ui/icons/RenameIcon';
import { ShareIcon } from 'shared/ui/icons/ShareIcon';
import { Catalog } from 'shared/models';
import { CATALOG_OWNER_TYPE, ROLE_KEY, ROOT_ORG_ID } from 'shared/constants';
import { ManageAccessIcon } from 'shared/ui/icons/ManageAccessIcon';
import { useAnchorElement, useAppSelector } from 'shared/hooks';
import { CatalogDeleteIcon } from 'shared/ui/icons/CatalogDeleteIcon';
// import { ExportIcon } from '../../shared/ui/icons/ExportIcon';
// import { DownloadIcon } from '../../shared/ui/icons/DownloadIcon';
// import { CatalogDeleteIcon } from '../../shared/ui/icons/CatalogDeleteIcon';
// import { ArchiveIcon } from '../../shared/ui/icons/ArchiveIcon';
import { RestoreIcon } from '../../shared/ui/icons/RestoreIcon';

const defaultId = 'default';

interface Props {
  catalog: Catalog;
  handleOpenCatalog: () => void;
  handleRenameCatalog: () => void;
  handleRemoveCatalogAccess: () => void;
  handleShareCatalog: () => void;
  handleManageAccessCatalog: () => void;
  handleExportCatalog: () => void;
  handleDeleteCatalog: () => void;
  handleArchiveCatalog: () => void;
  handleRestoreCatalog: () => void;
}

export const CatalogItemActions: FC<Props> = ({
  catalog,
  handleOpenCatalog,
  handleRenameCatalog,
  handleRemoveCatalogAccess,
  handleShareCatalog,
  handleManageAccessCatalog,
  // handleExportCatalog,
  handleDeleteCatalog,
  // handleArchiveCatalog,
  // handleRestoreCatalog,
}) => {
  const { t } = useTranslation();
  const { open, anchorEl, onOpenMenu, onCloseMenu } = useAnchorElement();

  const userId = localStorage.getItem('userId') ?? '';

  const { userRoles } = useAppSelector((st) => st.permissions);
  const { flattedOrgs } = useAppSelector((st) => st.orgs);

  const sharedWithIDs =
    catalog?.sharings?.filter((s) => s.permission.includes('Share'))?.map((s) => s.sharedWithId) ?? [];

  const isPrivateCatalog = catalog.owner.type === CATALOG_OWNER_TYPE.PRIVATE;
  const isOrgCatalog = catalog.owner.type === CATALOG_OWNER_TYPE.ORG;

  const catalogOrgID = flattedOrgs.find((o) => o.organizationId === catalog.owner.id)?.id ?? 0;
  const userRolesID = catalogOrgID === ROOT_ORG_ID ? defaultId : catalogOrgID;

  const isUserCatalogManager = isOrgCatalog ? !!userRoles[userRolesID]?.includes(ROLE_KEY.CATALOG_MANAGER) : false;

  const isOwner = catalog.owner.id === userId;
  const isCanManageAccess = isOwner && isPrivateCatalog;
  const isCanShare = isOwner || sharedWithIDs.includes(userId);
  const isCanDeleteCatalog = isOwner || isUserCatalogManager;
  const isCanRemoveOwnAccess = !isOwner && isPrivateCatalog;

  const menuItems = useMemo(
    () => [
      {
        title: 'Open',
        icon: OpenIcon,
        callback: handleOpenCatalog,
      },
      {
        title: 'Rename',
        icon: RenameIcon,
        callback: handleRenameCatalog,
      },
      ...(isCanRemoveOwnAccess
        ? [
            {
              title: 'Remove My Access',
              icon: RestoreIcon,
              callback: handleRemoveCatalogAccess,
            },
          ]
        : []),
      ...(isCanShare
        ? [
            {
              title: 'Share',
              icon: ShareIcon,
              callback: handleShareCatalog,
            },
          ]
        : []),
      ...(isCanManageAccess
        ? [
            {
              title: 'Manage Access',
              icon: ManageAccessIcon,
              callback: handleManageAccessCatalog,
            },
          ]
        : []),
      // {
      //   title: 'Export',
      //   icon: ExportIcon,
      //   callback: handleExportCatalog,
      // },
      // {
      //   title: 'Download',
      //   icon: DownloadIcon,
      //   callback: () => {
      //     console.log('');
      //   },
      // },
      ...(isCanDeleteCatalog
        ? [
            {
              title: 'Delete',
              icon: CatalogDeleteIcon,
              callback: handleDeleteCatalog,
            },
          ]
        : []),
      // {
      //   title: 'Archive',
      //   icon: ArchiveIcon,
      //   callback: handleArchiveCatalog,
      // },
      // {
      //   title: 'Restore',
      //   icon: RestoreIcon,
      //   callback: handleRestoreCatalog,
      // },
    ],
    []
  );

  return (
    <>
      <IconButton sx={{ p: 0 }} onClick={onOpenMenu}>
        <CatalogDotsIcon width="24" height="24" viewBox="0 0 24 24" cursor={'pointer'} />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onCloseMenu}
        slotProps={{
          paper: {
            sx: {
              display: 'flex',
              alignItems: 'flex-start',
              width: '327px',
              flexDirection: 'column',
              borderRadius: '16px',
              border: '0.3px solid #D4D4D4',
              background: '#F6FAF7',
              boxShadow: '5px 5px 10px 0px rgba(0, 0, 0, 0.25)',
            },
          },
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Stack
          direction={'row'}
          width={'100%'}
          justifyContent={'space-between'}
          sx={{
            mb: 2,
            px: '12px',
            pt: '12px',
          }}
        >
          <Typography
            sx={{
              color: '#1D8742',
              fontFamily: 'Roboto',
              fontSize: '18px',
              fontWeight: 500,
              lineHeight: '130%',
            }}
          >
            {t('Action Menu')}
          </Typography>
          <IconButton onClick={onCloseMenu} sx={{ p: 0 }}>
            <CloseGreenIcon width="24" height="24" viewBox="0 0 24 24" />
          </IconButton>
        </Stack>
        <List sx={{ width: '100%' }}>
          {menuItems.map((i) => {
            const Icon = i.icon;

            return (
              <ListItem key={i.title} disablePadding>
                <ListItemButton
                  disableGutters
                  onClick={() => {
                    i.callback();
                    onCloseMenu();
                  }}
                  sx={{
                    px: '12px',
                    '&:hover': { backgroundColor: 'rgba(29, 135, 69, 0.16)' },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: '40px',
                    }}
                  >
                    <Icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          color: '#242731',
                          fontFamily: 'Roboto',
                          fontSize: '16px',
                          lineHeight: '130%',
                        }}
                      >
                        {t(i.title)}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Popover>
    </>
  );
};
