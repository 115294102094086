import { Button, ButtonProps, styled, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC } from 'react';
import { Icon } from '../Icon';
import { Fade } from './Fade';

interface BigBtnProps {
  outlined?: boolean;
}

const BigBtn = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'outlined',
})<BigBtnProps>(({ theme, outlined }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: outlined ? theme.palette.secondary.dark : theme.palette.primary.main,
  border: `1px solid ${outlined ? theme.palette.success.contrastText : theme.palette.primary.main}`,
  borderRadius: '10px',
  height: '94px',
  padding: '19px',
  minWidth: '350px',
  position: 'relative',
  ...(!outlined && { boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }),
  '&:hover': {
    backgroundColor: outlined ? theme.palette.secondary.dark : theme.palette.primary.main,
  },
  '&:disabled': {
    color: theme.palette.info.dark,
  },
}));

const Text = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'outlined',
})<BigBtnProps>(({ theme, outlined }) => ({
  color: outlined ? theme.palette.success.contrastText : theme.palette.info.main,
  textTransform: 'capitalize',
  textAlign: 'left',
  fontSize: '18px',
  lineHeight: '16px',
}));

interface Props extends ButtonProps {
  icon: string;
  text: string;
  subText: string;
  outlined?: boolean;
}

export const BigCreateButton: FC<Props> = ({ icon, text, subText, disabled, onClick, outlined }) => {
  const theme = useTheme();

  return (
    <BigBtn onClick={onClick} disabled={disabled} outlined={!!outlined}>
      <Icon path={icon} />
      <Box sx={{ px: '16px', flexGrow: 1 }}>
        <Text outlined={outlined} sx={{ fontWeight: 600, mb: '7px' }}>
          {text}
        </Text>
        <Text outlined={outlined}>{subText}</Text>
      </Box>
      <Icon path={theme.palette.mode === 'dark' ? 'big-white-plus' : 'big-green-plus'} />

      <Fade isFade={!!disabled} />
    </BigBtn>
  );
};
