import { Button, Stack, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CATALOG_MANAGE_TAB, CATALOG_OWNER_TYPE } from 'shared/constants';
import { useAppSelector, useCatalogSearchParams } from 'shared/hooks';
import { BlackToolTip, Icon, TAB, TabPanel } from 'shared/ui';
import { CatalogAvailability, CatalogTranslations, GroupsSubtypesBrands } from './components';
import { selectCurrentCatalog } from 'shared/slices';

export const CatalogManager = () => {
  const { t } = useTranslation();
  const { manageIndex, onChangeManageIndex, navigateToCatalogTable } = useCatalogSearchParams();

  const currentCatalog = useAppSelector(selectCurrentCatalog);
  const isAvailabilityTabIncluded = currentCatalog.owner?.type !== CATALOG_OWNER_TYPE.PRIVATE;

  const manageTabs = [
    {
      label: 'Groups, Sub Types & Brands',
      value: CATALOG_MANAGE_TAB.GROUPS,
      component: <GroupsSubtypesBrands />,
    },
    {
      label: 'Translations',
      value: CATALOG_MANAGE_TAB.TRANSLATIONS,
      component: <CatalogTranslations />,
    },
  ];

  if (isAvailabilityTabIncluded) {
    manageTabs.push({
      label: 'Availability',
      value: CATALOG_MANAGE_TAB.AVAILABILITY,
      component: <CatalogAvailability />,
    });
  }

  const handleChangeTab = (_: React.SyntheticEvent, index: number) => onChangeManageIndex(index);

  return (
    <Stack sx={{ bgcolor: 'info.main', height: '100%', p: '24px 32px', position: 'relative' }}>
      <Stack direction="row" alignItems="center" sx={{ position: 'relative', zIndex: 2 }}>
        <BlackToolTip title={t('Back to Table')}>
          <Button
            onClick={navigateToCatalogTable}
            sx={{
              bgcolor: 'info.main',
              minWidth: '32px',
              height: '32px',
              width: '32px',
              '&:hover': { bgcolor: 'info.light' },
            }}
          >
            <Icon path="back-arrow" />
          </Button>
        </BlackToolTip>

        <Tabs value={+manageIndex} onChange={handleChangeTab} sx={{ minHeight: '0px', ml: 4 }}>
          {manageTabs.map(({ label, value }) => (
            <TAB key={value} label={t(label)} value={value} />
          ))}
        </Tabs>
      </Stack>

      {manageTabs.map(({ value, component }) => (
        <TabPanel key={value} value={+manageIndex} index={value}>
          {component}
        </TabPanel>
      ))}
    </Stack>
  );
};
