import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const CatalogDeleteIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M4.91262 6.12075H5.46498H18.5342H19.0866L19.0317 6.67038L17.7027 19.9927C17.7027 19.9929 17.7027 19.9931 17.7027 19.9934C17.6705 20.3213 17.5173 20.6255 17.2731 20.8466C17.0289 21.0678 16.7111 21.1901 16.3817 21.1897C16.3814 21.1897 16.3811 21.1897 16.3808 21.1897M4.91262 6.12075L16.3817 20.6897L16.3808 21.1897M4.91262 6.12075L4.96745 6.67038L6.29645 19.9927C6.29647 19.9928 6.29648 19.993 6.2965 19.9932M4.91262 6.12075L6.2965 19.9932M16.3808 21.1897H7.61839M16.3808 21.1897H7.61839M7.61839 21.1897L7.61753 21.1897L7.61839 21.1897ZM6.2965 19.9932L6.29655 19.9937L6.79408 19.9441L6.2965 19.9932ZM16.383 21.8449H16.3817L7.61753 21.8449L7.61604 21.8449C7.12429 21.8464 6.64968 21.6643 6.28511 21.3343C5.92053 21.0042 5.69222 20.5501 5.64486 20.0606L5.64472 20.0591L4.22541 5.82548L4.22541 5.82548L4.22531 5.82449C4.22069 5.77909 4.22566 5.73321 4.23989 5.68985C4.25413 5.64648 4.27731 5.60659 4.30795 5.57276C4.33858 5.53892 4.37598 5.5119 4.41772 5.49343C4.45946 5.47497 4.50462 5.46548 4.55026 5.46558V5.46558H4.55132H19.4478C19.4478 5.46558 19.4479 5.46558 19.4479 5.46558C19.4936 5.46559 19.5388 5.47518 19.5806 5.49372L19.783 5.03756L19.5806 5.49372C19.6225 5.51228 19.6599 5.53939 19.6906 5.5733L20.0611 5.23788L19.6906 5.5733C19.7214 5.60722 19.7446 5.64719 19.7589 5.69064C19.7733 5.7341 19.7783 5.78007 19.7738 5.82559L18.3545 20.0583L18.3543 20.0598C18.307 20.5494 18.0787 21.0036 17.7141 21.3338C17.3495 21.6639 16.8748 21.8462 16.383 21.8449ZM16.3817 22.3449H7.61753H16.3817Z"
      fill="none"
      stroke="#8C8D92"
    />
    <path
      d="M21.5173 6.62075H2.48286C2.26337 6.62075 2.05287 6.53356 1.89767 6.37835C1.74247 6.22315 1.65527 6.01265 1.65527 5.79316C1.65527 5.57367 1.74247 5.36317 1.89767 5.20797C2.05287 5.05277 2.26337 4.96558 2.48286 4.96558H21.5173C21.7368 4.96558 21.9473 5.05277 22.1025 5.20797C22.2577 5.36317 22.3449 5.57367 22.3449 5.79316C22.3449 6.01265 22.2577 6.22315 22.1025 6.37835C21.9473 6.53356 21.7368 6.62075 21.5173 6.62075ZM12.0001 19.0345C11.7806 19.0345 11.5701 18.9474 11.4149 18.7921C11.2597 18.6369 11.1725 18.4264 11.1725 18.207V9.10351C11.1725 8.88402 11.2597 8.67352 11.4149 8.51832C11.5701 8.36311 11.7806 8.27592 12.0001 8.27592C12.2196 8.27592 12.4301 8.36311 12.5853 8.51832C12.7405 8.67352 12.8277 8.88402 12.8277 9.10351V18.207C12.8277 18.4264 12.7405 18.6369 12.5853 18.7921C12.4301 18.9474 12.2196 19.0345 12.0001 19.0345ZM9.10272 19.0345C8.89626 19.0343 8.69733 18.957 8.54498 18.8177C8.39262 18.6783 8.29785 18.4871 8.27927 18.2814L7.45169 9.17799C7.43191 8.95951 7.49969 8.74211 7.64014 8.57358C7.78058 8.40505 7.98219 8.29918 8.20065 8.27923C8.41926 8.2599 8.63663 8.32794 8.80521 8.46845C8.97379 8.60896 9.07987 8.81051 9.10024 9.02902L9.92783 18.1325C9.93818 18.247 9.92453 18.3625 9.88776 18.4715C9.85099 18.5805 9.7919 18.6806 9.71426 18.7655C9.63662 18.8504 9.54214 18.9182 9.43684 18.9645C9.33155 19.0108 9.21775 19.0347 9.10272 19.0345ZM14.8975 19.0345C14.7824 19.0347 14.6687 19.0108 14.5634 18.9645C14.4581 18.9182 14.3636 18.8504 14.2859 18.7655C14.2083 18.6806 14.1492 18.5805 14.1124 18.4715C14.0757 18.3625 14.062 18.247 14.0724 18.1325L14.9 9.02902C14.9422 8.57385 15.3394 8.24613 15.7995 8.27923C16.018 8.29918 16.2196 8.40505 16.3601 8.57358C16.5005 8.74211 16.5683 8.95951 16.5485 9.17799L15.7209 18.2814C15.7023 18.4871 15.6076 18.6783 15.4552 18.8177C15.3029 18.957 15.1039 19.0343 14.8975 19.0345Z"
      fill="#8C8D92"
    />
    <path
      d="M15.7241 6.62067H8.27583C8.05634 6.62067 7.84584 6.53348 7.69064 6.37827C7.53543 6.22307 7.44824 6.01257 7.44824 5.79308V2.48274C7.44824 2.26325 7.53543 2.05275 7.69064 1.89755C7.84584 1.74234 8.05634 1.65515 8.27583 1.65515H15.7241C15.9436 1.65515 16.1541 1.74234 16.3093 1.89755C16.4645 2.05275 16.5517 2.26325 16.5517 2.48274V5.79308C16.5517 6.01257 16.4645 6.22307 16.3093 6.37827C16.1541 6.53348 15.9436 6.62067 15.7241 6.62067ZM9.10341 4.9655H14.8965V3.31032H9.10341V4.9655Z"
      fill="#8C8D92"
    />
  </SvgIcon>
);
