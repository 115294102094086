import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const NewCatalogIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props} >
    <path
      fill={'none'}
      d="M13.6523 2.80981L16.6903 5.84785M11.3737 11.1644L7.57617 11.9239L8.33568 8.12639L15.1257 1.33636C15.231 1.22981 15.3563 1.14521 15.4945 1.08747C15.6327 1.02973 15.781 1 15.9308 1C16.0806 1 16.2289 1.02973 16.3671 1.08747C16.5053 1.14521 16.6306 1.22981 16.7359 1.33636L18.1638 2.76424C18.2703 2.8695 18.3549 2.99486 18.4126 3.13306C18.4704 3.27126 18.5001 3.41955 18.5001 3.56932C18.5001 3.7191 18.4704 3.86738 18.4126 4.00558C18.3549 4.14378 18.2703 4.26914 18.1638 4.3744L11.3737 11.1644Z"
      stroke="#8C8D92"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fill={'none'}
      d="M8.3356 2.80957H3.39878C2.89519 2.80957 2.41223 3.00962 2.05614 3.36571C1.70005 3.7218 1.5 4.20476 1.5 4.70835V16.101C1.5 16.6046 1.70005 17.0876 2.05614 17.4437C2.41223 17.7997 2.89519 17.9998 3.39878 17.9998H14.7914C15.0408 17.9998 15.2877 17.9507 15.5181 17.8553C15.7484 17.7598 15.9578 17.62 16.1341 17.4437C16.3104 17.2673 16.4503 17.058 16.5457 16.8276C16.6411 16.5973 16.6902 16.3504 16.6902 16.101V11.1642"
      stroke="#8C8D92"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
