import { Navigate, Outlet } from 'react-router-dom';
import { ROUTE } from 'shared/enum';

interface Props {
  isAuthenticated: boolean;
  redirectPath?: string;
}

export const ProtectedRoute: React.FC<Props> = ({ isAuthenticated, redirectPath = ROUTE.LOGIN }) => {
  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};
