import { Container, Typography } from '@mui/material';
import { HeadPanel } from 'shared/ui';
import { FC } from 'react';

export const Home: FC = () => {
  return (
    <>
      <HeadPanel>Home</HeadPanel>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Typography>Home</Typography>
      </Container>
    </>
  );
};
