import { FC } from 'react';
import { Checkbox, CheckboxProps, FormControlLabel, Stack } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';

interface Props extends CheckboxProps {
  onToggle?: () => void;
  isSelected?: boolean;
}

export const SquareCheckBox: FC<Props> = ({ checked, onToggle, disabled, isSelected }) => {
  return (
    <FormControlLabel
      checked={checked}
      onClick={onToggle}
      label=""
      control={
        <Checkbox
          icon={
            <Stack sx={{ width: '32px', height: '32px', bgcolor: isSelected ? 'primary.light' : 'info.main' }} />
          }
          checkedIcon={
            <Stack
              alignItems="center"
              justifyContent="center"
              sx={{
                width: '32px',
                height: '32px',
                bgcolor: disabled ? 'info.light' : 'primary.light',
              }}
            >
              <DoneIcon sx={{ width: '20px', height: '20px', color: 'darksome.main' }} />
            </Stack>
          }
        />
      }
      sx={{ m: 0, '& .MuiCheckbox-root': { p: 0 } }}
    />
  );
};
