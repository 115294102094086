import { ChangeEvent, FC, KeyboardEvent, ReactNode, useState } from 'react';
import {
  Box,
  IconButton,
  InputBase,
  Stack,
  Button as MuiButton,
  styled,
  Typography,
  CircularProgress,
} from '@mui/material';
import { Icon } from '../Icon';
import { ErrorTooltip } from '../Tooltips';

const Button = styled(MuiButton)(({ theme }) => ({
  backgroundColor: 'transparent',
  textTransform: 'unset',
  justifyContent: 'flex-start',
  color: 'black',
  height: '30px',
  '&:hover': { backgroundColor: theme.palette.info.main },
}));

interface Props {
  title: ReactNode;
  disabled: boolean;
  onConfirm: (value: string) => Promise<void>;
  isOpenInput: boolean;
  onOpen: () => void;
  onClose: () => void;
  error?: boolean;
  errorText?: string;
  isLoading?: boolean;
}

export const AddItemButton: FC<Props> = ({
  disabled,
  onConfirm,
  title,
  errorText,
  isLoading,
  isOpenInput,
  onOpen,
  onClose,
}) => {
  const [value, setValue] = useState('');

  const [error, setError] = useState(false);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setValue(e.target.value);

    const isError = !name || name.length < 3 || name.length > 50;
    setError(isError);
  };

  const handleConfirm = async () => {
    if (error) return;
    await onConfirm(value);
    setValue('');
    setError(false);
  };

  const handleClose = () => {
    setValue('');
    setError(false);
    onClose();
  };

  return (
    <Box sx={{ width: '100%', borderTop: '1px solid lightgrey' }}>
      {isOpenInput ? (
        <Box maxWidth={'100%'} px="5px">
          <ErrorTooltip open={error} title={errorText} arrow>
            <InputBase
              value={value}
              onChange={onChange}
              autoFocus
              onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                if (e.code === 'Enter' || e.code === 'Return') handleConfirm();
              }}
              sx={{
                minWidth: '100%',
                width: '30px',
                color: 'primary.main',
                height: '32px',
                p: '3px',
              }}
              endAdornment={
                <Stack direction="row">
                  {isLoading ? (
                    <CircularProgress size={15} color="primary" />
                  ) : (
                    <>
                      <IconButton onClick={handleClose} sx={{ p: 0 }}>
                        <Icon path="circle-btn-close" />
                      </IconButton>
                      <IconButton onClick={handleConfirm} sx={{ p: 0 }}>
                        <Icon path="circle-btn-done" />
                      </IconButton>
                    </>
                  )}
                </Stack>
              }
            />
          </ErrorTooltip>
        </Box>
      ) : (
        <Button onClick={onOpen} fullWidth disabled={disabled} sx={{ py: '16px', borderRadius: 0 }}>
          <Typography
            noWrap
            sx={{
              fontSize: '16px',
              lineHeight: '16px',
              letterSpacing: '1%',
              color: disabled ? 'info.dark' : 'primary.main',
            }}
          >
            {title}
          </Typography>
        </Button>
      )}
    </Box>
  );
};
