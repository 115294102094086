import { Box, Stack } from '@mui/material';
import { ChangeEvent, FC, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { BigTitle, Loader, SearchInput } from 'shared/ui';
import { OrganizationsTable } from './components';
import { NewOrganizationModal } from 'features';
import { useTranslation } from 'react-i18next';
import { selectHomeTabState, selectOrganizations, selectOrgLoading, selectPermissions } from '../../shared/slices';

export const OrganizationsList: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const orgs = useAppSelector(selectOrganizations);
  const isLoading = useAppSelector(selectOrgLoading);
  const permissions = useAppSelector(selectPermissions);
  const { orgSearch } = useAppSelector(selectHomeTabState);

  const isListOrganizationPermissionAble = Object.values(permissions).some(
    (p) => p.organization && p.organization.list
  );

  const [searchValue, setSearchValue] = useState<string>(orgSearch);

  const onClearClick = () => setSearchValue('');
  const handleChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value);

  // useEffect(() => {
  //   return () => {
  //     dispatch(setHomeTabState({ orgSearch: searchValue }));
  //   };
  // }, [searchValue]);

  return (
    <>
      <Box sx={{ height: '100%', p: '20px 40px' }}>
        <BigTitle sx={{ fontSize: 24 }}>{t('organizations')}</BigTitle>

        {isLoading ? (
          <Box height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <Loader />
          </Box>
        ) : (
          <Stack
            sx={{
              bgcolor: 'info.main',
              borderRadius: '8px',
              mt: '20px',
            }}
          >
            <Stack
              direction="row"
              spacing="25px"
              justifyContent="space-between"
              alignItems="flex-end"
              sx={{ width: '100%', mt: '15px', px: '10px', borderBottom: '1px solid grey' }}
            >
              <Box sx={{ maxWidth: '354px', flexGrow: 1 }}>
                <SearchInput
                  value={searchValue}
                  autoComplete={'new-password'}
                  onChange={handleChangeSearchValue}
                  onClearClick={onClearClick}
                  name={t('search_organization')}
                  icon={'green-search'}
                  sx={{ '& .MuiInputBase-root': { bgcolor: 'none' } }}
                />
              </Box>
            </Stack>

            {isListOrganizationPermissionAble && <OrganizationsTable orgs={orgs} searchValue={searchValue} />}
          </Stack>
        )}
      </Box>

      <NewOrganizationModal />
    </>
  );
};
