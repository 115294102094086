import {
  Fade,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  styled,
  useTheme,
} from '@mui/material';
import React, { FC, useEffect, useRef } from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { clearSelectedCells, deleteProductItems, pasteCopiedCells, undoProductAction } from 'shared/slices';
import { Icon } from 'shared/ui';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { SubMenu } from './SubMenu';
import { CellType, ContextMenuType } from 'shared/models';
import { useCatalogsControl } from 'shared/hooks';
import { useTranslation } from 'react-i18next';
import { copyCellsToClipboard, getCellsFromClipboard } from 'shared/lib';
import ContentCutIcon from '@mui/icons-material/ContentCut';

export const ListBTN = styled(ListItemButton)(({ theme }) => ({
  padding: '4px 7px',
  cursor: 'pointer',
  borderRadius: '10px',

  '&:hover': {
    backgroundColor: theme.palette.success.dark,
  },
}));
export const ListIcon = styled(ListItemIcon)(() => ({
  minWidth: '10px',
}));
export const ListText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  margin: '0 7px',
}));

interface Props {
  contextMenu: ContextMenuType | null;
  onClose: () => void;
  isSelectedFullRow: boolean;
  openEditMode: (cell: CellType) => void;
}

export const ProductContextMenu: FC<Props> = ({ contextMenu, onClose, isSelectedFullRow, openEditMode }) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const dispatch = useAppDispatch();

  const { selectedCells } = useAppSelector((st) => st.openedCatalogs);

  const { catalogId, isEditing, actionHistory } = useCatalogsControl();

  const isNoSelectedCells = !selectedCells.length;
  const isFullRowWasSelected = useRef<boolean>(false);

  useEffect(() => {
    isFullRowWasSelected.current = isSelectedFullRow;
  }, [contextMenu]);

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    onClose();
    setIsSub(false);
  };
  const handleDelete = (e: React.MouseEvent) => {
    if (isFullRowWasSelected.current) {
      catalogId && dispatch(deleteProductItems(catalogId));
    } else {
      catalogId && dispatch(clearSelectedCells(catalogId));
      setTimeout(() => {
        openEditMode(selectedCells[0]);
      }, 0);
    }
    handleClose(e);
  };
  const handleCut = async (e: React.MouseEvent) => {
    await copyCellsToClipboard(selectedCells);

    if (isFullRowWasSelected.current) {
      catalogId && dispatch(deleteProductItems(catalogId));
    } else {
      catalogId && dispatch(clearSelectedCells(catalogId));
    }
    handleClose(e);
  };
  const handleCopy = async (e: React.MouseEvent) => {
    await copyCellsToClipboard(selectedCells);

    handleClose(e);
  };
  const handlePaste = async (e: React.MouseEvent) => {
    const cells = await getCellsFromClipboard();
    setTimeout(() => {
      catalogId && dispatch(pasteCopiedCells({ catalogId, cells }));
    }, 0);

    handleClose(e);
  };
  const handleUndo = (e: React.MouseEvent) => {
    setTimeout(() => {
      catalogId && dispatch(undoProductAction(catalogId));
    }, 0);
    handleClose(e);
  };

  const [isSub, setIsSub] = React.useState(false);
  const openSubMenu = () => {
    if (!isNoSelectedCells) setIsSub(true);
  };
  const closeSubMenu = () => {
    setIsSub(false);
  };

  return (
    <Menu
      open={contextMenu !== null}
      onClose={handleClose}
      onClick={handleClose}
      onContextMenu={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
      sx={{
        '& .MuiPaper-root': {
          overflow: 'auto',
          maxWidth: 'auto',
          width: '300px',
          height: '300px',
          bgcolor: 'transparent',
          boxShadow: 'none',
          backgroundImage: 'none',
        },
        '& .MuiList-root': {
          padding: 0,
          borderRadius: '10px',
        },
        '& .MuiTypography-root.MuiListItemText-primary': {
          ...(palette.mode === 'light' && { color: 'black' }),
        },
        '& .MuiListItemButton-root:hover': {
          backgroundColor: 'rgba(237, 248, 255, 1)',
        },
      }}
    >
      <List
        sx={{
          bgcolor: 'info.light',
          borderRadius: '10px',
          padding: 0,
          width: '150px',
          height: 'auto',
          '& .MuiListItemIcon-root': { width: '19px' },
        }}
      >
        <ListItem disablePadding>
          <ListBTN onClick={handleCopy} disabled={isNoSelectedCells}>
            <ListIcon>
              <Icon path="products-view/menu-copy" />
            </ListIcon>
            <ListText primary={t('copy')} />
          </ListBTN>
        </ListItem>

        {isEditing && (
          <>
            <ListItem disablePadding>
              <ListBTN onClick={handlePaste} disabled={isNoSelectedCells}>
                <ListIcon>
                  <Icon path="products-view/menu-paste" />
                </ListIcon>
                <ListText primary={t('paste')} />
              </ListBTN>
            </ListItem>
            <ListItem disablePadding>
              <ListBTN onClick={handleCut} disabled={isNoSelectedCells}>
                <ListIcon>
                  <ContentCutIcon sx={{ color: 'primary.main', width: '17px', ml: '1px' }} />
                </ListIcon>
                <ListText primary={t('Cut')} />
              </ListBTN>
            </ListItem>
            <ListItem disablePadding>
              <ListBTN onClick={handleDelete} disabled={isNoSelectedCells}>
                <ListIcon>
                  <Icon path="products-view/menu-delete" />
                </ListIcon>
                <ListText primary={t('delete')} />
              </ListBTN>
            </ListItem>
            <ListItem disablePadding onMouseEnter={openSubMenu} onMouseLeave={closeSubMenu}>
              <ListBTN disabled={isNoSelectedCells}>
                <ListIcon>
                  <Icon path="products-view/menu-copy-value" />
                </ListIcon>
                <ListText primary={t('copy_value')} />
                <ListIcon>
                  <PlayArrowIcon
                    sx={{
                      width: '12px',
                      height: '12px',
                      color: 'darksome.main',
                      margin: '2px 0 0 2px',
                    }}
                  />
                </ListIcon>
              </ListBTN>

              <Fade in={isSub}>
                <div>
                  <SubMenu onClose={handleClose} />
                </div>
              </Fade>
            </ListItem>
            <ListItem disablePadding>
              <ListBTN onClick={handleUndo} disabled={!actionHistory.length}>
                <ListIcon>
                  <Icon path="products-view/menu-undo" />
                </ListIcon>
                <ListText primary={t('undo')} />
              </ListBTN>
            </ListItem>
          </>
        )}
      </List>
    </Menu>
  );
};
