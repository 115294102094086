import { axiosBase } from 'shared/axios';
import { BaseResponse, Brand } from 'shared/models';
import { requestWrapper } from 'shared/lib';

export class BrandService {
  static async createBrand(catalogId: string, brandName: string) {
    const { data } = await requestWrapper(
      axiosBase.post<BaseResponse<string[]>>(`/Catalogs/${catalogId}/brands`, [{ brandName }])
    );
    if (data) {
      const id = data[0];
      const newBrand: Brand = { id, name: brandName };
      return newBrand;
    }
  }

  static async changeBrand(catalogId: string, id: string, name: string) {
    const params = { name };
    const { error } = await requestWrapper(axiosBase.put(`/Catalogs/${catalogId}/brands/${id}`, params));
    if (!error) return true;
  }

  static async deleteBrand(catalogId: string, id: string) {
    const params = { data: [id] };
    const { error } = await requestWrapper(axiosBase.delete(`/Catalogs/${catalogId}/brands`, params));
    if (!error) return true;
  }
}
