import { createSlice } from '@reduxjs/toolkit';

export interface AuthState {
  token: string;
}

const initialState: AuthState = {
  token: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    deleteToken: (state) => {
      state.token = '';
    },
    resetAuth: () => initialState,
  },
  selectors: {
    selectAuthToken: (state) => state.token,
  },
});

export const { setToken, deleteToken, resetAuth } = authSlice.actions;

export const { selectAuthToken } = authSlice.selectors;

export const authReducer = authSlice.reducer;
