import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const ArchiveIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <rect width="24" height="24" fill="none" />
    <path
      d="M2.13514 6.97297V20.5946H20.2973V6.97297M8.94595 10.9459H13.4865M1 3H22V6.40541H1V3Z"
      stroke="#8C8D92"
      strokeWidth="1.7027"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
