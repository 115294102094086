import { Role, User } from 'shared/models';

export const isUserGlobalAdmin = (users: User[], allRoles: Role[], userId: string): boolean => {
  const globalAdminRole = allRoles.find((r) => r.name === 'Global Admin');

  if (!globalAdminRole) {
    return false;
  }

  const currentUser = users.find((u) => u.userId === userId);

  if (!currentUser) {
    return false;
  }

  return currentUser.roles.includes(globalAdminRole.id);
};
