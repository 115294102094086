import { ChangeEvent, FC, useState } from 'react';
import { Box, Button, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { DialogModal, SaveControl, SearchInput } from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { ManageAccessIcon } from '../../shared/ui/icons/ManageAccessIcon';
import { PlusIcon } from '../../shared/ui/icons/PlusIcon';
import { CatalogManageAccessItem } from './CatalogManageAccessItem';
import { Catalog } from 'shared/models';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { emailRegexp } from 'shared/constants';
import { setCatalogs } from 'shared/slices';
import { CatalogService } from 'shared/services';

interface Props {
  open: boolean;
  onClose: () => void;
  catalog: Catalog;
}

export const ManageAccessCatalogModal: FC<Props> = ({ open, onClose, catalog }) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const dispatch = useAppDispatch();

  const { catalogs } = useAppSelector((st) => st.catalogs);
  const { users } = useAppSelector((st) => st.users);
  const [isLoading, setIsLoading] = useState(false);

  const [searchValue, setSearchValue] = useState('');
  const [errorText, setErrorText] = useState('');
  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    setErrorText('');
  };

  const sharedWithUsers =
    catalog.sharings?.map(({ id, sharedWithId, permission }) => {
      const user = users.find((u) => u.userId === sharedWithId);

      return {
        catalogId: catalog.id,
        sharingId: id,
        userName: user?.name ?? 'No name',
        email: user?.email ?? 'No email',
        permission,
      };
    }) ?? [];

  const searchedUsers = sharedWithUsers.filter(({ email }) =>
    email.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase().trim())
  );

  const onShareCatalog = async () => {
    if (searchedUsers.length) return;
    if (!emailRegexp.test(searchValue)) {
      setErrorText('Invalid Email');
      return;
    }

    const emails = users.map((u) => u.email);
    if (!emails.includes(searchValue.trim())) {
      setErrorText('No such users in the system');
      return;
    }

    setIsLoading(true);

    const email = searchValue.trim();
    const dto = { emailSharings: [{ email, permission: 'Read', sendEmail: true }] };

    const sharingId = await CatalogService.share(catalog.id, dto);
    if (sharingId) {
      const sharedWithId = users.find((u) => u.email === email)?.userId ?? 'id';
      const updatedCatalogs = catalogs.map((c) =>
        c.id === catalog.id
          ? {
              ...c,
              sharings: [...c.sharings, { sharedWithId, permission: 'Read', scope: 'User', id: sharingId }],
            }
          : c
      );

      dispatch(setCatalogs(updatedCatalogs));
      setSearchValue('');
    }

    setIsLoading(false);
  };

  const handleClose = () => {
    onClose();
    setSearchValue('');
  };

  return (
    <DialogModal
      open={open}
      withCloseButton={false}
      onClose={handleClose}
      padding={'0px'}
      sx={{
        '& .MuiBackdrop-root': {
          top: '44px',
        },
        '& .MuiPaper-root': {
          width: '480px',
        },
      }}
    >
      <Stack sx={{ overflow: 'hidden' }}>
        <Stack direction={'row'} sx={{ justifyContent: 'space-between', mb: '12px', px: '48px', pt: '48px' }}>
          <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
            <ManageAccessIcon />
            <Typography
              sx={{
                color: '#4D4E4C',
                fontFamily: 'Roboto',
                fontSize: '18px',
                fontWeight: 400,
                lineHeight: '130%',
                letterSpacing: '0.18px',
              }}
            >
              {t('Manage Users Access')}
            </Typography>
          </Stack>
        </Stack>

        <Box mb={'24px'} sx={{ px: '48px' }}>
          <Typography
            sx={{
              color: '#4D4E4C',
              fontFamily: 'Roboto',
              fontSize: '18px',
              fontWeight: 600,
              lineHeight: '130%',
              letterSpacing: '0.18px',
            }}
          >
            {catalog.title}
          </Typography>
        </Box>

        <Box mb={'24px'} sx={{ px: '48px' }}>
          <Stack direction={'row'} sx={{ borderBottom: '1px solid #D4D4D4', position: 'relative' }}>
            <SearchInput
              value={searchValue}
              autoComplete={'new-password'}
              onChange={onChangeSearch}
              onClearClick={() => {
                setSearchValue('');
                setErrorText('');
              }}
              name={t('Search for a user')}
              icon={'green-search'}
              sx={{
                '& .MuiInputBase-root': {
                  pl: 0,
                  bgcolor: 'unset',
                },
              }}
            />

            <Typography
              sx={{
                position: 'absolute',
                top: '100%',
                left: 0,
                color: 'error.main',
                fontSize: 12,
              }}
            >
              {errorText}
            </Typography>

            <Button onClick={onShareCatalog} disabled={!searchValue} sx={{ display: 'flex', gap: '4px', p: 0 }}>
              {isLoading ? <CircularProgress size={14} sx={{ m: 0.5 }} /> : <PlusIcon />}
              <Typography
                sx={{
                  color: '#7B7D7B',
                  fontFamily: 'Roboto',
                  fontSize: '18px',
                  lineHeight: '130%',
                  letterSpacing: '0.18px',
                  textTransform: 'none',
                }}
              >
                {t('Share')}
              </Typography>
            </Button>
          </Stack>
        </Box>

        <Box
          mb={'48px'}
          sx={{
            maxHeight: '200px',
            overflowY: 'auto',
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: palette.primary.main,
              borderRadius: '4px',
            },
            '&::-webkit-scrollbar': {
              width: '8px',
              backgroundColor: palette.info.dark,
              borderRadius: '4px',
            },
          }}
        >
          {searchedUsers.map((i) => (
            <CatalogManageAccessItem key={i.sharingId} {...i} />
          ))}
        </Box>

        <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%', px: '48px', pb: '48px' }}>
          <SaveControl btnTexts={['', 'Close']} isSaveExist={false} onCancel={handleClose} isLoading={isLoading} />
        </Stack>
      </Stack>
    </DialogModal>
  );
};
