import { IconButton, styled, TableCell, TableRow } from '@mui/material';
import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { STATUS } from 'shared/constants';
import { selectAllRoles, selectPermissions, setSelectedUser, toggleUserDetails } from 'shared/slices';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { User } from 'shared/models';
import { HoverToolTip, Icon } from 'shared/ui';

const Cell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.info.main,
  color: theme.palette.darksome.main,
  fontSize: '16px',
  lineHeight: '16px',
  fontWeight: 600,
  border: 'none',
  paddingLeft: 0,
  borderBottom: `1px solid grey`,
  borderColor: theme.palette.info.light,
  maxWidth: '100px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  // flexShrink: 1,
}));

interface Props {
  user: User;
}

export const OrganizationUserItem: FC<Props> = ({ user }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { organizationId } = useParams();
  const { name, roles, phone, email, status } = user;
  const permissions = useAppSelector(selectPermissions);
  const allRoles = useAppSelector(selectAllRoles);

  const userRoles = allRoles
    .filter((r) => roles.includes(r.id))
    .map((r) => r.name)
    .join(', ');

  const handleOpenUserDetails = () => {
    dispatch(setSelectedUser(user));

    setTimeout(() => {
      dispatch(toggleUserDetails());
    }, 100);
  };

  const isUpdateUserAble = organizationId ? permissions[organizationId].user.update : false;

  return (
    <TableRow
      onDoubleClick={isUpdateUserAble ? handleOpenUserDetails : undefined}
      sx={{
        position: 'relative',
        borderBottom: '1px solid grey',
        borderColor: 'success.dark',
        backgroundColor: 'info.main',

        '&:hover': {
          backgroundColor: 'secondary.main',
          cursor: 'pointer',
        },
      }}
    >
      <Cell component="th" sx={{ pl: { xs: '20px', xl: '60px' }, ml: '30px' }}>
        <HoverToolTip title={name ?? 'Empty'} placement="top-start">
          <>{name ?? 'Empty'}</>
        </HoverToolTip>
      </Cell>
      <Cell component="th">
        <HoverToolTip title={userRoles} placement="top-start">
          <> {userRoles}</>
        </HoverToolTip>
      </Cell>
      <Cell component="th">
        <HoverToolTip title={email} placement="top-start">
          <> {email}</>
        </HoverToolTip>
      </Cell>
      <Cell component="th">
        <HoverToolTip title={phone} placement="top-start">
          <>{phone}</>
        </HoverToolTip>
      </Cell>
      <Cell component="th" align="center" padding="checkbox">
        {!roles.length ? t('not_active') : status === STATUS.ACTIVE ? t('active') : t('pending')}
      </Cell>
      {isUpdateUserAble && (
        <Cell component="th" align="center" sx={{}}>
          <IconButton onClick={handleOpenUserDetails}>
            <Icon path={'org-open-edit-grey'} />
          </IconButton>
        </Cell>
      )}
    </TableRow>
  );
};
