import { Stack, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  last?: boolean;
}

export const HeadCell: FC<Props> = ({ children }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        cursor: 'pointer',
        pr: '5px',
        height: '60px',
      }}
    >
      <Typography
        noWrap
        sx={{
          color: 'grey',
          fontSize: '18px',
          lineHeight: '18px',
          fontWeight: 600,
        }}
      >
        {children}
      </Typography>
      {/* {!last && (
        <Box sx={{ ml: '10px' }}>
          <Icon path={`${palette.mode === 'dark' ? 'DARK' : 'LIGHT'}_THEME/down-arrow`} />
        </Box>
      )} */}
    </Stack>
  );
};
