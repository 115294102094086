import React, { useState } from 'react';
import {
  Typography,
  Box,
  Select as MuiSelect,
  useTheme,
  SelectProps,
  FormControl,
  CircularProgress,
} from '@mui/material';
import { Icon } from '../Icon';
import { OutlinedSelectInput, UnderlinedSelectInput } from './ui';

interface Props extends SelectProps {
  label?: string;
  variation?: 'outlined' | 'underlined';
  onWhiteOnly?: boolean;
  isLoading?: boolean;
  helperText?: string;
}

export const Select: React.FC<Props> = ({
  label,
  disabled,
  name,
  variation,
  onWhiteOnly,
  isLoading,
  helperText,
  ...props
}) => {
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const handleToggle = () => setOpen((prev) => !prev);

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      {label && (
        <Typography
          noWrap
          sx={{
            color: '#242731',
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '130%',
            letterSpacing: '0.18px',
            marginBottom: '8px',
          }}
        >
          {label}
        </Typography>
      )}
      <FormControl disabled={disabled} fullWidth>
        <MuiSelect
          open={open}
          onOpen={handleToggle}
          onClose={handleToggle}
          input={
            variation === 'outlined' ? (
              <OutlinedSelectInput fullWidth disabled={disabled} name={name} />
            ) : (
              <UnderlinedSelectInput
                fullWidth
                disabled={disabled}
                name={name}
                onWhiteOnly={onWhiteOnly}
                placeholder="Select SubType"
              />
            )
          }
          IconComponent={() => (
            <>
              {isLoading ? (
                <Box sx={{ mt: '5px', mr: -2 }}>
                  <CircularProgress size={28} />
                </Box>
              ) : (
                <Box
                  onClick={handleToggle}
                  sx={{
                    transform: `rotate(${open ? '-180deg' : '0deg'})`,
                    pointerEvents: disabled ? 'none' : 'auto',
                    ...(!disabled && { cursor: 'pointer' }),
                  }}
                >
                  <Icon
                    path={
                      disabled
                        ? 'grey-down-arrow'
                        : onWhiteOnly
                        ? 'black-down-arrow'
                        : theme.palette.mode === 'dark'
                        ? 'white-down-arrow'
                        : 'black-down-arrow'
                    }
                  />
                </Box>
              )}
            </>
          )}
          MenuProps={{
            PaperProps: {
              sx: {
                maxWidth: '300px',
                maxHeight: '45%',
                mt: '10px',
                borderRadius: '12px',
                px: '8px',
                '& .MuiMenuItem-root': {
                  borderRadius: '8px',
                },
                '& .Mui-selected, .MuiMenuItem-root:hover': {
                  bgcolor: 'primary.light',
                },
              },
            },
          }}
          {...props}
        >
          {props.children}
        </MuiSelect>
      </FormControl>
      <Typography
        sx={{
          position: 'absolute',
          ml: 2,
          color: 'error.main',
          fontSize: 14,
        }}
      >
        {helperText}
      </Typography>
    </Box>
  );
};
