import { Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import { useAppDispatch, useAppNavigator, useAppSelector } from 'shared/hooks';
import { setSynchronizedOrgData, toggleOrgDetails } from 'shared/slices';
import { DialogModal, SaveControl } from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { OrgService } from 'shared/services';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const DeleteOrgModal: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { navigateToOrganizations } = useAppNavigator();

  const { selectedOrg } = useAppSelector((st) => st.orgs);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleDeleteOrg = async () => {
    if (!selectedOrg) {
      console.error('No selected organization.');
      return;
    }

    setIsLoading(true);

    const isDeleted = await OrgService.deleteOrg(selectedOrg.id);
    if (isDeleted) {
      dispatch(setSynchronizedOrgData(false));
      dispatch(toggleOrgDetails());

      navigateToOrganizations();
    }
    setIsLoading(false);
  };

  return (
    <DialogModal withCloseButton={false} open={open} onClose={onClose} padding={'96px'}>
      <Typography
        sx={{
          color: '#242731',
          textAlign: 'center',
          fontFamily: 'Roboto',
          fontSize: '18px',
          fontWeight: 600,
          lineHeight: '130%',
          letterSpacing: '0.18px',
          mb: '12px',
        }}
      >
        {t('deleting_org_is_an_Irreversible_action')}!
      </Typography>
      <Typography
        sx={{
          color: '#242731',
          textAlign: 'center',
          fontFamily: 'Roboto',
          fontSize: '18px',
          lineHeight: '130%',
          letterSpacing: '0.18px',
          mb: '24px',
        }}
      >
        {t('are_you_sure_you_want_to_delete')}?
      </Typography>

      <Stack alignItems="center">
        <SaveControl onSave={handleDeleteOrg} onCancel={onClose} isLoading={isLoading} btnTexts={[t('Delete')]} />
      </Stack>
    </DialogModal>
  );
};
