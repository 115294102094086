import { useLayoutEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { OrgService } from 'shared/services';
import {
  selectIsSyncedOrg,
  setOrganizations,
  setOrganizationsLoading,
  setSynchronizedOrgData,
} from 'shared/slices';
import { BaseLayout } from 'shared/ui';
import { OrganizationsList } from 'widgets';
import { SideMenu } from 'widgets/SideMenu';
import { TabBar } from 'widgets/TabBar';

export const OrganizationsPage = () => {
  const dispatch = useAppDispatch();
  const isSynchronizedOrgData = useAppSelector(selectIsSyncedOrg);

  useLayoutEffect(() => {
    const getData = async () => {
      dispatch(setOrganizationsLoading(true));

      const organizations = await OrgService.getOrgs();
      if (organizations) {
        dispatch(setOrganizations(organizations));
        dispatch(setSynchronizedOrgData(true));
      }
      dispatch(setOrganizationsLoading(false));
    };

    !isSynchronizedOrgData && getData();
  }, [isSynchronizedOrgData]);

  return (
    <BaseLayout tabBar={<TabBar />} sideMenu={<SideMenu />}>
      <OrganizationsList />
    </BaseLayout>
  );
};
