import { FC, useEffect } from 'react';
import { Box, Stack, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'shared/ui';
import CloseIcon from '@mui/icons-material/Close';
import { CatalogTypeSelect } from './CatalogTypeSelect';
import { useCatalogSearchParams } from 'shared/hooks';

const FilterItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.info.light,
  color: theme.palette.darksome.main,
  borderRadius: '8px',
  padding: '6px 14px',
  marginRight: '20px',
  fontSize: 18,
}));

interface Props {
  disabled?: boolean;
  isEditing: boolean;
  isSaveLoading: boolean;
  filters: { field: string; values: string[] }[];
  onChangeFilters: (filters: { field: string; values: string[] }[]) => void;
}

export const CatalogFiltersPanel: FC<Props> = ({
  disabled,
  isEditing,
  filters,
  onChangeFilters,
  isSaveLoading,
}) => {
  const { t } = useTranslation();
  const { type } = useCatalogSearchParams();

  const isShowFilters = !!filters?.length;

  const onResetAllFilters = () => onChangeFilters([]);
  const onRemoveFilter = (field: string) => {
    const updatedFilters = filters.filter((f) => f.field !== field);
    onChangeFilters(updatedFilters);
  };

  useEffect(() => {
    onResetAllFilters();
  }, [type]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        py: 1,
        px: '32px',
        position: 'relative',
        borderTop: '1px solid lightgrey',
      }}
    >
      <CatalogTypeSelect disabled={disabled} isEditing={isEditing} isSaveLoading={isSaveLoading} />

      <Stack direction="row" justifyContent="center" sx={{ flexGrow: 1 }}>
        {isShowFilters && <Icon path={'product-filter-green'} sx={{ mr: 2 }} />}

        {filters?.map(({ field }) => (
          <FilterItem key={field}>
            {t(field)}
            <CloseIcon
              onClick={() => onRemoveFilter(field)}
              sx={{ width: '20px', ml: 1, mt: '2px', cursor: 'pointer' }}
            />
          </FilterItem>
        ))}
      </Stack>

      {isShowFilters && (
        <Button onClick={onResetAllFilters} filled sx={{ height: '36px' }}>
          <Icon path={'reset-filters-white'} sx={{ mr: 1 }} />

          {t('Clear all filters')}
        </Button>
      )}

      {isEditing && <Box sx={{ position: 'absolute', inset: 0, bgcolor: 'rgb(0,0,0,0.4)' }} />}
    </Stack>
  );
};
