import { IconButton, Stack, Tabs } from '@mui/material';
import { FC, startTransition, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector, useCatalogUserPermissions } from 'shared/hooks';
import { CATALOG_OWNER_TYPE, CATALOG_SPEC, CATALOG_TYPE } from 'shared/constants';
import { ActiveButton, HoverToolTip, Icon, TAB } from 'shared/ui';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { TableSearch } from 'features';

import { selectCatalogs, selectFlattedOrg, selectPermissions } from '../../../shared/slices';

import { useParams } from 'react-router-dom';
import { changeSpecification, selectCurrentCatalog, startEditing } from 'shared/slices';
import { CellType } from 'shared/models';

interface Props {
  disabled: boolean;
  type: string;
  specification: number;
  isEditing: boolean;
  isDistributionCurveType: boolean;
  tableSearch: { search: string; currentIdx: number; cells: CellType[] };
  onAddItem: () => void;
}

export const TableTabs: FC<Props> = ({
  disabled,
  type,
  specification,
  isEditing,
  isDistributionCurveType,
  tableSearch,
  onAddItem,
}) => {
  const { id: catalogId } = useParams();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { isCanEditCatalog } = useCatalogUserPermissions();

  const [tabValue, setTabValue] = useState(specification);
  const [isEditingValue, setIsEditingValue] = useState(isEditing);

  const handleChangeTabValue = (_: React.SyntheticEvent, index: CATALOG_SPEC) => {
    setTabValue(index);
    startTransition(() => {
      catalogId && dispatch(changeSpecification({ catalogId, index }));
    });
  };
  const handleChangeIsEditingValue = () => {
    setIsEditingValue(true);
    startTransition(() => {
      catalogId && dispatch(startEditing(catalogId));
    });
  };

  useEffect(() => {
    if (!isEditing) {
      setIsEditingValue(false);
    }
  }, [isEditing]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ bgcolor: 'info.main', px: '32px' }}
    >
      <Tabs value={tabValue ?? 0} onChange={handleChangeTabValue} sx={{ minHeight: '0px' }}>
        <TAB label={t('general')} value={CATALOG_SPEC.GENERAL} disabled={disabled} />

        {(type === CATALOG_TYPE.PIPE || type === CATALOG_TYPE.INTEGRAL) && (
          <TAB label={t('pipe_spec')} value={CATALOG_SPEC.PIPE} disabled={disabled} />
        )}
        {type === CATALOG_TYPE.EMITTER && (
          <TAB label={t('emitter_spec')} value={CATALOG_SPEC.EMITTER} disabled={disabled} />
        )}

        {type === CATALOG_TYPE.INTEGRAL && (
          <TAB label={t('integral_emitter_spec')} value={CATALOG_SPEC.INTEGRAL} disabled={disabled} />
        )}
        {isDistributionCurveType && (
          <TAB label={t('place 0-25')} value={CATALOG_SPEC.PLACE_0_26} disabled={disabled} />
        )}
        {isDistributionCurveType && (
          <TAB label={t('place 26-52')} value={CATALOG_SPEC.PLACE_26_52} disabled={disabled} />
        )}
      </Tabs>

      <TableSearch tableSearch={tableSearch} />

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ flexGrow: 1 }}>
        {isEditingValue && (
          <IconButton onClick={onAddItem} sx={{ mr: 1 }}>
            <AddCircleIcon sx={{ color: 'primary.main' }} />
          </IconButton>
        )}

        {isCanEditCatalog && (
          <HoverToolTip
            title={'You cant edit this catalog'}
            disableHoverListener={isCanEditCatalog}
            disableFocusListener={isCanEditCatalog}
            disableTouchListener={isCanEditCatalog}
            placement="top-start"
          >
            <ActiveButton
              onClick={handleChangeIsEditingValue}
              isActive={isEditingValue}
              defaultBgcolor="info.main"
              disabled={!isCanEditCatalog}
            >
              <Icon
                path={`products-view/catalog-mode-${isEditingValue ? 'edit' : 'view'}`}
                sx={{ width: '16px' }}
              />
            </ActiveButton>
          </HoverToolTip>
        )}
      </Stack>
    </Stack>
  );
};
