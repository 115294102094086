import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useAppSelector, useCatalogSearchParams, useCatalogsControl } from 'shared/hooks';
import { getFatherAvailability } from 'shared/lib';
import { AvailabilityItem, AvailableComponent, AvailableGroup } from 'shared/models';
import { AvailabilityService, CatalogService } from 'shared/services';

export const useAvailabilities = () => {
  const { catalogId, type, onChangeManageType } = useCatalogSearchParams();
  const { loadedType, items } = useCatalogsControl();
  const { flattedOrgs } = useAppSelector((st) => st.orgs);
  const groups = useAppSelector((st) => st.catalogs.groups);
  const catalogGroups = groups[catalogId] ?? [];
  const [isAvailabilitiesLoading, setIsAvailabilitiesLoading] = useState(false);
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [isAvailabilitiesSaving, setIsAvailabilitiesSaving] = useState(false);
  const [isAvailabilityView, setIsAvailabilityView] = useState(false);
  const [isAddButton, setIsAddButton] = useState(false);
  const [isOrgHasFatherAvailability, setIsOrgHasFatherAvailability] = useState(false);
  const [isAvailabilityHasChanges, setIsAvailabilityHasChanges] = useState(false);

  const [availabilities, setAvailabilities] = useState<AvailabilityItem[]>([]);

  const [topOrgTypeGroups, setTopOrgTypeGroups] = useState<AvailableGroup[]>([]);
  const [fatherAvailableGroups, setFatherAvailableGroups] = useState<AvailableGroup[]>([]);

  const [currentCatalogId, setCurrentCatalogId] = useState('');
  const [currentOrgId, setCurrentOrgId] = useState('');
  const [viewGroupID, setViewGroupID] = useState('');
  const onChangeViewGroupID = (id: string) => setViewGroupID((prev) => (id === prev ? '' : id));

  const [selectedGroupIDs, setSelectedGroupIDs] = useState<string[]>([]);
  const [selectedProductIDs, setSelectedProductIDs] = useState<string[]>([]);

  const currentAvailabilityRef = useRef<AvailabilityItem | null>(null);

  const viewGroups = fatherAvailableGroups.filter((g) => g.type === type);
  const viewProducts = viewGroupID ? viewGroups.find((g) => g.id === viewGroupID)?.availableComponents ?? [] : [];
  const selectedViewGroupsAmount = viewGroups.filter((g) => selectedGroupIDs.includes(g.id)).length;
  const selectedViewProductsAmount = viewProducts.filter((p) => selectedProductIDs.includes(p.id)).length;

  const handleLoadGroupProducts = async (type: string) => {
    const isNoGroups = !catalogGroups.filter((g) => g.type === type).length;
    if (isNoGroups) {
      setTopOrgTypeGroups([]);
      return [];
    }

    const products = (await CatalogService.getProducts(catalogId, type)) ?? [];

    const groups: AvailableGroup[] =
      catalogGroups
        .filter((g) => g.type === type)
        .map(({ id, name, type }) => {
          const availableComponents: AvailableComponent[] = products
            .filter((p) => p.groupId === id)
            .map(({ id, sku, description }) => ({ id, sku, description }) as AvailableComponent);
          return { id, name, type, availableComponents };
        }) ?? [];

    setTopOrgTypeGroups(groups);

    return groups;
  };

  const fetchAvailabilities = async () => {
    setIsAvailabilitiesLoading(true);

    const promises: Promise<any>[] = [AvailabilityService.getAvailabilities(catalogId)];
    if (loadedType !== type) {
      const promise = handleLoadGroupProducts(type);
      promises.push(promise);
    } else {
      const groups: AvailableGroup[] =
        catalogGroups
          .filter((g) => g.type === type)
          .map(({ id, name, type }) => {
            const availableComponents: AvailableComponent[] = items
              .filter((p) => p.groupId === id)
              .map(({ id, sku, description }) => ({ id, sku, description }) as AvailableComponent);
            return { id, name, type, availableComponents };
          }) ?? [];
      setTopOrgTypeGroups(groups);
    }

    const [availabilities, topGroups] = await Promise.all(promises);

    if (availabilities) setAvailabilities(availabilities);
    if (topGroups) setTopOrgTypeGroups(topGroups);

    if (currentOrgId) {
      onChangeCurrentOrg(currentOrgId);
    }

    setIsAvailabilitiesLoading(false);
  };

  useEffect(() => {
    if (catalogId !== currentCatalogId) {
      setSelectedGroupIDs([]);
      setSelectedProductIDs([]);
      setTopOrgTypeGroups([]);
      setFatherAvailableGroups([]);
    }

    setCurrentCatalogId(catalogId);
    fetchAvailabilities();
  }, [catalogId]);

  const onChangeCurrentOrg = async (orgId: string) => {
    setIsAvailabilitiesLoading(true);

    setCurrentOrgId(orgId);

    const orgAvailability = await AvailabilityService.getOrgAvailability(catalogId, orgId);
    currentAvailabilityRef.current = orgAvailability;

    const isOrgHaveAvailability =
      orgAvailability?.sourceAvailability && orgAvailability.sourceAvailability.orgId === orgId;

    if (isOrgHaveAvailability) {
      openAvailabilityView(orgId);
    } else {
      setIsAddButton(true);
      setIsAvailabilityView(false);
      setSelectedGroupIDs([]);
      setSelectedProductIDs([]);
    }

    setIsAvailabilitiesLoading(false);
  };

  const openAvailabilityView = async (orgId: string) => {
    setIsAvailabilityView(true);
    setIsAddButton(false);

    const fatherAvailability = getFatherAvailability(orgId, flattedOrgs, availabilities);
    setIsOrgHasFatherAvailability(!!fatherAvailability);

    let groups: AvailableGroup[];

    if (fatherAvailability) {
      groups = fatherAvailability.sourceCatalog.availableGroups;
    } else {
      const isAnotherLoadedType = type !== topOrgTypeGroups?.[0]?.type || catalogId !== currentCatalogId;

      if (isAnotherLoadedType) {
        setIsProductsLoading(true);
        groups = await handleLoadGroupProducts(type);

        const selectedGroupID = groups.find((g) => g.type === type && selectedGroupIDs.includes(g.id))?.id ?? '';
        setViewGroupID(selectedGroupID);

        setIsProductsLoading(false);
      } else {
        groups = topOrgTypeGroups;
      }
    }
    setFatherAvailableGroups(groups);

    const isOrgHaveAvailability =
      currentAvailabilityRef.current?.sourceAvailability &&
      currentAvailabilityRef.current.sourceAvailability.orgId === orgId;

    if (isOrgHaveAvailability) {
      const availability = currentAvailabilityRef.current;

      if (availability) {
        const groups = availability.sourceCatalog?.availableGroups;
        const groupIDs = groups?.map(({ id }) => id);

        setSelectedGroupIDs(groupIDs);
        const selectedGroupID = groups.find((g) => g.type === type && groupIDs.includes(g.id))?.id ?? '';
        setViewGroupID(selectedGroupID);

        const selectedProducts = availability.sourceCatalog.availableGroups
          .map((g) => g.availableComponents.map((c) => c.id))
          .flat();
        setSelectedProductIDs(selectedProducts);
      }
    } else {
      setSelectedGroupIDs([]);
      setSelectedProductIDs([]);
    }
  };

  const handleChangeType = async (newType: string) => {
    if (newType === type) return;
    onChangeManageType(newType);

    const fatherAvailability = getFatherAvailability(currentOrgId, flattedOrgs, availabilities);
    let selectedGroupID;

    if (fatherAvailability) {
      selectedGroupID = fatherAvailableGroups.find((g) => selectedGroupIDs.includes(g.id))?.id ?? '';
    } else {
      setIsProductsLoading(true);
      const groups = await handleLoadGroupProducts(newType);
      setFatherAvailableGroups(groups);

      selectedGroupID = groups.find((g) => selectedGroupIDs.includes(g.id))?.id ?? '';

      setIsProductsLoading(false);
    }
    setViewGroupID(selectedGroupID);
  };

  const onToggleAllGroupsSelected = () => {
    setIsAvailabilityHasChanges(true);

    const allSelected = selectedGroupIDs.length === viewGroups.length;

    if (allSelected) {
      setSelectedGroupIDs([]);
      setSelectedProductIDs([]);
    } else {
      setSelectedGroupIDs(viewGroups.map((g) => g.id));
      const productIDs = viewGroups
        .map((g) => g.availableComponents)
        .reduce((a, c) => [...a, ...c], [])
        .map(({ id }) => id);
      setSelectedProductIDs((prev) => [...prev, ...productIDs]);
    }
  };

  const onToggleGroup = (id: string) => {
    setIsAvailabilityHasChanges(true);

    const isAdded = selectedGroupIDs.includes(id);

    const groupProductIDs = (viewGroups.find((g) => g.id === id)?.availableComponents ?? []).map((p) => p.id);

    let groupIDs: string[];
    let productIDs: string[];

    if (isAdded) {
      groupIDs = selectedGroupIDs.filter((gId) => gId !== id);
      productIDs = selectedProductIDs.filter((id) => !groupProductIDs.includes(id));
    } else {
      groupIDs = [...selectedGroupIDs, id];
      productIDs = [...new Set([...selectedProductIDs, ...groupProductIDs])];
      setViewGroupID(id);
    }

    setSelectedProductIDs(productIDs);
    setSelectedGroupIDs(groupIDs);
  };

  const onToggleAllProductsSelected = () => {
    setIsAvailabilityHasChanges(true);

    const allSelected = selectedViewProductsAmount === viewProducts.length;
    let productIDs: string[];
    let groupIDs: string[] = [...new Set([...selectedGroupIDs])];

    const groupProductIDs = viewProducts.map((p) => p.id);

    if (allSelected) {
      productIDs = selectedProductIDs.filter((id) => !groupProductIDs.includes(id));
      groupIDs = groupIDs.filter((id) => id !== viewGroupID);
    } else {
      productIDs = [...new Set([...selectedProductIDs, ...groupProductIDs])];
      groupIDs = [...new Set([...groupIDs, viewGroupID])];
    }

    setSelectedProductIDs(productIDs);
    setSelectedGroupIDs(groupIDs);
  };

  const onToggleProduct = (id: string) => {
    setIsAvailabilityHasChanges(true);

    const isAdded = selectedProductIDs.includes(id);
    let productIDs: string[];

    if (isAdded) {
      productIDs = selectedProductIDs.filter((pId) => pId !== id);
      if (!productIDs.length) {
        setSelectedGroupIDs((prev) => prev.filter((id) => id !== viewGroupID));
      }
    } else {
      productIDs = [...selectedProductIDs, id];
      if (!selectedGroupIDs.includes(viewGroupID)) {
        setSelectedGroupIDs([...selectedGroupIDs, viewGroupID]);
      }
    }

    setSelectedProductIDs(productIDs);
  };

  const onSaveAvailability = async () => {
    setIsAvailabilitiesSaving(true);
    const isOrgHaveAvailability =
      currentAvailabilityRef.current?.sourceAvailability &&
      currentAvailabilityRef.current.sourceAvailability.orgId === currentOrgId;

    const whiteListGroupIDs = [...selectedGroupIDs];

    const whiteList = whiteListGroupIDs.map((groupId) => {
      const groupProducts = fatherAvailableGroups.find((g) => g.id === groupId)?.availableComponents ?? [];
      const componentsId = groupProducts.filter(({ id }) => selectedProductIDs.includes(id)).map(({ id }) => id);

      const isSelectedAllGroupItems = groupProducts.length === componentsId.length;
      if (isSelectedAllGroupItems && !isOrgHasFatherAvailability) return { groupId, componentsId: [] };

      return { groupId, componentsId };
    });

    if (!isOrgHaveAvailability) {
      if (whiteList.length) {
        const newAvailability = await AvailabilityService.createAvailability(currentOrgId, catalogId, whiteList);
        if (newAvailability) {
          currentAvailabilityRef.current = newAvailability;
          setAvailabilities((prev) => [...prev, newAvailability]);
          toast.success('New availability was created');
        }
      } else {
        onCancelClick();
      }
    }

    if (isOrgHaveAvailability && !whiteList.length) {
      const availabilityId = currentAvailabilityRef.current?.sourceAvailability.id || '';

      const isDeleted = await AvailabilityService.deleteAvailability(availabilityId);
      if (isDeleted) {
        const updatedAvailabilities = availabilities.filter((a) => a.sourceAvailability.id !== availabilityId);
        setAvailabilities(updatedAvailabilities);
        setIsAvailabilityView(false);
        setIsAddButton(true);
        currentAvailabilityRef.current = null;
        toast.success('Availability deleted');
      } else {
        onCancelClick();
      }
    }

    if (isOrgHaveAvailability && whiteList.length) {
      const availabilityId = currentAvailabilityRef.current?.sourceAvailability.id || '';

      const updatedAvailability = await AvailabilityService.changeAvailability(availabilityId, whiteList);
      if (updatedAvailability) {
        const updatedAvailabilities = availabilities.map((a) =>
          a.sourceAvailability.id === availabilityId ? updatedAvailability : a
        );
        setAvailabilities(updatedAvailabilities);
        toast.success('Changes were successfully saved');
      } else {
        onCancelClick();
      }
    }

    setIsAvailabilitiesSaving(false);
    setIsAvailabilityHasChanges(false);
  };

  const onCancelClick = () => {
    openAvailabilityView(currentOrgId);
    setIsAvailabilityHasChanges(false);
  };

  return {
    type,
    handleChangeType,
    viewGroups,
    viewProducts,
    isAvailabilitiesLoading,
    currentOrgId,
    onChangeCurrentOrg,
    isAddButton,
    openAvailabilityView,
    isAvailabilityView,
    isAvailabilityHasChanges,
    isLoading: isProductsLoading,
    viewGroupID,
    onChangeViewGroupID,
    selectedGroupIDs,
    selectedProductIDs,
    selectedViewGroupsAmount,
    selectedViewProductsAmount,
    isAvailabilitiesSaving,
    onToggleAllGroupsSelected,
    onToggleGroup,
    onToggleAllProductsSelected,
    onToggleProduct,
    onSaveAvailability,
    onCancelClick,
  };
};
