import { Box, IconButton, Stack, Typography } from '@mui/material';
import { GridColumnHeaderParams } from '@mui/x-data-grid-premium';
import { CatalogItemsFilter } from 'features';

import { SORTING } from 'shared/constants';
import { useAnchorElement } from 'shared/hooks';
import { BlackToolTip, Icon } from 'shared/ui';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { FC } from 'react';
import { TableProductInfo } from 'shared/models';

type Props = GridColumnHeaderParams & {
  isEditing: boolean;
  sorting: { field: string; value: SORTING };
  filters: { field: string; values: string[] }[];
  items: TableProductInfo[];
  hidedItems: TableProductInfo[];
};

export const RenderHeaderCell: FC<Props> = (params) => {
  const { colDef, field, sorting, filters, isEditing, items, hidedItems } = params;

  const { open, anchorEl, onOpenMenu, onCloseMenu } = useAnchorElement();

  const isSorted = sorting?.field === params.field;
  const isFiltered = filters?.map((f) => f.field)?.includes(params.field);

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        width: '100%',
        bgcolor: 'inherit',
        '.MuiDataGrid-columnHeaderTitleContainer:has(&)': {
          paddingRight: isFiltered && isSorted ? '40px' : '20px',
        },
      }}
    >
      <BlackToolTip title={colDef?.headerName} placement="top-start">
        <Typography noWrap flexGrow={1}>
          {colDef?.headerName}
        </Typography>
      </BlackToolTip>

      <Box
        sx={{
          bgcolor: 'inherit',
          position: 'absolute',
          right: 0,
          mr: -0.5,
        }}
      >
        <IconButton onClick={onOpenMenu} disableTouchRipple disableFocusRipple disableRipple>
          <Box>
            {!(isSorted || isFiltered) && (
              <PlayArrowIcon
                sx={{ height: '16px', mr: -0.8, rotate: '90deg', color: open ? 'primary.main' : 'darksome.main' }}
              />
            )}

            {isSorted && <Icon path={`sort-${sorting?.value === SORTING.DESC ? 'desc' : 'asc'}`} />}

            {isFiltered && <Icon path={'product-filter-black'} sx={{ ml: 0.5 }} />}
          </Box>
        </IconButton>
      </Box>

      <CatalogItemsFilter
        open={open}
        anchorEl={anchorEl}
        onClose={onCloseMenu}
        field={field}
        isNumberType={colDef?.type === 'number'}
        isEditing={isEditing}
        sorting={sorting}
        filters={filters}
        items={items}
        hidedItems={hidedItems}
      />
    </Stack>
  );
};
