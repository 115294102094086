import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const ExportIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M15.75 3H20M20 3V7.25M20 3L11.5 11.5M10.65 3H5.125C4.56141 3 4.02091 3.22388 3.6224 3.6224C3.22388 4.02091 3 4.56141 3 5.125V17.875C3 18.4386 3.22388 18.9791 3.6224 19.3776C4.02091 19.7761 4.56141 20 5.125 20H17.875C18.4386 20 18.9791 19.7761 19.3776 19.3776C19.7761 18.9791 20 18.4386 20 17.875V12.35"
      stroke="#8C8D92"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
