import { useEffect, useState } from 'react';
import { useAppSelector } from 'shared/hooks';

export const useRenameCatalogValues = (open: boolean, name: string, description: string) => {
  const { catalogs } = useAppSelector((st) => st.catalogs);
  const catalogNames = catalogs.map((c) => c.title);

  const [newCatalogData, setNewCatalogData] = useState({ name: '', description: '' });
  const [isNoChangeName, setIsNoChangeName] = useState(true);
  const [nameHelpText, setNameHelpText] = useState('');

  const onNewCatalogDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'name') setIsNoChangeName(false);
    // if (e.target.name === 'description') setIsNoChangeDescription(false);
    setNewCatalogData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const checkIsValid = () => {
    setNameHelpText('');

    let isValid = true;

    if (!newCatalogData.name) {
      setNameHelpText('Please enter catalog title');
      isValid = false;
    } else if (newCatalogData.name.length < 5 || newCatalogData.name.length > 50) {
      setNameHelpText('Catalog title must be between 5 and 50 characters');
      isValid = false;
    } else if (catalogNames.includes(newCatalogData.name)) {
      setNameHelpText('Catalog name already exist');

      isValid = false;
    }

    return isValid;
  };

  useEffect(() => {
    if (open) {
      setNewCatalogData({ name, description });
    }
  }, [open]);

  useEffect(() => {
    if (!isNoChangeName) {
      checkIsValid();
    }
  }, [newCatalogData]);

  return {
    newCatalogData,
    onNewCatalogDataChange,
    checkIsValid,
    nameHelpText,
  };
};
