import { useEffect, useState } from 'react';
import { useAppSelector } from 'shared/hooks';

export const useNewOrgValues = (open: boolean) => {
  const { flattedOrgs } = useAppSelector((st) => st.orgs);
  const orgNames = flattedOrgs.map((o) => o.organizationName);

  const [newOrgName, setOrgName] = useState('');

  const [isNoChanged, setIsNoChanged] = useState(true);
  const [nameHelpText, setNameHelpText] = useState('');
  const [country, setCountry] = useState('');
  const [logo, setLogo] = useState('');

  const onOrgNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsNoChanged(false);
    setOrgName(e.target.value);
    /*  !newOrgName &&  */ setNameHelpText('');
  };

  const checkIsValid = () => {
    setNameHelpText('');

    let isValid = true;

    if (!newOrgName) {
      setNameHelpText("Organization name can't be empty");
      isValid = false;
    } else if (newOrgName.length < 3 || newOrgName.length > 50) {
      setNameHelpText('Organization name must be between 3 and 50 characters');
      isValid = false;
    } else if (orgNames.includes(newOrgName)) {
      setNameHelpText('Such organization already exist');

      isValid = false;
    }

    return isValid;
  };

  const onChangeCountry = (code: string) => setCountry(code);
  const onChangeLogo = (logo: string) => setLogo(logo);

  useEffect(() => {
    if (!newOrgName) {
      setNameHelpText('');
    }
  }, [newOrgName]);

  useEffect(() => {
    if (open) {
      setIsNoChanged(true);
      setOrgName('');
      setCountry('');
      setLogo('');
      setNameHelpText('');
    }
  }, [open]);

  return {
    newOrgName,
    onOrgNameChange,
    checkIsValid,
    nameHelpText,
    country,
    logo,
    onChangeCountry,
    onChangeLogo,
  };
};
