import { FC, useState, MouseEvent } from 'react';
import {
  Badge,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';
import { FilterIcon } from '../../shared/ui/icons/FilterIcon';
// import { ArchiveIcon } from '../../shared/ui/icons/ArchiveIcon';
import { useTranslation } from 'react-i18next';
import { CATALOG_OWNER_TYPE } from '../../shared/constants';
import { OwnerTypeFilters } from '../../widgets';
import { Icon } from 'shared/ui';

const filterTexts = {
  [CATALOG_OWNER_TYPE.ORG]: 'Organization',
  [CATALOG_OWNER_TYPE.PRIVATE]: 'Private',
  [CATALOG_OWNER_TYPE.PUBLISHED]: 'Public',
};

interface Props {
  filters: OwnerTypeFilters;
  handleCheckFilter: (value: CATALOG_OWNER_TYPE) => void;
}

export const CatalogOwnerTypeFilter: FC<Props> = ({ filters, handleCheckFilter }) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const isFiltered = Object.values(filters).some((v) => v === true);

  return (
    <>
      <IconButton onClick={handleClick}>
        <Badge
          color="primary"
          variant="dot"
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          invisible={!isFiltered}
        >
          <FilterIcon cursor={'pointer'} width="16" height="16" viewBox="-3 -3 26 26" />
        </Badge>
      </IconButton>

      <Popover
        slotProps={{
          paper: {
            sx: {
              display: 'flex',
              width: '220px',
              padding: '16px 24px 24px 24px',
              flexDirection: 'column',
              gap: '14px',
              borderRadius: '0px 0px 16px 16px',
              background: '#FFF',
              boxShadow: '0px 10px 23px 0px rgba(0,0,0,0.25)',
            },
          },
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} disablePadding>
          {Object.values(CATALOG_OWNER_TYPE).map((value) => {
            return (
              <ListItem key={value} disablePadding>
                <ListItemButton role={undefined} onClick={() => handleCheckFilter(value)} dense disableGutters>
                  <ListItemIcon sx={{ minWidth: '24px' }}>
                    <Checkbox
                      sx={{ p: 0 }}
                      edge="end"
                      checked={filters[value]}
                      tabIndex={-1}
                      disableRipple
                      icon={<Icon path="checkbox-icon" />}
                      checkedIcon={<Icon path="checkbox-icon-checked" />}
                    />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ m: 0 }}
                    primary={
                      <Typography sx={{ color: '#000', fontFamily: 'Roboto, Inter', fontSize: '16px' }}>
                        {t(`${filterTexts[value]}`)}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>

        {/* <Box sx={{ width: '100%', height: '1px', background: '#D4D4D4' }} />
        <ListItemButton dense disableGutters>
          <ListItemIcon sx={{ minWidth: '30px' }}>
            <ArchiveIcon width="24" height="24" viewBox="0 0 24 24" />
          </ListItemIcon>
          <ListItemText
            sx={{
              m: 0,
            }}
            primary={
              <Typography
                sx={{
                  color: '#000',
                  fontFamily: 'Inter',
                  fontSize: '16px',
                }}
              >
                {t('Go to Archive')}
              </Typography>
            }
          />
        </ListItemButton> */}
      </Popover>
    </>
  );
};
