export function calculateTextWidth(
  text: string,
  fontSize: number,
  fontFamily: string,
  fontWeight = 'normal',
  fontStyle = 'normal'
): number {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (!context) {
    throw new Error('Unable to create canvas context');
  }

  context.font = `${fontStyle} ${fontWeight} ${fontSize}px ${fontFamily}`;
  const metrics = context.measureText(text);

  return metrics.width;
}
