import { List, ListItem, Box } from '@mui/material';
import React, { FC } from 'react';
import { ListBTN, ListText } from '.';
import {
  pasteToColumnDown,
  pasteToColumnUp,
  pasteToSelectionDown,
  pasteToSelectionUp,
  selectCurrentCatalog,
} from 'shared/slices';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { Group } from 'shared/models';

interface Props {
  onClose: (e: React.MouseEvent) => void;
}

export const SubMenu: FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { id: catalogId } = useParams();
  const { selectedCells } = useAppSelector((st) => st.openedCatalogs);
  const { groups } = useAppSelector(selectCurrentCatalog);

  const isDisabledPasteSelection = new Set(selectedCells.map((c) => c.id)).size < 2;

  const closeWrapper = (callBack: ActionCreatorWithPayload<{ catalogId: string; groups: Group[] }>) => {
    return (e: React.MouseEvent) => {
      setTimeout(() => catalogId && dispatch(callBack({ catalogId, groups })), 0);
      onClose(e);
    };
  };

  const actions = [
    {
      title: t('selection_down'),
      onClick: closeWrapper(pasteToSelectionDown),
      disable: isDisabledPasteSelection,
    },
    {
      title: t('selection_up'),
      onClick: closeWrapper(pasteToSelectionUp),
      disable: isDisabledPasteSelection,
    },
    {
      title: t('column_down'),
      onClick: closeWrapper(pasteToColumnDown),
    },
    {
      title: t('column_up'),
      onClick: closeWrapper(pasteToColumnUp),
    },
  ];

  return (
    <Box
      onClick={onClose}
      sx={{
        position: 'absolute',
        zIndex: 10000,
        top: 0,
        left: '100%',
        width: '150px',
      }}
    >
      <List sx={{ bgcolor: 'info.light', borderRadius: '10px', padding: 0 }}>
        {actions.map(({ title, onClick, disable }, i) => (
          <ListItem disablePadding key={i}>
            <ListBTN onClick={onClick} disabled={disable}>
              <ListText primary={title} />
            </ListBTN>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
