import React from 'react';
import { Box, BoxProps } from '@mui/material';

interface Props extends BoxProps {
  path: string;
  isLogo?: boolean;
  alt?: string;
}

export const Icon: React.FC<Props> = ({ path, isLogo, alt, sx, ...props }) => {
  return (
    <Box
      component="img"
      src={isLogo ? path : `/images/${path}.svg`}
      alt={alt ?? 'IMG'}
      sx={{
        width: isLogo ? '43px' : 'auto',
        height: isLogo ? '43px' : 'auto',
        ...sx,
      }}
      {...props}
    />
  );
};
