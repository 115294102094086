import { Box, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, { FC } from 'react';
import { Icon } from 'shared/ui';

export const UserProfile: FC = () => {
  const [email, setEmail] = React.useState('');
  const [userName, setUserName] = React.useState('');

  React.useEffect(() => {
    setEmail(localStorage.getItem('email') ?? '');
    setUserName(localStorage.getItem('name') ?? '');
  }, []);

  return (
    <Stack
      direction="row"
      sx={{
        minHeight: '71px',
        alignItems: 'center',
        borderBottom: '1px solid white',
        borderColor: 'info.dark',
        mb: '11px',
      }}
    >
      <Box sx={{ minWidth: '55px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Icon path="Logo" />
      </Box>
      <Box sx={{ width: '100%' }}>
        <Typography
          noWrap
          sx={{
            color: 'info.main',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '16px',
            maxWidth: '185px',
          }}
        >
          {userName}
        </Typography>
        <Typography
          noWrap
          sx={{
            color: 'info.main',
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '16px',
            maxWidth: '185px',
          }}
        >
          {email}
        </Typography>
      </Box>
      {/* <Box sx={{}}>
        <IconButton sx={{ mr: '10px' }}>
          <BaseIcon path="white-down-arrow" />
        </IconButton>
      </Box> */}
    </Stack>
  );
};
