import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Permissions, Role } from '../../models';

export interface PermissionsState {
  isLoading: boolean;
  allRoles: Role[];
  permissions: Permissions;
  userRoles: { [key: string | number]: string[] };
}

const initialState: PermissionsState = {
  isLoading: true,
  allRoles: [],
  permissions: {},
  userRoles: {},
};

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    resetPermissions: () => initialState,
    setPermissionsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setPermissions: (state, action: PayloadAction<Permissions>) => {
      state.permissions = action.payload;
    },
    setAllRoles: (state, action: PayloadAction<Role[]>) => {
      state.allRoles = action.payload;
    },
    setUserRoles: (state, action: PayloadAction<{ [key: string | number]: string[] }>) => {
      state.userRoles = action.payload;
    },
  },
  selectors: {
    selectPermissions: (state) => state.permissions,
    selectAllRoles: (state) => state.allRoles,
    selectUserRoles: (state) => state.userRoles,
    selectPermissionsLoading: (state) => state.isLoading,
    selectPermissionsState: (state) => state,
  },
});

export const { setAllRoles, setPermissions, resetPermissions, setPermissionsLoading, setUserRoles } =
  permissionsSlice.actions;

export const {
  selectPermissions,
  selectPermissionsLoading,
  selectPermissionsState,
  selectUserRoles,
  selectAllRoles,
} = permissionsSlice.selectors;

export const permissionsReducer = permissionsSlice.reducer;
