import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const PlusRoundedIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M12.5 -0.000488281C10.1266 -0.000488281 7.80655 0.703299 5.83316 2.02188C3.85977 3.34045 2.3217 5.2146 1.41345 7.40731C0.505199 9.60002 0.267559 12.0128 0.730582 14.3406C1.1936 16.6684 2.33649 18.8066 4.01472 20.4848C5.69295 22.163 7.83115 23.3059 10.1589 23.7689C12.4867 24.232 14.8995 23.9943 17.0922 23.0861C19.2849 22.1778 21.1591 20.6397 22.4776 18.6663C23.7962 16.693 24.5 14.3729 24.5 11.9995C24.4968 8.8179 23.2315 5.76751 20.9817 3.51777C18.732 1.26803 15.6816 0.00271657 12.5 -0.000488281ZM17.186 12.7561H13.2566V16.6855C13.2566 16.8862 13.1769 17.0786 13.035 17.2205C12.8931 17.3624 12.7007 17.4421 12.5 17.4421C12.2993 17.4421 12.1069 17.3624 11.965 17.2205C11.8231 17.0786 11.7434 16.8862 11.7434 16.6855V12.7561H7.814C7.61333 12.7561 7.42088 12.6764 7.27899 12.5345C7.1371 12.3926 7.05738 12.2002 7.05738 11.9995C7.05738 11.7988 7.1371 11.6064 7.27899 11.4645C7.42088 11.3226 7.61333 11.2429 7.814 11.2429H11.7434V7.31351C11.7434 7.11284 11.8231 6.92039 11.965 6.7785C12.1069 6.6366 12.2993 6.55689 12.5 6.55689C12.7007 6.55689 12.8931 6.6366 13.035 6.7785C13.1769 6.92039 13.2566 7.11284 13.2566 7.31351V11.2429H17.186C17.3867 11.2429 17.5791 11.3226 17.721 11.4645C17.8629 11.6064 17.9426 11.7988 17.9426 11.9995C17.9426 12.2002 17.8629 12.3926 17.721 12.5345C17.5791 12.6764 17.3867 12.7561 17.186 12.7561Z"
      fill="white"
    />
  </SvgIcon>
);
