import { FC, MutableRefObject, useMemo } from 'react';
import { GridPreProcessEditCellProps, GridValueOptionsParams, ValueOptions } from '@mui/x-data-grid-premium';
import { GridBaseColDef } from '@mui/x-data-grid-pro/internals';
import { Box, useTheme } from '@mui/material';
import {
  CATALOG_TYPE,
  DISTRIBUTION_CURVE_COLUMNS,
  EMITTER_COLUMNS,
  GENERAL_COLUMNS,
  INLET_COLUMNS,
  INTEGRAL_COLUMNS,
  NOZZLE_SWIVEL_COLUMNS,
  PIPE_COLUMNS,
  PRODUCT_KEY,
  SORTING,
} from 'shared/constants';
import { getMaxDescriptionCellWidth, getProductsMap, validateCellValue } from 'shared/lib';
import { RenderDCEditModeCell, RenderEditCell, RenderHeaderCell } from './components';
import { useTranslation } from 'react-i18next';
import { MemoGrid } from './MemoGrid';
import { Brand, CellType, Group, SubType, TableProductInfo } from 'shared/models';
import { localStorageService } from 'shared/services';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { useCatalogsControl } from 'shared/hooks';
import { calculateTextWidth } from '../../shared/lib/catalogs/calculateTextWidth';

interface Props {
  type: string;
  specification: number;
  sorting: { field: string; value: SORTING };
  filters: { field: string; values: string[] }[];
  items: TableProductInfo[];
  hidedItems: TableProductInfo[];
  isEditing: boolean;
  changedItems: { [id: string]: TableProductInfo };
  deletedItemIDs: { [id: string]: boolean };
  newItemIDs: { [id: string]: boolean };
  tableSearch: { search: string; currentIdx: number; cells: CellType[] };
  isDistributionCurveType: boolean;
  catalogTypeGroups: Group[];
  catalogBrands: Brand[];
  catalogTypeSubtypes: SubType[];
  isValidating: boolean;
  cellErrors: { [id: string]: { [key: string]: boolean } };
  onToggleValidating: () => void;
  tableRef: MutableRefObject<GridApiPremium>;
}

export interface ExtendedGridColDef extends GridBaseColDef {
  valueOptions?: ValueOptions | ((params: GridValueOptionsParams) => ValueOptions);
}

export const GridContainer: FC<Props> = ({
  type,
  specification,
  items,
  hidedItems,
  isEditing,
  newItemIDs,
  changedItems,
  deletedItemIDs,
  tableSearch,
  isDistributionCurveType,
  catalogTypeGroups,
  catalogTypeSubtypes,
  filters,
  sorting,
  catalogBrands,
  cellErrors,
  isValidating,
  onToggleValidating,
  tableRef,
}) => {
  const { palette } = useTheme();
  const { t } = useTranslation();
  const { catalogId, emitters, allComponents } = useCatalogsControl();

  const columns: ExtendedGridColDef[] = useMemo(() => {
    const generalColumns = GENERAL_COLUMNS.map((c) => {
      if (c.field === PRODUCT_KEY.DESC && !isDistributionCurveType) {
        return { ...c, width: getMaxDescriptionCellWidth(items) };
      }

      if (c.field === PRODUCT_KEY.GROUP) {
        return {
          ...c,
          editable: !!catalogTypeGroups.length,
          valueOptions: catalogTypeGroups.map((g) => g.name),
        };
      }

      if (c.field === PRODUCT_KEY.BRAND) {
        return {
          ...c,
          editable: !!catalogBrands.length,
          valueOptions: catalogBrands.map((b) => b.name),
        };
      }

      if (c.field === PRODUCT_KEY.SUBTYPE && isDistributionCurveType) {
        return {
          ...c,
          editable: !!catalogTypeSubtypes.length,
          type: 'singleSelect',
          valueOptions: catalogTypeSubtypes.map((s) => s.name),
        };
      }
      return c;
    });

    const inletColumns = !isDistributionCurveType ? INLET_COLUMNS : [];
    const nozzleSwivelColumns =
      isDistributionCurveType || type === CATALOG_TYPE.EMITTER ? NOZZLE_SWIVEL_COLUMNS : [];
    const pipeColumns = type === CATALOG_TYPE.PIPE ? PIPE_COLUMNS : [];
    const emitterColumns = type === CATALOG_TYPE.EMITTER ? EMITTER_COLUMNS : [];
    const integralColumns = type === CATALOG_TYPE.INTEGRAL ? [...PIPE_COLUMNS, ...INTEGRAL_COLUMNS] : [];
    const distributionCurveColumns = isDistributionCurveType ? DISTRIBUTION_CURVE_COLUMNS : [];

    const allColumns = [
      ...generalColumns,
      ...nozzleSwivelColumns,
      ...inletColumns,
      ...pipeColumns,
      ...emitterColumns,
      ...integralColumns,
      ...distributionCurveColumns,
    ];

    const widths = localStorageService.catalogsColumnWidths;
    const currentTableWidths = widths?.[catalogId as string] ?? {};

    const maxWidthText = emitters?.reduce((longest, current) => {
      if (!current.description) return longest;

      return current.description.length > longest.length ? current.description : longest;
    }, '');

    const WIDTH_GAP = 50;

    const maxWidthDesc = Math.round(calculateTextWidth(maxWidthText, 16, 'Myriad Pro')) + WIDTH_GAP;

    return allColumns.map((c, i) => {
      if (i === 0) return c;

      return {
        ...c,
        ...{
          headerName: t(c.field),
          width: currentTableWidths?.[c.field] ?? c.width,
          renderHeader: (props) => (
            <RenderHeaderCell
              {...props}
              isEditing={isEditing}
              filters={filters}
              sorting={sorting}
              items={items}
              hidedItems={hidedItems}
            />
          ),
          preProcessEditCellProps: (params: GridPreProcessEditCellProps) => preValidateCellValue(params, c.field),

          ...(c.field !== PRODUCT_KEY.GROUP &&
            c.field !== PRODUCT_KEY.BRAND &&
            c.field !== PRODUCT_KEY.SUBTYPE &&
            c.field !== PRODUCT_KEY.ORIENT && { renderEditCell: RenderEditCell }),

          ...(isDistributionCurveType &&
            isEditing &&
            (c.field === PRODUCT_KEY.DESC || c.field === PRODUCT_KEY.GROUP) && {
              renderEditCell: (params) => <RenderDCEditModeCell {...params} maxWidthDesc={maxWidthDesc} />,
            }),
        },
      };
    });
  }, [isEditing, filters, sorting, items]);

  const preValidateCellValue = (params: GridPreProcessEditCellProps, field: string) => {
    const id = params.id.toString();
    const value = params.props.value;

    const itemsMap = getProductsMap(items);
    const error = validateCellValue(
      id,
      field,
      value,
      itemsMap,
      deletedItemIDs,
      isDistributionCurveType,
      changedItems,
      allComponents
    );

    return { ...params.props, error };
  };

  return (
    <Box
      sx={{
        height: 'calc(100vh - 270px)',
        bgcolor: 'lightsome.main',
        color: 'darksome.main',
        borderRadius: 0,
        position: 'relative',
        '*': { outline: 'none !important' },
        '& .MuiDataGrid-root': {
          borderRadius: 0,
          '*': { boxShadow: 'none !important' },
        },
        '& .product-grid--headerCell': {
          backgroundColor: isEditing ? 'warning.main' : '#ECECEC',
          color: 'black',
          fontSize: 16,
          fontWeight: 600,
          textTransform: 'capitalize',
          borderRight: '1px solid black',
          borderBottom: '1px solid black',
          borderColor: 'rgba(177, 177, 177, 1) !important',
          justifyContent: 'space-between',
          width: '100%',
        },

        '& .angular--headerCell': { p: '0 !important', span: { width: '30px', pl: '12px' } },
        '& .MuiDataGrid-pinnedColumns, & .MuiDataGrid-pinnedColumnHeaders': {
          bgcolor: 'lightsome.main',
          lineHeight: '33px !important',
          boxShadow: 'none',
          backgroundImage: 'none',
          outline: 'none',
        },
        '& .MuiDataGrid-columnHeaders': {
          backgroundColor: isEditing ? 'warning.main' : '#ECECEC',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
        '& .MuiDataGrid-columnHeader': {
          position: 'relative',
          outline: 'none',
        },
        '& .MuiDataGrid-columnHeaderTitleContainer span': {
          overflow: 'hidden',
          outline: 'none',
        },
        '& .MuiDataGrid-columnHeaderTitleContainer, & .MuiDataGrid-columnHeaderTitleContainerContent': {
          width: '100%',
        },
        '& .MuiDataGrid-virtualScroller': {
          // cursor: 'grab',
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(102, 112, 133, 1)',
            borderRadius: '12px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: 'rgba(102, 112, 133, 1)',
          },
          '&::-webkit-scrollbar': {
            height: '12px',
            width: '12px',
            backgroundColor: palette.info.light,
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: palette.info.light,
          },
          '&::-webkit-scrollbar-corner': {
            backgroundColor: palette.info.light,
            background: palette.info.light,
          },
        },
        '& .MuiDataGrid-cell': {
          color: 'black',
          borderWidth: '0',
          borderBottomWidth: '1px',
          borderRightWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'rgba(177, 177, 177, 1) !important',
          userSelect: 'none',
        },
        '& .no_padding': { py: 0, pr: 0 },
        '& .MuiDataGrid-cellContent': { cursor: 'default', my: '4px', height: '22px', lineHeight: '22px' },
        '& .deleted_row *': { color: 'error.main', textDecoration: 'line-through' },
        '& .new_row *': { color: 'primary.main', textDecoration: 'none' },
        '& .changed_cell *': { color: 'warning.main', textDecoration: 'underline' },
        '& .grey_cell *': { bgcolor: 'rgba(200, 195, 195, 1)', color: 'info.main' },
        '& .MuiOutlinedInput-input': { pl: '8px', fontSize: 14 },
        '& .MuiDataGrid-cell:focus ': {
          border: '2px solid green',
          borderColor: 'rgba(29, 135, 66, 1) !important',
        },
        '& .MuiDataGrid-columnHeader:focus': { outline: 'none !important' },
        '& .MuiDataGrid-cell.grey_cell': { bgcolor: 'rgba(200, 195, 195, 1)', color: 'rgba(177, 177, 177, 1)' },
        '& .MuiDataGrid-cell--editing': {
          '*': { color: 'black', boxShadow: 'none !important' },
          input: {
            borderBottom: '1px solid black',
            px: 0,
            color: 'black',
            fontSize: 16,
          },
          height: '30px',
          color: 'black !important',
          p: '0px !important',
          fieldset: { display: 'none' },
        },
        '& .MuiDataGrid-cell--editing.error_cell': {
          border: '2px solid red !important',
          borderColor: 'red !important',
        },
        '& .MuiDataGrid-cell.error_cell': {
          border: '2px solid red ',
          borderColor: 'red !important',
          color: 'red',
        },
        '& .MuiDataGrid-row.Mui-hovered, & .MuiDataGrid-row:hover': {
          backgroundColor: 'transparent !important',
        },
        '& .MuiDataGrid-row.Mui-hovered.Mui-selected, & .MuiDataGrid-row.Mui-selected:hover': {
          backgroundColor: 'rgba(29, 135, 66, 0.08) !important',
        },
        '& .MuiDataGrid-cell.Mui-hovered.Mui-selected, & .MuiDataGrid-cell.Mui-selected:hover': {
          backgroundColor: 'rgba(29, 135, 66, 0.08) !important',
        },
      }}
    >
      <MemoGrid
        items={items}
        tableRef={tableRef}
        columns={columns}
        specification={specification}
        changedItems={changedItems}
        isEditing={isEditing}
        deletedItemIDs={deletedItemIDs}
        newItemIDs={newItemIDs}
        tableSearch={tableSearch}
        cellErrors={cellErrors}
        isValidating={isValidating}
        onToggleValidating={onToggleValidating}
        isDistributionCurveType={isDistributionCurveType}
        catalogTypeGroups={catalogTypeGroups}
        catalogBrands={catalogBrands}
      />
    </Box>
  );
};
