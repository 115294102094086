import { ChangeEvent, FC, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { useAppSelector } from 'shared/hooks';
import { BigTitle, SearchInput } from 'shared/ui';
import { UsersTable } from './components';
import { useTranslation } from 'react-i18next';
import { selectHomeTabState, selectPermissions, selectUsers, selectUsersLoading } from 'shared/slices';
import { Loader } from 'shared/ui';

export const UsersManagement: FC = () => {
  const { t } = useTranslation();
  const permissions = useAppSelector(selectPermissions);
  const users = useAppSelector(selectUsers);
  const isLoading = useAppSelector(selectUsersLoading);
  const { userSearch } = useAppSelector(selectHomeTabState);

  const [searchValue, setSearchValue] = useState(userSearch);

  const onClearClick = () => setSearchValue('');
  const handleChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value);

  const isListPermissionAble = Object.values(permissions).some((p) => p.user && p.user.list);

  // useEffect(() => {
  //   return () => {
  //     dispatch(setHomeTabState({ userSearch: searchValue }));
  //   };
  // }, [searchValue]);

  return (
    <Box sx={{ height: '100%', p: '20px 40px' }}>
      <BigTitle sx={{ fontSize: 24 }}>{t('Users management')}</BigTitle>

      {isLoading ? (
        <Box height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Loader />
        </Box>
      ) : (
        <>
          <BigTitle sx={{ fontSize: 18, mt: '15px' }}>{t('users_table')}</BigTitle>

          <Stack
            sx={{
              bgcolor: 'info.main',
              borderRadius: '8px',
              mt: '8px',
            }}
          >
            <Stack
              direction="row"
              spacing="25px"
              justifyContent="space-between"
              alignItems="flex-end"
              sx={{ width: '100%', mt: '15px', px: '10px', borderBottom: '1px solid grey' }}
            >
              <Box sx={{ maxWidth: '354px', flexGrow: 1 }}>
                <SearchInput
                  value={searchValue}
                  autoComplete={'new-password'}
                  onChange={handleChangeSearchValue}
                  onClearClick={onClearClick}
                  name={t('Search for a user')}
                  icon={'green-search'}
                  sx={{
                    '& .MuiInputBase-root': {
                      bgcolor: 'none',
                    },
                  }}
                />
              </Box>
            </Stack>

            {isListPermissionAble && <UsersTable users={users} searchValue={searchValue} />}
          </Stack>
        </>
      )}
    </Box>
  );
};
