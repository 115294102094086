import { FC, useMemo } from 'react';
import { Box, Stack, styled, Typography, useTheme } from '@mui/material';
import { User } from 'shared/models';
import { useTranslation } from 'react-i18next';
import { UserItem } from 'entities/User/components/UserItem';
import { HeadCell } from 'widgets/OrganizationsList/components/HeadCell';

const NoUsersText = styled(Typography)(({ theme }) => ({
  fontSize: '18px',
  lineHeight: '16px',
  color: theme.palette.secondary.light,
  width: '100%',
  textAlign: 'center',
  marginTop: '30px',
}));

interface Props {
  users: User[];
  searchValue: string;
}

export const UsersTable: FC<Props> = ({ users, searchValue }) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const filteredUsers = useMemo(() => {
    return users.filter(
      (u) =>
        u.name?.toLocaleLowerCase().trim().includes(searchValue.toLocaleLowerCase().trim()) ||
        u.phone?.toLocaleLowerCase().trim().includes(searchValue.toLocaleLowerCase().trim()) ||
        u.email?.toLocaleLowerCase().trim().includes(searchValue.toLocaleLowerCase().trim())
    );
  }, [searchValue, users]);

  return (
    <Box sx={{ height: { xs: 'calc(100vh - 238px)', lg: 'calc(100vh - 245px)' } }}>
      <Stack direction="row" justifyContent="space-between">
        <Box sx={{ width: '15%', pl: '30px' }}>
          <HeadCell>{t("User's Name")}</HeadCell>
        </Box>
        <Box sx={{ width: '15%', minWidth: '100px' }}>
          <HeadCell>{t('Email')}</HeadCell>
        </Box>
        <Box sx={{ width: '20%' }}>
          <HeadCell>{t('telephone')}</HeadCell>
        </Box>
        <Box sx={{ width: '10%', minWidth: '100px' }}>
          <HeadCell>{t('status')}</HeadCell>
        </Box>
        <Box sx={{ width: '15%', minWidth: '100px' }}>
          <HeadCell>{t('related_organizations')}</HeadCell>
        </Box>
        <Stack sx={{ width: '10%' }} alignItems="center">
          <HeadCell last>{t('actions')}</HeadCell>
        </Stack>
      </Stack>

      <Box
        sx={{
          height: 'calc(100% - 60px)',
          width: '100%',
          overflowX: 'hidden',
          overflowY: 'auto',
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: palette.primary.main,
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar': {
            width: '8px',
            backgroundColor: palette.info.dark,
            borderRadius: '4px',
          },
        }}
      >
        {filteredUsers.length ? (
          filteredUsers.map((u) => <UserItem key={u?.userName} user={u} />)
        ) : (
          <NoUsersText>{t('users_management_no_users')}</NoUsersText>
        )}
      </Box>
    </Box>
  );
};
