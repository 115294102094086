import { createSlice } from '@reduxjs/toolkit';

interface ModalState {
  openSideMenu: boolean;
  openOrgCreating: boolean;
  openUserDetails: boolean;
  openInviteUser: boolean;
  openOrgDetails: boolean;
  openCatalogFinalUpdates: boolean;
  openCatalogSavingStatus: boolean;
}

const initialState: ModalState = {
  openSideMenu: true,
  openOrgCreating: false,
  openUserDetails: false,
  openInviteUser: false,
  openOrgDetails: false,
  openCatalogFinalUpdates: false,
  openCatalogSavingStatus: false,
};

export const modalSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    toggleMenu: (state) => {
      state.openSideMenu = !state.openSideMenu;
    },
    toggleOrgCreating: (state) => {
      state.openOrgCreating = !state.openOrgCreating;
    },
    toggleUserDetails: (state) => {
      state.openUserDetails = !state.openUserDetails;
    },
    toggleInviteUser: (state) => {
      state.openInviteUser = !state.openInviteUser;
    },
    toggleOrgDetails: (state) => {
      state.openOrgDetails = !state.openOrgDetails;
    },
    toggleCatalogFinalUpdates: (state) => {
      state.openCatalogFinalUpdates = !state.openCatalogFinalUpdates;
    },
    toggleCatalogSavingStatus: (state) => {
      state.openCatalogSavingStatus = !state.openCatalogSavingStatus;
    },
  },
  selectors: {
    selectOpenOrgCreating: (state) => state.openOrgCreating,
    selectOpenUserDetails: (state) => state.openUserDetails,
    selectOpenInviteUser: (state) => state.openInviteUser,
    selectOpenOrgDetails: (state) => state.openOrgDetails,
    selectOpenCatalogFinalUpdates: (state) => state.openCatalogFinalUpdates,
    selectOpenCatalogSavingStatus: (state) => state.openCatalogSavingStatus,
    selectModalsState: (state) => state,
    selectOpenSide: (state) => state.openSideMenu,
  },
});

export const {
  toggleMenu,
  toggleOrgCreating,
  toggleUserDetails,
  toggleInviteUser,
  toggleOrgDetails,
  toggleCatalogFinalUpdates,
  toggleCatalogSavingStatus,
} = modalSlice.actions;

export const {
  selectOpenOrgCreating,
  selectOpenCatalogFinalUpdates,
  selectOpenCatalogSavingStatus,
  selectOpenOrgDetails,
  selectOpenUserDetails,
  selectOpenInviteUser,
  selectModalsState,
  selectOpenSide,
} = modalSlice.selectors;

export const modalActions = modalSlice.actions;

export const modalReducer = modalSlice.reducer;
