import { axiosBase } from 'shared/axios';
import {
  AssignToOrgDTO,
  BaseResponse,
  CheckUserEmailDTO,
  CheckUserEmailResponse,
  EditUserDTO,
  EmailDTO,
  User,
  UserDTO,
  UserOrganizationRole,
} from 'shared/models';
import { requestWrapper } from 'shared/lib';
import { AppType } from 'shared/enum';

export class UserService {
  static async getUsers() {
    const { data } = await requestWrapper(axiosBase.get<BaseResponse<User[]>>('/Users'));
    if (data) return data;
  }

  static async getOrgUsers(orgId: string) {
    const { data } = await requestWrapper(axiosBase.get<BaseResponse<User[]>>(`/Users/${orgId}`));
    if (data) return data;
  }

  static async inviteNewUser(dto: UserDTO) {
    const { error } = await requestWrapper(axiosBase.post('/User', dto));
    if (!error) return true;
  }

  static async assignUserToOrg(dto: AssignToOrgDTO) {
    const { error } = await requestWrapper(axiosBase.put('/User/assign-to-organization', dto));
    if (!error) return true;
  }

  static async resendInvitationEmail(userId: string, appType: AppType) {
    const { error } = await requestWrapper(axiosBase.post(`/User/resend-invitation`, { userId, appType }));
    if (!error) return true;
  }

  static async deleteUser(id: string) {
    const { error } = await requestWrapper(axiosBase.delete<BaseResponse<boolean>>(`/User/${id}`));
    if (!error) return true;
  }

  static async deactivateUserFromOrg(body: { id: string; organizationId: number }) {
    const { error } = await requestWrapper(axiosBase.put('/User/deactivate-user-from-organization', body));
    if (!error) return true;
  }

  static async editUserDetails(editUserDto: EditUserDTO) {
    const { error } = await requestWrapper(axiosBase.put('/User', editUserDto));
    if (!error) return true;
  }

  static async activateUser(userId: string) {
    const { error } = await requestWrapper(axiosBase.put('/User/activate', { userId }));
    if (!error) return true;
  }

  static async resetPassword(dto: EmailDTO) {
    const { error } = await requestWrapper(axiosBase.post('/User/reset-password', dto));
    if (!error) return true;
  }

  static async setNewPassword(dto: { userId: string; password: string }) {
    const { error } = await requestWrapper(axiosBase.put('/User/password', dto));
    if (!error) return true;
  }

  static async getUserOrganizations(userId: string) {
    const { data } = await requestWrapper(
      axiosBase.get<BaseResponse<UserOrganizationRole[]>>(`/UserOrganizationRole/${userId}`)
    );
    if (data) return data;
  }

  static async checkUserExist(dto: CheckUserEmailDTO) {
    const { data } = await requestWrapper(
      axiosBase.post<BaseResponse<CheckUserEmailResponse>>('/User/exist', dto)
    );
    if (data) return data;
  }
}
