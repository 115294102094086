import { CATALOG_TYPE } from '../constants';
import { useLayoutEffect, useMemo } from 'react';
import { PRODUCT_KEY, SORTING } from 'shared/constants';
import { useAppDispatch, useAppSelector, useCatalogSearchParams } from 'shared/hooks';
import {
  CatalogItemState,
  CreatedCurveDTO,
  DeletedCurveDTO,
  TableProductInfo,
  UpdatedCurveDTO,
} from 'shared/models';
import {
  addNewProductItem,
  changeSpecification,
  getAllCatalogComponentsThunk,
  getCatalogEmittersThunk,
  getCatalogProductsThunk,
  openCatalog,
  saveDistributionCurvesThunk,
  selectCurrentCatalog,
  selectGroups,
  selectOpenedCatalogById,
  selectOpenedCatalogsState,
  selectShouldUpdateCatalogProducts,
  selectTabs,
  setCatalogFilters,
  setCatalogSorting,
  setEmptyItems,
  setTableSearch,
  setTableSearchCurrentIdx,
  startEditing,
  stopEditing,
  toggleValidating,
} from 'shared/slices';
import { getCurrentViewProducts } from 'shared/lib';
import { toast } from 'react-toastify';

export const useCatalogsControl = () => {
  const dispatch = useAppDispatch();
  const { catalogId, type } = useCatalogSearchParams();

  const shouldUpdateCatalogProducts = useAppSelector(selectShouldUpdateCatalogProducts);

  const currentCatalog = useAppSelector(selectCurrentCatalog);
  const groups = useAppSelector(selectGroups);

  const { selectedCells, isLoading, isSaveLoading } = useAppSelector(selectOpenedCatalogsState);
  const tabs = useAppSelector(selectTabs);

  const catalogInfo = useMemo(() => tabs?.find((c) => c.id === catalogId), [catalogId]);
  const catalogName = catalogInfo?.title ?? '';
  const catalogOwnerType = catalogInfo?.catalogInfo.type;
  const catalogOwnerName = catalogInfo?.catalogInfo.owner;

  const currentOpenedCatalog = selectOpenedCatalogById(catalogId || '') as CatalogItemState;

  const {
    loadedType,
    specificationIdx: specification,
    isEditing,
    isValidating,
    visibleItems,
    hidedItems,
    newItemIDs,
    deletedItemIDs,
    changedItems,
    actionHistory,
    sorting,
    filters,
    cellErrors,
    saveBulk,
    movedProductGroups,
    emitterComponents,
    emitters,
    tableSearch,
    allComponents,
  } = currentOpenedCatalog || {};

  const isCatalogInfoLoaded = currentCatalog?.title;
  const isDistributionCurveType = type === CATALOG_TYPE.DISTRIBUTION_CURVE;
  const catalogTypeGroups = (groups?.[catalogId] ?? []).filter(
    (g) => g.type === (isDistributionCurveType ? CATALOG_TYPE.EMITTER : type)
  );
  const catalogTypeSubtypes = (currentCatalog?.subtypes ?? []).filter(
    (st) => st.type === (isDistributionCurveType ? CATALOG_TYPE.EMITTER : type)
  );
  const catalogBrands = currentCatalog?.brands ?? [];

  const items: TableProductInfo[] = useMemo(() => {
    return getCurrentViewProducts(visibleItems, changedItems) ?? [];
  }, [catalogId, visibleItems, changedItems]);

  const onSetEmptyProductList = () => dispatch(setEmptyItems({ catalogId, type }));
  const onChangeSpecTab = (index: number) => dispatch(changeSpecification({ catalogId, index }));
  const onStartEditing = () => dispatch(startEditing(catalogId));
  const onStopEditing = () => dispatch(stopEditing(catalogId));
  const onAddNewItem = () => dispatch(addNewProductItem({ catalogId }));
  const onChangeSort = (field: string, sorting: SORTING) =>
    dispatch(setCatalogSorting({ catalogId, field, sorting }));
  const onSetDefaultSorting = () =>
    dispatch(setCatalogSorting({ catalogId, field: PRODUCT_KEY.DESC, sorting: SORTING.ASC }));
  const onChangeFilters = (filters: { field: string; values: string[] }[]) =>
    dispatch(setCatalogFilters({ catalogId, filters }));
  const onResetFilter = (field: string) => {
    const updatedFilters = filters.filter((f) => f.field !== field);
    dispatch(setCatalogFilters({ catalogId, filters: updatedFilters }));
  };
  const onToggleValidating = () => dispatch(toggleValidating(catalogId));

  const getProducts = async (type: string, signal?: AbortSignal) => {
    dispatch(openCatalog(catalogId));
    onSetEmptyProductList();
    const isDistributionCurveType = type === CATALOG_TYPE.DISTRIBUTION_CURVE;

    if (type === CATALOG_TYPE.DISTRIBUTION_CURVE) {
      await dispatch(getCatalogEmittersThunk({ catalogId, type: CATALOG_TYPE.EMITTER })).unwrap();
      return;
    }

    await dispatch(getCatalogProductsThunk({ catalogId, type, isDistributionCurveType, signal }));
  };

  const getAllComponents = async (signal?: AbortSignal) => {
    await dispatch(getAllCatalogComponentsThunk({ catalogId, signal }));
  };

  const handleGetAllCatalogProducts = (signal: AbortSignal) => {
    if (loadedType === type && !shouldUpdateCatalogProducts) return;

    try {
      getAllComponents(signal);
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  const onSaveCurves = async (
    newCurves: CreatedCurveDTO[],
    changedCurves: UpdatedCurveDTO[],
    deletedCurves: DeletedCurveDTO[]
  ) => {
    catalogId &&
      (await dispatch(saveDistributionCurvesThunk({ catalogId, newCurves, changedCurves, deletedCurves })));
  };

  const onChangeTableSearch = (value: string) => dispatch(setTableSearch({ catalogId, value }));
  const onMoveToNewCurrentIdx = (moveTo: 'next' | 'prev') =>
    dispatch(setTableSearchCurrentIdx({ catalogId, moveTo }));

  useLayoutEffect(() => {
    const abortController = new AbortController();

    handleGetAllCatalogProducts(abortController.signal);

    return () => abortController.abort();
  }, [catalogId]);

  return {
    catalogId: catalogId as string,
    loadedType,
    catalogName,
    catalogOwnerName,
    catalogOwnerType,
    currentCatalog,
    selectedCells,
    isLoading,
    isSaveLoading,
    isCatalogInfoLoaded,
    onSetEmptyProductList,
    specification,
    onChangeSpecTab,
    isEditing,
    onStartEditing,
    onStopEditing,
    onAddNewItem,
    items,
    allComponents,
    visibleItems,
    hidedItems,
    newItemIDs,
    deletedItemIDs,
    changedItems,
    actionHistory,
    sorting,
    onChangeSort,
    onSetDefaultSorting,
    filters,
    onChangeFilters,
    onResetFilter,
    cellErrors,
    catalogTypeGroups,
    catalogTypeSubtypes,
    catalogBrands,
    isValidating,
    onToggleValidating,
    saveBulk,
    movedProductGroups,
    getProducts,
    getAllComponents,
    isDistributionCurveType,
    emitterComponents,
    emitters,
    onSaveCurves,
    tableSearch,
    onChangeTableSearch,
    onMoveToNewCurrentIdx,
  };
};
