import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useForgotPassValue = () => {
  const [searchParams] = useSearchParams();

  const [emailData, setEmailData] = useState('');

  const [isNoChanged, setIsNoChanged] = useState(true);
  const [emailError, setEmailError] = useState(false);

  const onEmailDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsNoChanged(false);
    setEmailData(e.target.value);
  };

  const checkIsValid = () => {
    setEmailError(false);

    let isValid = true;

    if (!emailData) {
      setEmailError(true);
      isValid = false;
    }

    return isValid;
  };

  useEffect(() => {
    !isNoChanged && checkIsValid();
  }, [emailData]);

  useEffect(() => {
    const emailParam = searchParams.get('email') || '';

    if (!emailParam) return;

    setEmailData(emailParam);
  }, [searchParams]);

  return {
    emailData,
    onEmailDataChange,
    checkIsValid,
    emailError,
  };
};
