import { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { PlusRoundedIcon } from '../../shared/ui/icons/PlusRoundedIcon';
import { useTranslation } from 'react-i18next';

interface Props {
  onClick: () => void;
}

export const NoCatalogsPopup: FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        width: '610px',
        height: '340px',
        padding: '96px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '24px',
        borderRadius: '24px',
        background: '#FFF',
        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.16), 0px -1px 4px 0px rgba(0, 0, 0, 0.25)',
      }}
    >
      <Typography
        sx={{
          color: '#242731',
          textAlign: 'center',
          fontFamily: 'Roboto',
          fontSize: '18px',
          fontWeight: 600,
          lineHeight: '130%',
          letterSpacing: '0.18px',
        }}
      >
        {t('You don’t have any catalogs')}
      </Typography>
      <Button
        onClick={onClick}
        sx={{
          display: 'flex',
          height: '48px',
          padding: '0px 24px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '8px',
          flexShrink: 0,
          borderRadius: '8px',
          background: '#1D8742',
          color: '#FFF',
          textAlign: 'center',
          fontFamily: 'Roboto',
          fontSize: '18px',
          fontWeight: 500,
          lineHeight: '130%',
          letterSpacing: '0.18px',
          textTransform: 'capitalize',
          border: '1px solid #1D8742',
        }}
      >
        <PlusRoundedIcon width="25" height="24" viewBox="0 0 25 24" />
        {t('Create Catalog')}
      </Button>
    </Box>
  );
};
