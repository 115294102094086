import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const DoneIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    {/* <svg width="24" height="25" viewBox="0 0 24 25" fill="none"> */}
    <path
      d="M18.7104 7.45986C18.6175 7.36613 18.5069 7.29174 18.385 7.24097C18.2632 7.1902 18.1324 7.16406 18.0004 7.16406C17.8684 7.16406 17.7377 7.1902 17.6159 7.24097C17.494 7.29174 17.3834 7.36613 17.2904 7.45986L9.84044 14.9199L6.71044 11.7799C6.61392 11.6866 6.49998 11.6133 6.37512 11.5641C6.25026 11.5149 6.11694 11.4908 5.98276 11.4931C5.84858 11.4954 5.71617 11.5241 5.59309 11.5776C5.47001 11.6311 5.35868 11.7083 5.26544 11.8049C5.1722 11.9014 5.09889 12.0153 5.04968 12.1402C5.00048 12.265 4.97635 12.3984 4.97867 12.5325C4.98099 12.6667 5.00972 12.7991 5.06321 12.9222C5.1167 13.0453 5.19392 13.1566 5.29044 13.2499L9.13044 17.0899C9.2234 17.1836 9.334 17.258 9.45586 17.3088C9.57772 17.3595 9.70843 17.3857 9.84044 17.3857C9.97245 17.3857 10.1032 17.3595 10.225 17.3088C10.3469 17.258 10.4575 17.1836 10.5504 17.0899L18.7104 8.92986C18.8119 8.83622 18.893 8.72257 18.9484 8.59607C19.0038 8.46957 19.0324 8.33296 19.0324 8.19486C19.0324 8.05676 19.0038 7.92015 18.9484 7.79365C18.893 7.66715 18.8119 7.5535 18.7104 7.45986Z"
      // fill="#1D8742"
      fill={props.fill ?? '#1D8742'}
    />
    {/* </svg> */}
  </SvgIcon>
);
