import { axiosBase } from 'shared/axios';
import { BaseResponse, NewOrgDTO, Organization, UpdateOrgDTO } from 'shared/models';
import { requestWrapper } from 'shared/lib';

export class OrgService {
  static async getOrgs() {
    const { data } = await requestWrapper(axiosBase.get<BaseResponse<Organization[]>>('/Organizations'));
    if (data) return data;
  }

  static async createOrg(orgDto: NewOrgDTO) {
    const { error } = await requestWrapper(axiosBase.post('/Organizations', orgDto));
    if (!error) return true;
  }

  static async changeOrg(orgDto: UpdateOrgDTO) {
    const { data } = await requestWrapper(axiosBase.put<BaseResponse<Organization>>('/Organizations', orgDto));
    if (data) return data;
  }

  static async deleteOrg(id: number) {
    const { error } = await requestWrapper(axiosBase.delete(`/Organizations/${id}`));
    if (!error) return true;
  }

  static async getOrgDetails(id: string) {
    const { data } = await requestWrapper(axiosBase.get<BaseResponse<Organization>>(`/Organizations/${id}`));
    if (data) return data;
  }
}
