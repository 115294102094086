import { axiosBase } from 'shared/axios';
import { BaseResponse, Group } from 'shared/models';
import { requestWrapper } from 'shared/lib';

export class GroupService {
  static async createGroup(catalogId: string, body: { name: string; type: string }) {
    const { data } = await requestWrapper(
      axiosBase.put<BaseResponse<{ id: string }>>(`/Catalogs/${catalogId}/groups`, body)
    );
    if (data) {
      const id = data.id;
      const newGroup: Group = { id, ...body };
      return newGroup;
    }
  }

  static async changeGroup(
    catalogId: string,
    id: string,
    body: { name?: string; subtypeId?: string | null; isNewLegacyGroupId?: string }
  ) {
    const { error } = await requestWrapper(axiosBase.put(`/Catalogs/${catalogId}/groups/${id}`, body));
    if (!error) return true;
  }

  static async deleteGroup(catalogId: string, id: string) {
    const params = { data: { groupsId: [id] } };
    const { error } = await requestWrapper(axiosBase.delete(`/Catalogs/${catalogId}/groups`, params));
    if (!error) return true;
  }
}
