import { useParams } from 'react-router-dom';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAppSelector } from 'shared/hooks';
import { getDefaultFatherOrgId, getFilteredFatherOrganizations } from 'shared/lib';

export const useOrgDetailsValues = (open: boolean) => {
  const { organizationId } = useParams();

  const { selectedOrg, organizations, flattedOrgs } = useAppSelector((st) => st.orgs);
  const { permissions } = useAppSelector((st) => st.permissions);

  const filteredFatherOrganizations = getFilteredFatherOrganizations(organizations, permissions);

  const defaultFatherOrdId = getDefaultFatherOrgId(selectedOrg, filteredFatherOrganizations);
  const orgNames = flattedOrgs.map((o) => o.organizationName);

  const [orgDetails, setOrgDetails] = useState({
    logo: '',
    organizationName: '',
    checked: true,
    fatherOrganizationId: defaultFatherOrdId,
    fatherOrganizationName: '',
    country: '',
    salesRep: '',
    erpCustomerNumber: '',
    organizationDescription: '',
  });

  useEffect(() => {
    if (!selectedOrg) return;

    setOrgDetails({
      logo: selectedOrg.logo ?? '',
      organizationName: selectedOrg.organizationName,
      checked: !!selectedOrg?.isActive,
      fatherOrganizationId: defaultFatherOrdId,
      fatherOrganizationName: selectedOrg.fatherOrganizationName,
      country: selectedOrg.country ?? '',
      salesRep: selectedOrg.salesRep ?? '',
      erpCustomerNumber: selectedOrg.erpCustomerNumber ?? '',
      organizationDescription: selectedOrg.organizationDescription ?? '',
    });
  }, [organizationId, open]);

  const [nameHelpText, setNameHelpText] = useState('');

  const onChangeOrgDetails = (e: ChangeEvent<HTMLInputElement>) => {
    setOrgDetails((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const onChangeLogoImg = (imgCode: string) => {
    setOrgDetails((prev) => ({ ...prev, logo: imgCode }));
  };
  const onChangeCountry = (code: string) => setOrgDetails((prev) => ({ ...prev, country: code }));
  const onToggleIsActive = () => setOrgDetails((prev) => ({ ...prev, checked: !prev.checked }));
  const onChangeFatherOrgId = (id: number) => setOrgDetails((prev) => ({ ...prev, fatherOrganizationId: id }));

  const checkIsValid = () => {
    setNameHelpText('');

    let isValid = true;

    if (!orgDetails.organizationName) {
      setNameHelpText("Organization name can't be empty");
      isValid = false;
    } else if (orgDetails.organizationName.length > 50) {
      setNameHelpText('Organization name must be between 5 and 50 characters');
      isValid = false;
    } else if (
      orgNames.includes(orgDetails.organizationName) &&
      selectedOrg?.organizationName !== orgDetails.organizationName
    ) {
      setNameHelpText('Such organization already exist');

      isValid = false;
    }

    return isValid;
  };

  useEffect(() => {
    checkIsValid();
  }, [orgDetails.organizationName]);

  return {
    orgDetails,
    onChangeOrgDetails,
    onChangeLogoImg,
    onChangeCountry,
    onToggleIsActive,
    onChangeFatherOrgId,
    nameHelpText,
  };
};
