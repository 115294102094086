import React from 'react';

export const useAnchorElement = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const onOpenMenu = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const onCloseMenu = () => setAnchorEl(null);

  return {
    open,
    anchorEl,
    onOpenMenu,
    onCloseMenu,
  };
};
