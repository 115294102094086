// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { FC, useState } from 'react';
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  createFilterOptions,
} from '@mui/material';
import { DialogModal, SaveControl } from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { ShareIcon } from '../../shared/ui/icons/ShareIcon';
import { Catalog } from 'shared/models';
import { useCatalogShareEmail } from './hooks';
import { useAppDispatch } from 'shared/hooks';
import { addToCatalogSharing } from 'shared/slices';
import { CatalogService } from 'shared/services';

interface Props {
  open: boolean;
  onClose: () => void;
  catalog: Catalog;
}

export const CatalogShareModal: FC<Props> = ({ open, onClose, catalog }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState('Read');
  const [canShare, setCanShare] = useState(false);

  const { openSearch, onToggleSearch, user, activeUsers, onUserChange } = useCatalogShareEmail(catalog);

  const onSubmit = async () => {
    if (!user.email) return;

    setIsLoading(true);
    const dtoPermissionText = `${permission}${canShare ? ', Share' : ''}`;
    const dto = { emailSharings: [{ email: user.email, permission: dtoPermissionText, sendEmail: true }] };

    const sharingId = await CatalogService.share(catalog.id, dto);
    if (sharingId) {
      dispatch(
        addToCatalogSharing({
          catalogId: catalog.id,
          sharingWith: {
            sharedWithId: user?.userId,
            permission: dtoPermissionText,
            scope: 'User',
            id: sharingId,
          },
        })
      );
      onUserChange('', null);
      onClose();
    }

    setIsLoading(false);
  };

  const handleClose = () => {
    onClose();
    onUserChange(null, null);
  };

  return (
    <DialogModal
      open={open}
      withCloseButton={false}
      onClose={handleClose}
      padding={'48px'}
      sx={{
        '& .MuiBackdrop-root': {
          top: '44px',
        },
        '& .MuiPaper-root': {
          width: '480px',
        },
      }}
    >
      <Stack sx={{ overflow: 'hidden' }}>
        <Stack mb={'12px'} direction={'row'} justifyContent={'space-between'}>
          <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
            <ShareIcon />
            <Typography
              sx={{
                color: '#4D4E4C',
                fontFamily: 'Roboto',
                fontSize: '18px',
                fontWeight: 400,
                lineHeight: '130%',
                letterSpacing: '0.18px',
              }}
            >
              {t('Share Catalog')}
            </Typography>
          </Stack>
        </Stack>
        <Box mb={'24px'}>
          <Typography
            sx={{
              color: '#4D4E4C',
              fontFamily: 'Roboto',
              fontSize: '18px',
              fontWeight: 600,
              lineHeight: '130%',
              letterSpacing: '0.18px',
            }}
          >
            {t(catalog.title)}
          </Typography>
        </Box>
        <Box mb={'24px'}>
          <Typography
            noWrap
            sx={{
              color: '#242731',
              fontFamily: 'Roboto',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '130%',
              letterSpacing: '0.18px',
              marginBottom: '8px',
            }}
          >
            {t('Share with user')}
          </Typography>
          <Autocomplete
            open={openSearch}
            onOpen={onToggleSearch}
            onClose={onToggleSearch}
            onChange={onUserChange}
            value={user}
            options={activeUsers ?? []}
            getOptionLabel={(item) => item.email}
            isOptionEqualToValue={(option, value) => option?.email == value?.email}
            filterOptions={createFilterOptions({ matchFrom: 'any' })}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Enter email"
                sx={{
                  '& .MuiInputBase-root': {
                    height: '44px',
                    borderRadius: '8px',
                    p: '0 10px',
                    fontSize: 16,
                  },
                }}
              />
            )}
            noOptionsText={t('The user does not exist in the system.')}
          />
        </Box>
        <Stack mb={'48px'}>
          <FormControl
            sx={{
              mb: '16px',
              '& label.Mui-focused': {
                color: '#242731',
              },
            }}
          >
            <FormLabel
              sx={{
                color: '#242731',
                fontFamily: 'Roboto',
                fontSize: '18px',
                lineHeight: '130%',
                letterSpacing: '0.18px',
                mb: '16px',
              }}
            >
              {t('Select Permissions')}
            </FormLabel>
            <RadioGroup
              defaultValue="Read"
              name="radio-buttons-group"
              sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
            >
              <FormControlLabel
                value="Read"
                control={
                  <Radio
                    sx={{ py: 0 }}
                    checked={permission === 'Read'}
                    onChange={(e) => setPermission(e.target.value)}
                  />
                }
                label={
                  <Typography
                    sx={{
                      color: '#242731',
                      fontFamily: 'Roboto',
                      fontSize: '16px',
                    }}
                  >
                    {t('Viewer')}
                  </Typography>
                }
              />
              <FormControlLabel
                value="Write"
                control={
                  <Radio
                    sx={{ py: 0 }}
                    checked={permission === 'Write'}
                    onChange={(e) => setPermission(e.target.value)}
                  />
                }
                label={
                  <Typography
                    sx={{
                      color: '#242731',
                      fontFamily: 'Roboto',
                      fontSize: '16px',
                    }}
                  >
                    {t('Editor')}
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
          <Box sx={{ width: '100%', height: '1px', background: '#D4D4D4', mb: '16px' }} />
          <FormControlLabel
            control={
              <Checkbox sx={{ py: 0 }} checked={canShare} onChange={(e) => setCanShare(e.target.checked)} />
            }
            label={
              <Typography
                sx={{
                  color: '#242731',
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                }}
              >
                {t('Can share')}
              </Typography>
            }
          />
        </Stack>
        <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
          <SaveControl onSave={onSubmit} onCancel={handleClose} isLoading={isLoading} isSaveDisabled={!user} />
        </Stack>
      </Stack>
    </DialogModal>
  );
};
