import { Box, Stack } from '@mui/material';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { Button, BigTitle, SearchInput, Icon } from 'shared/ui';
import { OrgUsersTable } from './components';
import {
  selectHomeTabState,
  selectPermissions,
  selectUsers,
  setHomeTabState,
  setSelectedUser,
  toggleInviteUser,
} from 'shared/slices';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Loader } from 'shared/ui';

interface Props {
  loading: boolean;
}

export const OrganizationUsers: FC<Props> = ({ loading }) => {
  const dispatch = useAppDispatch();

  const { organizationId } = useParams();

  const { t } = useTranslation();
  const permissions = useAppSelector(selectPermissions);
  const { orgUsersSearch } = useAppSelector(selectHomeTabState);
  const users = useAppSelector(selectUsers);

  const [searchValue, setSearchValue] = useState(orgUsersSearch);

  const onClearClick = () => setSearchValue('');
  const handleChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value);

  // useEffect(() => {
  //   return () => {
  //     dispatch(setHomeTabState({ orgUsersSearch: searchValue }));
  //   };
  // }, [searchValue]);

  const isAddUserPermissionAble = organizationId
    ? permissions[organizationId]?.organization['add-user'] ||
      permissions[organizationId]?.sub_organization['add-user']
    : false;

  const isListUsersPermissionAble = organizationId
    ? permissions[organizationId]?.organization['list-users'] ||
      permissions[organizationId]?.sub_organization['list-users']
    : false;

  const filteredUsers = users
    .filter((item) => item.roles.length)
    .filter(
      (u) =>
        u.name?.toLocaleLowerCase().trim().includes(searchValue.toLocaleLowerCase().trim()) ||
        u.phone?.toLocaleLowerCase().trim().includes(searchValue.toLocaleLowerCase().trim()) ||
        u.email?.toLocaleLowerCase().trim().includes(searchValue.toLocaleLowerCase().trim())
    );

  const isOrgHasNoUsers = users.filter((item) => item.roles.length).length === 0;

  const onClickInviteUser = () => {
    dispatch(setSelectedUser(null));
    dispatch(toggleInviteUser());
  };

  return (
    <Box sx={{ p: '0 40px', height: '100%' }}>
      <BigTitle sx={{ fontSize: 18, mt: '20px' }}>{t('users_table')}</BigTitle>

      {loading ? (
        <Box height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Loader />
        </Box>
      ) : (
        <Stack
          sx={{
            bgcolor: 'info.main',
            borderRadius: '8px',
            mt: '20px',
            position: 'relative',
            overflow: 'hidden',
            height: '85%',
          }}
        >
          {!isOrgHasNoUsers && isListUsersPermissionAble && (
            <Stack
              direction={'row'}
              justifyContent="space-between"
              sx={{ width: '100%', mt: '15px', pb: '5px', px: '10px', borderBottom: '1px solid grey' }}
            >
              <Box sx={{ maxWidth: '354px', flexGrow: 1 }}>
                <SearchInput
                  value={searchValue}
                  onChange={handleChangeSearchValue}
                  onClearClick={onClearClick}
                  name={t('search_users')}
                  icon={'green-search'}
                  sx={{
                    '& .MuiInputBase-root': {
                      bgcolor: 'none',
                    },
                  }}
                />
              </Box>

              {isAddUserPermissionAble && (
                <Button
                  onClick={onClickInviteUser}
                  sx={{
                    border: 'none',
                    bgcolor: 'info.main',
                    color: 'darksome.main',
                    '&:hover': { bgcolor: 'info.main' },
                  }}
                >
                  <Icon path={'org-add-new'} sx={{ mr: 1 }} />
                  {t('invite_user')}
                </Button>
              )}
            </Stack>
          )}

          <Box mt={2}>{isListUsersPermissionAble && <OrgUsersTable users={filteredUsers} />}</Box>

          {isOrgHasNoUsers && isAddUserPermissionAble && (
            <Stack
              justifyContent="center"
              alignItems={'center'}
              sx={{ position: 'absolute', inset: 0, bgcolor: 'rgba(0, 0, 0, 0.20)', zIndex: 3 }}
            >
              <Stack alignItems={'center'} sx={{ borderRadius: '24px', p: 5, pt: 4, bgcolor: 'info.main' }}>
                <BigTitle sx={{ fontSize: 18, mb: 2, color: 'darksome.main' }}>
                  {t('You have not invited any user yet!')}
                </BigTitle>

                <Button onClick={onClickInviteUser} filled>
                  <Icon path={'org-add-new-white'} sx={{ mr: 1 }} />
                  {t('invite_user')}
                </Button>
              </Stack>
            </Stack>
          )}
        </Stack>
      )}
    </Box>
  );
};
