import { useAppSelector } from 'shared/hooks';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ProtectedRoute } from 'features';
import { LoginPage } from './common/login';
import { ForgotPasswordPage } from './common/forgot-password';
import { ResetPasswordPage } from './common/reset-password';
import { SingUpPage } from './common/sign-up';
import { HomePage } from './main/home';
import { ActivationPage } from './common/activation';
import { OrganizationsPage } from './main/organizations';
import { OrganizationPage } from './main/organizations/[organizationId]';
import { ProductCatalogsPage } from './main/product-catalogs';
import { ProductCatalogPage } from './main/product-catalogs/[id]';
import { CatalogManagementPage } from './main/product-catalogs/[id]/management';
import { UsersManagementPage } from './main/users-management';
import { ROUTE } from 'shared/enum';
import { selectAuthToken } from '../shared/slices';

/**
 * SIGN UP BUG TODO
 */

const commonRoutes = [
  { path: ROUTE.LOGIN, element: <LoginPage /> },
  { path: ROUTE.FORGOT_PASSWORD, element: <ForgotPasswordPage /> },
  { path: ROUTE.ACTIVATION, element: <ActivationPage /> },
  { path: ROUTE.RESET_PASSWORD, element: <ResetPasswordPage /> },
  { path: ROUTE.SIGN_UP, element: <SingUpPage /> },
  { path: '/*', element: <LoginPage />, replace: true },
];

const privateRoutes = [
  { path: ROUTE.HOME, element: <HomePage /> },
  { path: ROUTE.ORGANIZATIONS, element: <OrganizationsPage /> },
  { path: `${ROUTE.ORGANIZATIONS}/:organizationId`, element: <OrganizationPage /> },
  { path: ROUTE.USER_MANAGEMENT, element: <UsersManagementPage /> },
  { path: ROUTE.CATALOGS, element: <ProductCatalogsPage /> },
  { path: `${ROUTE.CATALOGS}/:id`, element: <ProductCatalogPage /> },
  { path: `${ROUTE.CATALOGS}/:id/management`, element: <CatalogManagementPage /> },
];

export const Routing = () => {
  const stateToken = useAppSelector(selectAuthToken);
  const token = localStorage.getItem('token');

  const isAuthenticated = !!stateToken || !!token;

  return (
    <Routes>
      {commonRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={
            route.replace ? <Navigate to={isAuthenticated ? ROUTE.HOME : ROUTE.LOGIN} replace /> : route.element
          }
        />
      ))}

      <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
        {privateRoutes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Route>
    </Routes>
  );
};
