import { Routing } from 'pages';
import { ToastContainer } from 'react-toastify';
import { ProcessesWrapper } from 'processes';
import { withProviders } from './providers';

const App = () => {
  return (
    <ProcessesWrapper>
      <Routing />
      <ToastContainer
        position="top-center"
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        theme="dark"
      />
    </ProcessesWrapper>
  );
};

export default withProviders(App);
