import { RESOURCES } from './resources';

export const ACTIONS = {
  [RESOURCES.ORGANIZATION]: {
    DELETE: 'delete',
    UPDATE: 'update',
    LIST: 'list',
    CREATE: 'create',
    ADD_USER: 'add-user',
    LIST_USERS: 'list-users',
    REMOVE_USER: 'remove-user',
    READ: 'read',
  },
  [RESOURCES.CATALOG]: {
    LIST: 'list',
    CREATE_ORG: 'create-org',
    DELETE_ORG: 'delete-org',
    IMPORT: 'import',
    MANAGE_PRIVATE: 'manage-private',
    MANAGE_ORG: 'manage-private',
    PUBLISH: 'publish',
    READ_ORG: 'read-org',
    REQUEST_PUBLISH: 'request-publish',
    SHARE_WITH_USER: 'share-with-user',
    UPDATE_AVAILABILITY: 'update-availability',
    UPDATE_ORG: 'update-org',
  },
  [RESOURCES.PROJECTS]: {
    UPDATE: 'update',
    CREATE: 'create',
    DELETE: 'delete',
    LIST: 'list',
    READ: 'read',
  },
  [RESOURCES.USER]: {
    UPDATE: 'update',
    CREATE: 'create',
    DELETE: 'delete',
    LIST: 'list',
    READ: 'read',
    SUSPEND_USER: 'suspend-user',
  },
  [RESOURCES.GRANT_ROLE]: {
    ORGANIZATION_ADMIN: 'organization-admin',
    GLOBAL_ADMIN: 'global-admin',
    PLATFORM_ADMIN: 'platform-admin',
    CAD_DESIGNER: 'cad-designer',
    CATALOG_MANAGER: 'catalog-manager',
    AVAILABILITY_MANAGER: 'availability-manager',
    RAPID_USER: 'rapid-user',
  },
  [RESOURCES.SUB_ORGANIZATION]: {
    UPDATE: 'update',
    CREATE: 'create',
    DELETE: 'delete',
    LIST: 'list',
    READ: 'read',
    ADD_USER: 'add-user',
    LIST_USERS: 'list-users',
    REMOVE_USER: 'remove-user',
  },
};
