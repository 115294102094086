import { TableBody, TableHead, TableRow, Typography } from '@mui/material';
import { FC } from 'react';
import { HeadCell, Table } from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { OrganizationUserItem } from 'entities/User/components/OrganizationUserItem';

import { User } from 'shared/models';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'shared/hooks';

interface Props {
  users: User[];
}

export const OrgUsersTable: FC<Props> = ({ users }) => {
  const { t } = useTranslation();
  const { organizationId } = useParams();
  const { permissions } = useAppSelector((st) => st.permissions);

  const isUpdateUserAble = organizationId ? permissions[organizationId].user.update : false;

  return (
    <Table height="calc(100vh - 390px)" width={'100%'} sx={{ position: 'relative' }}>
      <TableHead>
        <TableRow>
          <HeadCell sx={{ pl: { xs: '20px', xl: '60px' } }}>{t('name')}</HeadCell>
          <HeadCell> {t('permission')}</HeadCell>
          <HeadCell> {t('mail')}</HeadCell>
          <HeadCell> {t('telephone')}</HeadCell>

          <HeadCell sx={{ px: '20px' }}> {t('status')}</HeadCell>
          {isUpdateUserAble && (
            <HeadCell sx={{ textAlign: 'center', pr: { xs: '20px', xl: '75px' }, width: '180px' }}>
              {t('details')}
            </HeadCell>
          )}
        </TableRow>
      </TableHead>

      <TableBody>
        {users.length ? (
          users.map((u) => <OrganizationUserItem key={u?.userName} user={u} />)
        ) : (
          <Typography
            sx={{
              position: 'absolute',
              top: '70px',
              fontSize: 18,
              width: '100%',
              textAlign: 'center',
              fontWeight: 600,
            }}
          >
            No Users
          </Typography>
        )}
      </TableBody>
    </Table>
  );
};
