import { useEffect, useState } from 'react';
import { ROLE_KEY, ROOT_ORG_ID } from 'shared/constants';
import { useAppSelector } from 'shared/hooks';

const defaultId = 'default';

export const useCatalogAllowedOrgs = (open: boolean, isForOrg: boolean) => {
  const { userRoles } = useAppSelector((st) => st.permissions);
  const { flattedOrgs } = useAppSelector((st) => st.orgs);

  const [noOrgsMessage, setNoOrgsMessage] = useState<string>('');

  const [catalogOrgId, setCatalogOrgId] = useState<string>('');
  const onChangeCatalogOrgId = (value: string) => setCatalogOrgId(value);

  const allowedOrgIds = Object.entries(userRoles)
    .filter(([_, value]) => value.includes(ROLE_KEY.CATALOG_MANAGER))
    .map(([orgId]) => (orgId === defaultId ? ROOT_ORG_ID : +orgId));
  // const allowedOrgIds = Object.entries(permissions)
  //   .filter(([_, value]) => value.catalog['manage-org'])
  //   .map(([orgId]) => +orgId);
  const allowedOrgs = flattedOrgs.filter((o) => allowedOrgIds.includes(o.id));

  useEffect(() => {
    if (!open) {
      setNoOrgsMessage('');
      return;
    }

    if (!allowedOrgs.length) {
      setNoOrgsMessage('No available organizations');
      return;
    }

    setCatalogOrgId(allowedOrgs[0].organizationId);
  }, [open, isForOrg]);

  return {
    allowedOrgs,
    noOrgsMessage,
    catalogOrgId,
    onChangeCatalogOrgId,
  };
};
