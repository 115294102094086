import { FC, useRef } from 'react';
import { IconButton, Popover, Stack, TextField, Typography } from '@mui/material';
import { TableProductInfo } from 'shared/models';
import { TranslationItem } from './index';
import { useAnchorElement } from 'shared/hooks';
import { Icon } from 'shared/ui';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

interface Props {
  item: TableProductInfo;
  onUpdateItem: (val: string) => void;
  selected: string;
  onSelect: (id: string) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  error: string | null;
}

export const EditDescription: FC<Props> = ({ onSelect, selected, item, onUpdateItem, onKeyDown, error }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { open, anchorEl, onOpenMenu, onCloseMenu } = useAnchorElement();

  return (
    <TranslationItem
      onClick={() => onSelect(item.id)}
      onKeyDown={onKeyDown}
      tabIndex={0}
      sx={{
        boxSizing: 'border-box',
        textTransform: 'none',
        padding: '4px 8px !important',
        minWidth: '360px',
        maxWidth: '360px',
        borderRight: '1px solid lightgrey',
        ...(selected === item.id && { bgcolor: 'primary.light' }),
        ...(error && { border: '2px solid red', padding: '2px 6px !important' }),
      }}
    >
      {selected === item.id ? (
        <Stack direction="row">
          <TextField
            fullWidth
            value={item.description}
            onChange={(e) => onUpdateItem(e.target.value)}
            inputRef={inputRef}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                inputRef.current?.blur();
              }
            }}
            sx={{
              input: {
                color: 'primary.main',
                fontSize: 16,
                fontWeight: 400,
                lineHeight: '16px',
                letterSpacing: '0.16px',
                p: 0,
              },
              '.MuiOutlinedInput-notchedOutline': { border: 'none' },
            }}
          />

          {!!error && (
            <IconButton onClick={onOpenMenu} sx={{ p: '0px' }}>
              <Icon path="error-help" sx={{ width: '14px' }} />
            </IconButton>
          )}

          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onCloseMenu}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            sx={{ '& .MuiPaper-root': { borderRadius: '6px' }, mt: 1 }}
          >
            <Stack
              alignItems={'center'}
              sx={{ textAlign: 'center', maxWidth: '230px', color: 'error.main', fontSize: 14, p: 1, pt: 0 }}
            >
              <PlayArrowIcon sx={{ height: '16px', rotate: '90deg', color: 'error.main' }} />

              {error}
            </Stack>
          </Popover>
        </Stack>
      ) : (
        <Typography noWrap>{item.description}</Typography>
      )}
    </TranslationItem>
  );
};
