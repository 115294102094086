import React from 'react';
import { AuthLayout } from 'shared/ui';
import { Login } from 'widgets/Login';

export const LoginPage = () => {
  return (
    <AuthLayout>
      <Login />
    </AuthLayout>
  );
};
