export const formatDate = (stamp: string) => {
  if (!stamp || isNaN(Date.parse(stamp))) {
    return '';
  }

  const date = new Date(stamp);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear().toString().slice(-2);

  return `${day.toString().padStart(2, '0')}.${month.toString().padStart(2, '0')}.${year}`;
};
