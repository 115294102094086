import { useState } from 'react';
import { STATUS } from 'shared/constants';
import { useAppSelector } from 'shared/hooks';
import { Catalog, User } from 'shared/models';

export const useCatalogShareEmail = (catalog: Catalog) => {
  const { users } = useAppSelector((st) => st.users);

  const userMail = localStorage.getItem('email');

  const alreadySharedWithUserIDs = catalog?.sharings?.map((s) => s.sharedWithId) ?? [];

  const activeUsers = users.filter(
    (u) => u.status === STATUS.ACTIVE && u.email !== userMail && !alreadySharedWithUserIDs.includes(u.userId)
  );

  const [user, setUser] = useState<User | null>(null);
  const [openSearch, setOpenSearch] = useState(false);
  const onToggleSearch = () => setOpenSearch(!openSearch);

  const onUserChange = (_: any, user: User | null) => {
    setUser(user);
  };

  return {
    openSearch,
    onToggleSearch,
    user,
    activeUsers,
    onUserChange,
  };
};
