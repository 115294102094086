export enum PRODUCT_KEY {
  ID = 'id',
  SKU = 'sku',
  DESC = 'description',

  BRAND = 'brand',
  BRAND_ID = 'brandId',
  SUBTYPE = 'subtype',
  GROUP = 'group',
  GROUP_ID = 'groupId',
  STRING_RESOURCES = 'stringResources',

  INLET = 'inlet',
  OUTLET = 'outlet',
  OPT_FACTOR1 = 'optimizationFactor1',
  OPT_FACTOR2 = 'optimizationFactor2',

  CLASS = 'class',
  THICKNESS = 'thicknes',
  INT_RNL = 'intrnl',
  V_MAX = 'vmax',
  HW_COF = 'hwcof',
  PIPE_DHA = 'pipedha',
  PIPE_DHB = 'pipedhb',
  PIPE_DHC = 'pipedhc',

  NOZZLE = 'nozzle',
  SWIVEL = 'swivel',

  Q_NOM = 'qnom',
  P_MIN = 'pmin',
  P_MAX = 'pmax',
  PRESSURE_NOMINAL_M = 'pressureNominalM',
  KD = 'kd',
  EMITTER_QA = 'emitterqa',
  EMITTER_QB = 'emitterqb',

  SPACING = 'spacing',
  QL_HR_100M = 'qlHr100m',

  EDC_PROFILES = 'edcProfiles',
  FATHER_ID = 'fatherEmitterId',
  ORIENT = 'orient',
  PRESSURE = 'pressure',
  RISER = 'riser',
  BASE_DIS = 'basedis',
  FLOW_RATE = 'flowRate',
  RANGE = 'range',
}
