import { FC, useRef } from 'react';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { Organization } from 'shared/models';
import { Icon } from 'shared/ui';
import { borderDashStyle } from '../OrganizationItem/borderDashStyle';

interface Props {
  org: Organization;
  depth: number;
  isOpen?: boolean;
  onToggle?: () => void;
  currentOrgId: string;
  onChangeCurrentOrgId: (id: string) => void;
  disabled: boolean;
  onSetBtnCoord: (y: number) => void;
  isLastChild?: boolean;
}

export const OrgTitle: FC<Props> = ({
  org,
  depth,
  isOpen,
  onToggle,
  currentOrgId,
  onChangeCurrentOrgId,
  disabled,
  onSetBtnCoord,
  isLastChild,
}) => {
  const refTitle = useRef<HTMLDivElement>(null);

  const haveSubOrgs = org.subOrganizations.length > 0;
  const isSelected = org.organizationId === currentOrgId;

  const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (!onToggle || !haveSubOrgs) return;

    onToggle();
  };

  const handleClick = () => {
    onChangeCurrentOrgId(org.organizationId);
    const y = refTitle.current?.getBoundingClientRect().y;
    onSetBtnCoord(y ?? 0);
  };

  return (
    <Stack
      onClick={handleClick}
      ref={refTitle}
      direction="row"
      alignItems="center"
      className={'ROOT-ITEM'}
      sx={{
        pl: `${depth * 28 + 5}px`,
        height: '48px',
        bgcolor: isSelected ? 'primary.light' : 'transparent',
        zIndex: 6,
        borderBottom: '1px solid',
        borderColor: 'info.light',
        cursor: 'pointer',
        position: 'relative',
      }}
    >
      <Box sx={{ position: 'relative' }}>
        {!!depth && (
          <Box
            sx={{
              position: 'absolute',
              width: '25px',
              height: '2px',
              right: '20px',
              top: '50%',
              ...borderDashStyle,
            }}
          />
        )}

        {haveSubOrgs ? (
          <IconButton
            onClick={handleOpen}
            onDoubleClick={handleOpen}
            sx={{
              transform: isOpen ? 'rotate(0deg) translateY(4px)' : 'rotate(-90deg)',
              transition: 'all 0.3s',
              position: 'relative',
              zIndex: 1,
              width: '32px',
              height: '32px',
            }}
          >
            <Icon path={`org-arrow`} />
          </IconButton>
        ) : (
          <Icon path={'org-sub-ball'} sx={{ mx: '8px', mt: '8px', position: 'relative', zIndex: 6 }} />
        )}
      </Box>

      <Typography
        noWrap
        sx={{ color: disabled ? 'info.dark' : 'darksome.main', textTransform: 'capitalize', userSelect: 'none' }}
      >
        {org.organizationName}
      </Typography>
    </Stack>
  );
};
