import { FC } from 'react';
import { CircularProgress, Divider, ListItemButton, Stack, styled } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { DRAWER_CLOSED_WIDTH, DRAWER_OPEN_WIDTH } from './constants';
import { MenuLink, UserProfile } from './components';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { selectOpenSide, selectPermissionsLoading, toggleMenu } from 'shared/slices';
import { useSideMenuItems } from './hooks/useSideMenuItems';

const Line = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.info.dark,
  margin: '10px 12px',
}));

export const SideMenu: FC = () => {
  const dispatch = useAppDispatch();
  const isOpenSide = useAppSelector(selectOpenSide);
  const isLoading = useAppSelector(selectPermissionsLoading);

  const { sideMenuItems } = useSideMenuItems();

  const handleToggle = () => dispatch(toggleMenu());

  return (
    <Stack
      sx={{
        width: isOpenSide ? `${DRAWER_OPEN_WIDTH}px` : `${DRAWER_CLOSED_WIDTH}px`,
        minWidth: isOpenSide ? `${DRAWER_OPEN_WIDTH}px` : `${DRAWER_CLOSED_WIDTH}px`,
        bgcolor: 'primary.main',
        height: '100%',
        transition: 'all 0.3s',
        overflow: 'hidden',
        justifyContent: 'flex-start',
      }}
    >
      <UserProfile />

      {isLoading ? (
        <CircularProgress color="info" sx={{ margin: '0 auto' }} />
      ) : (
        sideMenuItems.map((item) => <MenuLink key={item.title} {...item} />)
      )}

      <Line />

      {/* {location.pathname === '/main/home' && (
        <>
          {BOTTOM_MENU_ITEMS.map((item) => (
            <MenuLink key={item.title} {...item} />
          ))}

          <Line />

          <Typography
            noWrap
            sx={{
              fontWeight: 700,
              color: 'info.main',
              fontSize: '18px',
              mx: '14px',
              visibility: isOpenSide ? 'visible' : 'hidden',
            }}
          >
            {t('favorite_files')}:
          </Typography>
          {BOTTOM_MENU_ITEMS.map((item) => (
            <MenuLink key={item.title} {...item} />
          ))}
          <Line />
        </>
      )} */}

      <Stack sx={{ pb: '15px', flexGrow: 1 }}>
        <Stack
          sx={{
            flexGrow: 1,
          }}
        />

        <ListItemButton
          disableRipple
          onClick={handleToggle}
          sx={{
            justifyContent: 'flex-end',
            transition: 'all 0.3s',
            maxHeight: '44px',
            px: '10px',
          }}
        >
          {isOpenSide ? (
            <ChevronLeft color="info" sx={{ fontSize: 35 }} />
          ) : (
            <ChevronRight color="info" sx={{ fontSize: 35 }} />
          )}
        </ListItemButton>
      </Stack>
    </Stack>
  );
};
