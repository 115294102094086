import { useLayoutEffect } from 'react';
import { useAppDispatch } from 'shared/hooks';
import { setUsers, setUsersLoading } from 'shared/slices';
import { BaseLayout } from 'shared/ui';
import { SideMenu } from 'widgets/SideMenu';
import { TabBar } from 'widgets/TabBar';
import { UsersManagement } from 'widgets/UsersManagement';
import { UserDetails } from 'widgets';
import { UserService } from 'shared/services';

export const UsersManagementPage = () => {
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    const getData = async () => {
      dispatch(setUsersLoading(true));

      const users = await UserService.getUsers();
      if (users) dispatch(setUsers(users));

      dispatch(setUsersLoading(false));
    };

    getData();
  }, []);

  return (
    <BaseLayout tabBar={<TabBar />} sideMenu={<SideMenu />}>
      <UsersManagement />
      <UserDetails />
    </BaseLayout>
  );
};
