import { BaseLayout } from 'shared/ui';
import { Home } from 'widgets/Home';
import { SideMenu } from 'widgets/SideMenu';
import { TabBar } from 'widgets/TabBar';

export const HomePage = () => {
  return (
    <BaseLayout tabBar={<TabBar />} sideMenu={<SideMenu />}>
      <Home />
    </BaseLayout>
  );
};
