import { FC } from 'react';
import { CircularProgress, TableCell, TableRow } from '@mui/material';
import { styled } from '@mui/system';
import { TableProductInfo } from 'shared/models';
import { ACTION_NAME } from 'shared/constants';
import { useTranslation } from 'react-i18next';
import { Icon } from 'shared/ui';

const Cell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.secondary.light,
  fontSize: '16px',
  lineHeight: '16px',
  border: 'none',
  paddingLeft: 0,
  paddingBlock: '12px',
  whiteSpace: 'nowrap',
  borderBottom: '1px solid lightgrey',
}));

const actions = ['created', 'deleted', 'updated'];

interface Props {
  product: TableProductInfo;
  action: ACTION_NAME;
  isReverting?: boolean;
  onRevert?: () => void;
  isLoading?: boolean;
  status?: string;
}

export const UpdatedCatalogProduct: FC<Props> = ({
  product,
  action,
  isReverting,
  // onRevert,
  isLoading,
  status,
}) => {
  const { t } = useTranslation();

  return (
    <TableRow sx={{ height: '52px' }}>
      <Cell sx={{ pl: '24px' }}>{product.sku}</Cell>
      <Cell>{product.description}</Cell>
      <Cell>{t(actions[action])}</Cell>

      {isReverting ? (
        // <Cell>
        //   <IconButton onClick={onRevert}>
        //     <Icon path={`products-view/catalog-revert`} />
        //   </IconButton>
        // </Cell>
        <></>
      ) : (
        <Cell>
          {isLoading ? (
            <CircularProgress size={24} />
          ) : status === 'Succeeded' ? (
            <Icon path={`circle-btn-done`} />
          ) : (
            <Icon path={`circle-btn-close`} />
          )}
        </Cell>
      )}
    </TableRow>
  );
};
