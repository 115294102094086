import React, { FC, useCallback, useMemo, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useAppDispatch, useAppNavigator, useAppSelector } from 'shared/hooks';
import { addTab, openCatalog } from 'shared/slices';
import { Catalog, TabItem } from 'shared/models';
import { CATALOG_OWNER_TYPE, CATALOG_TYPE } from 'shared/constants';
import { flatOrgs, formatDate, getOrgIds } from 'shared/lib';
import { useTranslation } from 'react-i18next';
import { PrivateIcon } from '../../../shared/ui/icons/PrivateIcon';
import { OrganizationIcon } from '../../../shared/ui/icons/OrganizationIcon';
import { PublicIcon } from '../../../shared/ui/icons/PublicIcon';
import {
  CatalogArchiveModal,
  CatalogDeleteModal,
  CatalogExportModal,
  CatalogItemActions,
  CatalogRemoveAccessModal,
  CatalogRenameModal,
  CatalogRestoreModal,
  CatalogShareModal,
  ManageAccessCatalogModal,
} from 'features';
import { HoverToolTip } from 'shared/ui';
import { localStorageService } from 'shared/services';
import { ROUTE } from 'shared/enum';

const Cell = styled(Stack)(({ theme }) => ({
  paddingLeft: '30px',
  fontSize: '18px',
  lineHeight: '16px',
  fontWeight: 600,
  border: 'none',
  color: theme.palette.darksome.main,
  userSelect: 'none',
  cursor: 'pointer',
}));

const catalogOwners = {
  [CATALOG_OWNER_TYPE.PRIVATE]: 'Private',
  [CATALOG_OWNER_TYPE.ORG]: 'Organization',
  [CATALOG_OWNER_TYPE.PUBLISHED]: 'Public',
};

const catalogIcons = {
  [CATALOG_OWNER_TYPE.PRIVATE]: <PrivateIcon width="24" height="24" viewBox="0 0 24 24" />,
  [CATALOG_OWNER_TYPE.ORG]: <OrganizationIcon width="20" height="20" viewBox="0 0 20 20" />,
  [CATALOG_OWNER_TYPE.PUBLISHED]: <PublicIcon width="20" height="20" viewBox="0 0 20 20" />,
};

interface Props {
  catalog: Catalog;
}

export const CatalogItem: FC<Props> = ({ catalog }) => {
  const { id, title, owner } = catalog;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { navigate, navigateToCurrentCatalog } = useAppNavigator();

  const { flattedOrgs } = useAppSelector((st) => st.orgs);
  const { users } = useAppSelector((st) => st.users);
  const { permissions } = useAppSelector((st) => st.permissions);
  const { organizations } = useAppSelector((st) => st.orgs);

  const [selected, setSelected] = useState(false);

  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [openRemoveAccessModal, setOpenRemoveAccessModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [openManageAccessModal, setOpenManageAccessModal] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openArchiveModal, setOpenArchiveModal] = useState(false);
  const [openRestoreModal, setOpenRestoreModal] = useState(false);

  const toggleRenameModal = () => setOpenRenameModal((prev) => !prev);
  const toggleRemoveAccessModal = () => setOpenRemoveAccessModal((prev) => !prev);
  const toggleShareModal = () => setOpenShareModal((prev) => !prev);
  const toggleManageAccessModal = () => setOpenManageAccessModal((prev) => !prev);
  const toggleExportModal = () => setOpenExportModal((prev) => !prev);
  const toggleDeleteModal = () => setOpenDeleteModal((prev) => !prev);
  const toggleArchiveModal = () => setOpenArchiveModal((prev) => !prev);
  const toggleRestoreModal = () => setOpenRestoreModal((prev) => !prev);

  const catalogType = t(catalogOwners[owner.type as CATALOG_OWNER_TYPE]);
  const typeIcon = catalogIcons[owner.type as CATALOG_OWNER_TYPE];
  const organizationsIds = useMemo(() => getOrgIds(flatOrgs(organizations)), []);

  const ownerName = useMemo(() => {
    if (owner.type === CATALOG_OWNER_TYPE.ORG) {
      return flattedOrgs.find((o) => o.organizationId === owner.id)?.organizationName ?? 'Unknown';
    } else {
      return users.find((u) => u.userId === owner.id)?.name ?? 'Unknown';
    }
  }, [owner]);

  const isManagePrivatePermissionAble = Object.values(permissions).some(
    (p) => p.catalog && p.catalog['manage-private']
  );

  const handleSelect = () => setSelected(!selected);
  const handleOpenCatalog = () => {
    const tab = localStorageService.tabs.find((t) => t.id === id);
    if (tab) {
      navigate(tab.route);
      return;
    }

    const tabItem: TabItem = {
      id,
      title,
      kind: 'catalog',
      catalogInfo: { owner: ownerName, type: owner.type as CATALOG_OWNER_TYPE },
      route: `${ROUTE.CATALOGS}/${id}?type=${CATALOG_TYPE.PIPE}`,
    };
    dispatch(addTab(tabItem));
    handleSaveTabToLS(tabItem);
    dispatch(openCatalog(id));
    navigateToCurrentCatalog(id);
  };

  const handleSaveTabToLS = (tab: TabItem) => {
    const tabs: TabItem[] = localStorageService.tabs;
    const isPushed = tabs.find((t) => t.id === tab.id);
    if (!isPushed) {
      tabs.push(tab);
      localStorageService.tabs = tabs;
    }
  };

  const handleRemoveCatalog = () => console.log();
  const checkAbilityToEdit = (catalog: Catalog) =>
    useCallback(() => {
      if (catalog.owner.type === CATALOG_OWNER_TYPE.PRIVATE) return isManagePrivatePermissionAble;
      if (catalog.owner.type === CATALOG_OWNER_TYPE.ORG) {
        const organizationId = organizationsIds.find((o) => o.organizationId === catalog.owner.id)?.id;
        return !!organizationId && permissions[organizationId].catalog['manage-private'];
      }
      return true;
    }, []);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={handleSelect}
        onDoubleClick={handleOpenCatalog}
        sx={{
          cursor: 'pointer',
          height: '60px',
          position: 'relative',
          bgcolor: selected ? 'rgba(29, 135, 66, 0.04)' : 'white',
          '&:hover': { bgcolor: 'rgba(29, 135, 66, 0.04)' },
        }}
      >
        <Cell sx={{ width: '20%', pl: '20px', pr: '5px' }}>
          <HoverToolTip title={title} placement="top-start">
            <Typography noWrap sx={{ fontSize: '18px', lineHeight: '16px', fontWeight: 600 }}>
              {title}
            </Typography>
          </HoverToolTip>
        </Cell>
        <Cell sx={{ width: '20%', pl: 0, pr: '5px' }}>
          <HoverToolTip title={catalogType} placement="top-start">
            <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
              {typeIcon}
              <Typography noWrap sx={{ fontSize: '18px', lineHeight: '16px', fontWeight: 600 }}>
                {catalogType}
              </Typography>
            </Stack>
          </HoverToolTip>
        </Cell>
        <Cell sx={{ width: '20%', pl: 0, pr: '5px' }}>
          <HoverToolTip title={ownerName} placement="top-start">
            <Typography noWrap sx={{ fontSize: '18px', lineHeight: '16px', fontWeight: 600 }}>
              {ownerName}
            </Typography>
          </HoverToolTip>
        </Cell>
        <Cell sx={{ width: '30%', pl: 0, pr: '5px' }}>{formatDate(catalog.auditInfo.modifiedDate)}</Cell>
        <Cell direction={'row'} justifyContent={'flex-end'} sx={{ width: '10%', pl: 0, pr: '32px' }}>
          <CatalogItemActions
            catalog={catalog}
            handleOpenCatalog={handleOpenCatalog}
            handleRenameCatalog={toggleRenameModal}
            handleRemoveCatalogAccess={toggleRemoveAccessModal}
            handleShareCatalog={toggleShareModal}
            handleManageAccessCatalog={toggleManageAccessModal}
            handleExportCatalog={toggleExportModal}
            handleDeleteCatalog={toggleDeleteModal}
            handleArchiveCatalog={toggleArchiveModal}
            handleRestoreCatalog={toggleRestoreModal}
          />
        </Cell>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '1px',
            bgcolor: 'lightgrey',
            zIndex: 10,
          }}
        />
      </Stack>

      <CatalogRenameModal catalog={catalog} open={openRenameModal} onClose={toggleRenameModal} />
      <CatalogShareModal catalog={catalog} open={openShareModal} onClose={toggleShareModal} />
      <ManageAccessCatalogModal catalog={catalog} open={openManageAccessModal} onClose={toggleManageAccessModal} />
      <CatalogExportModal catalogName={title} open={openExportModal} onClose={toggleExportModal} />
      <CatalogDeleteModal
        catalogName={title}
        catalogId={catalog.id}
        open={openDeleteModal}
        onClose={toggleDeleteModal}
      />
      <CatalogRemoveAccessModal catalog={catalog} open={openRemoveAccessModal} onClose={toggleRemoveAccessModal} />
      <CatalogArchiveModal catalogName={title} open={openArchiveModal} onClose={toggleArchiveModal} />
      <CatalogRestoreModal catalogName={title} open={openRestoreModal} onClose={toggleRestoreModal} />
    </>
  );
};

// <TableRow
//   onDoubleClick={handleOpenCatalog}
//   sx={{
//     '&:hover': {
//       backgroundColor: 'secondary.main',
//       cursor: 'pointer',
//     },
//   }}
// >
//   <Cell component="th" sx={{ pl: '34px', ml: '30px' }}>
//     <Stack direction="row" alignItems="center">
//       <Box onClick={handleSelect}>
//         <Icon
//           path={`${palette.mode === 'dark' ? 'DARK' : 'LIGHT'}_THEME/${
//             selected ? 'filled-star' : 'empty-star'
//           }`}
//         />
//       </Box>
//       <Box sx={{ px: '15px' }}>
//         <Icon
//           path={`${palette.mode === 'dark' ? 'DARK' : 'LIGHT'}_THEME/${
//             owner.type === CATALOG_OWNER_TYPE.PRIVATE ? 'private_catalog' : 'public_catalog'
//           }`}
//           //file-img
//         />
//       </Box>
//       <Typography
//         noWrap
//         sx={{
//           color: 'secondary.light',
//           fontSize: '16px',
//           lineHeight: '16px',
//           fontWeight: 600,
//         }}
//       >
//         {title}
//       </Typography>
//     </Stack>
//   </Cell>
//   <Cell component="th">{catalogType}</Cell>
//   <Cell component="th">{ownerName}</Cell>
//   {/* <Cell component="th">3 days ago</Cell> */}
//
//   <Cell component="th" sx={{ pr: '30px' }}>
//     <Stack direction="row">
//       {checkAbilityToEdit(catalog) && (
//         <IconButton onClick={handleOpenCatalog}>
//           <Icon path={`${palette.mode === 'dark' ? 'DARK' : 'LIGHT'}_THEME/edit-action`} />
//         </IconButton>
//       )}
//       <IconButton>
//         <Icon path={`${palette.mode === 'dark' ? 'DARK' : 'LIGHT'}_THEME/share-action`} />
//       </IconButton>
//       <IconButton
//         onClick={() => {
//           dispatch(setCurrentCatalog(catalog));
//           setOpen(true);
//         }}
//       >
//         <Icon path={`${palette.mode === 'dark' ? 'DARK' : 'LIGHT'}_THEME/delete-action`} />
//       </IconButton>
//     </Stack>
//   </Cell>
//
//   <DeleteCatalogModal open={open} onClose={() => setOpen(false)} />
// </TableRow>
