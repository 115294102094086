import { useEffect, useState } from 'react';

const upperRegexp = /^.*((?=.*[A-Z]){1}).*$/;
const lowerRegexp = /^.*((?=.*[a-z]){1}).*$/;
const numberRegexp = /^.*(?=.*\d).*$/;
const specialCharRegexp = /^.*((?=.*[(*&^%$#@!)]){1}).*$/;

export const useNewPassword = () => {
  const [newPassData, setLoginData] = useState({ password: '', confirmPassword: '' });

  const [errors, setErrors] = useState({
    upperCase: true,
    lowerCase: true,
    number: true,
    specialChar: true,
    atLeastSix: true,
    matchPasswords: true,
  });

  const isWrongPassword = Object.values(errors).includes(true);

  const onLoginDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    let upperCase = true;
    let lowerCase = true;
    let number = true;
    let specialChar = true;
    let atLeastSix = true;
    let matchPasswords = true;

    if (upperRegexp.test(newPassData.password)) upperCase = false;
    if (lowerRegexp.test(newPassData.password)) lowerCase = false;
    if (numberRegexp.test(newPassData.password)) number = false;
    if (specialCharRegexp.test(newPassData.password)) specialChar = false;
    if (newPassData.password.length >= 6) atLeastSix = false;
    if (newPassData.password && newPassData.confirmPassword === newPassData.password) matchPasswords = false;

    setErrors({ upperCase, lowerCase, number, specialChar, atLeastSix, matchPasswords });
  }, [newPassData]);

  return {
    newPassData,
    onLoginDataChange,
    errors,
    isWrongPassword,
  };
};
