import { ChangeEvent, useEffect, useState } from 'react';
import { STATUS, emailRegexp, telRegexp } from 'shared/constants';
import { useAppSelector } from 'shared/hooks';
import { selectSelectedUser } from '../../../shared/slices';

export const useUserValues = (open: boolean, onPhoneChange: (phone: string) => void) => {
  const selectedUser = useAppSelector(selectSelectedUser);

  const [userData, setUserData] = useState({
    name: '',
    email: '',
    roles: [] as string[],
    isActive: false,
  });

  useEffect(() => {
    setUserData({
      name: selectedUser?.name ?? '',
      email: selectedUser?.email ?? '',
      roles: selectedUser?.roles ?? [],
      isActive: selectedUser?.status === STATUS.ACTIVE,
    });
    onPhoneChange(selectedUser?.phone ?? '');
    setNameErrorText('');
    setEmailErrorText('');
  }, [open, selectedUser]);

  const [isNoChangeName, setIsNoChangeName] = useState(true);
  const [isNoChangeEmail, setIsNoChangeEmail] = useState(true);

  const [nameErrorText, setNameErrorText] = useState('');
  const [emailErrorText, setEmailErrorText] = useState('');
  const [rolesErrorText, setRolesErrorText] = useState('');

  const onUserDataChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'name') setIsNoChangeName(false);
    if (e.target.name === 'email') setIsNoChangeEmail(false);
    // if (e.target.name === 'phone') {
    //   setIsNoChangePhone(false);
    //   onPhoneChange(e.target.value);
    //   if (e.target.name.length > 15) return;
    // }
    setUserData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const toggleRoles = (roles: string[]) => {
    setUserData((prev) => ({ ...prev, roles }));
  };

  const toggleStatus = () => setUserData((prev) => ({ ...prev, isActive: !prev.isActive }));

  const checkIsValid = () => {
    setNameErrorText('');
    setEmailErrorText('');

    let isValid = true;

    if (!userData.name) {
      setNameErrorText('Please enter user name');
      isValid = false;
    } else if (userData.name.length > 50) {
      isValid = false;
      setNameErrorText('Name length should be max 50');
    }

    if (!userData.email) {
      setEmailErrorText('Please enter your email');
      isValid = false;
    } else if (!emailRegexp.test(userData.email)) {
      setEmailErrorText('Invalid email address');
      isValid = false;
    }

    if (!userData.roles.length) {
      setRolesErrorText('You must select permissions to invite user');
      isValid = false;
    }

    console.log({ userData });

    return isValid;
  };

  useEffect(() => {
    if (!isNoChangeName && !isNoChangeEmail) {
      checkIsValid();
    }
  }, [userData]);

  return {
    userData,
    onUserDataChange,
    toggleRoles,
    toggleStatus,
    checkIsValid,
    nameErrorText,
    emailErrorText,
    rolesErrorText,
  };
};
