import { Tab, styled } from '@mui/material';

export const TAB = styled(Tab)(({ theme }) => ({
  textTransform: 'capitalize',
  marginRight: '12px',
  minHeight: '0px',
  fontWeight: 600,
  '&.Mui-selected': {
    color: theme.palette.darksome.main,
  },
}));
