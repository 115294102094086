import { ACTIONS } from './actions';
import { RESOURCES } from './resources';

// ORGANIZATION

export const ADD_USER_ORGANIZATION_REQUIREMENT = {
  action: ACTIONS[RESOURCES.ORGANIZATION].ADD_USER,
  resource: RESOURCES.ORGANIZATION,
};

export const LIST_USERS_ORGANIZATION_REQUIREMENT = {
  action: ACTIONS[RESOURCES.ORGANIZATION].LIST_USERS,
  resource: RESOURCES.ORGANIZATION,
};

export const LIST_ORGANIZATION_REQUIREMENT = {
  action: ACTIONS[RESOURCES.ORGANIZATION].LIST,
  resource: RESOURCES.ORGANIZATION,
};

export const READ_ORGANIZATION_REQUIREMENT = {
  action: ACTIONS[RESOURCES.ORGANIZATION].READ,
  resource: RESOURCES.ORGANIZATION,
};

export const UPDATE_ORGANIZATION_REQUIREMENT = {
  action: ACTIONS[RESOURCES.ORGANIZATION].UPDATE,
  resource: RESOURCES.ORGANIZATION,
};

export const DELETE_ORGANIZATION_REQUIREMENT = {
  action: ACTIONS[RESOURCES.ORGANIZATION].DELETE,
  resource: RESOURCES.ORGANIZATION,
};

export const REMOVE_USER_ORGANIZATION_REQUIREMENT = {
  action: ACTIONS[RESOURCES.ORGANIZATION].REMOVE_USER,
  resource: RESOURCES.ORGANIZATION,
};

// Sub Organization

export const ADD_USER_SUB_ORGANIZATION_REQUIREMENT = {
  action: ACTIONS[RESOURCES.SUB_ORGANIZATION].ADD_USER,
  resource: RESOURCES.SUB_ORGANIZATION,
};

export const LIST_USERS_SUB_ORGANIZATION_REQUIREMENT = {
  action: ACTIONS[RESOURCES.SUB_ORGANIZATION].LIST_USERS,
  resource: RESOURCES.SUB_ORGANIZATION,
};

export const READ_SUB_ORGANIZATION_REQUIREMENT = {
  action: ACTIONS[RESOURCES.SUB_ORGANIZATION].READ,
  resource: RESOURCES.SUB_ORGANIZATION,
};

export const UPDATE_SUB_ORGANIZATION_REQUIREMENT = {
  action: ACTIONS[RESOURCES.SUB_ORGANIZATION].UPDATE,
  resource: RESOURCES.SUB_ORGANIZATION,
};

export const DELETE_SUB_ORGANIZATION_REQUIREMENT = {
  action: ACTIONS[RESOURCES.SUB_ORGANIZATION].DELETE,
  resource: RESOURCES.SUB_ORGANIZATION,
};

export const CREATE_SUB_ORGANIZATION_REQUIREMENT = {
  action: ACTIONS[RESOURCES.SUB_ORGANIZATION].CREATE,
  resource: RESOURCES.SUB_ORGANIZATION,
};

export const REMOVE_USER_SUB_ORGANIZATION_REQUIREMENT = {
  action: ACTIONS[RESOURCES.SUB_ORGANIZATION].REMOVE_USER,
  resource: RESOURCES.SUB_ORGANIZATION,
};

// User

export const UPDATE_USER_REQUIREMENT = {
  action: ACTIONS[RESOURCES.USER].UPDATE,
  resource: RESOURCES.USER,
};

export const LIST_USER_REQUIREMENT = {
  action: ACTIONS[RESOURCES.USER].LIST,
  resource: RESOURCES.USER,
};

export const READ_USER_REQUIREMENT = {
  action: ACTIONS[RESOURCES.USER].READ,
  resource: RESOURCES.USER,
};

export const SUSPEND_USER_REQUIREMENT = {
  action: ACTIONS[RESOURCES.USER].SUSPEND_USER,
  resource: RESOURCES.USER,
};

// Grant Role

export const ORGANIZATION_ADMIN_GRANT_ROLE_REQUIREMENT = {
  action: ACTIONS[RESOURCES.GRANT_ROLE].ORGANIZATION_ADMIN,
  resource: RESOURCES.GRANT_ROLE,
};

export const GLOBAL_ADMIN_GRANT_ROLE_REQUIREMENT = {
  action: ACTIONS[RESOURCES.GRANT_ROLE].GLOBAL_ADMIN,
  resource: RESOURCES.GRANT_ROLE,
};

export const PLATFORM_ADMIN_GRANT_ROLE_REQUIREMENT = {
  action: ACTIONS[RESOURCES.GRANT_ROLE].PLATFORM_ADMIN,
  resource: RESOURCES.GRANT_ROLE,
};

export const CATALOG_MANAGER_GRANT_ROLE_REQUIREMENT = {
  action: ACTIONS[RESOURCES.GRANT_ROLE].CATALOG_MANAGER,
  resource: RESOURCES.GRANT_ROLE,
};

export const CAD_DESIGNER_GRANT_ROLE_REQUIREMENT = {
  action: ACTIONS[RESOURCES.GRANT_ROLE].CAD_DESIGNER,
  resource: RESOURCES.GRANT_ROLE,
};

export const AVAILABILITY_MANAGER_GRANT_ROLE_REQUIREMENT = {
  action: ACTIONS[RESOURCES.GRANT_ROLE].AVAILABILITY_MANAGER,
  resource: RESOURCES.GRANT_ROLE,
};

export const RAPID_USER_GRANT_ROLE_REQUIREMENT = {
  action: ACTIONS[RESOURCES.GRANT_ROLE].RAPID_USER,
  resource: RESOURCES.GRANT_ROLE,
};

// Catalog

export const LIST_CATALOG_REQUIREMENT = {
  action: ACTIONS[RESOURCES.CATALOG].LIST,
  resource: RESOURCES.CATALOG,
};

export const CREATE_ORG_CATALOG_REQUIREMENT = {
  action: ACTIONS[RESOURCES.CATALOG].CREATE_ORG,
  resource: RESOURCES.CATALOG,
};

export const MANAGE_PRIVATE_CATALOG_REQUIREMENT = {
  action: ACTIONS[RESOURCES.CATALOG].MANAGE_PRIVATE,
  resource: RESOURCES.CATALOG,
};

export const UPDATE_AVAILABILITY_CATALOG_REQUIREMENT = {
  action: ACTIONS[RESOURCES.CATALOG].UPDATE_AVAILABILITY,
  resource: RESOURCES.CATALOG,
};

export const MANAGE_ORG_CATALOG_REQUIREMENT = {
  action: ACTIONS[RESOURCES.CATALOG].MANAGE_ORG,
  resource: RESOURCES.CATALOG,
};
