import { useLayoutEffect } from 'react';
import { useAppDispatch } from 'shared/hooks';
import { setCatalogs, setCatalogsLoading, setOrganizations, setUsers } from 'shared/slices';
import { BaseLayout } from 'shared/ui';
import { CatalogsList } from 'widgets';
import { SideMenu } from 'widgets/SideMenu';
import { TabBar } from 'widgets/TabBar';
import { CatalogService, OrgService, UserService } from 'shared/services';

export const ProductCatalogsPage = () => {
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    const getData = async () => {
      dispatch(setCatalogsLoading(true));
      try {
        const [catalogs, organizations, users] = await Promise.all([
          CatalogService.getCatalogs(),
          OrgService.getOrgs(),
          UserService.getUsers(),
        ]);

        catalogs && dispatch(setCatalogs(catalogs));
        organizations && dispatch(setOrganizations(organizations));
        users && dispatch(setUsers(users));
      } catch (error) {
        console.log('####: error', error);
      } finally {
        dispatch(setCatalogsLoading(false));
      }
    };

    getData();
  }, []);

  return (
    <BaseLayout tabBar={<TabBar />} sideMenu={<SideMenu />}>
      <CatalogsList />
    </BaseLayout>
  );
};
