import { Stack } from '@mui/material';
import { useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import {
  selectCatalogTypes,
  selectPermissions,
  setCatalogTypes,
  setCurrentCatalog,
  setGroups,
  selectGroups,
} from 'shared/slices';
import { BaseLayout } from 'shared/ui';
import { CatalogProducts } from 'widgets';
import { TabBar } from 'widgets/TabBar';
import { CATALOG_TYPE } from 'shared/constants';
import { CatalogService } from 'shared/services';

export const ProductCatalogPage = () => {
  const dispatch = useAppDispatch();
  const { id: catalogId } = useParams();

  const permissions = useAppSelector(selectPermissions);
  const types = useAppSelector(selectCatalogTypes);
  const groups = useAppSelector(selectGroups);
  const isNoTypes = !types?.length;
  const isNoGroups = !groups?.[catalogId as string];

  const [loading, setLoading] = useState(false);

  useLayoutEffect(() => {
    const abortController = new AbortController();

    const getData = async () => {
      if (!catalogId) return;
      setLoading(true);

      try {
        const promiseArray: Promise<any>[] = [];

        if (isNoGroups) {
          const currentCatalogPromise = CatalogService.getCurrentCatalog(catalogId, abortController.signal);
          promiseArray.push(currentCatalogPromise);
        }
        if (isNoTypes) {
          const catalogTypesPromise = CatalogService.getTypes(abortController.signal);
          promiseArray.push(catalogTypesPromise);
        }

        const [currentCatalog, catalogTypes] = await Promise.all(promiseArray);

        if (currentCatalog) {
          dispatch(setCurrentCatalog(currentCatalog));
          dispatch(setGroups({ catalogId, groups: currentCatalog?.groups ?? [] }));
        }
        if (catalogTypes) {
          dispatch(
            setCatalogTypes([
              ...catalogTypes.filter((t: string) => t !== 'General'),
              CATALOG_TYPE.DISTRIBUTION_CURVE,
            ])
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    getData();

    return () => abortController.abort();
  }, [catalogId]);

  const isListPermissionAble = Object.values(permissions).some((p) => p.catalog && p.catalog.list);

  return (
    <BaseLayout tabBar={<TabBar />}>
      <Stack sx={{ flexGrow: 1, overflow: 'hidden', height: '100%' }}>
        {isListPermissionAble && (
          <>
            <CatalogProducts isGroupsLoading={loading} />
          </>
        )}
      </Stack>
    </BaseLayout>
  );
};
