import { Organization } from 'shared/models';

export function getAllSubOrgIds(organizations: Organization[]): number[] {
  const ids: number[] = [];

  function recursiveExtract(obj: Organization) {
    if (obj.id) {
      ids.push(obj.id);
    }

    if (obj.subOrganizations && obj.subOrganizations.length > 0) {
      for (const subOrg of obj.subOrganizations) {
        recursiveExtract(subOrg);
      }
    }
  }

  for (const organization of organizations) {
    recursiveExtract(organization);
  }

  return ids;
}
