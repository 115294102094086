import { Box, Button, Stack } from '@mui/material';
import { useAppSelector } from 'shared/hooks';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { selectCatalogs, selectCatalogsLoading, selectHomeTabState, selectPermissions } from 'shared/slices';
import { BigTitle, Loader, SearchInput } from '../../shared/ui';
import { PlusIcon } from '../../shared/ui/icons/PlusIcon';
import { CatalogsTable } from './components';
import { CatalogOwnerTypeFilter, CatalogSharingTypeFilter, NewCatalogModal, NoCatalogsPopup } from 'features';
import { CATALOG_OWNER_TYPE, USER_CATALOG_SHARING_TYPE } from 'shared/constants';

export type OwnerTypeFilters = {
  [CATALOG_OWNER_TYPE.ORG]: boolean;
  [CATALOG_OWNER_TYPE.PRIVATE]: boolean;
  [CATALOG_OWNER_TYPE.PUBLISHED]: boolean;
};

export const CatalogsList = () => {
  const { t } = useTranslation();

  const permissions = useAppSelector(selectPermissions);
  const catalogs = useAppSelector(selectCatalogs);
  const isLoading = useAppSelector(selectCatalogsLoading);
  const { catalogSearch } = useAppSelector(selectHomeTabState);

  const [searchValue, setSearchValue] = useState(catalogSearch);

  const [sharingTypeFilter, setSharingTypeFilter] = useState<USER_CATALOG_SHARING_TYPE>(
    USER_CATALOG_SHARING_TYPE.ALL
  );
  const [ownerTypeFilters, setOwnerTypeFilters] = useState<OwnerTypeFilters>({
    [CATALOG_OWNER_TYPE.ORG]: false,
    [CATALOG_OWNER_TYPE.PRIVATE]: false,
    [CATALOG_OWNER_TYPE.PUBLISHED]: false,
  });

  const handleChangeOwnerTypeFilter = (value: CATALOG_OWNER_TYPE) => {
    setOwnerTypeFilters((prev) => ({ ...prev, [value]: !prev[value] }));
  };

  const onClearClick = () => setSearchValue('');
  const handleChangeSearchValue = (e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value);

  const [isNewCatalogOpen, setIsNewCatalogOpen] = useState(false);

  // const isManageOrgPermissionAble = Object.values(permissions).some((p) => p.catalog && p.catalog['manage-org']);
  //
  // const isManagePrivatePermissionAble = Object.values(permissions).some(
  //   (p) => p.catalog && p.catalog['manage-private']
  // );

  const isListPermissionAble = Object.values(permissions).some((p) => p.catalog && p.catalog.list);

  // useEffect(() => {
  //   return () => {
  //     dispatch(setHomeTabState({ catalogSearch: searchValue }));
  //   };
  // }, [searchValue]);

  return (
    <Box sx={{ height: '100%', p: '20px 40px' }}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'} mb={'20px'}>
        <BigTitle noWrap sx={{ fontSize: 24 }}>
          {t('Catalogs List')}
        </BigTitle>
        <Button
          onClick={() => setIsNewCatalogOpen(true)}
          sx={{
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontWeight: 500,
            lineHeight: '130%',
            letterSpacing: '0.18px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
            padding: '12px 24px 12px 20px',
            borderRadius: '16px',
            border: '1px solid #1D8742',
            textTransform: 'capitalize',
          }}
        >
          <PlusIcon width="24" height="24" viewBox="0 0 24 24" />
          {t('Create Catalog')}
        </Button>
      </Stack>

      {isLoading ? (
        <Box height={'100%'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Loader />
        </Box>
      ) : (
        <Box
          sx={{
            ...(!catalogs.length && {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }),
            height: { xs: 'calc(100vh - 238px)', lg: 'calc(100vh - 205px)' },
            background: !catalogs.length ? 'rgba(0, 0, 0, 0.20)' : '#fff',
            borderRadius: '8px',
          }}
        >
          {catalogs.length ? (
            <Box sx={{ bgcolor: 'info.main', borderRadius: '8px', mt: '20px' }}>
              <Stack sx={{ width: '100%', pt: '5px' }}>
                <Stack
                  width={'100%'}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ borderBottom: '1px solid grey', pl: '10px', pr: '15px' }}
                >
                  <Stack direction="row">
                    <CatalogSharingTypeFilter
                      filter={sharingTypeFilter}
                      onChange={(tab) => setSharingTypeFilter(tab)}
                    />

                    <Box sx={{ maxWidth: '354px', flexGrow: 1, mt: -0.75 }}>
                      <SearchInput
                        value={searchValue}
                        autoComplete={'new-password'}
                        onChange={handleChangeSearchValue}
                        onClearClick={onClearClick}
                        name={t('Search for a catalog')}
                        icon={'green-search'}
                        sx={{ '& .MuiInputBase-root': { bgcolor: 'none' } }}
                      />
                    </Box>
                  </Stack>

                  <CatalogOwnerTypeFilter
                    filters={ownerTypeFilters}
                    handleCheckFilter={handleChangeOwnerTypeFilter}
                  />
                </Stack>

                {isListPermissionAble && (
                  <CatalogsTable
                    searchValue={searchValue}
                    sharingTypeFilter={sharingTypeFilter}
                    ownerTypeFilters={ownerTypeFilters}
                  />
                )}
              </Stack>
            </Box>
          ) : (
            <NoCatalogsPopup onClick={() => setIsNewCatalogOpen(true)} />
          )}
        </Box>
      )}

      <NewCatalogModal open={isNewCatalogOpen} onClose={() => setIsNewCatalogOpen(false)} />
    </Box>
  );
};
