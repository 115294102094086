import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const ArrowDown: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M11.7043 0.789939C11.6114 0.696211 11.5008 0.621816 11.3789 0.571048C11.257 0.520279 11.1263 0.494141 10.9943 0.494141C10.8623 0.494141 10.7316 0.520279 10.6097 0.571048C10.4879 0.621816 10.3773 0.696211 10.2843 0.789939L5.99433 5.08994L1.70433 0.789939C1.51602 0.601635 1.26063 0.495848 0.994326 0.495848C0.728024 0.495848 0.472629 0.601635 0.284326 0.789939C0.0960219 0.978243 -0.00976562 1.23364 -0.00976562 1.49994C-0.00976563 1.76624 0.0960219 2.02164 0.284326 2.20994L5.28433 7.20994C5.37729 7.30367 5.48789 7.37806 5.60975 7.42883C5.73161 7.4796 5.86231 7.50574 5.99433 7.50574C6.12634 7.50574 6.25704 7.4796 6.3789 7.42883C6.50076 7.37806 6.61136 7.30367 6.70432 7.20994L11.7043 2.20994C11.7981 2.11698 11.8724 2.00638 11.9232 1.88452C11.974 1.76266 12.0001 1.63195 12.0001 1.49994C12.0001 1.36793 11.974 1.23722 11.9232 1.11536C11.8724 0.993503 11.7981 0.882902 11.7043 0.789939Z"
      fill={props.fill ?? 'black'}
    />
  </SvgIcon>
);
