export enum STATUS {
  PENDING,
  PASSWORD_CREATION,
  VERIFICATION,
  ACTIVE,
  DEACTIVATED,
}

export enum ACTION_NAME {
  ADD,
  DELETE,
  CHANGE,
}

export enum SORTING {
  ASC = 'asc',
  DESC = 'desc',
}

export enum CATALOG_TYPE {
  PIPE = 'Pipe',
  EMITTER = 'Emitter',
  INTEGRAL = 'Integral',
  DISTRIBUTION_CURVE = 'Distribution Curve',
}

export enum CATALOG_OWNER_TYPE {
  ORG = 'Org',
  PRIVATE = 'Private',
  PUBLISHED = 'Published',
}

export enum USER_CATALOG_SHARING_TYPE {
  ALL = 'all',
  MY = 'my',
  SHARED = 'shared',
}

export enum CATALOG_SPEC {
  GENERAL,
  PIPE,
  EMITTER,
  INTEGRAL,
  PLACE_0_26,
  PLACE_26_52,
}
export enum CATALOG_MANAGE_TAB {
  GROUPS,
  TRANSLATIONS,
  AVAILABILITY,
}

export enum ORIENTATION {
  UPRIGHT = 'Upright',
  INVERTED = 'Inverted',
}

export enum ROLE_KEY {
  PLATFORM_ADMIN = 'Platform_Admin',
  GLOBAL_ADMIN = 'Global_Admin',
  ORG_ADMIN = 'Organization_Admin',
  CATALOG_MANAGER = 'Catalog_Manager',
  AVAILABILITY_MANAGER = 'Availability_Manager',
  CAD_DESIGNER = 'Designer',
  RAPID_USER = 'Rapid_user',
  HYDRAULIC_TOOL = 'HydraulicTool',
}
