import { FC } from 'react';
import { Box } from '@mui/material';

interface Props {
  open: boolean;
}

export const DisabledFade: FC<Props> = ({ open }) => {
  return <>{open && <Box sx={{ position: 'absolute', inset: 0, bgcolor: 'rgb(0,0,0,0.4)' }} />}</>;
};
