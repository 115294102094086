import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const OrganizationIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <g clipPath="url(#clip0_768_393)">
      <path
        d="M18.125 13.75H16.25V10.625C16.25 10.4592 16.1842 10.3003 16.0669 10.1831C15.9497 10.0658 15.7908 10 15.625 10H10V7.5H11.875C12.0408 7.5 12.1997 7.43415 12.3169 7.31694C12.4342 7.19973 12.5 7.04076 12.5 6.875V1.875C12.5 1.70924 12.4342 1.55027 12.3169 1.43306C12.1997 1.31585 12.0408 1.25 11.875 1.25H6.875C6.70924 1.25 6.55027 1.31585 6.43306 1.43306C6.31585 1.55027 6.25 1.70924 6.25 1.875V6.875C6.25 7.04076 6.31585 7.19973 6.43306 7.31694C6.55027 7.43415 6.70924 7.5 6.875 7.5H8.75V10H3.125C2.95924 10 2.80027 10.0658 2.68306 10.1831C2.56585 10.3003 2.5 10.4592 2.5 10.625V13.75H0.625C0.45924 13.75 0.300269 13.8158 0.183058 13.9331C0.065848 14.0503 0 14.2092 0 14.375L0 18.125C0 18.2908 0.065848 18.4497 0.183058 18.5669C0.300269 18.6842 0.45924 18.75 0.625 18.75H5.625C5.79076 18.75 5.94973 18.6842 6.06694 18.5669C6.18415 18.4497 6.25 18.2908 6.25 18.125V14.375C6.25 14.2092 6.18415 14.0503 6.06694 13.9331C5.94973 13.8158 5.79076 13.75 5.625 13.75H3.75V11.25H15V13.75H13.125C12.9592 13.75 12.8003 13.8158 12.6831 13.9331C12.5658 14.0503 12.5 14.2092 12.5 14.375V18.125C12.5 18.2908 12.5658 18.4497 12.6831 18.5669C12.8003 18.6842 12.9592 18.75 13.125 18.75H18.125C18.2908 18.75 18.4497 18.6842 18.5669 18.5669C18.6842 18.4497 18.75 18.2908 18.75 18.125V14.375C18.75 14.2092 18.6842 14.0503 18.5669 13.9331C18.4497 13.8158 18.2908 13.75 18.125 13.75Z"
        fill="#8C8D92"
      />
    </g>
    <defs>
      <clipPath id="clip0_768_393">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
