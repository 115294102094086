import { MenuItem } from '../constants';
import { useEffect, useMemo } from 'react';
import { useAppNavigator, useAppSelector } from '../../../shared/hooks';
import { useLocation } from 'react-router-dom';
import { ROUTE } from 'shared/enum';
import { selectPermissions } from '../../../shared/slices';

type ReturnValue = {
  sideMenuItems: MenuItem[];
};

export const useSideMenuItems = (): ReturnValue => {
  const {
    navigateToHome,
    navigateToOrganizations,
    navigateToCatalogs,
    navigateToUsersManagement,
    navigateToFieldRapidIrrigationApp,
    navigateToHTApp,
  } = useAppNavigator();

  const location = useLocation();

  const permissions = useAppSelector(selectPermissions);

  const isListUserPermissionAble = Object.values(permissions).some((p) => p.user && p.user.list);
  const isListOrganizationPermissionAble = Object.values(permissions).some(
    (p) => p.organization && p.organization.list
  );
  const isListCatalogPermissionAble = Object.values(permissions).some((p) => p.catalog && p.catalog.list);
  const isRapidUserPermissionAble = Object.values(permissions).some((p) => p.applications['rapid-access']);
  const isHTUserPermissionAble = Object.values(permissions).some((p) => p.applications['ht-access']);

  const sideMenuItems: MenuItem[] = useMemo(
    () => [
      ...(isListOrganizationPermissionAble
        ? [
            {
              title: 'organizations',
              route: ROUTE.ORGANIZATIONS,
              icon: 'menuIcons/organizations',
              callback: navigateToOrganizations,
            },
          ]
        : []),
      ...(isListUserPermissionAble
        ? [
            {
              title: 'users_management',
              route: ROUTE.USER_MANAGEMENT,
              icon: 'menuIcons/organizations',
              callback: navigateToUsersManagement,
            },
          ]
        : []),
      ...(isListCatalogPermissionAble
        ? [
            {
              title: 'products_catalog',
              route: ROUTE.CATALOGS,
              icon: 'menuIcons/products',
              callback: navigateToCatalogs,
            },
          ]
        : []),
      ...(isRapidUserPermissionAble
        ? [
            {
              title: 'RAPID Field app',
              route: '',
              icon: 'menuIcons/rapid_field',
              callback: navigateToFieldRapidIrrigationApp,
            },
          ]
        : []),
      ...(isHTUserPermissionAble
        ? [
            {
              title: 'Hydraulic Tool App',
              route: '',
              icon: 'menuIcons/rapid_field',
              callback: navigateToHTApp,
            },
          ]
        : []),
    ],
    [permissions]
  );

  useEffect(() => {
    const isForbidOrgRoute = location.pathname === ROUTE.ORGANIZATIONS && !isListOrganizationPermissionAble;
    const isForbidUsersRoute = location.pathname === ROUTE.USER_MANAGEMENT && !isListUserPermissionAble;
    const isForbidCatalogRoute = location.pathname === ROUTE.CATALOGS && !isListCatalogPermissionAble;

    if (isForbidOrgRoute || isForbidUsersRoute || isForbidCatalogRoute) {
      navigateToHome();
    }
  }, [location]);

  return { sideMenuItems };
};
