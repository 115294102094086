import { ChangeEvent, useEffect, useState } from 'react';
import { ROLE_KEY, emailRegexp } from 'shared/constants';
import { useAppSelector } from 'shared/hooks';
import { useParams } from 'react-router-dom';
import { CheckUserEmailDTO, CheckUserEmailResponse } from '../../../shared/models';
import { selectAllRoles, selectOpenInviteUser } from '../../../shared/slices';
import { UserService } from 'shared/services';

export const useInviteUserValues = (onPhoneChange: (phone: string) => void) => {
  const { organizationId } = useParams();
  const openInviteUser = useAppSelector(selectOpenInviteUser);
  const allRoles = useAppSelector(selectAllRoles);

  const [isLoading, setIsLoading] = useState(false);
  const [isExist, setIsExist] = useState(true);
  const [isUserAlreadyAdded, setIsUserAlreadyAdded] = useState(false);

  const [isCanShowInfoText, setIsCanShowInfoText] = useState(false);

  const [userData, setUserData] = useState({
    name: '',
    email: '',
    roles: [] as string[],
  });

  const [nameErrorText, setNameErrorText] = useState('');
  const [emailErrorText, setEmailErrorText] = useState('');
  const [rolesErrorText, setRolesErrorText] = useState('');

  const inviteError = nameErrorText || emailErrorText || rolesErrorText;

  const checkEmail = async (email?: string) => {
    setIsUserAlreadyAdded(false);
    setIsExist(false);

    const currentEmail = email || userData.email;

    if (!organizationId) return;
    if (!emailRegexp.test(currentEmail)) {
      return;
    }

    const body: CheckUserEmailDTO = { organizationId: +organizationId, email: currentEmail };
    setIsLoading(true);

    const response = await UserService.checkUserExist(body);
    if (response) {
      const { name, phone, userExistIn, userExistInCurrent }: CheckUserEmailResponse = response;

      if (userExistIn) {
        if (userExistInCurrent) {
          setIsUserAlreadyAdded(true);
        }
        setUserData((prev) => ({ ...prev, name, phone }));
        onPhoneChange && onPhoneChange(phone);
        setNameErrorText('');
        setIsCanShowInfoText(true);
        setIsExist(true);
      } else {
        setUserData((prev) => ({ ...prev, phone: '', name: '' }));
        setIsExist(false);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (openInviteUser) {
      setUserData({ name: '', roles: [], email: '' });
      setNameErrorText('');
      setEmailErrorText('');
      setRolesErrorText('');
      setIsUserAlreadyAdded(false);
      setIsExist(false);
    }
  }, [openInviteUser]);

  const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserData((prev) => ({ ...prev, name: e.target.value }));
  };

  const checkIsNameValid = () => {
    if (!userData.name) {
      setNameErrorText('Please enter user name');
    } else if (userData.name.length > 50) {
      setNameErrorText('Name length should be max 50');
    } else {
      setNameErrorText('');
    }
  };

  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setUserData((prev) => ({ ...prev, email: e.target.value }));
  };

  const checkIsEmailValid = (email?: string) => {
    const currentEmail = email || userData.email;
    if (!currentEmail) {
      setEmailErrorText('Invalid email');
    } else if (!emailRegexp.test(currentEmail)) {
      setEmailErrorText('Invalid email');
    } else {
      setEmailErrorText('');
    }
  };

  const onRolesChange = (roles: string[]) => {
    setUserData((prev) => ({ ...prev, roles }));
    setRolesErrorText('');
  };

  const checkIsValid = () => {
    setNameErrorText('');
    setEmailErrorText('');
    setRolesErrorText('');

    let isValid = true;

    if (!userData.name) {
      setNameErrorText('Please enter user name');
      isValid = false;
    } else if (userData.name.length > 50) {
      setNameErrorText('Name length should be max 50');
    }

    if (!userData.email) {
      setEmailErrorText('Please enter email');
      isValid = false;
    } else if (!emailRegexp.test(userData.email)) {
      setEmailErrorText('Invalid email');
      isValid = false;
    }

    if (!userData.roles.length) {
      setRolesErrorText('You must select permissions to invite user');
      isValid = false;
    }

    return isValid;
  };

  const rapidRole = allRoles.find((r) => r.key === ROLE_KEY.RAPID_USER);
  const isOnlyRapidRoleSelected =
    rapidRole && userData.roles.length === 1 && userData.roles.includes(rapidRole.id);

  return {
    userData,
    setUserData,
    onNameChange,
    onChangeEmail,
    onRolesChange,
    checkEmail,
    checkIsValid,
    checkIsEmailValid,
    checkIsNameValid,
    nameErrorText,
    emailErrorText,
    rolesErrorText,
    inviteError,
    setIsCanShowInfoText,
    isCheckLoading: isLoading,
    isExist: isExist && isCanShowInfoText,
    isUserAlreadyAdded: isUserAlreadyAdded && isCanShowInfoText,

    isOnlyRapidRoleSelected,
  };
};
