import React, { FC } from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import { Checkbox, CheckboxProps, FormControlLabel, Stack, Typography, styled } from '@mui/material';

const IconBox = styled(Stack)(({ theme }) => ({
  width: 24,
  height: 24,
  border: `2px solid ${theme.palette.info.dark}`,
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
}));

interface Props extends CheckboxProps {
  label: string;
  checked: boolean;
  toggle: (item: string) => void;
}

export const RoleCheckBox: FC<Props> = ({ disabled, label, value, toggle, checked }) => {
  return (
    <FormControlLabel
      sx={{ mb: 2 }}
      label={
        <Typography
          noWrap
          sx={{
            color: disabled ? 'info.dark' : 'darksome.main',
            userSelect: 'none',
          }}
        >
          {label}
        </Typography>
      }
      value={value}
      control={
        <Checkbox
          sx={{ py: 0 }}
          size={'small'}
          disabled={disabled}
          checked={checked}
          onChange={() => {
            toggle(value as string);
          }}
          icon={<IconBox />}
          checkedIcon={
            <IconBox>
              <CircleIcon
                sx={{
                  color: disabled ? 'info.dark' : 'primary.main',
                  width: 12,
                  height: 12,
                }}
              />
            </IconBox>
          }
        />
      }
    />
  );
};
