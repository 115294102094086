import { useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { selectOrgUsersSyncedCount, setOrganizations, setSelectedOrg, setUsers } from 'shared/slices';
import { BaseLayout } from 'shared/ui';
import { OrganizationUsers } from 'widgets/OrganizationUsers';
import { TabBar } from 'widgets/TabBar';
import { InviteUser, OrganizationDetails, SideMenu, UserDetails } from 'widgets';
import { OrganizationInfo } from 'features';
import { OrgService, UserService } from 'shared/services';

export const OrganizationPage = () => {
  const dispatch = useAppDispatch();
  const { organizationId } = useParams();

  const [loading, setLoading] = useState(false);
  const orgUsersSynchronizeCount = useAppSelector(selectOrgUsersSyncedCount);

  useLayoutEffect(() => {
    if (!organizationId) return;

    const getData = async () => {
      setLoading(true);

      try {
        const [users, organizations, organizationDetails] = await Promise.all([
          UserService.getOrgUsers(organizationId),
          OrgService.getOrgs(),
          OrgService.getOrgDetails(organizationId),
        ]);

        users && dispatch(setUsers(users));
        organizations && dispatch(setOrganizations(organizations));
        organizationDetails && dispatch(setSelectedOrg(organizationDetails));
      } catch (error) {
        console.log('####: error', error);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [organizationId, orgUsersSynchronizeCount]);

  return (
    <BaseLayout tabBar={<TabBar />} sideMenu={<SideMenu />}>
      <OrganizationInfo />

      <OrganizationUsers loading={loading} />

      <OrganizationDetails />
      <UserDetails />
      <InviteUser />
    </BaseLayout>
  );
};
