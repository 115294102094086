import { Box } from '@mui/system';
import React, { FC } from 'react';

interface Props {
  isFade: boolean;
}

export const Fade: FC<Props> = ({ isFade }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '-2px',
        left: '-2px',
        width: 'calc(100% + 4px)',
        height: '110%',
        bgcolor: 'secondary.dark',
        borderRadius: '10px',
        transition: 'all 0.3s',
        opacity: isFade ? 0.4 : 0,
        flexGrow: 1,
      }}
    />
  );
};
