import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Popover,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { CatalogUserIcon } from '../../shared/ui/icons/CatalogUserIcon';
import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnchorElement, useAppDispatch, useAppSelector } from 'shared/hooks';
import { setCatalogs } from 'shared/slices';
import { CatalogService } from 'shared/services';

interface Props {
  catalogId: string;
  sharingId: string;
  userName: string;
  email: string;
  permission: string;
}

export const CatalogManageAccessItem: FC<Props> = ({ catalogId, sharingId, userName, permission }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { open, anchorEl, onOpenMenu, onCloseMenu } = useAnchorElement();

  const { catalogs } = useAppSelector((st) => st.catalogs);

  const [selectedPermission, setSelectedPermission] = useState('Read');
  const [canShare, setCanShare] = useState(false);

  useEffect(() => {
    setSelectedPermission(permission.includes('Read') ? 'Read' : 'Write');
    setCanShare(permission.includes('Share'));
  }, [open]);

  const [isLoading, setIsLoading] = useState(false);

  const onDeleteAccess = async () => {
    setIsLoading(true);
    onCloseMenu();

    const isDeleted = await CatalogService.deleteCatalogAccess(catalogId, sharingId);
    if (isDeleted) {
      const updatedCatalogs = catalogs.map((c) =>
        c.id === catalogId ? { ...c, sharings: c.sharings.filter((s) => s.id !== sharingId) } : c
      );
      dispatch(setCatalogs(updatedCatalogs));
    }
    setIsLoading(false);
  };

  const onChangeAccess = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPermission(e.target.value);
    onCloseMenu();

    setIsLoading(true);
    const dto = { permission: `${e.target.value}${canShare ? ', Share' : ''}` };

    const isChanged = await CatalogService.changeCatalogAccess(catalogId, sharingId, dto);
    if (isChanged) {
      const updatedCatalogs = catalogs.map((c) =>
        c.id === catalogId
          ? {
              ...c,
              sharings: c.sharings.map((s) => (s.id === sharingId ? { ...s, permission: dto.permission } : s)),
            }
          : c
      );
      dispatch(setCatalogs(updatedCatalogs));
      setSelectedPermission(dto.permission.includes('Read') ? 'Read' : 'Write');
      setCanShare(dto.permission.includes('Share'));
    }
    setIsLoading(false);
  };

  const handleOpenMenu = (e: React.MouseEvent<HTMLElement>) => {
    if (isLoading) return;
    onOpenMenu(e);
  };

  return (
    <>
      <Stack
        onClick={handleOpenMenu}
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{
          py: '12px',
          pl: '48px',
          pr: '40px',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'rgba(29, 135, 69, 0.16)',
          },
        }}
      >
        <Stack direction={'row'} gap={'12px'} alignItems={'center'}>
          <CatalogUserIcon />
          <Typography
            sx={{
              color: '#242731)',
              fontFamily: 'Roboto',
              fontSize: '16px',
              lineHeight: '130%',
            }}
          >
            {userName}
          </Typography>
        </Stack>
        <Stack direction={'row'}>
          <Typography
            sx={{
              color: ' #1D8742',
              fontFamily: 'Roboto',
              fontSize: '14px',
              lineHeight: '130%',
            }}
          >
            {t(selectedPermission === 'Read' ? 'Viewer' : 'Editor')}
          </Typography>

          {isLoading && <CircularProgress size={12} sx={{ m: 0.5 }} />}
        </Stack>
      </Stack>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onCloseMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 380 }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        slotProps={{
          paper: {
            sx: {
              padding: '16px',
              display: 'flex',
              alignItems: 'flex-start',
              width: '300px',
              flexDirection: 'column',
              borderRadius: '4px',
              border: '0.3px solid #D4D4D4',
              background: '#FFF',
              boxShadow:
                '0px 23px 52px 0px rgba(0, 0, 0, 0.10), 0px 94px 94px 0px rgba(0, 0, 0, 0.09), 0px 211px 127px 0px rgba(0, 0, 0, 0.05), 0px 376px 150px 0px rgba(0, 0, 0, 0.01), 0px 587px 164px 0px rgba(0, 0, 0, 0.00)', // assuming these are the desired shadow values
            },
          },
        }}
      >
        <Stack sx={{ width: '100%' }}>
          <FormControl
            sx={{
              mb: '16px',
              '& label.Mui-focused': {
                color: '#242731',
              },
            }}
          >
            <FormLabel
              sx={{
                color: '#242731',
                fontFamily: 'Roboto',
                fontSize: '18px',
                lineHeight: '130%',
                letterSpacing: '0.18px',
                mb: '16px',
              }}
            >
              {t('Select Permissions')}
            </FormLabel>
            <RadioGroup value={selectedPermission} sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              <FormControlLabel
                value="Read"
                control={
                  <Radio sx={{ py: 0 }} checked={selectedPermission === 'Read'} onChange={onChangeAccess} />
                }
                label={
                  <Typography
                    sx={{
                      color: '#242731',
                      fontFamily: 'Roboto',
                      fontSize: '16px',
                    }}
                  >
                    {t('Viewer')}
                  </Typography>
                }
              />
              <FormControlLabel
                value="Write"
                control={
                  <Radio sx={{ py: 0 }} checked={selectedPermission === 'Write'} onChange={onChangeAccess} />
                }
                label={
                  <Typography
                    sx={{
                      color: '#242731',
                      fontFamily: 'Roboto',
                      fontSize: '16px',
                    }}
                  >
                    {t('Editor')}
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>

          <Box sx={{ width: '100%', height: '1px', background: '#D4D4D4', mb: '16px' }} />
          <FormControlLabel
            sx={{ mb: '16px' }}
            control={
              <Checkbox sx={{ py: 0 }} checked={canShare} onChange={(e) => setCanShare(e.target.checked)} />
            }
            label={
              <Typography
                sx={{
                  color: '#242731',
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                }}
              >
                {t('Can share')}
              </Typography>
            }
          />
          <Box sx={{ width: '100%', height: '1px', background: '#D4D4D4', mb: '5px' }} />

          <Button onClick={onDeleteAccess} sx={{ justifyContent: 'flex-start' }}>
            <Typography
              sx={{
                color: 'rgba(206, 41, 41, 1)',
                fontFamily: 'Roboto',
                fontSize: '16px',
                textTransform: 'capitalize',
                textAlign: 'left',
              }}
            >
              {t('Remove Access')}
            </Typography>
          </Button>
        </Stack>
      </Popover>
    </>
  );
};
