import { FC, PropsWithChildren } from 'react';
import { Box, Stack, Typography, useMediaQuery } from '@mui/material';

export const AuthLayout: FC<PropsWithChildren> = ({ children }) => {
  const isMobile = useMediaQuery('(max-width:460px)');

  return (
    <Stack direction="row" sx={{ height: '100svh', overflow: 'hidden' }}>
      <Box
        sx={{
          width: '100%',
          background: "url('/images/big-login-bg.png') center/cover no-repeat",
        }}
      />
      <Stack
        justifyContent="space-between"
        sx={{
          bgcolor: 'info.main',
          width: isMobile ? '100%' : '460px',
          minWidth: isMobile ? '100%' : '460px',
          height: '100%',
          p: isMobile ? '28px 20px' : '32px 48px',
          overflowY: isMobile ? 'auto' : 'hidden',
        }}
      >
        <Box sx={{ mb: '22px', height: isMobile ? '15px' : '47px' }}>{/*<Icon path="rcadi-logo" />*/}</Box>
        {children}
        <Typography
          sx={{
            color: 'info.dark',
            fontFamily: 'Roboto',
            fontSize: '12px',
            lineHeight: '16px',
            textAlign: 'right',
          }}
        >
          © Rivulis LTD 2022
        </Typography>
      </Stack>
    </Stack>
  );
};
