import { GRID_CHECKBOX_SELECTION_COL_DEF, GridColDef } from '@mui/x-data-grid-premium';
import { ORIENTATION } from '../enums';
import { PRODUCT_KEY } from './enums';

export const CURVE_PLACES_AMOUNT = 53;

const baseColValues: Partial<GridColDef> = {
  align: 'left',
  headerClassName: 'product-grid--headerCell',
  cellClassName: 'product-grid--cell',
  headerAlign: 'left',
  sortable: false,
  disableColumnMenu: true,
  type: 'number',
  editable: true,
  width: 130,
};

export const GENERAL_COLUMNS: GridColDef[] = [
  {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    ...baseColValues,
    width: 32,
    minWidth: 32,
    maxWidth: 32,
    editable: false,
    headerClassName: `${baseColValues.headerClassName} angular--headerCell`,
  },
  {
    ...baseColValues,
    width: 110,
    field: PRODUCT_KEY.SKU,
    type: 'string',
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.DESC,
    type: 'string',
    width: 170,
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.BRAND,
    width: 100,
    type: 'singleSelect',
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.SUBTYPE,
    width: 110,
    editable: false,
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.GROUP,
    width: 100,
    type: 'singleSelect',
  },
];

export const INLET_COLUMNS: GridColDef[] = [
  {
    ...baseColValues,
    field: PRODUCT_KEY.INLET,
    width: 100,
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.OUTLET,
    width: 100,
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.OPT_FACTOR1,
    width: 220,
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.OPT_FACTOR2,
    width: 220,
  },
];

export const PIPE_COLUMNS: GridColDef[] = [
  {
    ...baseColValues,
    field: PRODUCT_KEY.CLASS,
    type: 'string',
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.THICKNESS,
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.INT_RNL,
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.V_MAX,
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.HW_COF,
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.PIPE_DHA,
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.PIPE_DHB,
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.PIPE_DHC,
  },
];

export const NOZZLE_SWIVEL_COLUMNS: GridColDef[] = [
  {
    ...baseColValues,
    field: PRODUCT_KEY.NOZZLE,
    type: 'string',
    width: 90,
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.SWIVEL,
    type: 'string',
    width: 90,
  },
];

export const EMITTER_COLUMNS: GridColDef[] = [
  {
    ...baseColValues,
    field: PRODUCT_KEY.Q_NOM,
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.P_MIN,
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.P_MAX,
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.PRESSURE_NOMINAL_M,
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.KD,
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.EMITTER_QA,
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.EMITTER_QB,
  },
];

export const INTEGRAL_COLUMNS: GridColDef[] = [
  {
    ...baseColValues,
    field: PRODUCT_KEY.Q_NOM,
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.SPACING,
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.QL_HR_100M,
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.P_MIN,
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.P_MAX,
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.PRESSURE_NOMINAL_M,
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.KD,
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.EMITTER_QA,
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.EMITTER_QB,
  },
];

const places = [...new Array(CURVE_PLACES_AMOUNT)].map((_, i) => ({
  ...baseColValues,
  field: `place ${i}`,
  width: 110,
}));

export const DISTRIBUTION_CURVE_COLUMNS: GridColDef[] = [
  {
    ...baseColValues,
    field: PRODUCT_KEY.ORIENT,
    type: 'singleSelect',
    valueOptions: [ORIENTATION.UPRIGHT, ORIENTATION.INVERTED],
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.PRESSURE,
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.RISER,
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.BASE_DIS,
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.FLOW_RATE,
  },
  {
    ...baseColValues,
    field: PRODUCT_KEY.RANGE,
  },
  ...places,
];
