import { Box, Stack, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { DeleteIcon, DialogModal, SaveControl } from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../shared/hooks';
import { deleteCatalog } from '../../shared/slices';
import { CatalogService } from 'shared/services';
import { toast } from 'react-toastify';

interface Props {
  open: boolean;
  onClose: () => void;
  catalogName: string;
  catalogId: string;
}

export const CatalogDeleteModal: FC<Props> = ({ open, onClose, catalogName, catalogId }) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const onSubmit = async () => {
    setIsLoading(true);

    const isDeleted = await CatalogService.delete(catalogId);
    if (isDeleted) {
      dispatch(deleteCatalog(catalogId));
      toast.success(`Catalog ${catalogName} was successfully deleted`);
    }

    setIsLoading(false);
  };

  return (
    <DialogModal
      open={open}
      withCloseButton={false}
      onClose={onClose}
      padding={'48px'}
      sx={{
        '& .MuiBackdrop-root': {
          top: '44px',
        },
        '& .MuiPaper-root': {
          width: '560px',
        },
      }}
    >
      <Stack sx={{ overflow: 'hidden' }}>
        <Stack mb={'12px'} direction={'row'} justifyContent={'space-between'}>
          <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
            <DeleteIcon />
            <Typography
              sx={{
                color: '#4D4E4C',
                fontFamily: 'Roboto',
                fontSize: '18px',
                fontWeight: 400,
                lineHeight: '130%',
                letterSpacing: '0.18px',
              }}
            >
              {t('Delete Catalog')}
            </Typography>
          </Stack>
        </Stack>
        <Box mb={'24px'}>
          <Typography
            sx={{
              color: '#4D4E4C',
              fontFamily: 'Roboto',
              fontSize: '18px',
              fontWeight: 600,
              lineHeight: '130%',
              letterSpacing: '0.18px',
            }}
          >
            {t(catalogName)}
          </Typography>
        </Box>
        <Box mb={'24px'}>
          <Typography
            sx={{
              color: '#4D4E4C',
              fontFamily: 'Roboto',
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '130%',
              letterSpacing: '0.18px',
            }}
          >
            {`Are you sure you want to delete ${t(catalogName)} catalog?`}
          </Typography>
        </Box>
        <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
          <SaveControl btnTexts={['Yes', 'No']} onSave={onSubmit} onCancel={onClose} isLoading={isLoading} />
        </Stack>
      </Stack>
    </DialogModal>
  );
};
