import { FC } from 'react';
import { Box, Stack, Typography, styled } from '@mui/material';
import { CATALOG_OWNER_TYPE } from 'shared/constants';
import { useAppSelector, useCatalogSearchParams, useCatalogsControl } from 'shared/hooks';
import { ActiveButton, BlackToolTip, Icon } from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { selectTabs } from 'shared/slices';

const Text = styled(Typography)({
  padding: '2px 10px',
  fontSize: 18,
  marginRight: '30px',
  cursor: 'pointer',
});

const catalogTypeIcons = {
  [CATALOG_OWNER_TYPE.ORG]: 'products-view/catalog-type-org',
  [CATALOG_OWNER_TYPE.PRIVATE]: 'products-view/catalog-type-private',
  [CATALOG_OWNER_TYPE.PUBLISHED]: 'products-view/catalog-type-public',
};
const catalogTypeTitles = {
  [CATALOG_OWNER_TYPE.ORG]: 'Organization',
  [CATALOG_OWNER_TYPE.PRIVATE]: 'Private',
  [CATALOG_OWNER_TYPE.PUBLISHED]: 'Public',
};

interface Props {
  isEditing?: boolean;
}

export const CatalogInfo: FC<Props> = ({ isEditing }) => {
  const { t } = useTranslation();
  const { catalogId, manageIndex, navigateToCatalogManagement } = useCatalogSearchParams();

  const tabs = useAppSelector(selectTabs);

  const isManagementPage = Boolean(manageIndex?.toString());
  const catalogTab = tabs?.find((c) => c.id === catalogId);
  const catalogName = catalogTab?.title;

  const catalogTypeIcon = catalogTypeIcons[catalogTab?.catalogInfo.type ?? CATALOG_OWNER_TYPE.PUBLISHED];
  const catalogTypeTitle = catalogTypeTitles[catalogTab?.catalogInfo.type ?? CATALOG_OWNER_TYPE.PUBLISHED];
  const catalogOwner = catalogTab?.catalogInfo.owner ?? 'Unknown';

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        pl: '32px',
        pr: '23px',
        position: 'relative',
        minHeight: '54px',
      }}
    >
      <Stack direction="row" justifyContent="flex-start" alignItems="center">
        <Icon path="products-view/catalog-title-icon" />
        <BlackToolTip title={t('Catalog Name')}>
          <Text>{catalogName}</Text>
        </BlackToolTip>

        <Icon path="products-view/catalog-owner" />
        <BlackToolTip title={t('Catalog Owner')}>
          <Text>{catalogOwner}</Text>
        </BlackToolTip>

        <Icon path={catalogTypeIcon} />
        <BlackToolTip title={t('Catalog Type')}>
          <Text>{catalogTypeTitle}</Text>
        </BlackToolTip>
      </Stack>

      <Stack direction="row" justifyContent="flex-start" alignItems="center">
        <BlackToolTip title={t('Settings')}>
          <ActiveButton
            onClick={navigateToCatalogManagement}
            isActive={isManagementPage}
            defaultBgcolor="secondary.contrastText"
          >
            <Icon
              path={`products-view/catalog-settings-${isManagementPage ? 'green' : 'black'}`}
              sx={{ width: '24px' }}
            />
          </ActiveButton>
        </BlackToolTip>

        {/* <BlackToolTip title={t('Import')}>
          <IconButton>
            <Icon path="products-view/catalog-export" sx={{ width: '24px' }} />
          </IconButton>
        </BlackToolTip> */}
      </Stack>

      {isEditing && <Box sx={{ position: 'absolute', inset: 0, bgcolor: 'rgb(0,0,0,0.4)' }} />}
    </Stack>
  );
};
