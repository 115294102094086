import { IconButton, IconButtonProps } from '@mui/material';
import { FC } from 'react';

interface Props extends IconButtonProps {
  isActive: boolean;
  defaultBgcolor: string;
}

export const ActiveButton: FC<Props> = ({ children, isActive, defaultBgcolor, sx, ...props }) => {
  return (
    <IconButton
      sx={{
        p: '4px',
        borderRadius: '6px',
        border: '1px solid green',
        borderColor: isActive ? 'primary.light' : defaultBgcolor,
        bgcolor: isActive ? 'primary.light' : defaultBgcolor,
        ...sx,
      }}
      {...props}
    >
      {children}
    </IconButton>
  );
};
