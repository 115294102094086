import axios from 'axios';

const deleteToken = () => {
  localStorage.removeItem('token');
  window.location.href = '/';
};

export const axiosBase = axios.create({
  baseURL: '/api',
});

axiosBase.interceptors.request.use((config) => {
  config.headers.Authorization = window.localStorage.getItem('token');
  return config;
});

axiosBase.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      deleteToken();
    }
    return Promise.reject(error);
  }
);
