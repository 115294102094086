import { FC, useMemo, useState } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { AuthInput, Button } from 'shared/ui';
import { useNewPassword } from './hooks/useNewPassword';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useAppNavigator } from 'shared/hooks';
import { ValidationCheckmark } from './components';
import { UserService } from 'shared/services';

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.darksome.main,
  fontSize: '12px',
  fontWeight: 400,
  margin: '20px 0 5px 13px',
}));

interface Props {
  isReset?: boolean;
}

export const SignUp: FC<Props> = ({ isReset }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { navigateToLogin, navigateToHTApp, navigateToFieldRapidIrrigationApp } = useAppNavigator();

  const { email, userId, appType } = Object.fromEntries([...searchParams]);

  const [isCreated, setIsCreated] = useState(false);
  const [loading, setLoading] = useState(false);

  const { newPassData, onLoginDataChange, errors, isWrongPassword } = useNewPassword();

  const appTypeCallbacks: { [key: string]: () => void } = useMemo(
    () => ({
      HydraulicTool: navigateToHTApp,
      Field: navigateToFieldRapidIrrigationApp,
    }),
    [appType]
  );

  const onSubmit = async () => {
    if (isWrongPassword) return;

    setLoading(true);

    const newPasswordDto = { userId, password: newPassData.password };

    const isSuccess = await UserService.setNewPassword(newPasswordDto);
    if (isSuccess) {
      setIsCreated(true);

      const callback = appTypeCallbacks[appType] || navigateToLogin;
      setTimeout(() => callback(), 3000);
    }
    setLoading(false);
  };

  const handleBackToLogin = appTypeCallbacks[appType] || navigateToLogin;

  return (
    <Box sx={{ flexGrow: 1 }}>
      {isReset && (
        <Box mb={'13px'}>
          <AuthInput disabled placeholder={email} name="email" />
        </Box>
      )}
      <Typography
        sx={{
          color: 'primary.main',
          fontSize: '20px',
          fontWeight: 600,
        }}
      >
        {isReset ? t('reset_your_password') : isCreated ? t('mail_activation') : t('create_new_password')}
      </Typography>

      {isReset && (
        <Box mb={'24px'}>
          <Text onClick={handleBackToLogin} sx={{ color: '#007AFF', cursor: 'pointer', m: 0 }}>
            {t('back_to_login')}
          </Text>
        </Box>
      )}

      {isReset && <Text sx={{ color: 'grey', m: 0 }}>{t('please_choose_your_new_password')}</Text>}

      <Box component="form" sx={{ flexGrow: 1, mt: '15px' }}>
        {!isReset && (
          <>
            <Text>{t('login')}</Text>
            <AuthInput disabled placeholder={email} name="email" />
          </>
        )}
        {isCreated ? (
          isReset ? (
            <Typography
              sx={{
                color: 'primary.main',
                fontSize: '20px',
                mt: '50px',
                textAlign: 'center',
              }}
            >
              Your password has been updated
            </Typography>
          ) : (
            <>
              <Typography
                sx={{
                  color: 'primary.main',
                  fontSize: '20px',
                  mt: '50px',
                  textAlign: 'center',
                }}
              >
                {t('pass_has_been_submitted')}
              </Typography>
            </>
          )
        ) : (
          <>
            <Text sx={{}}>{isReset ? t('new_password') : t('password')}</Text>
            <AuthInput
              value={newPassData.password}
              onChange={onLoginDataChange}
              name="password"
              password
              placeholder={isReset ? t('enter_your_new_password') : t('enter_password')}
            />

            <Text sx={{ mt: '28px' }}>{t('confirm_password')}</Text>
            <AuthInput
              value={newPassData.confirmPassword}
              onChange={onLoginDataChange}
              name="confirmPassword"
              password
              placeholder={isReset ? t('confirm_your_new_password') : t('enter_password')}
            />

            <Box sx={{ mt: '10px' }}>
              <ValidationCheckmark isInvalid={errors.upperCase} text={t('Uppercase letter')} />
              <ValidationCheckmark isInvalid={errors.lowerCase} text={t('Lowercase letter')} />
              <ValidationCheckmark isInvalid={errors.number} text={t('Number')} />
              <ValidationCheckmark isInvalid={errors.specialChar} text={t('Special character (*&^%$#@!)')} />
              <ValidationCheckmark isInvalid={errors.atLeastSix} text={t('At least 6 characters ')} />
              <ValidationCheckmark isInvalid={errors.matchPasswords} text={t('Passwords match')} />
            </Box>

            <Box sx={{ height: '40px', mt: '15px', position: 'relative' }}>
              <Button onClick={onSubmit} filled fullWidth loading={loading}>
                <Typography sx={{ fontWeight: 700 }}>{isReset ? t('save_new_password') : t('done')}</Typography>
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
