import { toast } from 'react-toastify';

export const userNotActivatedCb =
  (activationPendingMessage: string, accountDeactivatedMessage: string) =>
  (errorDetails: string): void => {
    if (errorDetails === 'User account is not activated') {
      toast.error(activationPendingMessage);
    }
    if (errorDetails === 'User account is deactivated') {
      toast.error(accountDeactivatedMessage);
    }
  };
