import { FC, useState } from 'react';
import { Button, Stack, styled, Typography, useTheme } from '@mui/material';
import { useAppDispatch } from 'shared/hooks';
import { Icon, SaveControl } from 'shared/ui';
import { useTranslation } from 'react-i18next';
import {
  saveDistributionCurvesThunk,
  setCellErrors,
  setSaveBulk,
  stopEditing,
  toggleCatalogFinalUpdates,
  toggleCatalogSavingStatus,
  toggleValidating,
} from 'shared/slices';
import { getDistributionCurveDTOs, getProductsSaveBulk, isTableRowEmpty, makeDeepValidation } from 'shared/lib';
import { useParams } from 'react-router-dom';
import { Brand, Group, TableProductInfo } from 'shared/models';

const Text = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  color: theme.palette.darksome.main,
  paddingRight: '5px',
  textTransform: 'capitalize',
}));
const Num = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  color: theme.palette.secondary.light,
  fontWeight: 700,
}));

const Row = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
});

type Props = {
  type: string;
  specification: number;
  items: TableProductInfo[];
  isEditing: boolean;
  changedItems: { [id: string]: TableProductInfo };
  deletedItemIDs: { [id: string]: boolean };
  newItemIDs: { [id: string]: boolean };
  isDistributionCurveType: boolean;
  isSaveLoading: boolean;
  cellErrors: { [id: string]: { [key: string]: boolean } };
  groups: Group[];
  brands: Brand[];
  allComponents: TableProductInfo[];
};

export const ChangeProductsControl: FC<Props> = ({
  type,
  items,
  changedItems,
  isEditing,
  deletedItemIDs,
  newItemIDs,
  isDistributionCurveType,
  isSaveLoading,
  cellErrors,
  groups,
  brands,
  allComponents,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { id: catalogId } = useParams();

  const onOpenCatalogFinalUpdates = () => {
    setTimeout(() => {
      dispatch(toggleCatalogFinalUpdates());
    }, 0);
  };
  const onCancelClick = () => {
    setTimeout(() => {
      catalogId && dispatch(stopEditing(catalogId));
    }, 0);
  };

  const allViewChangedItemsAmount = [
    ...Object.keys(newItemIDs ?? {}),
    ...Object.keys(deletedItemIDs ?? {}),
    ...Object.keys(changedItems ?? {}).filter((id) => !newItemIDs[id]),
  ].length;

  const [validationLoading, setValidationLoading] = useState(false);

  const handSaveChanges = async () => {
    if (!catalogId) return;

    if (!Object.keys(changedItems).length && !Object.keys(deletedItemIDs).length) {
      onCancelClick();
      return;
    }

    setValidationLoading(true);

    setTimeout(async () => {
      const hasErrors = !!Object.keys(cellErrors ?? {}).length;

      const nonEmptyItems = items.filter((item) => !isTableRowEmpty(item, isDistributionCurveType));

      const errors = makeDeepValidation(
        nonEmptyItems,
        deletedItemIDs,
        isDistributionCurveType,
        changedItems,
        allComponents,
        groups
      );

      if (errors || hasErrors) {
        errors && dispatch(setCellErrors({ catalogId, cellErrors: errors }));
        dispatch(toggleValidating(catalogId));

        setValidationLoading(false);

        return;
      }

      setValidationLoading(false);

      if (isDistributionCurveType) {
        const curveOptions = { items: nonEmptyItems, changedItems, newItemIDs, deletedItemIDs };
        const { newCurves, changedCurves, deletedCurves } = getDistributionCurveDTOs(curveOptions);

        catalogId &&
          (await dispatch(saveDistributionCurvesThunk({ catalogId, newCurves, changedCurves, deletedCurves })));

        return;
      }

      const bulkOptions = {
        type,
        items: nonEmptyItems,
        newItemIDs,
        deletedItemIDs,
        changedItems,
        groups,
        brands,
      };

      const { bulk, movedProductGroups } = getProductsSaveBulk(bulkOptions);

      catalogId && dispatch(setSaveBulk({ catalogId, bulk, movedProductGroups }));

      dispatch(toggleCatalogSavingStatus());
      setValidationLoading(false);
    }, 0);
  };

  return (
    <Row
      justifyContent="space-between"
      sx={{ borderTop: `1px solid ${theme.palette.success.dark}`, padding: '16px 35px' }}
    >
      <Row sx={{ height: '40px' }}>
        <Row>
          <Icon path="products-view/catalog-total-items" sx={{ mr: 1 }} />
          <Text noWrap>{t('total_items')} </Text>
          <Num>{items?.length}</Num>
        </Row>

        {isEditing && !!allViewChangedItemsAmount && (
          <Button
            sx={{
              ml: 2,
              border: '1px solid green',
              borderRadius: '10px',
              bgcolor: 'info.light',
              p: '5px 15px',
            }}
          >
            <Row onClick={onOpenCatalogFinalUpdates}>
              <Icon path="products-view/catalog-view-changes" sx={{ mr: 1 }} />
              <Text noWrap>{t('View Changes')} </Text>
              <Num>{allViewChangedItemsAmount}</Num>
            </Row>
          </Button>
        )}
      </Row>

      <Row justifyContent="end">
        {isEditing && (
          <SaveControl
            small
            onSave={handSaveChanges}
            onCancel={onCancelClick}
            isSaveDisabled={!allViewChangedItemsAmount}
            isLoading={validationLoading || (isDistributionCurveType && isSaveLoading)}
            btnTexts={[t('Save Changes')]}
            sx={{ height: '40px' }}
          />
        )}
      </Row>
    </Row>
  );
};
