import React, { FC } from 'react';
import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

const CustomToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {props.children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: theme.palette.info.main,
    backgroundColor: theme.palette.darksome.main,
    fontSize: '14px',
    borderRadius: '8px',
    maxWidth: 500,
    border: '1px solid black',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.darksome.main,
    // left: '-15px !important',
    '&:before': {
      border: '1px solid black',
    },
  },
}));

export const BlackToolTip: FC<TooltipProps> = ({ children, title, ...props }) => {
  return (
    <CustomToolTip title={title} arrow {...props}>
      <span>{children}</span>
    </CustomToolTip>
  );
};
