import { FC, useState } from 'react';
import { axiosBase } from 'shared/axios';
import { CircularProgress, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { ReverseIcon } from 'shared/ui';
import { TableProductInfo } from 'shared/models';
import { useAppDispatch, useCatalogSearchParams } from 'shared/hooks';
import { EditDescription } from './EditDescription';
import { EditTranslation } from './EditTranslation';
import { setShouldUpdateCatalogProducts } from 'shared/slices';
import { validateTranslationValue } from 'shared/lib';

interface Props {
  item: TableProductInfo;
  selected: string;
  onSelect: (id: string) => void;
  language: string;
  updateProduct: (updatedComponent: TableProductInfo) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  products: TableProductInfo[];
}

export const ProductTranslations: FC<Props> = ({
  item,
  selected,
  onSelect,
  language,
  updateProduct,
  onKeyDown,
  products,
}) => {
  const dispatch = useAppDispatch();
  const { catalogId } = useCatalogSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [localItem, setLocalItem] = useState<TableProductInfo>(item);
  const [descriptionError, setDescriptionError] = useState<string | null>(null);
  const [translationError, setTranslationError] = useState<string | null>(null);

  const productDescriptions = products
    .filter((product) => product.id !== item.id)
    .map((product) => product.description || '');

  const productTranslations = products
    .filter((product) => product.id !== item.id)
    .map(
      (product) => product.stringResources?.find((translation) => translation.languageId === language)?.value || ''
    )
    .filter((translation) => translation);

  const onDescriptionChange = (newDesc: string) => {
    console.log('Description Change:', newDesc); // Log the new description to debug
    setLocalItem((prev) => ({ ...prev, description: newDesc }));

    const validatedDescr = validateTranslationValue(newDesc, productDescriptions, 'Description');
    setDescriptionError(validatedDescr);
  };

  const onTranslationChange = (newTranslation: string) => {
    const stringResources = localItem.stringResources;

    const newTranslationData = {
      languageId: language,
      value: newTranslation,
    };

    const validatedTranslation = validateTranslationValue(newTranslation, productTranslations, 'Translation');
    setTranslationError(validatedTranslation);

    if (stringResources) {
      const filteredStringResources = stringResources.filter((sr) => sr.languageId !== language);

      const newStringResources = [...filteredStringResources, newTranslationData];

      setLocalItem((prev) => ({
        ...prev,
        stringResources: newStringResources,
      }));

      return;
    }

    setLocalItem((prev) => ({
      ...prev,
      stringResources: [newTranslationData],
    }));
  };

  const getDescriptionChanges = () => localItem.description !== item.description;

  const getTranslationChanges = () => {
    const localItemTranslation = localItem.stringResources?.find((sr) => sr.languageId === language)?.value;
    const itemTranslation = item.stringResources?.find((sr) => sr.languageId === language)?.value;
    return localItemTranslation !== itemTranslation;
  };

  const onBlur = async () => {
    if (descriptionError || translationError) return;

    const isDescriptionChanged = getDescriptionChanges();
    const isTranslationChanged = getTranslationChanges();

    if (isDescriptionChanged || isTranslationChanged) {
      try {
        setIsLoading(true);

        const body = [
          {
            itemRef: 0,
            operation: 'Update',
            groupId: localItem.groupId,
            componentId: localItem.id,
            componentData: {
              ...item,
              description: localItem.description,
              descriptionStringResources: localItem.stringResources,
            },
          },
        ];

        await axiosBase.put(`/Catalogs/${catalogId}/components`, body);

        dispatch(setShouldUpdateCatalogProducts(true));
        updateProduct(localItem);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      onBlur();
    } else {
      onKeyDown(e);
    }
  };

  return (
    <Stack direction="row" justifyContent={'space-between'} onBlur={onBlur}>
      <EditDescription
        item={localItem}
        onUpdateItem={onDescriptionChange}
        onSelect={onSelect}
        selected={selected}
        onKeyDown={handleKeyDown}
        error={descriptionError}
      />
      <Box
        onClick={() => onSelect(item.id)}
        sx={{
          ...(selected === item.id && { bgcolor: 'primary.light' }),
          flexGrow: 1,
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '30px',
          minWidth: '30px',
          maxWidth: '30px',
        }}
      >
        {selected === item.id && !isLoading && <ReverseIcon width="18" height="22" viewBox="0 0 18 22" />}
        {isLoading && <CircularProgress size={15} color="primary" />}
      </Box>
      <EditTranslation
        item={localItem}
        onUpdateItem={onTranslationChange}
        onSelect={onSelect}
        selected={selected}
        language={language}
        onKeyDown={handleKeyDown}
        error={translationError}
      />
    </Stack>
  );
};
