import { Stack, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ACTION_NAME } from 'shared/constants';
import { useAppDispatch, useAppSelector, useCatalogSearchParams } from 'shared/hooks';
import {
  saveCatalogChangesThunk,
  selectIsSaveLoading,
  selectOpenCatalogSavingStatus,
  stopEditing,
  toggleCatalogSavingStatus,
} from 'shared/slices';
import { Button, DialogModal } from 'shared/ui';
import { GridColDef } from '@mui/x-data-grid-pro';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { StatusHeaderCell } from './StatusHeaderCell';
import { StatusCell } from './StatusCell';
import { BulkElement, MovedProductGroupDTO, TableProductInfo } from 'shared/models';

const actionMap = {
  Create: ACTION_NAME.ADD,
  Update: ACTION_NAME.CHANGE,
  Delete: ACTION_NAME.DELETE,
};

const filterItems = ['Failed', 'Succeeded'];
const actions = ['created', 'deleted', 'updated'];

const baseColumn: Partial<GridColDef> = {
  type: 'string',
  align: 'left',
  headerAlign: 'left',
  sortable: false,
  editable: false,
  disableColumnMenu: true,
};

type Props = {
  saveBulk: {
    bulkElement: BulkElement;
    item: TableProductInfo;
    status?: string | undefined;
  }[];
  movedProductGroups: MovedProductGroupDTO[];
  getProducts: (type: string, signal?: AbortSignal) => void;
};

export const CatalogSavingStatus: FC<Props> = ({ saveBulk, movedProductGroups, getProducts }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { catalogId, type } = useCatalogSearchParams();

  const openCatalogSavingStatus = useAppSelector(selectOpenCatalogSavingStatus);
  const isSaveLoading = useAppSelector(selectIsSaveLoading);

  const columns: GridColDef[] = [
    {
      field: 'sku',
      headerName: t('sku'),
      width: 150,
      ...baseColumn,
    },
    {
      headerName: t('description'),
      field: 'description',
      width: 300,
      ...baseColumn,
    },
    {
      headerName: t('Action'),
      field: 'action',
      width: 150,
      ...baseColumn,
    },
    {
      field: 'status',
      width: 150,
      renderHeader: () => <StatusHeaderCell filters={filters} onChangeFilter={onChangeFilter} />,
      renderCell: (params) => <StatusCell {...params} />,

      ...baseColumn,
    },
  ];

  const [filters, setFilters] = useState([filterItems[0]]);
  const onChangeFilter = (value: string) => {
    const isAdded = filters.includes(value);

    if (isAdded) {
      const newFilters = filters.filter((f) => f !== value);
      setFilters(newFilters);
    } else {
      setFilters([...filters, value]);
    }
  };

  const onClickOk = () => {
    if (isSaveLoading) return;
    dispatch(toggleCatalogSavingStatus());
    dispatch(stopEditing(catalogId));
    getProducts(type);
  };

  const saveProducts = async () => {
    const bulk = saveBulk?.map((b) => b.bulkElement) ?? [];
    catalogId && (await dispatch(saveCatalogChangesThunk({ catalogId, bulk, movedProductGroups })));
  };

  useEffect(() => {
    if (!openCatalogSavingStatus) return;

    saveProducts();
  }, [openCatalogSavingStatus]);

  const filteredBulk = isSaveLoading
    ? saveBulk
    : saveBulk
        ?.map((item) => ({ ...item, status: item?.status ?? 'Failed' }))
        ?.filter(({ status }) => filters.includes(status as string));

  const gridItems = (filteredBulk ?? [])?.map(({ bulkElement, item, status }) => {
    const { sku, description } = item;
    const id = bulkElement.itemRef;
    const action = t(actions[actionMap[bulkElement.operation]]);

    return { id, sku, description, action, status };
  });

  const isNoFailedItems = !saveBulk
    ?.map((item) => ({ ...item, status: item?.status ?? 'Failed' }))
    ?.filter(({ status }) => status !== 'Succeeded')?.length;
  const isSuccessSaving = !isSaveLoading && !filters.includes('Succeeded') && isNoFailedItems;

  return (
    <DialogModal
      open={openCatalogSavingStatus}
      withCloseButton={false}
      padding={'24px'}
      sx={{ '& .MuiPaper-root': { width: '900px', maxWidth: '900px', minHeight: '220px' } }}
    >
      <Stack alignItems="center" sx={{ position: 'relative' }}>
        <DataGridPremium
          rowHeight={56}
          hideFooter
          columns={columns}
          rows={gridItems}
          disableColumnResize
          disableRowSelectionOnClick
          disableColumnReorder
          sx={{
            minHeight: '115px',
            width: '100%',
            maxHeight: '70vh',
            overflowX: 'hidden',
            p: 0,
            border: 'none',
            borderRadius: 0,
            '& .MuiDataGrid-cell': {
              padding: '0 1',
              fontSize: 16,
              borderBottom: '1px solid grey',
            },
            '& .MuiDataGrid-columnHeader': {
              fontSize: 16,
              borderBottom: 'none',
              '*': { fontWeight: 600 },
            },
            '& .MuiDataGrid-virtualScroller': {
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'primary.main',
                borderRadius: '30px',
                height: '10px !important',
              },
              '&::-webkit-scrollbar': {
                width: '10px',
                height: '10px !important',
                backgroundColor: 'info.light',
              },
              '&::-webkit-scrollbar-track': {
                width: '10px',
                height: '10px !important',
                backgroundColor: 'info.light',
              },
              '.MuiDataGrid-virtualScrollerContent': {
                ...(isSuccessSaving && { height: '0 !important' }),
              },
            },
            '& .MuiDataGrid-row.Mui-hovered, & .MuiDataGrid-row:hover': {
              backgroundColor: 'transparent !important',
            },
            '& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus': { outline: 'none !important' },
          }}
        />

        {isSuccessSaving && (
          <Typography sx={{ color: 'primary.dark', fontSize: 26, position: 'absolute', top: '65px' }}>
            {t('changes_saved')}
          </Typography>
        )}

        <Button
          onClick={onClickOk}
          disabled={isSaveLoading}
          filled
          sx={{ height: '40px', minWidth: '100px', mt: 1 }}
        >
          {t('ok')}
        </Button>
      </Stack>
    </DialogModal>
  );
};
