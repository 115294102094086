import React, { ReactNode } from 'react';
import { Typography, TypographyProps } from '@mui/material';

interface Props extends TypographyProps {
  children: ReactNode;
}

export const BigTitle: React.FC<Props> = ({ children, noWrap, sx }) => {
  return (
    <Typography
      noWrap={noWrap}
      sx={{
        fontSize: { xs: '24px', lg: '32px' },
        lineHeight: '36px',
        color: 'secondary.light',
        fontWeight: 600,
        ...sx,
      }}
    >
      {children}
    </Typography>
  );
};
