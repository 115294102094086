import { Organization, Permissions } from '../../models';
import { flatOrgs } from './organizationHelpers';

export const getFilteredFatherOrganizations = (
  organizations: Organization[],
  permissions: Permissions
): Organization[] => {
  const flatOrgsArray = flatOrgs(organizations);

  return flatOrgsArray.filter((flatOrg) => (flatOrg ? permissions[flatOrg.id].sub_organization.create : false));
};
