import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const ReverseIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M16.141 7.52365H1.85898C1.74618 7.52365 1.63448 7.50144 1.53026 7.45827C1.42605 7.4151 1.33135 7.35183 1.25159 7.27206C1.17183 7.1923 1.10855 7.09761 1.06539 6.99339C1.02222 6.88917 1 6.77747 1 6.66467C1 6.55187 1.02222 6.44017 1.06539 6.33595C1.10855 6.23174 1.17183 6.13704 1.25159 6.05728C1.33135 5.97751 1.42605 5.91424 1.53026 5.87107C1.63448 5.82791 1.74618 5.80569 1.85898 5.80569H14.0674L10.7386 2.47684C10.6565 2.3976 10.5911 2.30282 10.5461 2.19802C10.5011 2.09322 10.4774 1.9805 10.4764 1.86645C10.4754 1.75239 10.4971 1.63928 10.5403 1.53372C10.5835 1.42815 10.6473 1.33224 10.7279 1.25159C10.8086 1.17094 10.9045 1.10716 11.0101 1.06397C11.1156 1.02078 11.2287 0.999041 11.3428 1.00003C11.4568 1.00102 11.5696 1.02472 11.6744 1.06974C11.7792 1.11476 11.8739 1.1802 11.9532 1.26224L16.748 6.05766C16.868 6.17778 16.9497 6.33077 16.9828 6.4973C17.0159 6.66383 16.9989 6.83642 16.9339 6.99329C16.8689 7.15015 16.759 7.28425 16.6178 7.37865C16.4767 7.47305 16.3108 7.52351 16.141 7.52365ZM6.65383 21.4541C6.54104 21.4541 6.42935 21.4319 6.32518 21.3887C6.22101 21.3454 6.12641 21.282 6.04681 21.2021L1.25197 16.4067C1.13199 16.2866 1.0503 16.1336 1.01722 15.967C0.984138 15.8005 1.00115 15.6279 1.0661 15.4711C1.13105 15.3142 1.24103 15.1801 1.38215 15.0857C1.52327 14.9913 1.6892 14.9408 1.85898 14.9407H16.141C16.3688 14.9407 16.5873 15.0312 16.7484 15.1923C16.9095 15.3534 17 15.5719 17 15.7997C17 16.0275 16.9095 16.246 16.7484 16.4071C16.5873 16.5682 16.3688 16.6587 16.141 16.6587H3.93257L7.26142 19.9881C7.3814 20.1082 7.46308 20.2612 7.49617 20.4277C7.52925 20.5942 7.51224 20.7668 7.44729 20.9237C7.38233 21.0806 7.27235 21.2147 7.13123 21.3091C6.99011 21.4035 6.82361 21.4539 6.65383 21.4541Z"
      fill="#242731"
      stroke="black"
    />
  </SvgIcon>
);
