import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const DeleteIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M5 21C5 21 5 9.14051 5 6H4V4H9V3H15V4H20V6H19V21C16.0299 21 5 21 5 21ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z"
      fill="#242731"
    />
  </SvgIcon>
);