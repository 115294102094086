import React, { FC, useMemo } from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useAppSelector } from 'shared/hooks';
import { useTranslation } from 'react-i18next';
import { HeadCell } from '../../OrganizationsList/components/HeadCell';
import { CatalogItem } from '../../../entities';
import { OwnerTypeFilters } from '../index';
import { Catalog } from '../../../shared/models';
import { selectCatalogs } from '../../../shared/slices';
import { CATALOG_OWNER_TYPE, USER_CATALOG_SHARING_TYPE } from 'shared/constants';
import { localStorageService } from 'shared/services';

interface Props {
  searchValue: string;
  ownerTypeFilters: OwnerTypeFilters;
  sharingTypeFilter: USER_CATALOG_SHARING_TYPE;
}

const filterCatalogsByOwnerType = (catalogs: Catalog[], filters: OwnerTypeFilters) => {
  if (Object.values(filters).every((f) => !f)) {
    return catalogs;
  }

  const appliedFilters = Object.entries(filters)
    .filter(([, value]) => value)
    .map(([key]) => key);

  const result = catalogs.filter((c) => appliedFilters.some((f) => c.owner.type === f));

  return result;
};

const filterCatalogsBySharingType = (catalogs: Catalog[], filter: USER_CATALOG_SHARING_TYPE) => {
  if (filter === USER_CATALOG_SHARING_TYPE.ALL) return catalogs;

  const privates = catalogs.filter((c) => c.owner.type === CATALOG_OWNER_TYPE.PRIVATE);

  return privates.filter((c) =>
    filter === USER_CATALOG_SHARING_TYPE.MY
      ? c.owner.id === localStorageService.userId
      : c.owner.id !== localStorageService.userId
  );
};

export const CatalogsTable: FC<Props> = ({ searchValue, sharingTypeFilter, ownerTypeFilters }) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const catalogs = useAppSelector(selectCatalogs);

  const filteredCatalogs = useMemo(() => {
    const filteredByOwner = filterCatalogsByOwnerType(catalogs, ownerTypeFilters);
    const filteredBySharing = filterCatalogsBySharingType(filteredByOwner, sharingTypeFilter);

    return filteredBySharing.filter((c) =>
      c.title.toLocaleLowerCase().trim().includes(searchValue.toLocaleLowerCase().trim())
    );
  }, [searchValue, sharingTypeFilter, ownerTypeFilters, catalogs]);

  return (
    <Box sx={{ height: 'calc(100svh - 215px)' }}>
      <Stack direction="row" justifyContent="space-between">
        <Box sx={{ width: '20%', pl: '20px' }}>
          <HeadCell>{t('name')}</HeadCell>
        </Box>
        <Box sx={{ width: '20%', minWidth: '100px' }}>
          <HeadCell>{t('type')}</HeadCell>
        </Box>
        <Box sx={{ width: '20%' }}>
          <HeadCell>{t('owner')}</HeadCell>
        </Box>
        <Box sx={{ width: '30%', minWidth: '100px' }}>
          <HeadCell>{t('Last modified')}</HeadCell>
        </Box>
        <Stack sx={{ width: '10%' }} alignItems="center">
          <HeadCell>
            <></>
          </HeadCell>
        </Stack>
      </Stack>

      <Box
        sx={{
          height: 'calc(100% - 60px)',
          width: '100%',
          overflowX: 'hidden',
          overflowY: 'auto',
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: palette.primary.main,
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar': {
            width: '8px',
            backgroundColor: palette.info.dark,
            borderRadius: '4px',
          },
        }}
      >
        {filteredCatalogs.length ? (
          filteredCatalogs.map((c) => <CatalogItem key={c.id} catalog={c} />)
        ) : (
          <Typography sx={{ fontSize: 18, textAlign: 'center', fontWeight: 600 }}>{t('No Catalogs')}</Typography>
        )}
      </Box>
    </Box>
  );
};
