import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  List,
  ListItemButton,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import { useAnchorElement } from 'shared/hooks';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useTranslation } from 'react-i18next';
import { Icon } from 'shared/ui';

const filterItems = ['Failed', 'Succeeded'];

interface Props {
  filters: string[];
  onChangeFilter: (value: string) => void;
}

export const StatusHeaderCell = ({ filters, onChangeFilter }: Props) => {
  const { t } = useTranslation();
  const { open, anchorEl, onOpenMenu, onCloseMenu } = useAnchorElement();

  return (
    <Stack direction="row" alignItems="center">
      {t('Status')}
      <IconButton onClick={onOpenMenu} sx={{ p: 0, ml: 1 }}>
        <PlayArrowIcon sx={{ height: '26px', rotate: '90deg', color: 'rgba(77, 78, 76, 1)' }} />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onCloseMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{ ml: 0.5, mt: -0.5, '& .MuiPaper-root': { borderRadius: '8px', overflow: 'hidden' } }}
      >
        <List disablePadding>
          <Stack direction="row" alignItems="center" sx={{ p: '4px 9px' }}>
            <Typography sx={{ fontWeight: 600, mr: 1 }}>{t('status')}</Typography>
            <PlayArrowIcon sx={{ height: '16px', rotate: '90deg', color: 'primary.main' }} />
          </Stack>

          <Divider />

          {filterItems.map((value, i) => (
            <ListItemButton
              key={i}
              sx={{ p: 0, bgcolor: filters.includes(value) ? 'primary.light' : 'info.main' }}
            >
              <FormControlLabel
                checked={filters.includes(value)}
                onChange={() => onChangeFilter(value)}
                label={<Box sx={{ color: 'darksome.main' }}>{t(value)}</Box>}
                sx={{ m: 0, px: 2, width: '100%' }}
                control={
                  <Checkbox
                    icon={<Icon path="checkbox-icon" />}
                    checkedIcon={<Icon path="checkbox-icon-checked" />}
                    sx={{ ml: -1.5 }}
                  />
                }
              />
            </ListItemButton>
          ))}
        </List>
      </Popover>
    </Stack>
  );
};
