import { FC, useState } from 'react';
import { Box, Divider, Grid, MenuItem, Stack, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import {
  selectAllRoles,
  selectFlattedOrg,
  selectOpenOrgDetails,
  selectOrganizations,
  selectPermissions,
  selectSelectedOrg,
  selectUsers,
  setSelectedOrg,
  setSynchronizedOrgData,
  toggleOrgDetails,
} from 'shared/slices';
import {
  Button,
  ContentBox,
  CountrySelect,
  DeleteIcon,
  DialogModal,
  HoverToolTip,
  Icon,
  Input,
  SaveControl,
  Select,
} from 'shared/ui';
import { UpdateOrgDTO } from 'shared/models';
import { OrgDetailsLogo } from './components/OrgDetailsLogo';
import { DeleteOrgModal } from 'features';
import { useTranslation } from 'react-i18next';
import { getFatherOrganizations, isUserGlobalAdmin } from 'shared/lib';
import { useOrgDetailsValues } from './hooks';
import { OrgService } from 'shared/services';

interface Props {
  isUserManagement?: boolean;
}

export const OrganizationDetails: FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const openOrgDetails = useAppSelector(selectOpenOrgDetails);
  const organizations = useAppSelector(selectOrganizations);
  const selectedOrg = useAppSelector(selectSelectedOrg);
  const flattedOrgs = useAppSelector(selectFlattedOrg);
  const users = useAppSelector(selectUsers);
  const permissions = useAppSelector(selectPermissions);
  const allRoles = useAppSelector(selectAllRoles);

  const fatherOrganizations = selectedOrg ? getFatherOrganizations(selectedOrg, organizations) : [];

  const { orgDetails, onChangeOrgDetails, onChangeLogoImg, onChangeCountry, onChangeFatherOrgId, nameHelpText } =
    useOrgDetailsValues(openOrgDetails);

  const handleClose = () => dispatch(toggleOrgDetails());

  const handleDeleteOrg = () => setIsDelete(!isDelete);

  const handleDoneEdit = async () => {
    if (!selectedOrg || !isUpdatePermissionAble || nameHelpText) return;

    setIsLoading(true);

    const orgDto: UpdateOrgDTO = {
      id: selectedOrg.id,
      organizationName: orgDetails.organizationName,
      fatherOrganizationId: orgDetails.fatherOrganizationId ? +orgDetails.fatherOrganizationId : null,
      country: orgDetails.country,
      organizationDescription: orgDetails.organizationDescription,
      salesRep: orgDetails.salesRep,
      erpCustomerNumber: orgDetails.erpCustomerNumber,
      isActive: orgDetails.checked,
      ...(orgDetails.logo.includes('data:image') && { logo: orgDetails.logo }),
    };

    const updatedOrg = await OrgService.changeOrg(orgDto);
    if (updatedOrg) {
      dispatch(setSelectedOrg(updatedOrg));
      dispatch(setSynchronizedOrgData(false));
      handleClose();
    }
    setIsLoading(false);
  };

  const initFatherOrg = fatherOrganizations.find((o) => o.id === selectedOrg?.fatherOrganizationId);

  const isUpdatePermissionAble = selectedOrg
    ? permissions[selectedOrg.id].organization.update || permissions[selectedOrg.id].sub_organization.update
    : false;

  const isDeletePermissionAble = selectedOrg
    ? permissions[selectedOrg.id].organization.delete || permissions[selectedOrg.id].sub_organization.delete
    : false;

  const currentUserId = localStorage.getItem('userId');
  const isGlobalAdmin = isUserGlobalAdmin(users, allRoles, currentUserId ?? '');
  const isHasSubOrgs = !!flattedOrgs.find((o) => o.id === selectedOrg?.id)?.subOrganizations.length;

  const open = !isDelete && openOrgDetails;

  const isParentOrganizationDisabled = selectedOrg?.id === 1000;
  return (
    <>
      <DialogModal
        withCloseButton={false}
        padding={'24px 64px 35px 64px'}
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '800px',
            borderRadius: '10px',
          },
        }}
      >
        <Box component="form">
          <Stack direction="row" justifyContent={'space-between'} sx={{ mb: '8px' }}>
            <Typography
              sx={{
                color: '#141522',
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '130%',
                letterSpacing: '0.24px',
              }}
            >
              {t('Edit Organization Details')}
            </Typography>

            <HoverToolTip
              title={t('Organization cant be deleted. Contact support for more help')}
              disableHoverListener={!isDeletePermissionAble || !isHasSubOrgs}
            >
              <Button
                disabled={!isDeletePermissionAble || isHasSubOrgs}
                onClick={handleDeleteOrg}
                sx={{
                  bgcolor: 'secondary.dark',
                  border: '1px solid grey',
                  borderColor: 'info.dark',
                  height: '40px',
                  width: '187px',
                  padding: '8px 16px',
                }}
              >
                <DeleteIcon sx={{ mr: '8px' }} />

                <Typography
                  sx={{
                    color: '#242731',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    fontWeight: 400,
                    lineHeight: '130%',
                    letterSpacing: '0.14px',
                  }}
                >
                  Delete Organization
                </Typography>
              </Button>
            </HoverToolTip>
          </Stack>
          <Divider sx={{ borderColor: '#D4D4D4', borderBottomWidth: '1px', mb: '48px' }} />

          <ContentBox sx={{ height: '55svh' }}>
            <Stack direction="row" gap={'32px'} mb={'48px'}>
              <OrgDetailsLogo logo={orgDetails.logo} changeImg={onChangeLogoImg} />

              <Stack>
                <Typography
                  sx={{
                    color: '#242731',
                    fontFamily: 'Roboto',
                    fontSize: '24px',
                    fontWeight: 600,
                    lineHeight: '130%',
                    width: '520px',
                    textOverflow: 'ellipsis',
                    textWrap: 'nowrap',
                    overflow: 'hidden',
                    letterSpacing: '0.24px',
                    mb: '12px',
                  }}
                >
                  {orgDetails.organizationName}
                </Typography>
                {initFatherOrg && (
                  <>
                    <Typography
                      sx={{
                        color: '#242731',
                        fontFamily: 'Roboto',
                        fontSize: '18px',
                        fontWeight: 400,
                        lineHeight: '130%',
                        letterSpacing: '0.18px',
                        mb: '8px',
                      }}
                    >
                      Parent Organization
                    </Typography>
                    <Stack direction="row">
                      <Icon
                        isLogo={!!initFatherOrg?.logo}
                        path={initFatherOrg?.logo ?? 'org-placeholder'}
                        alt=""
                        sx={{ width: 24, height: 24, borderRadius: '6px', mr: '8px' }}
                      />
                      <Typography
                        sx={{
                          color: '#667085',
                          fontFamily: 'Roboto',
                          fontSize: '18px',
                          fontWeight: 400,
                          lineHeight: '140%',
                          letterSpacing: '0.18px',
                          width: '480px',
                          textOverflow: 'ellipsis',
                          textWrap: 'nowrap',
                          overflow: 'hidden',
                        }}
                      >
                        {initFatherOrg?.organizationName}
                      </Typography>
                    </Stack>
                  </>
                )}
              </Stack>
            </Stack>

            <Grid container spacing={3} sx={{ mb: '28px' }}>
              <Grid item xs={6}>
                <Input
                  value={orgDetails.organizationName ?? ''}
                  onChange={onChangeOrgDetails}
                  name="organizationName"
                  label={`${t('Organization name')} `}
                  error={!!nameHelpText}
                  helperText={nameHelpText}
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                {!isGlobalAdmin ? (
                  <Select
                    disabled={isParentOrganizationDisabled}
                    label={`${t('Parent Organization')}`}
                    value={orgDetails.fatherOrganizationId ?? ''}
                    variation="outlined"
                    name="fatherOrganizationId"
                  >
                    {fatherOrganizations.map((org, idx) => (
                      <MenuItem value={org.id} key={idx} onClick={() => onChangeFatherOrgId(org.id)}>
                        {org.organizationName}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <Input
                    label={`${t('father_org')}`}
                    value={orgDetails.fatherOrganizationName ?? 'No parent org'}
                    disabled
                  />
                )}
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ mb: '48px' }}>
              <Grid item xs={6}>
                <Input label={`${t('Organization ID')} `} value={selectedOrg?.id ?? ''} disabled />
              </Grid>
              <Grid item xs={6}>
                {' '}
                <CountrySelect
                  label={`${t('country')}`}
                  country={orgDetails.country ?? ''}
                  setCountry={onChangeCountry}
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <Stack sx={{ width: '100%' }} mb={'48px'}>
              <Input
                label={`${t('description')} `}
                big
                name="organizationDescription"
                value={orgDetails.organizationDescription ?? ''}
                onChange={onChangeOrgDetails}
              />
            </Stack>
          </ContentBox>

          <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ width: '100%', mt: 2 }}>
            <SaveControl
              onSave={handleDoneEdit}
              onCancel={handleClose}
              isLoading={isLoading}
              btnTexts={[t('Save changes')]}
            />
          </Stack>
        </Box>
      </DialogModal>

      <DeleteOrgModal open={isDelete} onClose={handleDeleteOrg} />
    </>
  );
};
