import { ROOT_ORG_ID } from 'shared/constants';
import { AvailabilityItem, Organization } from 'shared/models';

export const getAvailabilityPermittedOrgs = (allOrgs: Organization[], availableOrgIDs: number[]) => {
  let availableOrgs: Organization[] = [];

  allOrgs.forEach((o) => {
    const isOrgAvailabilityPermitted = availableOrgIDs.includes(o.id);

    if (isOrgAvailabilityPermitted) {
      availableOrgs.push(o);
    } else {
      const childAvailableOrg = getAvailabilityPermittedOrgs(o.subOrganizations, availableOrgIDs);
      availableOrgs = [...availableOrgs, ...childAvailableOrg];
    }
  });

  return availableOrgs;
};

export const getFatherAvailability = (
  orgID: string,
  orgs: Organization[],
  availabilities: AvailabilityItem[]
): AvailabilityItem | null => {
  if (!availabilities.length) return null;

  const currentOrg = orgs.find((o) => o.organizationId === orgID);
  if (!currentOrg || currentOrg.id === ROOT_ORG_ID) return null;

  const fatherOrgID = orgs.find((o) => o.id === currentOrg.fatherOrganizationId)?.organizationId;
  if (!fatherOrgID) return null;

  const fatherAvailability = availabilities.find((a) => a.sourceAvailability.orgId === fatherOrgID);
  if (fatherAvailability) return fatherAvailability;

  const availability: AvailabilityItem | null = getFatherAvailability(fatherOrgID, orgs, availabilities) ?? null;
  return availability;
};
