import { FC, useEffect, useMemo, useState } from 'react';
import { Box, FormControl, Typography } from '@mui/material';
import { useAppSelector } from 'shared/hooks';
import { RoleCheckBox } from './RoleCheckBox';
import { useTranslation } from 'react-i18next';
import { ADMIN_ROLES, APPLICATION_ROLES, CATALOG_ROLES, ROLE_KEY, ROOT_ORG_ID } from 'shared/constants';
import { Role } from 'shared/models';
import { selectPermissionsState, selectSelectedOrg, selectSelectedUser } from '../../shared/slices';

interface Props {
  isOpen?: boolean;
  isDisabled?: boolean;
  roleIDs: string[];
  setRoles: (roles: string[]) => void;
  rolesErrorText?: string;
}

const titleStyles = {
  color: '#242731',
  fontFamily: 'Roboto',
  fontSize: '18px',
  lineHeight: '23.4px',
  letterSpacing: '0.18px',
  mb: '12px',
};

export const UserPermissions: FC<Props> = ({ isOpen, isDisabled, roleIDs = [], setRoles, rolesErrorText }) => {
  const { t } = useTranslation();
  const { allRoles, permissions: appPermissions } = useAppSelector(selectPermissionsState);

  const selectedOrg = useAppSelector(selectSelectedOrg);
  const selectedUser = useAppSelector(selectSelectedUser);

  const grantRolePermissions = useMemo(
    () => (selectedOrg ? appPermissions[selectedOrg.id].grant_role : false),
    [selectedOrg, selectedUser]
  );

  const mappedRoles = useMemo(() => {
    return grantRolePermissions && selectedOrg
      ? allRoles
          .map((item) => ({ id: item.id, name: item.name, key: item.key }) as Role)
          .filter((item) => {
            const formattedKey = item.name.toLowerCase().replace(' ', '-') as keyof typeof grantRolePermissions;
            return grantRolePermissions[formattedKey];
          })
      : [];
  }, [grantRolePermissions, selectedUser]);

  const mappedRoleKeys = mappedRoles.map((r) => r.key);

  const adminRoles = useMemo(() => {
    const isNotRootOrg = selectedOrg?.id !== ROOT_ORG_ID;

    return ADMIN_ROLES.filter((k) => mappedRoleKeys.includes(k))
      .map((key) => mappedRoles.find((r) => r.key === key) as Role)
      .filter((r) => {
        const isPlatformAdminRole = r.key === ROLE_KEY.PLATFORM_ADMIN;
        return !(isNotRootOrg && isPlatformAdminRole);
      });
  }, [mappedRoles]);

  const catalogRoles = useMemo(
    () =>
      CATALOG_ROLES.filter((k) => mappedRoleKeys.includes(k)).map(
        (key) => mappedRoles.find((r) => r.key === key) as Role
      ),
    [mappedRoles]
  );
  const applicationRoles = useMemo(
    () =>
      APPLICATION_ROLES.filter((k) => mappedRoleKeys.includes(k)).map(
        (key) => mappedRoles.find((r) => r.key === key) as Role
      ),
    [mappedRoles]
  );

  const [disabledRoleIDs, setDisabledRoleIDs] = useState<string[]>([]);

  const handleToggle = (role: Role) => {
    let roles = roleIDs.map((id) => {
      const role = mappedRoles.find((r) => r.id === id) as Role;
      return role;
    });

    const isAdded = roleIDs.includes(role.id);

    const disableRoleBtn = (subRoleKey: string) => {
      const subRole = mappedRoles.find((r) => r.key === subRoleKey) as Role;
      if (!subRole) return;

      if (isAdded) {
        const disableIDs = disabledRoleIDs.filter((id) => id !== subRole?.id);
        setDisabledRoleIDs(disableIDs);
      } else {
        roles.push(role);
        setDisabledRoleIDs([...disabledRoleIDs, subRole.id]);
      }
    };

    if (role.key === ROLE_KEY.CAD_DESIGNER) {
      disableRoleBtn(ROLE_KEY.RAPID_USER);
    }
    if (role.key === ROLE_KEY.CATALOG_MANAGER) {
      disableRoleBtn(ROLE_KEY.AVAILABILITY_MANAGER);
    }
    if (role.key === ROLE_KEY.GLOBAL_ADMIN) {
      disableRoleBtn(ROLE_KEY.ORG_ADMIN);
    }
    if (role.key === ROLE_KEY.PLATFORM_ADMIN) {
      const orgAdminRole = mappedRoles.find((r) => r.key === ROLE_KEY.ORG_ADMIN) as Role;
      const globalAdminRole = mappedRoles.find((r) => r.key === ROLE_KEY.GLOBAL_ADMIN) as Role;

      if (isAdded) {
        const disableIDs = disabledRoleIDs.filter((id) => id !== orgAdminRole?.id && id !== globalAdminRole?.id);
        setDisabledRoleIDs(disableIDs);
      } else {
        roles.push(role);
        setDisabledRoleIDs([...disabledRoleIDs, orgAdminRole.id, globalAdminRole.id]);
      }
    }

    if (isAdded) {
      roles = roles.filter((r) => r?.id !== role?.id);
    } else {
      roles.push(role);
    }

    setRoles([...roles.map((r) => r?.id)]);
  };

  useEffect(() => {
    const updatedIdRoles = [...roleIDs];
    const disabledIDs = [...disabledRoleIDs];

    const disableRoleBtn = (roleKey: string) => {
      const role = mappedRoles.find((r) => r.key === roleKey) as Role;
      if (!role) return;
      disabledIDs.push(role.id);
    };

    roleIDs.forEach((id) => {
      const role = mappedRoles.find((r) => r.id === id) as Role;
      if (!role) return;

      if (role.key === ROLE_KEY.CAD_DESIGNER) {
        disableRoleBtn(ROLE_KEY.RAPID_USER);
      }
      if (role.key === ROLE_KEY.CATALOG_MANAGER) {
        disableRoleBtn(ROLE_KEY.AVAILABILITY_MANAGER);
      }
      if (role.key === ROLE_KEY.GLOBAL_ADMIN) {
        disableRoleBtn(ROLE_KEY.ORG_ADMIN);
      }
      if (role.key === ROLE_KEY.PLATFORM_ADMIN) {
        disableRoleBtn(ROLE_KEY.GLOBAL_ADMIN);
        disableRoleBtn(ROLE_KEY.ORG_ADMIN);
      }
    });

    setDisabledRoleIDs(disabledIDs);
    setRoles(updatedIdRoles);
  }, [isOpen, selectedUser]);

  return (
    <Box>
      <Typography
        sx={{
          color: '#242731',
          fontFamily: 'Roboto',
          fontSize: '18px',
          fontWeight: 600,
          lineHeight: '16px',
          letterSpacing: '0.18px',
          mb: '28px',
          position: 'relative',
        }}
      >
        {t('permissions')}

        {!!rolesErrorText && (
          <Typography
            sx={{
              color: '#F00',
              fontFamily: 'Roboto',
              fontSize: '12px',
              fontWeight: 400,
              lineHeight: '20px',
              position: 'absolute',
              top: '100%',
            }}
          >
            {rolesErrorText}
          </Typography>
        )}
      </Typography>

      <FormControl
        sx={{
          opacity: isDisabled ? 0.5 : 1,
          transition: 'opacity .3s ease-out',
          width: '100%',
          display: 'block',
        }}
      >
        <Box mb={'16px'}>
          <Typography sx={titleStyles}>{t('Admin')}</Typography>
          {adminRoles.map((role) => (
            <RoleCheckBox
              disabled={isDisabled || disabledRoleIDs.includes(role.id)}
              checked={roleIDs.includes(role.id)}
              toggle={() => handleToggle(role)}
              key={role.id}
              label={role.name}
              value={role.id}
            />
          ))}
        </Box>
        <Box mb={'16px'}>
          <Typography sx={titleStyles}>{t('Catalog')}</Typography>
          {catalogRoles.map((role) => (
            <RoleCheckBox
              disabled={isDisabled || disabledRoleIDs.includes(role.id)}
              checked={roleIDs.includes(role.id)}
              toggle={() => handleToggle(role)}
              key={role.id}
              label={role.name}
              value={role.id}
            />
          ))}
        </Box>

        <Box>
          <Typography sx={titleStyles}>{t('Application')}</Typography>
          {applicationRoles.map((role) => (
            <RoleCheckBox
              disabled={isDisabled || disabledRoleIDs.includes(role.id)}
              checked={roleIDs.includes(role.id)}
              toggle={() => handleToggle(role)}
              key={role.id}
              label={role.name}
              value={role.id}
            />
          ))}
        </Box>
      </FormControl>
    </Box>
  );
};
