import { InputBase, styled } from '@mui/material';

interface OutlinedSelectInputProps {
  disabled?: boolean;
}

export const OutlinedSelectInput = styled(InputBase, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<OutlinedSelectInputProps>(({ theme, disabled }) => ({
  fontFamily: 'Myriad Pro',
  borderRadius: '10px',
  border: `1px solid ${disabled ? theme.palette.info.light : theme.palette.info.dark} !important`,
  padding: ' 0 22px',
  color: disabled ? theme.palette.success.dark : theme.palette.secondary.light,
  fontSize: '18px',
  height: '46px',

  '&::placeholder': {
    opacity: 1,
    color: disabled ? theme.palette.success.dark : theme.palette.secondary.light,
    lineHeight: '16px',
    fontFamily: 'Myriad Pro',
  },
}));
