import { TableCell, styled } from '@mui/material';

export const HeadCell = styled(TableCell)(({ theme }) => ({
  color: 'grey',
  fontSize: '18px',
  lineHeight: '16px',
  fontWeight: 700,
  paddingLeft: 0,
  paddingRight: 0,
  whiteSpace: 'nowrap',
  backgroundColor: theme.palette.info.main,
  borderBottom: `1px solid grey`,
  borderColor: theme.palette.info.light,
}));
