import { FC, RefObject, useRef, useState } from 'react';
import { Box } from '@mui/system';
import { ItemsBox } from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { BoxHeader, Item } from './GroupsSubtypesBrands';
import { useTranslations } from '../hooks';
import { Stack, Typography, styled } from '@mui/material';
import { CATALOG_TYPE } from 'shared/constants';
import { CatalogProductTranslations } from 'features';

export const TranslationItem = styled(Box)(({ theme }) => ({
  padding: '5px !important',
  borderBottom: '1px solid grey',
  borderColor: theme.palette.info.light,
  color: 'black',
  textTransform: 'capitalize',
  justifyContent: 'flex-start',
  borderRadius: 0,
  '&:hover': { bgcolor: 'primary.light' },
}));

export const CatalogTranslations: FC = () => {
  const { t } = useTranslation();

  const {
    groupItems,
    languages,
    isLanguagesLoading,
    group,
    language,
    onLanguageChange,
    onGroupChange,
    selected,
    onSelect,
    catalogTypes,
    type,
    onTypeChange,
    filteredProducts,
    updateFilteredProduct,
    isProductsReady,
    onArrowKeyPress,
  } = useTranslations();

  const selectedItemRef = useRef<HTMLElement>(null);

  const [activeBox, setActiveBox] = useState<'' | 'type' | 'group' | 'translation'>('');

  const onKeyDownWrapper = (e: React.KeyboardEvent<HTMLDivElement | HTMLButtonElement>) => {
    onArrowKeyPress(e, activeBox);

    if (selectedItemRef.current) {
      const target = selectedItemRef.current;
      const parent = target.parentNode as HTMLElement;

      if (parent) {
        const parentRect = parent.getBoundingClientRect();
        const targetRect = target.getBoundingClientRect();

        if (e.key === 'ArrowDown') {
          parent.scrollTop -= parentRect.top - targetRect.top;
        }

        if (e.key === 'ArrowUp') {
          parent.scrollTop += targetRect.bottom - parentRect.bottom;
        }
      }
    }
  };

  return (
    <Stack
      direction="row"
      spacing={8}
      alignItems="flex-start"
      sx={{ height: '100%', overflowX: 'auto', width: '100%' }}
    >
      <ItemsBox
        isActive={activeBox === 'type'}
        header={<BoxHeader>{t('Select Type')}</BoxHeader>}
        sx={{ width: '240px' }}
      >
        {catalogTypes.map((ct) => (
          <Item
            key={ct}
            ref={ct === type ? (selectedItemRef as RefObject<HTMLButtonElement>) : null}
            // disabled={isLanguagesLoading || !isProductsReady}
            disabled={isLanguagesLoading}
            onClick={() => {
              onTypeChange(ct);
              setActiveBox('type');
            }}
            onKeyDown={onKeyDownWrapper}
            tabIndex={0}
            sx={{
              p: 1,
              ...(type === ct && { bgcolor: 'primary.light', '&:hover': { bgcolor: 'primary.light' } }),
              ...(ct === CATALOG_TYPE.DISTRIBUTION_CURVE && { display: 'none' }),
            }}
          >
            <Typography noWrap>{ct}</Typography>
          </Item>
        ))}
      </ItemsBox>

      <ItemsBox
        isActive={activeBox === 'group'}
        header={<BoxHeader>{t('Select Group')}</BoxHeader>}
        sx={{ width: '240px' }}
      >
        {groupItems.map((gi) => (
          <Item
            key={gi.id}
            ref={gi.id === group ? (selectedItemRef as RefObject<HTMLButtonElement>) : null}
            disabled={isLanguagesLoading || !isProductsReady}
            onClick={() => {
              onGroupChange(gi.id);
              setActiveBox('group');
            }}
            onKeyDown={onKeyDownWrapper}
            tabIndex={0}
            sx={{ p: 1, ...(group === gi.id && { bgcolor: 'primary.light' }) }}
          >
            <Typography noWrap>{gi.name}</Typography>
          </Item>
        ))}
      </ItemsBox>

      <CatalogProductTranslations
        isActive={activeBox === 'translation'}
        isLanguagesLoading={isLanguagesLoading}
        isProductsReady={isProductsReady}
        languages={languages}
        language={language}
        onLanguageChange={onLanguageChange}
        products={filteredProducts}
        selected={selected}
        updateProduct={updateFilteredProduct}
        onSelect={(id) => {
          onSelect(id);
          setActiveBox('translation');
        }}
        onKeyDown={onKeyDownWrapper}
        selectedItemRef={selectedItemRef}
      />
    </Stack>
  );
};
