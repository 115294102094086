import { FC } from 'react';
import { Stack } from '@mui/material';
import { BigTitle, Button, DialogModal } from 'shared/ui';
import { useTranslation } from 'react-i18next';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmCloseCatalogModal: FC<Props> = ({ open, onClose, onConfirm }) => {
  const { t } = useTranslation();

  const onConfirmClose = () => {
    onConfirm();
    onClose();
  };

  return (
    <DialogModal open={open} onClose={onClose}>
      <BigTitle sx={{ textAlign: 'center', my: '20px' }}>This catalog has changes</BigTitle>
      <BigTitle sx={{ textAlign: 'center', my: '20px' }}>Are you sure to close?</BigTitle>
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing="80px"
        sx={{ width: '100%', mt: '30px', height: '44px' }}
      >
        <Button onClick={onClose} red fullWidth>
          {t('no')}
        </Button>
        <Button onClick={onConfirmClose} filled fullWidth>
          {t('yes')}
        </Button>
      </Stack>
    </DialogModal>
  );
};
