import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CATALOG_TYPE } from 'shared/constants';
import { ROUTE } from 'shared/enum';
import { saveTabRouteToLS } from 'shared/lib';
import { localStorageService } from 'shared/services';

export const useCatalogSearchParams = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { type, manageIndex } = Object.fromEntries([...searchParams]);

  const catalogId = id as string;

  const onChangeType = (type: string) => {
    setSearchParams((prev) => ({ ...prev, type }));
    const route = `${ROUTE.CATALOGS}/${catalogId}?type=${type}`;
    saveTabRouteToLS(catalogId, route);
  };

  const onChangeManageIndex = (manageIndex: number) => {
    setSearchParams((prev) => ({ ...prev, manageIndex, type }));
    const route = `${ROUTE.CATALOGS}/${catalogId}/management?manageIndex=${manageIndex}&type=${type}`;
    saveTabRouteToLS(catalogId, route);
  };
  const onChangeManageType = (type: string) => {
    setSearchParams((prev) => ({ ...prev, manageIndex, type }));
    const route = `${ROUTE.CATALOGS}/${catalogId}/management?manageIndex=${manageIndex}&type=${type}`;
    saveTabRouteToLS(catalogId, route);
  };

  const navigateToCatalogTable = () => {
    const ids = localStorageService.lastDcCatalogIDs;
    const redirectType =
      type === CATALOG_TYPE.EMITTER && ids.includes(catalogId) ? CATALOG_TYPE.DISTRIBUTION_CURVE : type;
    localStorageService.lastDcCatalogIDs = ids.filter((id) => id !== catalogId);

    const route = `${ROUTE.CATALOGS}/${catalogId}?type=${redirectType}`;
    saveTabRouteToLS(catalogId, route);
    navigate(route);
  };

  const navigateToCatalogManagement = () => {
    const ids = localStorageService.lastDcCatalogIDs;
    localStorageService.lastDcCatalogIDs =
      type === CATALOG_TYPE.DISTRIBUTION_CURVE ? [...new Set([...ids, catalogId])] : ids;
    const redirectType = type === CATALOG_TYPE.DISTRIBUTION_CURVE ? CATALOG_TYPE.EMITTER : type;

    const route = `${ROUTE.CATALOGS}/${catalogId}/management?manageIndex=0&type=${redirectType}`;
    saveTabRouteToLS(catalogId, route);
    navigate(route);
  };

  return {
    catalogId,
    type,
    manageIndex,
    setSearchParams,
    onChangeType,
    onChangeManageIndex,
    onChangeManageType,
    navigateToCatalogTable,
    navigateToCatalogManagement,
  };
};
