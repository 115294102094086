import React, { FC, useEffect, useRef, useState } from 'react';
import { Box, List, ListItemButton, ListItemIcon, Popover, Stack, styled, Typography } from '@mui/material';
import { useAnchorElement, useAppDispatch, useAppSelector, usePhone } from 'shared/hooks';
import { ArrowDown, Button, DialogModal, Icon, Input, PhoneInput, SaveControl } from 'shared/ui';
import { EditUserDTO } from 'shared/models';
import { STATUS } from 'shared/constants';
import { useTranslation } from 'react-i18next';
import { UserPermissions } from 'entities/Permissions';
import {
  selectOpenUserDetails,
  selectPermissions,
  selectSelectedOrg,
  selectSelectedUser,
  setSynchronizedOrgData,
  toggleUserDetails,
  updateOrgUsersSynchronizeCount,
} from 'shared/slices';
import { useUserValues } from './hooks';
import PendingIcon from '@mui/icons-material/Pending';
import { AppType } from 'shared/enum';
import { UserService, localStorageService } from 'shared/services';

const ListButton = styled(ListItemButton)(({ theme }) => ({
  backgroundColor: theme.palette.info.dark,
  marginTop: 10,
  height: 40,
  maxHeight: 40,
  borderRadius: '8px',
}));

type HandleModalToggle = (setter: React.Dispatch<React.SetStateAction<boolean>>) => () => void;

export const UserDetails: FC = () => {
  const userFormRef = useRef<HTMLFormElement>(null);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { open, anchorEl, onOpenMenu, onCloseMenu } = useAnchorElement();

  const selectedUser = useAppSelector(selectSelectedUser);
  const selectedOrg = useAppSelector(selectSelectedOrg);
  const permissions = useAppSelector(selectPermissions);
  const openUserDetails = useAppSelector(selectOpenUserDetails);

  const [openDeleteUser, setOpenDeleteUser] = useState(false);
  const [openRemoveUserFromOrg, setOpenRemoveUserFromOrg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isSentInvitation, setIsSentInvitation] = useState(false);

  useEffect(() => {
    openUserDetails && setIsSentInvitation(false);
  }, [openUserDetails]);

  const { phoneNumber, onPhoneChange, checkIsPhoneValid, countryCode, phoneErrorText } = usePhone(openUserDetails);

  const { userData, onUserDataChange, toggleRoles, checkIsValid, nameErrorText, emailErrorText, rolesErrorText } =
    useUserValues(openUserDetails, onPhoneChange);

  const handleClose = () => dispatch(toggleUserDetails());

  const handleSave = async () => {
    if (!selectedOrg?.id || !selectedUser?.userId) return;
    if (phoneErrorText) return;

    const error = !checkIsValid();
    const phoneError = !checkIsPhoneValid();
    if (error || phoneError) return;

    setIsLoading(true);

    const editUserDto: EditUserDTO = {
      organizationId: +selectedOrg.id,
      id: selectedUser.userId,
      name: userData.name,
      email: userData.email,
      phone: phoneNumber.replaceAll(' ', ''),
      roles: userData.roles,
    };

    const isEdited = await UserService.editUserDetails(editUserDto);
    if (isEdited) {
      dispatch(updateOrgUsersSynchronizeCount());
      handleClose();
    }
    setIsLoading(false);
  };

  const handleRemoveUserFromOrg = async () => {
    if (!selectedOrg?.id) return;

    setIsRemoving(true);
    const body = {
      id: selectedUser?.userId ?? '',
      organizationId: +selectedOrg.id,
    };

    const isDeactivated = await UserService.deactivateUserFromOrg(body);
    if (isDeactivated) {
      dispatch(setSynchronizedOrgData(false));
      dispatch(updateOrgUsersSynchronizeCount());

      handleClose();
      setOpenRemoveUserFromOrg(false);
    }
    setIsRemoving(false);
  };

  const handleDeleteUser = async () => {
    if (!selectedUser?.userId) return;

    setIsLoading(true);

    const isDeleted = await UserService.deleteUser(selectedUser.userId);
    if (isDeleted) {
      dispatch(setSynchronizedOrgData(false));
      dispatch(updateOrgUsersSynchronizeCount());

      setOpenDeleteUser(false);
      handleClose();
    }
    setIsLoading(false);
  };

  const handleResendInvitationEmail = async () => {
    if (!selectedUser?.userId) return;

    setIsLoading(true);

    const isResent = await UserService.resendInvitationEmail(selectedUser.userId, AppType.Platform);
    setIsSentInvitation(!!isResent);
    setIsLoading(false);
  };

  const handleModalToggle: HandleModalToggle = (setter) => () => {
    setter((prev) => !prev);
    onCloseMenu();
  };

  const disableDeleting = selectedUser?.userId === localStorageService.userId;

  const openPopup = openUserDetails && !openDeleteUser && !openRemoveUserFromOrg;

  const isSuspendPermissionAble = selectedOrg ? permissions[selectedOrg.id]?.user.suspend : false;
  const isDeleteUserPermissionAble = selectedOrg ? permissions[selectedOrg.id]?.user.delete : false;
  const isResendEmailAble = !(
    selectedUser?.status === STATUS.ACTIVE || selectedUser?.status === STATUS.DEACTIVATED
  );

  const onlyCanRemoveUser =
    !disableDeleting && selectedUser?.status === STATUS.ACTIVE && !isResendEmailAble && !isSuspendPermissionAble;

  return (
    <>
      <DialogModal
        withCloseButton={false}
        padding={'24px 64px 44px 64px'}
        open={openPopup}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            overflowY: 'visible',
            width: '100%',
            maxWidth: '888px',
          },
        }}
      >
        <Box component="form" ref={userFormRef}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: '100%', mb: '48px' }}
          >
            <Typography
              sx={{
                color: '#141522',
                textAlign: 'center',
                fontFamily: 'Roboto',
                fontSize: '24px',
                fontWeight: 600,
                lineHeight: '130%',
                letterSpacing: '0.24px',
              }}
            >
              {t('Edit User')}
            </Typography>

            {!disableDeleting && !onlyCanRemoveUser && (
              <Button
                onClick={onOpenMenu}
                disabled={disableDeleting}
                sx={{
                  borderRadius: '8px',
                  border: '1px solid #D4D4D4',
                  borderColor: '#D4D4D4',
                  background: '#D0D5DD',
                  padding: '8px 16px',
                  height: '40px',
                  width: '176px',
                }}
              >
                <PendingIcon sx={{ color: 'secondary.light', mr: '8px' }} />
                <Typography
                  sx={{
                    textAlign: 'left',
                    width: '100%',
                    color: '#242731',
                    fontFamily: 'Roboto',
                    fontSize: '14px',
                    lineHeight: '130%',
                    letterSpacing: '0.14px',
                  }}
                >
                  Actions
                </Typography>
                <ArrowDown
                  viewBox="0 0 12 8"
                  sx={{ width: 12, height: 7, color: 'secondary.light', rotate: `${open ? 180 : 0}deg` }}
                />
              </Button>
            )}

            {onlyCanRemoveUser && (
              <Button
                onClick={handleModalToggle(setOpenRemoveUserFromOrg)}
                sx={{
                  borderRadius: '8px',
                  border: '1px solid #D4D4D4',
                  borderColor: '#D4D4D4',
                  background: '#D0D5DD',
                  padding: '8px 16px',
                  height: '40px',
                  minWidth: '176px',
                }}
              >
                <Icon path="products-view/menu-remove-user" />

                <Typography noWrap sx={{ color: 'darksome.main', ml: 1, width: '100%' }}>
                  Remove user
                </Typography>
              </Button>
            )}
          </Stack>

          <Stack direction={'row'} sx={{ mb: '48px' }}>
            <Stack
              sx={{
                flexShrink: 0,
                mr: 4,
                width: '328px',
                pr: '32px',
                borderRight: '1px solid grey',
                borderColor: 'info.dark',
              }}
            >
              <Stack
                justifyContent="space-between"
                sx={{
                  position: 'relative',
                  mb: 1,
                  '& > fieldset': {
                    border: 'none',
                    width: '100%',
                    p: 0,
                  },
                }}
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 700,
                      color: selectedUser?.status === STATUS.ACTIVE ? 'primary.main' : 'error.main',
                    }}
                  >
                    {!userData.roles?.length
                      ? t('not_active')
                      : selectedUser?.status === STATUS.ACTIVE
                      ? t('active')
                      : t('pending')}
                  </Typography>
                </Box>
                <Box mb={'32px'}>
                  <Input
                    disabled={selectedUser?.status === STATUS.ACTIVE}
                    value={userData.email}
                    onChange={onUserDataChange}
                    label={t('E-Mail')}
                    placeholder="example@mail.com"
                    name="email"
                    error={!!emailErrorText}
                    helperText={emailErrorText}
                  />
                </Box>

                <Box mb={'32px'}>
                  <Input
                    value={userData.name}
                    onChange={onUserDataChange}
                    onBlur={() => checkIsValid()}
                    label={t('name')}
                    placeholder="Name"
                    name="name"
                    error={!!nameErrorText}
                    helperText={nameErrorText}
                  />
                </Box>

                <PhoneInput
                  value={phoneNumber}
                  onChange={onPhoneChange}
                  onBlur={() => checkIsPhoneValid()}
                  countryCode={countryCode}
                  phoneErrorText={phoneErrorText}
                />
              </Stack>
            </Stack>

            <UserPermissions
              isOpen={openUserDetails}
              isDisabled={!userData.isActive}
              roleIDs={userData.roles}
              setRoles={toggleRoles}
              rolesErrorText={rolesErrorText}
            />
          </Stack>

          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <SaveControl onSave={handleSave} onCancel={handleClose} isLoading={isLoading} />
          </Stack>
        </Box>
      </DialogModal>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onCloseMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '10px',
            boxShadow: 'none',
            width: '176px',
            bgcolor: 'secondary.dark',
          },
          '& .MuiListItemIcon-root': {
            minWidth: '30px',
          },
        }}
      >
        <List sx={{ padding: 0, height: 'auto' }}>
          {isResendEmailAble && (
            <ListButton
              onClick={handleResendInvitationEmail}
              disabled={isSentInvitation}
              sx={{
                borderRadius: '8px',
                border: '1px solid #D4D4D4',
                borderColor: '#D4D4D4',
                background: '#D0D5DD',
                padding: '8px 16px',
                height: '40px',
                width: '176px',
              }}
            >
              <ListItemIcon>
                <Icon
                  path={`products-view/${isSentInvitation ? 'menu-email-already-sent' : 'menu-resend-email'}`}
                />
              </ListItemIcon>
              <Typography noWrap sx={{ color: 'darksome.main' }}>
                {isSentInvitation ? t('Invitation Sent') : t('Resend invitation')}
              </Typography>
            </ListButton>
          )}
          <ListButton
            onClick={handleModalToggle(setOpenRemoveUserFromOrg)}
            sx={{
              borderRadius: '8px',
              border: '1px solid #D4D4D4',
              borderColor: '#D4D4D4',
              background: '#D0D5DD',
              padding: '8px 16px',
              height: '40px',
              width: '176px',
            }}
          >
            <ListItemIcon>
              <Icon path="products-view/menu-remove-user" />
            </ListItemIcon>
            <Typography noWrap sx={{ color: 'darksome.main' }}>
              Remove user
            </Typography>
          </ListButton>

          {isDeleteUserPermissionAble && (
            <ListButton
              onClick={handleModalToggle(setOpenDeleteUser)}
              sx={{
                borderRadius: '8px',
                border: '1px solid #D4D4D4',
                borderColor: '#D4D4D4',
                background: '#D0D5DD',
                padding: '8px 16px',
                height: '40px',
                width: '176px',
              }}
            >
              <ListItemIcon>
                <Icon path="products-view/menu-delete-user" />
              </ListItemIcon>
              <Typography noWrap sx={{ color: 'darksome.main' }}>
                Delete user
              </Typography>
            </ListButton>
          )}
        </List>
      </Popover>

      <DialogModal open={openRemoveUserFromOrg} onClose={handleModalToggle(setOpenRemoveUserFromOrg)}>
        <Typography sx={{ textAlign: 'center', fontWeight: 600, fontSize: 18 }}>
          {t('Are you sure you want to remove user from organization?')}
        </Typography>
        <Typography sx={{ textAlign: 'center', fontSize: 18 }}>{t('This action cannot be undone.')}</Typography>

        <Stack alignItems="center" sx={{ mt: '30px' }}>
          <SaveControl
            onSave={handleRemoveUserFromOrg}
            onCancel={handleModalToggle(setOpenRemoveUserFromOrg)}
            isLoading={isRemoving}
            btnTexts={[t('Remove user')]}
          />
        </Stack>
      </DialogModal>

      <DialogModal open={openDeleteUser} onClose={handleModalToggle(setOpenDeleteUser)}>
        <Typography sx={{ textAlign: 'center', fontWeight: 600, fontSize: 18 }}>
          {t('Are you sure you want to delete user completely from the platform ?')}
        </Typography>
        <Typography sx={{ textAlign: 'center', fontSize: 18 }}>{t('This action cannot be undone.')}</Typography>

        <Stack alignItems="center" sx={{ mt: '30px' }}>
          <SaveControl
            onSave={handleDeleteUser}
            onCancel={handleModalToggle(setOpenDeleteUser)}
            isLoading={isLoading}
            btnTexts={[t('Delete user')]}
          />
        </Stack>
      </DialogModal>
    </>
  );
};
