import { Button as MuiButton, ButtonProps, CircularProgress } from '@mui/material';
import React, { ReactNode } from 'react';
import { Fade } from './Fade';

interface Props extends ButtonProps {
  filled?: boolean;
  red?: boolean;
  upper?: boolean;
  loading?: boolean;
  children: ReactNode;
}

export const Button: React.FC<Props> = ({
  filled,
  upper,
  red,
  loading,
  disabled,
  children,
  onClick,
  fullWidth,
  type,
  sx,
}) => {
  return (
    <MuiButton
      type={type}
      onClick={onClick}
      fullWidth={fullWidth}
      disabled={disabled}
      sx={{
        width: fullWidth ? '100%' : 'auto',
        boxSizing: 'border-box',
        position: 'relative',
        bgcolor: red ? 'error.main' : filled ? 'primary.main' : 'secondary.dark',
        border: '1px solid white',
        borderColor: filled ? (red ? 'error.main' : 'primary.main') : 'secondary.light',
        borderRadius: '8px',
        textTransform: upper ? 'uppercase' : 'capitalize',
        height: '100%',
        color: filled ? 'info.main' : 'secondary.light',
        fontSize: '18px',
        whiteSpace: 'nowrap',
        px: '15px',
        '&:hover': {
          bgcolor: red ? 'error.main' : filled ? 'primary.main' : 'secondary.dark',
        },
        '&:disabled': {
          color: disabled ? 'info.light' : 'info.main',
        },
        opacity: disabled ? 0.8 : 1,
        ...sx,
      }}
    >
      {loading ? <CircularProgress size={20} sx={{ color: 'info.main' }} /> : children}
      <Fade isFade={!!disabled} />
    </MuiButton>
  );
};
