import { useEffect, useState } from 'react';
import { CountryData, detectCountryData } from 'shared/lib';
import { telRegexp } from 'shared/constants';

export const usePhone = (open: boolean) => {
  const [countryData, setCountryData] = useState<CountryData>();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneErrorText, setPhoneErrorText] = useState('');

  useEffect(() => {
    async function getCountryCode() {
      const countryData = await detectCountryData();

      setCountryData(countryData);
    }

    !phoneNumber && getCountryCode();
  }, []);

  useEffect(() => {
    if (!open) {
      setPhoneNumber('');
      setPhoneErrorText('');
    }
  }, [open]);

  const onPhoneChange = (phone: string) => {
    setPhoneNumber(phone);
  };

  const checkIsPhoneValid = () => {
    let isValid = true;

    if (!phoneNumber || phoneNumber.length < 5) {
      setPhoneErrorText('Please enter your phone');
      isValid = false;
    } else if (!telRegexp.test(phoneNumber.replaceAll(' ', ''))) {
      setPhoneErrorText('Invalid phone number');
      isValid = false;
    } else {
      setPhoneErrorText('');
    }

    return isValid;
  };

  return { phoneNumber, onPhoneChange, checkIsPhoneValid, countryCode: countryData?.countryCode, phoneErrorText };
};
