import { FC, RefObject, useEffect, useState } from 'react';
import { Box, CircularProgress, InputAdornment, MenuItem, Select, Stack, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ItemsBox } from 'shared/ui';
import CheckIcon from '@mui/icons-material/Check';
import { LanguageData, TableProductInfo } from 'shared/models';
import { ProductTranslations } from './ProductTranslations';
import { useCatalogsControl } from 'shared/hooks';

export const TranslationItem = styled(Box)(({ theme }) => ({
  padding: '5px !important',
  height: 32,
  borderBottom: '1px solid grey',
  borderColor: theme.palette.info.light,
  color: 'black',
  textTransform: 'capitalize',
  justifyContent: 'flex-start',
  borderRadius: 0,
  '&:hover': { bgcolor: 'primary.light' },
  outline: 'none',
  '&:focus': { outline: 'none' },
}));

const LANGUAGES_ICONS: { [key: string]: string } = {
  'en-US': 'https://flagcdn.com/w40/us.png',
  'fr-FR': 'https://flagcdn.com/w40/fr.png',
  'he-IL': 'https://flagcdn.com/w40/il.png',
  'es-ES': 'https://flagcdn.com/w40/es.png',
  'pt-BR': 'https://flagcdn.com/w40/br.png',
  'pt-PO': 'https://flagcdn.com/w40/pt.png',
  'ot-OT': 'https://flagcdn.com/w40/aq.png',
};

export const BoxHeaderExtended = styled(Box)(() => ({
  padding: '4px 8px',
  fontWeight: 600,
  textTransform: 'capitalize',
}));

interface Props {
  isActive: boolean;
  isLanguagesLoading: boolean;
  isProductsReady: boolean;
  languages: LanguageData[];
  language: string;
  onLanguageChange: (langId: string) => void;
  products: TableProductInfo[];
  selected: string;
  updateProduct: (updatedComponent: TableProductInfo) => void;
  onSelect: (id: string) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  selectedItemRef: RefObject<HTMLElement> | null;
}

export const CatalogProductTranslations: FC<Props> = ({
  isActive,
  isLanguagesLoading,
  isProductsReady,
  languages,
  language,
  onLanguageChange,
  products,
  selected,
  updateProduct,
  onSelect,
  onKeyDown,
  selectedItemRef,
}) => {
  const { t } = useTranslation();

  const { allComponents } = useCatalogsControl();
  const [currentProducts, setCurrentProducts] = useState(allComponents);

  const onUpdateProduct = (updatedComponent: TableProductInfo) => {
    setCurrentProducts((prev) =>
      prev.map((product) => (product.id === updatedComponent.id ? updatedComponent : product))
    );
    updateProduct(updatedComponent);
  };

  useEffect(() => {
    setCurrentProducts(allComponents);
  }, [allComponents]);

  // const productsMap = products.reduce(
  //   (acc, p) => {
  //     acc[p.id] = p;
  //     return acc;
  //   },
  //   {} as { [id: string]: TableProductInfo }
  // );

  // const productColumn: GridColDef[] = [
  //   {
  //     field: 'description',
  //     type: 'string',
  //     align: 'left',
  //     headerAlign: 'left',
  //     sortable: false,
  //     editable: false,
  //     disableColumnMenu: true,
  //     width: 2500,
  //     renderCell: (params: GridRenderCellParams) => {
  //       const id = params.id as string;
  //       const item = productsMap[id];
  //       return (
  //         <ProductTranslations
  //           item={item}
  //           selected={selected}
  //           updateProduct={updateProduct}
  //           language={language}
  //           onSelect={() => onSelect(id)}
  //           onKeyDown={onKeyDown}
  //         />
  //       );
  //     },
  //   },
  // ];

  return (
    <ItemsBox
      isActive={isActive}
      sx={{ minWidth: '752px' }}
      header={
        <Stack direction="row" spacing="30px">
          <BoxHeaderExtended
            sx={{
              width: '360px',
              borderRight: '1px solid lightgrey',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            {t('Description')}

            {!isProductsReady ? (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CircularProgress size={15} color="primary" />
              </Box>
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <CheckIcon fontSize={'inherit'} color="primary" />
              </Box>
            )}
          </BoxHeaderExtended>
          <BoxHeaderExtended sx={{ width: '360px', borderLeft: '1px solid lightgrey' }}>
            <Select
              fullWidth
              startAdornment={
                isLanguagesLoading ? (
                  <InputAdornment position="start">
                    <CircularProgress size={15} color="primary" />
                  </InputAdornment>
                ) : (
                  <Box component="span" sx={{ display: 'inline-flex', '& > img': { mr: '24px', flexShrink: 0 } }}>
                    <img
                      style={{ height: '15px', width: '24px' }}
                      src={LANGUAGES_ICONS[languages.find((i) => i.id === language)?.culture ?? 'ot-OT']}
                      alt="country-flag"
                    />
                  </Box>
                )
              }
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: '24px',
                boxShadow: 'none',
                p: 0,
                '.MuiOutlinedInput-notchedOutline': { border: 'none' },
                '.MuiOutlinedInput-input': { display: 'flex', alignItems: 'center', p: 0 },
                color: '#000',
                fontFamily: 'Roboto',
                fontSize: 16,
                fontWeight: 600,
                lineHeight: '16px',
                letterSpacing: '0.16px',
              }}
              value={language}
              onChange={(e) => onLanguageChange(e.target.value as string)}
              disabled={!languages?.length}
            >
              {[...languages]
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((item) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
          </BoxHeaderExtended>
        </Stack>
      }
    >
      {
        isProductsReady &&
          products.map((p) => (
            <Box ref={selected === p.id ? (selectedItemRef as RefObject<HTMLDivElement>) : null} key={p.id}>
              <ProductTranslations
                item={p}
                selected={selected}
                updateProduct={onUpdateProduct}
                language={language}
                onSelect={() => onSelect(p.id)}
                onKeyDown={onKeyDown}
                products={currentProducts}
              />
            </Box>
          ))
        // <DataGridPremium
        //   rowHeight={32}
        //   columnHeaderHeight={0}
        //   hideFooter
        //   columns={productColumn}
        //   rows={products}
        //   disableRowSelectionOnClick
        //   sx={{
        //     minHeight: 0,
        //     maxHeight: '100%',
        //     overflowX: 'hidden',
        //     p: 0,
        //     border: 'none',
        //     borderRadius: 0,
        //     width: '750px',
        //     '*': { outline: 'none !important' },

        //     '& .MuiDataGrid-cell': {
        //       padding: '0 !important',
        //       border: 'none',
        //       width: '100%',
        //     },
        //     '& .MuiDataGrid-virtualScroller': {
        //       overflowX: 'hidden',

        //       '&::-webkit-scrollbar-thumb': {
        //         backgroundColor: 'info.dark',
        //         borderRadius: '30px',
        //       },
        //       '&::-webkit-scrollbar': {
        //         width: '4px',
        //         backgroundColor: 'info.light',
        //       },
        //     },
        //     '& .MuiDataGrid-row.Mui-hovered, & .MuiDataGrid-row:hover': {
        //       backgroundColor: 'transparent !important',
        //     },
        //     '& .MuiDataGrid-row.Mui-hovered.Mui-selected, & .MuiDataGrid-row.Mui-selected:hover': {
        //       backgroundColor: 'rgba(29, 135, 66, 0.08) !important',
        //     },
        //     '& .MuiDataGrid-cell.Mui-hovered.Mui-selected, & .MuiDataGrid-cell.Mui-selected:hover': {
        //       backgroundColor: 'rgba(29, 135, 66, 0.08) !important',
        //     },
        //   }}
        // />
      }
    </ItemsBox>
  );
};
