import { FC } from 'react';
import { Box, Stack } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';

interface Props {
  onScrollToLeft?: () => void;
  onScrollToRight?: () => void;
}

export const TableArrowScrolling: FC<Props> = ({ onScrollToLeft, onScrollToRight }) => {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems={'center'}
      sx={{
        width: '34px',
        height: '34px',
        borderRadius: '50%',
        border: '1px solid black',
        m: '15px auto',
        overflow: 'hidden',
      }}
    >
      <Box onClick={onScrollToLeft} sx={{ width: '50%', overflow: 'hidden', pt: '6px' }}>
        <ArrowBackIos sx={{ width: '15px', ml: '5px', cursor: 'pointer' }} />
      </Box>
      <Box onClick={onScrollToRight} sx={{ width: '50%', overflow: 'hidden', pt: '6px' }}>
        <ArrowForwardIos sx={{ width: '15px', cursor: 'pointer' }} />
      </Box>
    </Stack>
  );
};
