import { InputBase, styled } from '@mui/material';

interface UnderlinedSelectInputProps {
  disabled?: boolean;
  onWhiteOnly?: boolean;
}

export const UnderlinedSelectInput = styled(InputBase, {
  shouldForwardProp: (prop) => prop !== 'disabled' && prop !== 'onWhiteOnly',
})<UnderlinedSelectInputProps>(({ theme, onWhiteOnly }) => ({
  color: theme.palette.secondary.light,
  fontSize: '18px',
  lineHeight: '22px',
  fontFamily: 'Myriad Pro',
  borderBottom: '1px solid white',
  borderColor: theme.palette.info.dark,
  svg: {
    display: 'none',
  },

  '& .Mui-disabled': {
    WebkitTextFillColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 1)' : 'rgba(44, 44, 44, 1)',
    color: theme.palette.secondary.light,
  },
  '&:disabled': {
    WebkitTextFillColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 1)' : 'rgba(44, 44, 44, 1)',
    color: theme.palette.secondary.light,
  },
  ...(onWhiteOnly
    ? { WebkitTextFillColor: 'rgba(44, 44, 44, 1)' }
    : { WebkitTextFillColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 1)' : 'rgba(44, 44, 44, 1)' }),
}));
