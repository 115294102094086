import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Brand, Catalog, Group, SubType } from 'shared/models';

export interface CatalogsState {
  isLoading: boolean;
  isSynchronizedCatalogData: boolean;
  catalogs: Catalog[];
  currentCatalog: Catalog;
  catalogTypes: string[];
  groups: { [id: string]: Group[] };
  shouldUpdateCatalogProducts: boolean;
}

const initialState: CatalogsState = {
  isLoading: false,
  isSynchronizedCatalogData: false,
  catalogs: [],
  currentCatalog: {} as Catalog,
  catalogTypes: [],
  groups: {},
  shouldUpdateCatalogProducts: true,
};

export const catalogsSlice = createSlice({
  name: 'catalogs',
  initialState,
  reducers: {
    addCatalog: (state, action: PayloadAction<Catalog>) => {
      state.catalogs.push(action.payload);
    },
    setCatalogsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsSynchronizedCatalogData: (state, action: PayloadAction<boolean>) => {
      state.isSynchronizedCatalogData = action.payload;
    },
    deleteCatalog: (state, action: PayloadAction<string>) => {
      state.catalogs = state.catalogs.filter((c) => c.id !== action.payload);
    },
    setCatalogs: (state, action: PayloadAction<Catalog[]>) => {
      state.catalogs = action.payload;
    },
    setCurrentCatalog: (state, action: PayloadAction<Catalog>) => {
      let catalog = action.payload;

      const isCatalogsIncludesCurrentCatalog = state.catalogs.find((cat) => cat.id === catalog.id);

      // if catalogs is not initialized then add current catalog to catalogs
      if (!isCatalogsIncludesCurrentCatalog) {
        state.catalogs.push(catalog);
      }

      // TODO: could be fixed if backend send to front empty arrays instead of null
      if (!catalog.groups) {
        catalog = { ...catalog, groups: [] };
      }
      if (!catalog.subtypes) {
        catalog = { ...catalog, subtypes: [] };
      }
      state.currentCatalog = catalog;
    },
    setCurrentCatalogById: (state, action: PayloadAction<string>) => {
      const catalog = state.catalogs.find((cat) => cat.id === action.payload);
      if (catalog) {
        state.currentCatalog = catalog;
      }
    },
    setCatalogTypes: (state, action: PayloadAction<string[]>) => {
      state.catalogTypes = action.payload;
    },
    setCurrentCatalogGroups: (state, action: PayloadAction<Group[]>) => {
      state.currentCatalog.groups = [...action.payload];
    },
    setCurrentCatalogSubtypes: (state, action: PayloadAction<SubType[]>) => {
      state.currentCatalog.subtypes = [...action.payload];
    },
    setCurrentCatalogBrands: (state, action: PayloadAction<Brand[]>) => {
      const brands = [...action.payload];
      state.currentCatalog = { ...state.currentCatalog, brands };
    },
    updateCatalog: (state, action: PayloadAction<{ catalogId: string; title: string; description: string }>) => {
      const { catalogId, title, description } = action.payload;
      state.catalogs = state.catalogs.map((c) => (c.id === catalogId ? { ...c, title, description } : c));
    },
    addToCatalogSharing: (
      state,
      action: PayloadAction<{ catalogId: string; sharingWith: Catalog['sharings'][number] }>
    ) => {
      const catalog = state.catalogs.find((cat) => cat.id === action.payload.catalogId);
      if (catalog) {
        if (!Array.isArray(catalog.sharings)) {
          catalog.sharings = [];
        }
        catalog.sharings.push(action.payload.sharingWith);
      }
    },
    setGroups: (state, action: PayloadAction<{ catalogId: string; groups: Group[] }>) => {
      const { catalogId, groups } = action.payload;
      state.groups = { ...state.groups, [catalogId]: groups };
    },
    removeCatalogGroups: (state, action: PayloadAction<string>) => {
      const catalogId = action.payload;
      delete state.groups[catalogId];
    },
    resetCatalogs: () => initialState,
    setShouldUpdateCatalogProducts: (state, action: PayloadAction<boolean>) => {
      state.shouldUpdateCatalogProducts = action.payload;
    },
  },
  selectors: {
    selectCatalogs: (store) => store.catalogs,
    selectCurrentCatalog: (store) => store.currentCatalog,
    selectCatalogTypes: (store) => store.catalogTypes,
    selectCatalogsLoading: (store) => store.isLoading,
    selectSynchronizedCatalog: (store) => store.isSynchronizedCatalogData,
    selectGroups: (store) => store.groups,
    selectShouldUpdateCatalogProducts: (store) => store.shouldUpdateCatalogProducts,
  },
});

export const {
  addCatalog,
  setCatalogs,
  setCurrentCatalogById,
  deleteCatalog,
  setCatalogsLoading,
  setCurrentCatalog,
  resetCatalogs,
  setCatalogTypes,
  setIsSynchronizedCatalogData,
  setCurrentCatalogGroups,
  setCurrentCatalogSubtypes,
  setCurrentCatalogBrands,
  updateCatalog,
  setGroups,
  addToCatalogSharing,
  removeCatalogGroups,
  setShouldUpdateCatalogProducts,
} = catalogsSlice.actions;

export const {
  selectCatalogs,
  selectSynchronizedCatalog,
  selectCatalogsLoading,
  selectCurrentCatalog,
  selectCatalogTypes,
  selectGroups,
  selectShouldUpdateCatalogProducts,
} = catalogsSlice.selectors;

export const catalogsReducer = catalogsSlice.reducer;
