import { ChangeEvent, FC, KeyboardEvent, useEffect, useRef, useState } from 'react';
import {
  Box,
  CircularProgress,
  IconButton,
  InputBase,
  MenuItem as MuiMenuItem,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DialogModal, ErrorTooltip, Icon, SaveControl, Select } from 'shared/ui';
import { Group, SubType } from 'shared/models';

const MenuItem = styled(MuiMenuItem)(({ theme }) => ({
  backgroundColor: 'white',

  '&:hover': {
    backgroundColor: 'rgba(29, 135, 66, 0.1) !important',
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.light,
  },
}));

interface Props {
  groupInfo: Group;
  onDelete: () => void;
  onChangeGroup: (id: string, name: string, legacyGroupId: string | null, subtypeId: string | null) => void;
  subTypes: SubType[];
  isSelected?: boolean;
  onSelect: (id: string) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  isLoading: boolean;
  disabled: boolean;
}

export const GroupItem: FC<Props> = ({
  groupInfo: { id, name, legacyGroupId, subtype },
  onDelete,
  onChangeGroup,
  subTypes,
  isSelected,
  onSelect,
  onKeyDown,
  isLoading,
  disabled,
}) => {
  const { t } = useTranslation();
  const nameInputRef = useRef<HTMLInputElement>(null);
  const idInputRef = useRef<HTMLInputElement>(null);

  const [isConfirm, setIsConfirm] = useState(false);
  const onToggleConfirm = () => setIsConfirm((c) => !c);

  const [nameValue, setNameValue] = useState<string>(name);
  const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => setNameValue(e.target.value);
  const [idValue, setIdValue] = useState<string>(legacyGroupId ?? '');
  const onChangeIdValue = (e: ChangeEvent<HTMLInputElement>) => setIdValue(e.target.value);

  const nameError = nameValue.length < 3 || nameValue.length > 50;
  const idError = idValue?.length > 6;

  const [isNameEditing, setIsNameEditing] = useState(false);
  const [isIdEditing, setIsIdEditing] = useState(false);
  const [savingField, setSavingField] = useState<'legacyId' | 'name' | 'subtype'>('name');

  const handleDelete = () => {
    onToggleConfirm();
    onDelete();
  };
  const onOpenNameEditing = () => {
    setIsNameEditing(true);
    onSelect(id);
    setNameValue(name);
  };
  const onOpenIdEditing = () => {
    setIsIdEditing(true);
    onSelect(id);
    setIdValue(legacyGroupId as string);
  };
  const onCancelEditing = () => {
    setIsNameEditing(false);
    setIsIdEditing(false);

    onSelect('');
    setNameValue(name);
    setIdValue(legacyGroupId as string);
  };

  const handleSelect = () => {
    if (disabled || isNameEditing || isIdEditing) return;
    onSelect(id);
  };

  const handleSaveName = () => {
    if (!nameError && nameValue !== name) {
      setSavingField('name');
      onChangeGroup(id, nameValue, legacyGroupId || null, subtype?.id ?? null);
    }

    setIsNameEditing(false);
    setNameValue(name);
  };
  const handleSaveLegacyId = () => {
    if (!idError && idValue !== legacyGroupId) {
      setSavingField('legacyId');
      onChangeGroup(id, name, idValue, subtype?.id ?? null);
    }

    setIsIdEditing(false);
    setIdValue(legacyGroupId as string);
  };

  const handleSaveSubType = (subtypeId: string | null) => {
    onSelect('');
    if (subtype?.id === subtypeId) return;
    setSavingField('subtype');

    onChangeGroup(id, name, legacyGroupId || null, subtypeId);
    setIsNameEditing(false);
  };

  useEffect(() => {
    if (isNameEditing) {
      nameInputRef.current && nameInputRef.current?.focus();
    }
  }, [isNameEditing]);
  useEffect(() => {
    if (isIdEditing) {
      idInputRef.current && idInputRef.current?.focus();
    }
  }, [isIdEditing]);

  const isShowLoading = isLoading && !isSelected;
  const isNameLoading = isShowLoading && savingField === 'name';
  const isLegacyIdLoading = isShowLoading && savingField === 'legacyId';
  const isSubtypeLoading = isShowLoading && savingField === 'subtype';

  return (
    <>
      <Stack
        direction="row"
        sx={{
          width: '100%',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          height: '32px',
          borderBottom: '1px solid lightgrey',
          bgcolor: isSelected ? 'primary.light' : '#fff',
          color: disabled ? 'info.dark' : 'black',
        }}
      >
        <Stack
          onClick={handleSelect}
          onKeyDown={onKeyDown}
          tabIndex={0}
          direction="row"
          alignItems="center"
          sx={{
            width: '33%',
            pl: '10px',
            position: 'relative',
            borderRight: '1px solid lightgrey',
            height: '100%',
            outline: 'none',
            '&:focus': { outline: 'none' },
          }}
        >
          {isIdEditing ? (
            <>
              <ErrorTooltip open={idError} title={'Max 6 characters.'} arrow>
                <InputBase
                  value={idValue}
                  onChange={onChangeIdValue}
                  inputRef={idInputRef}
                  type="number"
                  onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                    if (e.code === 'Enter') {
                      handleSaveLegacyId();
                    }
                  }}
                  sx={{
                    p: 0,
                    mt: '1px',
                    input: { p: 0, color: 'primary.main' },
                    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                      WebkitAppearance: 'none',
                      margin: 0,
                    },
                  }}
                />
              </ErrorTooltip>
              <Stack direction="row" sx={{ pl: '7px' }}>
                <IconButton onClick={onCancelEditing} sx={{ p: 0 }}>
                  <Icon path="circle-btn-close" />
                </IconButton>
                <IconButton onClick={handleSaveLegacyId} sx={{ p: 0 }}>
                  <Icon path="circle-btn-done" />
                </IconButton>
              </Stack>
            </>
          ) : (
            <>
              <Typography noWrap sx={{ width: '100%' }}>
                {legacyGroupId}
              </Typography>
              {isLegacyIdLoading && <CircularProgress size={15} color="primary" sx={{ mr: 1 }} />}

              {isSelected && (
                <Stack direction="row" sx={{ pl: '7px' }}>
                  <IconButton onClick={onOpenIdEditing} sx={{ p: '3px' }}>
                    <Icon path="group-edit" />
                  </IconButton>
                </Stack>
              )}
            </>
          )}
        </Stack>

        <Stack
          onClick={handleSelect}
          onKeyDown={onKeyDown}
          tabIndex={0}
          direction="row"
          alignItems="center"
          sx={{
            height: '100%',
            width: '33%',
            minWidth: '33%',
            borderRight: '1px solid lightgrey',
            pl: '7px',
            outline: 'none',
            '&:focus': { outline: 'none' },
          }}
        >
          {isNameEditing ? (
            <>
              <ErrorTooltip open={nameError} title={'Group name must be between 3 and 50 characters.'} arrow>
                <InputBase
                  value={nameValue}
                  onChange={onChangeValue}
                  inputRef={nameInputRef}
                  onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                    if (e.code === 'Enter') {
                      handleSaveName();
                    }
                  }}
                  sx={{ p: 0, mt: '1px', input: { p: 0, color: 'primary.main' } }}
                />
              </ErrorTooltip>
              <Stack direction="row" sx={{ pl: '7px' }}>
                <IconButton onClick={onCancelEditing} sx={{ p: 0 }}>
                  <Icon path="circle-btn-close" />
                </IconButton>
                <IconButton onClick={handleSaveName} sx={{ p: 0 }}>
                  <Icon path="circle-btn-done" />
                </IconButton>
              </Stack>
            </>
          ) : (
            <>
              <Typography noWrap sx={{ width: '100%' }}>
                {name}
              </Typography>
              {isNameLoading && <CircularProgress size={15} color="primary" sx={{ mr: 1 }} />}

              {isSelected && (
                <Stack direction="row" sx={{ mr: -0.5, pl: '7px' }}>
                  <IconButton onClick={onOpenNameEditing} sx={{ p: '3px' }}>
                    <Icon path="group-edit" />
                  </IconButton>
                  <IconButton onClick={onToggleConfirm} sx={{ p: '3px' }}>
                    <Icon path="group-delete" />
                  </IconButton>
                </Stack>
              )}
            </>
          )}
        </Stack>

        <Stack sx={{ width: '33%', pl: '10px', position: 'relative' }}>
          <Select
            value={subtype?.id ?? 'Select sub type'}
            disabled={isNameEditing || isIdEditing || disabled}
            onWhiteOnly
            isLoading={true}
            sx={{
              border: 'none',
              '*': {
                WebkitTextFillColor: subtype?.id ? '#000' : isSelected ? 'white' : 'rgba(189, 189, 189, 1)',
              },
              px: '10px',
              height: '32px',
              p: 0,
              fontSize: 16,
              width: '100%',
            }}
            IconComponent={(props) => <Icon path="select-drop-icon" {...props} sx={{ mt: '4px' }} />}
          >
            <MenuItem value={'Select sub type'} sx={{ display: 'none' }}>
              {t('Select sub type')}
            </MenuItem>
            <MenuItem value={''} onClick={() => handleSaveSubType(null)}>
              {t('None')}
            </MenuItem>
            {subTypes?.map(({ id: subtypeId, name }) => (
              <MenuItem value={subtypeId} key={subtypeId} onClick={() => handleSaveSubType(subtypeId)}>
                <Typography noWrap>{name}</Typography>
              </MenuItem>
            ))}
          </Select>

          {isSubtypeLoading && (
            <Box
              sx={{
                position: 'absolute',
                right: 0,
                height: '15px',
                width: '20px',
                bgcolor: 'white',
                mt: '5px',
              }}
            >
              <CircularProgress size={15} color="primary" sx={{ mr: 1 }} />
            </Box>
          )}
        </Stack>
      </Stack>

      <DialogModal open={isConfirm} onClose={onToggleConfirm} maxWidth="xs">
        <Typography sx={{ textAlign: 'center', fontSize: 18, fontWeight: 600 }}>
          By deleting the group all group items will be removed, this action cant be undone
        </Typography>
        <Typography sx={{ textAlign: 'center', fontSize: 18, fontWeight: 600 }}>Are you sure ?</Typography>

        <Stack alignItems="center" sx={{ mt: '30px' }}>
          <SaveControl onSave={handleDelete} onCancel={onToggleConfirm} btnTexts={[t('yes'), t('no')]} />
        </Stack>
      </DialogModal>
    </>
  );
};
