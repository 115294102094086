import { AuthLayout } from 'shared/ui';
import { ForgotPassword } from 'widgets/ForgotPassword';

export const ForgotPasswordPage = () => {
  return (
    <AuthLayout>
      <ForgotPassword />
    </AuthLayout>
  );
};
