import { AuthLayout } from 'shared/ui';
import { SignUp } from 'widgets/SignUp';

export const SingUpPage = () => {
  return (
    <AuthLayout>
      <SignUp />
    </AuthLayout>
  );
};
