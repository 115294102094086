import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import { keyframes } from '@mui/system';
import { FC } from 'react';

const spin = keyframes`
    to {
        transform: rotate(-360deg);
    }
`;

const pulse = keyframes`
    0% {
        opacity: 1;
    }
    85%, 100% {
        opacity: 0.35;
    }
`;

const fade = keyframes`
    0% {
        opacity: 1;
    }
    85%, 100% {
        opacity: 0.2;
    }
`;

const load = keyframes`
    0% {
        transform: translate(-50%, -50%) scale(0);
    }
    85%, 100% {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
    }
`;

interface Props {
  size?: number;
}

export const Loader: FC<Props> = ({ size = 40 }) => {
  const theme = useTheme();
  const bg = theme.palette.primary.main;

  return (
    <Box
      sx={{
        position: 'relative',
        width: size, // Adjust for desired size
        height: size, // Adjust for desired size
        animation: `${spin} 30s linear infinite`,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: '50%',
          width: '100%',
          height: '100%',
          background: bg,
          animation: `${pulse} 1.5s alternate-reverse infinite`,
        }}
      />
      {Array.from({ length: 8 }, (_, i) => (
        <Box
          key={i}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: `translate(-50%, -50%) rotate(${45 * i}deg) translate(0, ${size + 10}px)`,
            width: 5,
            height: 5,
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%) scale(0)',
              borderRadius: '50%',
              width: size,
              height: size,
              border: `5px solid ${bg}`,
              animation: `${load} 1.5s ease-in-out infinite`,
              animationDelay: `${(7 - i) * 0.2}s`,
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%) scale(1)',
              borderRadius: '50%',
              width: '100%',
              height: '100%',
              background: bg,
              animation: `${fade} 1.5s ease-in-out infinite`,
              animationDelay: `${(7 - i) * 0.2}s`,
            }}
          />
        </Box>
      ))}
    </Box>
  );
};
