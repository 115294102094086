import { FC } from 'react';
import { Box, Dialog, DialogProps, IconButton, Stack } from '@mui/material';
import { CloseIcon } from '../icons/CloseIcon';

type Props = DialogProps & {
  withCloseButton?: boolean;
  padding?: string;
};

export const DialogModal: FC<Props> = ({
  withCloseButton = true,
  padding,
  open,
  onClose,
  children,
  sx,
  ...props
}) => {
  const handleClose = (e: React.MouseEvent<HTMLElement>) => onClose?.(e, 'backdropClick');

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '24px',
        },
        '& .MuiPaper-root': {
          borderRadius: '24px',
          width: '70vw',
          maxWidth: '655px',
        },
        ...sx,
      }}
      {...props}
    >
      <Stack
        sx={{
          p: padding ?? '24px 54px',
          borderRadius: '24px',
          position: 'relative',
        }}
      >
        {withCloseButton && (
          <Box sx={{ bgcolor: 'info.main', position: 'absolute', top: '15px', right: '15px', zIndex: 10000 }}>
            <IconButton onClick={handleClose}>
              <CloseIcon width="32" height="32" viewBox="0 0 32 32" />
            </IconButton>
          </Box>
        )}
        {children}
      </Stack>
    </Dialog>
  );
};
