import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const CatalogUserIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M12 2C9.34874 2.00296 6.80693 3.05748 4.93221 4.93221C3.05748 6.80693 2.00296 9.34874 2 12C2.00444 14.6508 3.05944 17.1918 4.93384 19.0662C6.80824 20.9406 9.3492 21.9956 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM18.0824 17.7704C17.5208 16.6376 16.6538 15.6843 15.5792 15.018C14.5046 14.3518 13.2652 13.9991 12.0008 13.9998C10.7364 14.0004 9.49741 14.3544 8.42353 15.0218C7.34964 15.6892 6.48363 16.6434 5.9232 17.7768C4.42793 16.226 3.59475 14.1542 3.6 12C3.6 9.77218 4.485 7.63561 6.0603 6.0603C7.63561 4.485 9.77218 3.6 12 3.6C14.2278 3.6 16.3644 4.485 17.9397 6.0603C19.515 7.63561 20.4 9.77218 20.4 12C20.405 14.1512 19.574 16.2203 18.0824 17.7704ZM12 6C11.288 6 10.592 6.21114 9.99995 6.60671C9.40793 7.00228 8.94651 7.56453 8.67403 8.22234C8.40156 8.88015 8.33027 9.60399 8.46917 10.3023C8.60808 11.0007 8.95095 11.6421 9.45442 12.1456C9.95789 12.6491 10.5993 12.9919 11.2977 13.1308C11.996 13.2697 12.7198 13.1984 13.3777 12.926C14.0355 12.6535 14.5977 12.1921 14.9933 11.6001C15.3889 11.008 15.6 10.312 15.6 9.6C15.6 9.12724 15.5069 8.65911 15.326 8.22234C15.145 7.78557 14.8799 7.38871 14.5456 7.05442C14.2113 6.72013 13.8144 6.45495 13.3777 6.27403C12.9409 6.09312 12.4728 6 12 6Z"
      fill="black"
      fillOpacity="0.5"
    />
  </SvgIcon>
);
