import { Check } from '@mui/icons-material';
import { Box, Checkbox, CheckboxProps, FormControlLabel, Radio, styled } from '@mui/material';
import React, { FC, ReactNode } from 'react';

interface IconBoxProps {
  small?: boolean;
}

const IconBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'small',
})<IconBoxProps>(({ theme, small }) => ({
  width: small ? '15px' : '18px',
  height: small ? '15px' : '18px',
  border: `2px solid green`,
  borderColor: theme.palette.secondary.light,
  borderRadius: '4px',
  margin: ' 0 5px',
}));

interface Props extends CheckboxProps {
  label?: ReactNode;
  baseColor?: string;
  small?: boolean;
  left?: boolean;
  radio?: boolean;
}

export const BaseCheck: FC<Props> = ({
  label,
  baseColor,
  small,
  left,
  disabled,
  name,
  value,
  radio,
  checked,
  onChange,
  sx,
}) => {
  return (
    <FormControlLabel
      label={label && label}
      labelPlacement={left ? 'start' : 'end'}
      disabled={disabled}
      value={value}
      sx={{
        display: 'flex',
        alignItems: 'center',
        ...(!label && { justifyContent: 'center' }),
        '& .MuiTypography-root': {
          color: baseColor ? baseColor : 'secondary.light',
          userSelect: 'none',
          p: 0,
        },
        ...sx,
      }}
      control={
        radio ? (
          <Radio
            checked={checked}
            onChange={onChange}
            icon={<IconBox sx={{ borderColor: baseColor }} small={small} />}
            name={name}
            checkedIcon={
              <IconBox sx={{ borderColor: baseColor }} small={small}>
                <Check
                  sx={{
                    color: baseColor ? baseColor : 'secondary.light',
                    width: small ? '11px' : '14px',
                    height: small ? '11px' : '14px',
                    mb: small ? '10px' : '4px',
                  }}
                />
              </IconBox>
            }
            sx={{ px: '3px' }}
          />
        ) : (
          <Checkbox
            checked={checked}
            onChange={onChange}
            name={name}
            icon={<IconBox sx={{ borderColor: baseColor }} small={small} />}
            checkedIcon={
              <IconBox sx={{ borderColor: baseColor }} small={small}>
                <Check
                  sx={{
                    color: baseColor ? baseColor : 'secondary.light',
                    width: small ? '11px' : '14px',
                    height: small ? '11px' : '14px',
                    mb: small ? '10px' : '4px',
                  }}
                />
              </IconBox>
            }
            sx={{ px: '3px' }}
          />
        )
      }
    />
  );
};
