import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const PrivateIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M17.56 10.2799H16.72V7.0799C16.68 4.5199 14.6 2.3999 12 2.3999C9.40004 2.3999 7.32004 4.5199 7.32004 7.0799V10.2799H6.48004C5.68004 10.2799 5.04004 10.9199 5.04004 11.7199V20.1999C5.04004 20.9999 5.68004 21.6399 6.48004 21.6399H17.6C18.4 21.6399 19.04 20.9999 19.04 20.1999V11.7199C19 10.9199 18.32 10.2799 17.56 10.2799ZM8.12004 7.0799C8.12004 4.9199 9.88004 3.1999 12 3.1999C14.12 3.1999 15.88 4.9599 15.88 7.0799V10.2799H8.12004V7.0799ZM12.4 16.8399V17.8799C12.4 18.1199 12.24 18.2799 12 18.2799C11.76 18.2799 11.6 18.1199 11.6 17.8799V16.8399C10.88 16.6399 10.36 15.9999 10.36 15.2399C10.36 14.3199 11.12 13.5999 12 13.5999C12.92 13.5999 13.64 14.3599 13.64 15.2399C13.64 16.0399 13.12 16.6799 12.4 16.8399Z"
      fill="#8C8D92"
    />
  </SvgIcon>
);
