import axios from 'axios';

export type CountryData = {
  country: string;
  countryCode: string;
};
interface Continent {
  code: string;
  name: string;
  name_translated: string;
}

interface Currency {
  symbol: string;
  name: string;
  symbol_native: string;
  decimal_digits: number;
  rounding: number;
  code: string;
  name_plural: string;
}

interface Language {
  name: string;
  name_native: string;
}

interface Country {
  alpha2: string;
  alpha3: string;
  calling_codes: string[];
  currencies: Currency[];
  emoji: string;
  ioc: string;
  languages: Language[];
  name: string;
  name_translated: string;
  timezones: string[];
  is_in_european_union: boolean;
}

interface City {
  name: string;
  name_translated: string;
}

interface Region {
  fips?: any;
  alpha2?: any;
  name: string;
  name_translated: string;
}

interface Location {
  geonames_id?: any;
  latitude: number;
  longitude: number;
  zip: string;
  continent: Continent;
  country: Country;
  city: City;
  region: Region;
}

interface Data {
  location: Location;
}

interface CountryResponse {
  data: Data;
}

export async function detectCountryData() {
  try {
    const {
      data: { data },
    } = await axios.get<CountryResponse>(
      'https://api.ipbase.com/v2/info?apikey=yAr4AnTaEWXfsnsarU53W1VI4Me38UeZ2kNwk7bg'
    );
    const {
      location: {
        country: { name: country, alpha2: countryCode },
      },
    } = data;
    return { countryCode: countryCode.toLowerCase(), country };
  } catch (e) {
    console.log(e);
    return { countryCode: '', country: '' };
  }
}
