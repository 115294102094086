import React, { FC, PropsWithChildren } from 'react';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';

const cacheRtlParams = {
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
};

const cacheRtl = createCache(cacheRtlParams);

interface Props extends PropsWithChildren {
  enable: boolean;
}

export const RtlProvider: FC<Props> = ({ children, enable }) => {
  return enable ? (
    <CacheProvider value={cacheRtl}>
      <div dir="rtl" style={{ width: '100%' }}>
        {children}
      </div>
    </CacheProvider>
  ) : (
    <>{children}</>
  );
};
