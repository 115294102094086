import { FC } from 'react';
import { MenuItem, Select } from '@mui/material';
import { useAppSelector, useCatalogSearchParams } from 'shared/hooks';
import { separateByUpperCase } from 'shared/lib';
import { selectCatalogTypes } from 'shared/slices';

interface Props {
  disabled?: boolean;
  isEditing: boolean;
  isSaveLoading: boolean;
}

export const CatalogTypeSelect: FC<Props> = ({ disabled, isEditing, isSaveLoading }) => {
  const { type, onChangeType } = useCatalogSearchParams();

  const catalogTypes: string[] = useAppSelector(selectCatalogTypes);

  const isNoTypes = !catalogTypes.length;
  const isDisabled = disabled || isEditing || isSaveLoading || isNoTypes;

  return (
    <Select
      value={isNoTypes ? '' : type}
      disabled={isDisabled}
      sx={{
        minWidth: '130px',
        borderRadius: '12px ',
        border: '2px solid green',
        borderColor: 'primary.main',
        fontWeight: 600,
        fieldSet: { display: 'none' },
        '& .MuiSelect-select': {
          padding: '8px 22px',
          bgcolor: 'info.light',
          borderRadius: '12px !important',
        },
        '& .MuiInputBase-input': {
          borderRadius: '12px',
        },
        '& .MuiInputBase-root': {
          borderRadius: '12px',
        },
        fieldset: {
          display: 'none',
        },
      }}
    >
      {catalogTypes.map((type) => (
        <MenuItem key={type} value={type} onClick={() => onChangeType(type)}>
          {separateByUpperCase(type)}
        </MenuItem>
      ))}

      <MenuItem value={''} sx={{ display: 'none' }}></MenuItem>
    </Select>
  );
};
