import { FC } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BigTitle, Button } from 'shared/ui';
import { useAppNavigator, useCatalogSearchParams, useCatalogUserPermissions } from 'shared/hooks';

export const NoGroupsModal: FC = () => {
  const { t } = useTranslation();

  const { isCanEditCatalog } = useCatalogUserPermissions();
  const { navigateToCatalogManagement } = useCatalogSearchParams();
  const { navigateToCatalogs } = useAppNavigator();

  return (
    <Stack
      justifyContent="center"
      alignItems={'center'}
      sx={{
        position: 'absolute',
        inset: 0,
        zIndex: 1000,
        background: 'rgba(0, 0, 0, 0.50)',
      }}
    >
      <Stack
        justifyContent="center"
        alignItems={'center'}
        sx={{ position: 'absolute', inset: 0, bgcolor: 'rgba(0, 0, 0, 0.20)', zIndex: 3 }}
      >
        <Stack alignItems={'center'} sx={{ borderRadius: '24px', py: 8, px: 15, bgcolor: 'info.main' }}>
          {isCanEditCatalog ? (
            <>
              <BigTitle sx={{ fontSize: 18, mb: 2, color: 'darksome.main' }}>
                {t('To add new items, first create groups')}
              </BigTitle>

              <Button onClick={navigateToCatalogManagement} filled>
                {t('Create Groups')}
              </Button>
            </>
          ) : (
            <>
              <BigTitle sx={{ fontSize: 18, color: 'darksome.main' }}>{t('No groups in the Catalog')}</BigTitle>
              <BigTitle sx={{ fontSize: 18, mb: 2, color: 'darksome.main' }}>
                {t('You cant add groups to this catalog')}
              </BigTitle>

              <Button onClick={navigateToCatalogs} filled>
                {t('Back to catalogs')}
              </Button>
            </>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
