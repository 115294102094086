import { Box, Collapse, MenuItem, Stack, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { useAppDispatch, useAppNavigator } from 'shared/hooks';
import { DialogModal, Input, SaveControl, Select, Switcher } from 'shared/ui';
import { CatalogDTO, TabItem } from 'shared/models';
import { useCatalogAllowedOrgs, useNewCatalogValues } from './hooks';
import { useTranslation } from 'react-i18next';
import { addTab, openCatalog } from 'shared/slices';
import { CATALOG_OWNER_TYPE, CATALOG_TYPE } from 'shared/constants';
import { CatalogService, localStorageService } from 'shared/services';
import { NewCatalogIcon } from '../../shared/ui/icons/NewCatalogIcon';
import { ROUTE } from 'shared/enum';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const NewCatalogModal: FC<Props> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { navigateToCurrentCatalog } = useAppNavigator();

  const [isForOrg, setIsForOrg] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const { newCatalogData, onNewCatalogDataChange, checkIsValid, nameHelpText, clearNewCatalogData } =
    useNewCatalogValues();

  const { allowedOrgs, noOrgsMessage, catalogOrgId, onChangeCatalogOrgId } = useCatalogAllowedOrgs(open, isForOrg);

  const ownerName =
    (isForOrg
      ? allowedOrgs.find((o) => o.organizationId === catalogOrgId)?.organizationName
      : localStorageService.userName) ?? 'Unknown';

  const ownerType = isForOrg ? CATALOG_OWNER_TYPE.ORG : CATALOG_OWNER_TYPE.PRIVATE;

  const handleForOrgChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsForOrg(e.target.checked);
  };

  const onSubmit = async () => {
    if (isForOrg && !catalogOrgId) return;
    if (!checkIsValid()) return;

    const dto: CatalogDTO = {
      title: newCatalogData.name,
      description: newCatalogData.description,
      ownerType,
      ...(isForOrg && { orgId: catalogOrgId }),
    };

    setIsLoading(true);

    const id = await CatalogService.create(dto);

    if (id) {
      const tabItem: TabItem = {
        id,
        title: newCatalogData.name,
        kind: 'catalog',
        catalogInfo: { owner: ownerName, type: ownerType },
        route: `${ROUTE.CATALOGS}/${id}?type=${CATALOG_TYPE.PIPE}`,
      };
      dispatch(addTab(tabItem));
      dispatch(openCatalog(id));

      const tabs: TabItem[] = localStorageService.tabs;
      const isPushed = tabs.find((t) => t.id === tabItem.id);
      if (!isPushed) {
        tabs.push(tabItem);
        localStorageService.tabs = tabs;
      }

      navigateToCurrentCatalog(id);

      onClose();
    }
    setIsLoading(false);
  };

  const onCancelClick = () => {
    clearNewCatalogData();
    onClose();
  };

  return (
    <DialogModal
      open={open}
      withCloseButton={false}
      onClose={onClose}
      padding={'48px'}
      sx={{
        '& .MuiBackdrop-root': {
          top: '44px',
        },
        '& .MuiPaper-root': {
          width: '613px',
        },
      }}
    >
      <Stack sx={{ overflow: 'hidden' }}>
        <Stack mb={'24px'} direction={'row'} justifyContent={'space-between'}>
          <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
            <NewCatalogIcon width="20" height="19" viewBox="0 0 20 19" />
            <Typography
              sx={{
                color: '#4D4E4C',
                fontFamily: 'Roboto',
                fontSize: '18px',
                fontWeight: 400,
                lineHeight: '130%',
                letterSpacing: '0.18px',
              }}
            >
              {t('Create Catalog')}
            </Typography>
          </Stack>
          <Switcher isOn={isForOrg} onChange={handleForOrgChange} />
        </Stack>

        <Box mb={'24px'}>
          <Input
            value={newCatalogData.name}
            onChange={onNewCatalogDataChange}
            label={t('catalog_name')}
            name="name"
            placeholder="Catalog name"
            error={!!nameHelpText}
            helperText={nameHelpText}
          />
        </Box>

        <Collapse in={isForOrg}>
          <div>
            <Box mb={'24px'}>
              <Select
                label={t('organization_name')}
                value={catalogOrgId}
                disabled={!allowedOrgs.length}
                variation="outlined"
                helperText={noOrgsMessage}
              >
                {allowedOrgs.length > 0 &&
                  allowedOrgs.map((org, idx) => (
                    <MenuItem
                      value={org.organizationId}
                      key={idx}
                      onClick={() => onChangeCatalogOrgId(org.organizationId)}
                    >
                      {org.organizationName}
                    </MenuItem>
                  ))}
              </Select>
            </Box>
          </div>
        </Collapse>

        <Box mb={'48px'}>
          <Input
            value={newCatalogData.description}
            onChange={onNewCatalogDataChange}
            label={t('catalog_description')}
            name="description"
            placeholder="Catalog description"
          />
        </Box>

        <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
          <SaveControl onSave={onSubmit} onCancel={onCancelClick} isLoading={isLoading} />
        </Stack>
      </Stack>

      {/*<Box component="form" ref={formRef}>*/}
      {/*  <BigTitle>{t('new_catalog')}</BigTitle>*/}
      {/*  <ContentBox sx={{ height: '50vh' }}>*/}
      {/*    <Stack*/}
      {/*      direction="row"*/}
      {/*      justifyContent="space-between"*/}
      {/*      spacing={{ xs: '15px', lg: '55px' }}*/}
      {/*      sx={{ width: '100%', my: '20px' }}*/}
      {/*    >*/}
      {/*      <Input*/}
      {/*        value={newCatalogData.name}*/}
      {/*        onChange={onNewCatalogDataChange}*/}
      {/*        label={t('catalog_name')}*/}
      {/*        name="name"*/}
      {/*        placeholder="Text"*/}
      {/*        error={!!nameHelpText}*/}
      {/*        helperText={nameHelpText}*/}
      {/*      />*/}
      {/*    </Stack>*/}
      {/*    {isForOrg && (*/}
      {/*      <Select*/}
      {/*        label={t('organization_name')}*/}
      {/*        value={catalogOrgId}*/}
      {/*        disabled={isOrgsLoading || !allowedOrgs.length}*/}
      {/*        variation="outlined"*/}
      {/*        isLoading={isOrgsLoading}*/}
      {/*        helperText={noOrgsMessage}*/}
      {/*      >*/}
      {/*        {allowedOrgs.length > 0 &&*/}
      {/*          allowedOrgs.map((org, idx) => (*/}
      {/*            <MenuItem*/}
      {/*              value={org.organizationId}*/}
      {/*              key={idx}*/}
      {/*              onClick={() => onChangeCatalogOrgId(org.organizationId)}*/}
      {/*            >*/}
      {/*              {org.organizationName}*/}
      {/*            </MenuItem>*/}
      {/*          ))}*/}
      {/*      </Select>*/}
      {/*    )}*/}
      {/*    <Stack*/}
      {/*      direction="row"*/}
      {/*      justifyContent="space-between"*/}
      {/*      spacing={{ xs: '15px', lg: '55px' }}*/}
      {/*      sx={{ width: '100%', my: '10px' }}*/}
      {/*    >*/}
      {/*      /!* <Input label={t('org_name')} name="Name" placeholder="Text" />*/}
      {/*      <Input label={t('manager')} name="manager" placeholder="Text" /> *!/*/}
      {/*    </Stack>*/}
      {/*    <Stack*/}
      {/*      direction="row"*/}
      {/*      justifyContent="space-between"*/}
      {/*      spacing={{ xs: '15px', lg: '55px' }}*/}
      {/*      sx={{ width: '100%' }}*/}
      {/*    >*/}
      {/*      <Input*/}
      {/*        value={newCatalogData.description}*/}
      {/*        onChange={onNewCatalogDataChange}*/}
      {/*        big*/}
      {/*        label={t('catalog_description')}*/}
      {/*        name="description"*/}
      {/*        placeholder="Text"*/}
      {/*        // error={descriptionError}*/}
      {/*        // helperText={'Please enter catalog description'}*/}
      {/*      />*/}
      {/*      /!* <Input big label={t('sharing')} name="manager" placeholder="Text" /> *!/*/}
      {/*    </Stack>*/}
      {/*  </ContentBox>*/}
      {/*  <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%', mt: '30px' }}>*/}
      {/*    <SaveControl onSave={onSubmit} onCancel={onClose} isLoading={isLoading} />*/}
      {/*  </Stack>*/}
      {/*</Box>*/}
    </DialogModal>
  );
};
