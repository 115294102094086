import React, { useRef } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { AuthInput, Button } from 'shared/ui';
import { EmailDTO } from 'shared/models';
import { useForgotPassValue } from './hooks';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { AppType } from 'shared/enum';
import { useAppNavigator } from 'shared/hooks';
import { UserService } from 'shared/services';

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.darksome.main,
  fontSize: '12px',
  fontWeight: 400,
}));

const APP_TYPES: { [key: string]: AppType } = {
  HydraulicTool: AppType.HydraulicTool,
  Field: AppType.Rapid,
};

export const ForgotPassword = () => {
  const { t } = useTranslation();
  const formRef = useRef();
  const { navigateToLogin, navigateToHTApp } = useAppNavigator();

  const [searchParams] = useSearchParams();
  const { appType } = Object.fromEntries([...searchParams]);

  const [loading, setLoading] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);

  const { emailData, onEmailDataChange, checkIsValid, emailError } = useForgotPassValue();

  const onSubmit = async () => {
    if (!checkIsValid()) return;

    setLoading(true);
    setIsSuccess(false);

    const emailDto: EmailDTO = {
      email: emailData,
      appType: appType ? APP_TYPES[appType] : AppType.Platform,
    };

    const isReset = await UserService.resetPassword(emailDto);
    if (isReset) setIsSuccess(true);
    setLoading(false);
  };

  const handleBackToLogin = () => {
    if (APP_TYPES[appType] === AppType.HydraulicTool) {
      navigateToHTApp();
      return;
    }
    navigateToLogin();
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      {isSuccess ? (
        <Typography
          sx={{
            color: 'primary.main',
            fontSize: '20px',
            fontWeight: 600,
            mt: '155px',
            textAlign: 'center',
          }}
        >
          {t('reset_letter_has_been_sent')}
        </Typography>
      ) : (
        <>
          <Box mb={'24px'}>
            <Text onClick={handleBackToLogin} sx={{ color: '#007AFF', cursor: 'pointer' }}>
              {t('back_to_login')}
            </Text>
          </Box>
          <Typography
            sx={{
              color: 'primary.main',
              fontSize: '20px',
              fontWeight: 600,
            }}
          >
            {t('reset_your_password')}
          </Typography>
          <Text sx={{ color: 'grey' }}>{t('send_a_link_to_your_email')}</Text>

          <Text sx={{ mx: 0, mt: 2, margin: '30px 0 2px 13px' }}> {t('Email')}</Text>
          <Box component="form" ref={formRef}>
            <AuthInput
              value={emailData}
              onChange={onEmailDataChange}
              error={emailError}
              helperText="Input your email"
              placeholder={t('email_or_phone_number')}
            />

            <Box sx={{ height: '40px', mt: '100px' }}>
              <Button onClick={onSubmit} filled fullWidth loading={loading}>
                <Typography sx={{ fontWeight: 700 }}>{t('reset_password')}</Typography>
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
