import { Box, Stack, Typography } from '@mui/material';
import { FC, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { setOrganizations, setPermissions, toggleOrgCreating } from 'shared/slices';
import { CountrySelect, DialogModal, HoverToolTip, Icon, Input, SaveControl } from 'shared/ui';
import { UploadPhoto } from 'features';
import { NewOrgDTO } from 'shared/models';
import { useNewOrgValues } from './hooks';
import { useTranslation } from 'react-i18next';
import { mapPermissions } from 'shared/lib/permissions/mapPermissions';
import { OrgService } from 'shared/services';

export const NewOrganizationModal: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { openOrgCreating } = useAppSelector((st) => st.modals);
  const onClose = () => dispatch(toggleOrgCreating());

  const { selectedOrg } = useAppSelector((st) => st.orgs);
  const { newOrgName, onOrgNameChange, checkIsValid, nameHelpText, country, logo, onChangeCountry, onChangeLogo } =
    useNewOrgValues(openOrgCreating);

  const [isLoading, setIsLoading] = useState(false);

  const orgFormRef = useRef<HTMLFormElement>(null);

  const handleSave = async () => {
    if (!orgFormRef.current) {
      console.error('orgFormRef is not defined.');
      return;
    }
    if (!checkIsValid()) return;

    if (selectedOrg) {
      setIsLoading(true);
      const inputData = new FormData(orgFormRef.current);

      const organizationDescription = inputData.get('org_description');
      const salesRep = inputData.get('sales_rep');
      const erpCustomerNumber = inputData.get('erp_customer_no');
      const roles = JSON.parse(localStorage.getItem('roles') ?? '[]');
      const organizationCreator = localStorage.getItem('name') ?? '';

      const orgDto: NewOrgDTO = {
        organizationName: newOrgName,
        organizationCreator: organizationCreator,
        fatherOrganizationId: +selectedOrg.id,
        country,
        organizationDescription: organizationDescription ? organizationDescription.toString() : '',
        salesRep: salesRep ? salesRep.toString() : '',
        erpCustomerNumber: erpCustomerNumber ? erpCustomerNumber.toString() : '',
        logo,
        files: 'files',
        isActive: true,
        roles,
      };

      const isCreated = await OrgService.createOrg(orgDto);
      if (isCreated) {
        const organizations = await OrgService.getOrgs();
        if (organizations) {
          const permissions = mapPermissions(organizations);

          dispatch(setOrganizations(organizations));
          dispatch(setPermissions(permissions));
        }
        onClose();
      }
      setIsLoading(false);
    }
  };

  return (
    <DialogModal
      padding={'96px'}
      open={openOrgCreating}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: '706px',
        },
      }}
    >
      <Box component="form" ref={orgFormRef} sx={{ width: '100%' }}>
        <Box sx={{ mb: '48px' }}>
          <Stack direction="row" justifyContent="space-between" alignItems={'flex-start'} mb={'36px'}>
            <Stack>
              <Typography
                sx={{
                  color: '#242731',
                  fontFamily: 'Roboto',
                  fontSize: '24px',
                  fontWeight: 600,
                  lineHeight: '130%',
                  letterSpacing: '0.24px',
                  marginBottom: '12px',
                }}
              >
                {t('new_organization')}
              </Typography>
              <Typography
                sx={{
                  color: '#242731',
                  fontFamily: 'Roboto',
                  fontSize: '18px',
                  lineHeight: '130%',
                  letterSpacing: '0.18px',
                  marginBottom: '8px',
                }}
              >
                {t('Parent Organization')}
              </Typography>
              <Stack direction="row">
                <Icon
                  isLogo={!!selectedOrg?.logo}
                  path={selectedOrg?.logo ?? 'org-placeholder'}
                  alt=""
                  sx={{ width: 24, height: 24, borderRadius: '6px', mr: '8px' }}
                />
                <HoverToolTip title={selectedOrg?.organizationName ?? ''}>
                  <Typography
                    noWrap
                    sx={{
                      fontFamily: 'Roboto',
                      fontSize: '18px',
                      lineHeight: '130%',
                      letterSpacing: '0.18px',
                      color: ' #667085',
                      width: '370px',
                      textWrap: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    {selectedOrg?.organizationName}
                  </Typography>
                </HoverToolTip>
              </Stack>
            </Stack>

            <UploadPhoto setLogo={onChangeLogo} />
          </Stack>

          <Stack direction="row" justifyContent="space-between" spacing="25px" sx={{ width: '514px', mb: '24px' }}>
            <Box width="47.66%">
              <Input
                value={newOrgName}
                onChange={onOrgNameChange}
                label={t('organization_name')}
                name="organizationName"
                placeholder="Enter Text"
                error={!!nameHelpText}
                helperText={nameHelpText}
              />
            </Box>

            <Box width="47.66%">
              <CountrySelect
                country={country}
                setCountry={onChangeCountry}
                label={`${t('country')}`}
                variant="outlined"
              />
            </Box>
          </Stack>

          <Input label={t('description')} name="org_description" />
        </Box>

        <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ width: '100%' }}>
          <SaveControl
            onSave={handleSave}
            onCancel={onClose}
            isLoading={isLoading}
            isSaveDisabled={!selectedOrg}
          />
        </Stack>
      </Box>
    </DialogModal>
  );
};
