import { CircularProgress, Stack } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-premium';
import { Icon } from 'shared/ui';

export const StatusCell = (params: GridRenderCellParams) => {
  const isLoading = !params.value;
  const isSucceeded = params.value === 'Succeeded';

  return (
    <Stack>
      {isLoading ? (
        <CircularProgress size={18} />
      ) : isSucceeded ? (
        <Icon path={`circle-btn-done`} />
      ) : (
        <Icon path={`circle-btn-close`} />
      )}
    </Stack>
  );
};
