import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { flatOrgs } from 'shared/lib';
import { Organization, User } from '../../models';

interface OrganizationsDataState {
  isLoading: boolean;
  isSynchronizedOrgData: boolean;
  selectedOrg: Organization | null;
  selectedUser: User | null;
  flattedOrgs: Organization[];
  organizations: Organization[];
}

const initialState: OrganizationsDataState = {
  isLoading: false,
  isSynchronizedOrgData: false,
  selectedOrg: null,
  selectedUser: null,
  flattedOrgs: [],
  organizations: [],
};

export const orgDataSlice = createSlice({
  name: 'orgs',
  initialState,
  reducers: {
    resetOrganizations: () => initialState,
    setOrganizationsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSynchronizedOrgData: (state, action: PayloadAction<boolean>) => {
      state.isSynchronizedOrgData = action.payload;
    },
    setOrganizations: (state, action: PayloadAction<Organization[]>) => {
      state.organizations = action.payload;
      state.flattedOrgs = flatOrgs(action.payload);
    },
    setSelectedOrg: (state, action: PayloadAction<Organization | null>) => {
      state.selectedOrg = action.payload;
    },
    setSelectedUser: (state, action: PayloadAction<User | null>) => {
      state.selectedUser = action.payload;
    },
  },
  selectors: {
    selectSelectedOrg: (state) => state.selectedOrg,
    selectSelectedUser: (state) => state.selectedUser,
    selectIsSyncedOrg: (state) => state.isSynchronizedOrgData,
    selectOrgLoading: (state) => state.isLoading,
    selectFlattedOrg: (state) => state.flattedOrgs,
    selectOrganizations: (state) => state.organizations,
  },
});

export const {
  setSelectedOrg,
  setOrganizationsLoading,
  setSelectedUser,
  setOrganizations,
  setSynchronizedOrgData,
  resetOrganizations,
} = orgDataSlice.actions;

export const {
  selectIsSyncedOrg,
  selectOrgLoading,
  selectFlattedOrg,
  selectSelectedUser,
  selectSelectedOrg,
  selectOrganizations,
} = orgDataSlice.selectors;

export const orgDataReducer = orgDataSlice.reducer;
