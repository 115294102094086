import React from 'react';
import {
  TextField,
  TextFieldProps,
  useTheme,
  Typography,
  Box,
  InputAdornment,
  CircularProgress,
} from '@mui/material';

type Props = TextFieldProps & {
  label: string;
  big?: boolean;
  helperText?: string;
  isLoading?: boolean;
};

export const Input: React.FC<Props> = ({
  label,
  big,
  disabled,
  placeholder,
  error,
  helperText,
  isLoading,
  ...props
}) => {
  const { palette } = useTheme();

  const autoFillColor = {
    '&:-webkit-autofill': {
      WebkitBoxShadow: `0 0 0 30px ${palette.secondary.dark} inset !important`,
      WebkitTextFillColor: `${palette.secondary.light} !important`,
    },
    '&:-webkit-autofill:hover': {
      WebkitBoxShadow: `0 0 0 30px ${palette.secondary.dark} inset !important`,
      WebkitTextFillColor: `${palette.secondary.light} !important`,
    },
    '&:-webkit-autofill:focus': {
      WebkitBoxShadow: `0 0 0 30px ${palette.secondary.dark} inset !important`,
      WebkitTextFillColor: `${palette.secondary.light} !important`,
    },
    '&:-webkit-autofill:active': {
      WebkitBoxShadow: `0 0 0 30px ${palette.secondary.dark} inset !important`,
      WebkitTextFillColor: `${palette.secondary.light} !important`,
    },
  };

  return (
    <Box sx={{ width: '100%', ...props.sx }}>
      <Typography
        noWrap
        sx={{
          color: '#242731',
          fontFamily: 'Roboto',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '130%',
          letterSpacing: '0.18px',
          marginBottom: '8px',
        }}
      >
        {label}
      </Typography>
      <TextField
        {...props}
        fullWidth
        disabled={disabled}
        color={'info'}
        placeholder={placeholder ? `${placeholder} ` : ''}
        multiline={!!big}
        minRows={6}
        helperText={error ? helperText : ''}
        sx={{
          fontFamily: 'Myriad Pro',
          '& .MuiInputBase-root': {
            ...(!big && { height: '46px' }),
            borderRadius: '8px',
            border: `1px solid green`,
            borderColor: error ? palette.error.main : palette.info.dark,
            pl: '20px',
            pr: isLoading ? '10px' : '20px',
          },
          '& .MuiFormHelperText-root': {
            lineHeight: 1,
            position: 'absolute',
            bottom: '-16px',
            color: palette.error.main,
            width: '200%',
          },
          '& .Mui-focused': {
            borderWidth: '1px !important',
            borderColor: error ? palette.error.main : palette.primary.main,
          },
          '& .Mui-disabled': {
            color: 'info.main !important',
            WebkitTextFillColor: `${palette.secondary.light}`,
          },
          'input, textarea': {
            color: 'secondary.light',
            fontFamily: 'Myriad Pro',
            fontSize: '18px',
            lineHeight: '16px',
            py: 0,
            px: 0,
            width: '100%',
            height: '41px',
            borderRadius: 'none',
            '&::placeholder': {
              opacity: 1,
              color: 'info.dark',
              lineHeight: '16px',
              fontFamily: 'Myriad Pro',
            },
            ...autoFillColor,
          },
          fieldset: {
            border: 'none !important',
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{isLoading && <CircularProgress size={27} />}</InputAdornment>
          ),
        }}
      />
    </Box>
  );
};
