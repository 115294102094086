import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ROUTE } from 'shared/enum';
import { HomeTabState, TabItem } from 'shared/models';

interface TabState {
  tabs: TabItem[];
  homeRoute: string;
  homeTabState: HomeTabState;
}

const initialState: TabState = {
  homeRoute: ROUTE.HOME,
  tabs: [],
  homeTabState: {
    orgSearch: '',
    orgUsersSearch: '',
    orgDetailsForm: {
      logo: '',
      name: '',
      checked: true,
      fatherOrganizationId: '',
      fatherOrganizationName: '',
      country: '',
      salesRep: '',
      erpCustomerNumber: '',
      organizationDescription: '',
    },
    inviteUserForm: {
      email: '',
      permissions: [],
    },
    editUserForm: {
      name: '',
      phone: '',
      email: '',
      permissions: [],
    },
    userSearch: '',
    catalogSearch: '',
  },
};

export const tabSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    resetTabs: () => initialState,

    setTabs: (state, action: PayloadAction<TabItem[]>) => {
      state.tabs = action.payload;
    },
    addTab: (state, action: PayloadAction<TabItem>) => {
      const tab = action.payload;
      const isAlreadyOpened = !!state.tabs.find((t) => t.id === tab.id);

      if (!isAlreadyOpened) {
        state.tabs.push(tab);
      }
    },
    removeTab: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      state.tabs = state.tabs && state.tabs.filter((t) => t.id !== id);
    },
    setHomeRoute: (state, action: PayloadAction<string>) => {
      state.homeRoute = action.payload;
    },
    setHomeTabState: (state, action: PayloadAction<Partial<HomeTabState>>) => {
      state.homeTabState = { ...state.homeTabState, ...action.payload };
    },
    setTabRoute: (state, action: PayloadAction<{ id: string; route: string }>) => {
      const { id, route } = action.payload;
      state.tabs = state.tabs.map((t) => (t.id === id ? { ...t, route } : t));
    },
  },
  selectors: {
    selectTabsState: (state) => state,
    selectTabs: (state) => state.tabs,
    selectHomeTabState: (state) => state.homeTabState,
    selectHomeRoute: (state) => state.homeRoute,
  },
});

export const { setHomeRoute, setTabs, addTab, removeTab, resetTabs, setHomeTabState, setTabRoute } =
  tabSlice.actions;

export const { selectHomeRoute, selectTabs, selectTabsState, selectHomeTabState } = tabSlice.selectors;

export const tabReducer = tabSlice.reducer;
