import { Organization } from "../../models";

export function flattenOrganizations(orgs: Organization[], uniqueIds: Set<number> = new Set()): Organization[] {
  let flatOrgs: Organization[] = [];

  for (const org of orgs) {
    if (!uniqueIds.has(org.id)) {
      uniqueIds.add(org.id);
      flatOrgs.push(org);
      if (org.subOrganizations.length > 0) {
        flatOrgs = flatOrgs.concat(flattenOrganizations(org.subOrganizations, uniqueIds));
      }
    }
  }

  return flatOrgs;
}

function findOrganizationById(orgs: Organization[], id: number): Organization | null {
  for (const org of orgs) {
    if (org.id === id) return org;

    const found = findOrganizationById(org.subOrganizations, id);
    if (found) return found;
  }

  return null;
}

export function findTopFather(orgs: Organization[], id: number): Organization | null {
  let organization = findOrganizationById(orgs, id);

  if (!organization) return null;

  while (organization.fatherOrganizationId) {
    const fatherOrg = findOrganizationById(orgs, organization.fatherOrganizationId);
    if (!fatherOrg) {
      // If fatherOrg doesn't exists then org is the top father
      break;
    }
    organization = fatherOrg;
  }

  return organization;
}