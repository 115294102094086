import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const RestoreIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M13.56 20.0001H2.72667C2.13612 20.0001 1.56976 19.7655 1.15218 19.3479C0.734595 18.9304 0.5 18.364 0.5 17.7735V6.94012C0.499997 6.34896 0.734379 5.78191 1.15177 5.36327C1.56916 4.94463 2.13551 4.70855 2.72667 4.70679H13.56C14.1523 4.70679 14.7204 4.94208 15.1392 5.36091C15.558 5.77975 15.7933 6.3478 15.7933 6.94012V17.7735C15.7916 18.3646 15.5555 18.931 15.1369 19.3484C14.7182 19.7657 14.1512 20.0001 13.56 20.0001ZM2.72667 6.04012C2.48913 6.04188 2.26192 6.13748 2.09458 6.30607C1.92723 6.47466 1.83333 6.70258 1.83333 6.94012V17.7735C1.83333 18.0104 1.92745 18.2376 2.09498 18.4051C2.26252 18.5727 2.48974 18.6668 2.72667 18.6668H13.56C13.7975 18.6668 14.0255 18.5729 14.1941 18.4055C14.3626 18.2382 14.4582 18.011 14.46 17.7735V6.94012C14.46 6.82193 14.4367 6.7049 14.3915 6.59571C14.3463 6.48651 14.28 6.3873 14.1964 6.30372C14.1128 6.22015 14.0136 6.15386 13.9044 6.10863C13.7952 6.0634 13.6782 6.04012 13.56 6.04012H2.72667Z"
      fill="#8C8D92"
    />
    <path
      d="M18.2737 15.2933H14.4604V6.94C14.4604 6.82181 14.4371 6.70478 14.3919 6.59558C14.3466 6.48639 14.2803 6.38718 14.1968 6.3036C14.1132 6.22003 14.014 6.15374 13.9048 6.10851C13.7956 6.06328 13.6786 6.04 13.5604 6.04H5.20703V2.22667C5.2088 1.63551 5.44487 1.06916 5.86351 0.651769C6.28215 0.234379 6.8492 -2.63382e-06 7.44036 0H18.2737C18.8642 0 19.4306 0.234595 19.8482 0.652176C20.2658 1.06976 20.5004 1.63612 20.5004 2.22667V13.06C20.5004 13.6512 20.266 14.2182 19.8486 14.6369C19.4312 15.0555 18.8649 15.2916 18.2737 15.2933ZM15.7937 13.96H18.2737C18.5112 13.9582 18.7384 13.8626 18.9058 13.6941C19.0731 13.5255 19.167 13.2975 19.167 13.06V2.22667C19.167 1.98974 19.0729 1.76252 18.9054 1.59498C18.7378 1.42745 18.5106 1.33333 18.2737 1.33333H7.44036C7.20282 1.33333 6.97491 1.42723 6.80631 1.59458C6.63772 1.76192 6.54212 1.98913 6.54036 2.22667V4.70667H13.5604C14.1527 4.70667 14.7207 4.94196 15.1396 5.3608C15.5584 5.77963 15.7937 6.34768 15.7937 6.94V13.96Z"
      fill="#8C8D92"
    />
  </SvgIcon>
);
