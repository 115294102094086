import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ROUTE } from 'shared/enum';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { selectHomeRoute, setHomeRoute } from 'shared/slices';

export const useTabState = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const homeRoute = useAppSelector(selectHomeRoute);

  const isTabRoute = useMemo(
    () =>
      location.pathname === `${ROUTE.CATALOGS}/${id}` ||
      location.pathname === `${ROUTE.CATALOGS}/${id}/management`,
    [location, id]
  );

  useEffect(() => {
    const route = location.pathname;

    !isTabRoute && dispatch(setHomeRoute(route));
  }, [location]);

  const onClickHomeTab = () => {
    const parentRoute = getParentRoute();

    if (isTabRoute) {
      navigate(homeRoute);
      return;
    }

    if (parentRoute == location.pathname) {
      navigate(ROUTE.HOME);
      return;
    }

    navigate(parentRoute);
  };

  const getParentRoute = () => location.pathname.split('/').slice(0, 3).join('/');

  return { onClickHomeTab, isTabRoute };
};
