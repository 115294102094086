import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LanguageData } from '../../models';

interface CatalogLanguagesState {
  isLoading: boolean;
  language: string;
  languages: LanguageData[];
}

const initialState: CatalogLanguagesState = {
  isLoading: false,
  language: '',
  languages: [],
};

export const catalogLanguagesSlice = createSlice({
  name: 'catalogLanguages',
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    setLanguages: (state, action: PayloadAction<LanguageData[]>) => {
      state.languages = action.payload;
    },
  },
  selectors: {
    selectCatalogLanguages: (state) => state.languages,
    selectCatalogLanguage: (state) => state.language,
    selectCatalogLanguagesLoading: (state) => state.isLoading,
    selectCatalogLanguagesState: (state) => state,
  },
});

export const { setLanguage, setLanguages } = catalogLanguagesSlice.actions;
export const {
  selectCatalogLanguages,
  selectCatalogLanguagesState,
  selectCatalogLanguagesLoading,
  selectCatalogLanguage,
} = catalogLanguagesSlice.selectors;

// export const modalActions = modalSlice.actions;

export const catalogLanguagesReducer = catalogLanguagesSlice.reducer;
