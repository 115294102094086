import { Box, IconButton, Stack, styled, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { useAppSelector } from 'shared/hooks';
import { User } from '../../../../shared/models';
import { UserOrganizationsModal } from 'features';
import { HoverToolTip, Icon } from '../../../../shared/ui';
import { STATUS } from 'shared/constants';
import { selectPermissions } from '../../../../shared/slices';

const Cell = styled(Stack)(({ theme }) => ({
  paddingLeft: '30px',
  fontSize: '18px',
  lineHeight: '16px',
  fontWeight: 600,
  border: 'none',
  color: theme.palette.darksome.main,
  userSelect: 'none',
}));

interface Props {
  user: User;
}

export const UserItem: FC<Props> = ({ user }) => {
  const permissions = useAppSelector(selectPermissions);

  const [openUserOrganizations, setOpenUserOrganizations] = useState(false);

  const { relatedOrganizations } = user;

  const handleViewUser = () => {
    setOpenUserOrganizations((prev) => !prev);
  };

  const isReadPermissionAble = Object.values(permissions).some((p) => p.user && p.user.read);

  const userStatus = user.status === STATUS.ACTIVE ? 'Active' : 'Pending';

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        onDoubleClick={isReadPermissionAble ? handleViewUser : undefined}
        sx={{
          height: '60px',
          position: 'relative',
          '&:hover': { bgcolor: 'rgba(219, 236, 225, 1)' },
        }}
      >
        <Cell sx={{ width: '15%' }}>
          <HoverToolTip title={user.name} placement="top-start">
            <Typography noWrap sx={{ fontSize: '18px', lineHeight: '16px', fontWeight: 600 }}>
              {user.name}
            </Typography>
          </HoverToolTip>
        </Cell>
        <Cell sx={{ width: '15%', pl: 0 }}>
          <HoverToolTip title={user.email} placement="top-start">
            <Typography noWrap sx={{ fontSize: '18px', lineHeight: '16px', fontWeight: 600 }}>
              {user.email}
            </Typography>
          </HoverToolTip>
        </Cell>
        <Cell sx={{ width: '20%', pl: 0 }}>
          <HoverToolTip title={user.phone} placement="top-start">
            <>{user.phone}</>
          </HoverToolTip>
        </Cell>
        <Cell sx={{ width: '10%', pl: 0.5 }}>{userStatus}</Cell>
        <Cell sx={{ width: '15%', pl: 0.5 }}>{relatedOrganizations}</Cell>
        <Cell sx={{ width: '10%', pl: 0 }}>
          <Stack direction={'row'} justifyContent={'center'}>
            <IconButton onClick={isReadPermissionAble ? handleViewUser : undefined}>
              <Icon path={'org-open-edit-grey'} />
            </IconButton>
          </Stack>
        </Cell>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '1px',
            bgcolor: 'lightgrey',
            zIndex: 10,
          }}
        />
      </Stack>

      <UserOrganizationsModal
        open={openUserOrganizations}
        onClose={() => setOpenUserOrganizations((prev) => !prev)}
        user={user}
      />
    </>
  );
};
