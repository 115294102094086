import { Box, Typography } from '@mui/material';
import React, { FC, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'shared/ui';

interface Props {
  logo: string;
  changeImg: (imgCode: string) => void;
}

export const OrgDetailsLogo: FC<Props> = ({ logo, changeImg }) => {
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (!inputRef.current) return;
    inputRef.current.click();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target || !e.target.files) return;

    const file = e.target.files[0];

    if (!file) {
      console.error('No uploaded files.');
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      changeImg(reader.result as string);
    };
  };

  return (
    <Box
      onClick={handleClick}
      sx={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', gap: '2px', alignItems: 'center' }}
    >
      <Box
        component="input"
        type="file"
        accept="image/*,.png,.jpg,.gif,.web"
        ref={inputRef}
        onChange={handleChange}
        sx={{
          opacity: 0,
          height: 0,
          width: 0,
          padding: 0,
          margin: 0,
        }}
      />
      <Icon
        isLogo={!!logo}
        path={logo || 'org-placeholder-green'}
        sx={{ width: 100, height: 100, borderRadius: '6px' }}
      />

      <Typography
        sx={{
          color: '#667085',
          fontFamily: 'Roboto',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '130%',
          letterSpacing: '0.14px',
        }}
      >
        {t('Change Photo')}
      </Typography>
    </Box>
  );
};
