import { FC, ReactNode, useEffect, useRef } from 'react';
import { Box, BoxProps, CircularProgress, Divider, Stack } from '@mui/material';

interface Props extends BoxProps {
  header?: ReactNode;
  withHeaderBorder?: boolean;
  isActive?: boolean;
  handleScrollAppear?: (val: boolean) => void;
  isDisableLoading?: boolean;
}

export const ItemsBox: FC<Props> = ({
  header,
  children,
  withHeaderBorder = true,
  isActive = false,
  handleScrollAppear,
  isDisableLoading,
  onClick,
  sx,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current || !handleScrollAppear) return;

    handleScrollAppear(containerRef.current.scrollHeight > containerRef.current.clientHeight);
  }, [containerRef.current, handleScrollAppear]);

  return (
    <Box
      onClick={onClick}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '8px',
        minHeight: '60px',
        border: '1px solid grey',
        borderColor: 'info.dark',
        overflowX: 'hidden',
        width: '200px',
        minWidth: '200px',
        maxHeight: 'calc(100svh - 210px)',
        flexShrink: 1,
        position: 'relative',
        zIndex: 2,
        ...sx,
      }}
    >
      <Box sx={{ minHeight: '6px', bgcolor: isActive ? '#FDDD56' : 'info.dark' }} />

      {header && header}

      {withHeaderBorder && <Divider />}

      <Box
        ref={containerRef}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
          overflowX: 'hidden',
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'info.dark',
            borderRadius: '30px',
          },
          '&::-webkit-scrollbar': {
            height: '6px',
            width: '6px',
            backgroundColor: 'info.light',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'info.light',
          },
          '&::-webkit-scrollbar-corner': {
            backgroundColor: 'info.light',
            background: 'info.light',
          },
        }}
      >
        {children}
      </Box>

      {isDisableLoading && (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            bgcolor: 'info.light',
            opacity: 0.65,
          }}
        >
          <CircularProgress size={25} color="primary" />
        </Stack>
      )}
    </Box>
  );
};
