import { Box, IconButton, Stack, styled, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { useAppDispatch, useAppNavigator, useAppSelector } from 'shared/hooks';
import { selectOrganizations, setSelectedOrg, toggleOrgCreating } from 'shared/slices';
import { HoverToolTip, Icon } from 'shared/ui';
import { Organization } from 'shared/models';
import { borderDashStyle } from './borderDashStyle';
import { findOrganizationAdmin, findTopFather, flattenOrganizations } from 'shared/lib';

const Cell = styled(Stack)(({ theme }) => ({
  paddingLeft: '30px',
  fontSize: '18px',
  lineHeight: '16px',
  fontWeight: 600,
  border: 'none',
  color: theme.palette.darksome.main,
  userSelect: 'none',
}));

interface Props {
  org: Organization;
  show?: () => void;
  depth: number;
  searched?: boolean;
}

export const OrgRow: FC<Props> = ({ org, show, depth, searched }) => {
  const dispatch = useAppDispatch();
  const organizations = useAppSelector(selectOrganizations);

  const { navigateToCurrentOrganization } = useAppNavigator();

  const { organizationName, id, subOrganizations, logo, activeUsers } = org || {};
  const haveSubOrgs = org.subOrganizations.length > 0;

  const orgsState = JSON.parse(localStorage.getItem('organizationState') ?? '[]');

  const isOrgOpen = orgsState.includes(org?.id);
  const [isOpenArrow, setIsOpenArrow] = useState(isOrgOpen);

  const onOpenCreateSubOrgModal = () => {
    dispatch(setSelectedOrg(org));
    dispatch(toggleOrgCreating());
  };

  const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();

    if (!show || !haveSubOrgs) {
      return;
    }

    show();
    setIsOpenArrow(!isOpenArrow);
    saveOpenOrgIdToLS(id);
  };

  const handleShowOrg = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    dispatch(setSelectedOrg(org));
    navigateToCurrentOrganization(org.id);
  };

  const saveOpenOrgIdToLS = (id: number) => {
    let openedOrgs = JSON.parse(localStorage.getItem('organizationState') ?? '[]');

    if (openedOrgs?.includes(id)) {
      openedOrgs = openedOrgs.filter((orgId: number) => orgId !== id);
    } else {
      openedOrgs?.push(id);
    }
    localStorage.setItem('organizationState', JSON.stringify(openedOrgs));
  };

  const flattenOrgs = flattenOrganizations(organizations);
  const isPossibleToView = findTopFather(flattenOrgs, org.id)?.permissions.organization.read;
  const isCreateSubOrgAble = findTopFather(flattenOrgs, org.id)?.permissions.sub_organization.create;

  const organizationAdmin = findOrganizationAdmin(flattenOrgs, org.id);

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      onDoubleClick={isPossibleToView ? handleShowOrg : undefined}
      sx={{ height: '60px', position: 'relative' }}
    >
      <Cell
        direction="row"
        alignItems="center"
        sx={{
          justifyContent: 'flex-start',
          width: '35%',
          pl: `${searched ? 30 : depth * 28 + 5}px`,
        }}
      >
        {!searched && (
          <Box sx={{ position: 'relative' }}>
            {!!depth && (
              <Box
                sx={{
                  position: 'absolute',
                  width: '25px',
                  height: '2px',
                  top: '50%',
                  right: '20px',
                  ...borderDashStyle,
                }}
              />
            )}

            {haveSubOrgs ? (
              <IconButton
                onClick={handleOpen}
                onDoubleClick={handleOpen}
                sx={{
                  transform: isOpenArrow ? 'rotate(0deg) translateY(4px)' : 'rotate(-90deg)',

                  transition: 'all 0.3s',
                  position: 'relative',
                  zIndex: 1,
                }}
              >
                <Icon path={`org-arrow`} />
              </IconButton>
            ) : (
              <>
                {depth ? (
                  <Icon path={'org-sub-ball'} sx={{ mx: '8.5px', mt: '5px' }} />
                ) : (
                  <Box sx={{ width: '32px' }} />
                )}
              </>
            )}
          </Box>
        )}

        <Box sx={{ ml: '5px', mr: '20px' }}>
          <Icon
            isLogo
            path={logo ?? '/images/org-placeholder.svg'}
            alt="placeholder"
            sx={{ borderRadius: '6px' }}
          />
        </Box>
        <Box
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <HoverToolTip title={organizationName} placement="top-start">
            <Typography
              noWrap
              sx={{
                fontSize: '18px',
                lineHeight: '16px',
                fontWeight: 600,
              }}
            >
              {organizationName}
            </Typography>
          </HoverToolTip>
        </Box>
      </Cell>

      <Cell sx={{ width: '20%', pl: 0 }}>
        <Box
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <HoverToolTip title={organizationAdmin} placement="top-start">
            <Typography noWrap sx={{ fontSize: '18px', lineHeight: '16px', fontWeight: 600 }}>
              {organizationAdmin}
            </Typography>
          </HoverToolTip>
        </Box>
      </Cell>

      <Cell sx={{ width: '10%' }}>
        <HoverToolTip title={subOrganizations.length} placement="top-start">
          <Typography sx={{ fontSize: '18px', lineHeight: '16px', fontWeight: 600 }}>
            {subOrganizations.length}
          </Typography>
        </HoverToolTip>
      </Cell>
      <Cell sx={{ width: '10%' }}>
        <HoverToolTip title={activeUsers ?? '--'} placement="top-start">
          <Typography sx={{ fontSize: '18px', lineHeight: '16px', fontWeight: 600 }}>
            {activeUsers ?? '--'}
          </Typography>
        </HoverToolTip>
      </Cell>

      <Cell sx={{ width: '10%', pl: '0px', alignItems: 'center' }}>
        <Stack direction={'row'}>
          {isPossibleToView && (
            <IconButton onClick={handleShowOrg}>
              <Icon path={'org-open-edit-grey'} />
            </IconButton>
          )}

          {isCreateSubOrgAble && (
            <IconButton onClick={onOpenCreateSubOrgModal}>
              <Icon path={'org-add-new'} />
            </IconButton>
          )}
        </Stack>
      </Cell>

      <Box
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '1px',
          bgcolor: 'lightgrey',
          zIndex: 10,
        }}
      />
    </Stack>
  );
};
