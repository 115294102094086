import React, { FC, useRef, useState } from 'react';
import { Box, Fade, List, ListItem, ListItemButton, ListItemText, Stack, styled, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector, usePhone } from 'shared/hooks';
import { DialogModal, Input, PhoneInput, SaveControl } from 'shared/ui';
import { AssignToOrgDTO, UserDTO } from 'shared/models';
import { selectOpenInviteUser, toggleInviteUser, updateOrgUsersSynchronizeCount } from 'shared/slices';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useInviteUserValues } from './hooks';
import { UserPermissions } from 'entities/Permissions';
import { UserService, localStorageService } from 'shared/services';

const GreenText = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  bottom: '-20px',
  fontSize: '14px',
  color: theme.palette.primary.main,
  paddingLeft: '10px',
}));

function Divider() {
  return null;
}

export const InviteUser: FC = () => {
  const { t } = useTranslation();
  const { organizationId } = useParams();
  const dispatch = useAppDispatch();
  const userFormRef = useRef();

  const [transferEmailSuggestions, setTransferEmailSuggestions] = useState<string[]>(
    JSON.parse(localStorageService.transferEmailSuggestions ?? '[]')
  );

  const addTransferEmailSuggestion = (email: string) => {
    const updatedData = [...new Set([...transferEmailSuggestions, email])];
    localStorageService.transferEmailSuggestions = JSON.stringify(updatedData);
    setTransferEmailSuggestions(updatedData);
  };

  const openInviteUser = useAppSelector(selectOpenInviteUser);

  const { phoneNumber, onPhoneChange, checkIsPhoneValid, countryCode, phoneErrorText } = usePhone(openInviteUser);

  const {
    userData,
    onNameChange,
    checkIsValid,
    checkIsEmailValid,
    checkIsNameValid,
    nameErrorText,
    emailErrorText,
    rolesErrorText,
    onRolesChange,
    onChangeEmail,
    isCheckLoading,
    checkEmail,
    isExist,
    setUserData,
    isUserAlreadyAdded,
    setIsCanShowInfoText,
    inviteError,
    isOnlyRapidRoleSelected,
  } = useInviteUserValues(onPhoneChange);

  const onClose = () => {
    dispatch(toggleInviteUser());
    setIsCanShowInfoText(false);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const handleSave = async () => {
    if (!organizationId) return;
    if (inviteError || phoneErrorText) return;

    const error = !checkIsValid();
    const phoneError = !checkIsPhoneValid();
    if (error || phoneError) return;

    const addNewUserDto: UserDTO = {
      organizationId: +organizationId,
      userName: userData.name,
      email: userData.email,
      phoneNumber: phoneNumber.replaceAll(' ', ''),
      roles: userData.roles,
      appType: isOnlyRapidRoleSelected ? 2 : 0,
    };
    const assignDto: AssignToOrgDTO = {
      organizationId: +organizationId,
      email: userData.email,
      roles: userData.roles,
    };

    setIsLoading(true);

    let successRequest;

    if (isExist) {
      successRequest = await UserService.assignUserToOrg(assignDto);
    } else {
      successRequest = await UserService.inviteNewUser(addNewUserDto);
    }
    if (successRequest) {
      const email = isExist ? assignDto.email : addNewUserDto.email;
      addTransferEmailSuggestion(email);
      dispatch(updateOrgUsersSynchronizeCount());
      onClose();
    }
    setIsLoading(false);
  };

  return (
    <DialogModal
      open={openInviteUser}
      withCloseButton={false}
      onClose={onClose}
      padding={'24px 96px 64px 96px'}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: '952px',
          overflowY: 'visible',
        },
      }}
    >
      <Box component="form" ref={userFormRef}>
        <Typography
          sx={{
            color: '#141522',
            fontFamily: 'Roboto',
            fontSize: '24px',
            fontWeight: 600,
            lineHeight: '130%',
            letterSpacing: '0.24px',
            mb: '48px',
          }}
        >
          {t('invite_user_title')}
        </Typography>

        <Stack direction={'row'} sx={{ mb: '48px' }}>
          <Stack
            flexShrink={0}
            sx={{
              mr: '32px',
              width: '325px',
              pr: '32px',
              borderRight: '1px solid grey',
              borderColor: 'info.dark',
            }}
          >
            <Box sx={{ position: 'relative', mb: '32px' }}>
              <Input
                autoComplete="new-password"
                value={userData.email}
                onChange={onChangeEmail}
                onBlur={() => {
                  setShowSuggestions(false);
                  checkIsEmailValid();
                  checkEmail();
                }}
                onFocus={() => setShowSuggestions(true)}
                label={t('E-Mail')}
                placeholder="example@mail.com"
                name="email"
                isLoading={isCheckLoading}
                error={!!emailErrorText && (!isUserAlreadyAdded || !isExist)}
                helperText={emailErrorText}
              />
              <Fade in={showSuggestions && !!transferEmailSuggestions.length && !userData.email.length}>
                <List
                  sx={{
                    position: 'absolute',
                    maxHeight: 300,
                    overflow: 'auto',
                    top: '75px',
                    p: 0,
                    width: '100%',
                    border: '1px solid rgba(29, 135, 66, 1)',
                    bgcolor: 'info.main',
                    zIndex: 100,
                  }}
                >
                  {transferEmailSuggestions.map((item) => (
                    <React.Fragment key={item}>
                      <ListItem disablePadding>
                        <ListItemButton
                          sx={{ p: 0, px: '8px' }}
                          onClick={() => {
                            setUserData((prev) => ({ ...prev, email: item }));
                            checkIsEmailValid(item);
                            checkEmail(item);
                          }}
                        >
                          <ListItemText primary={<Typography>{item}</Typography>} />
                        </ListItemButton>
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  ))}
                </List>
              </Fade>

              {isUserAlreadyAdded && <GreenText>The user already exists in the organization</GreenText>}

              {isExist && !isUserAlreadyAdded && <GreenText>The user already exists in the system</GreenText>}
            </Box>

            <Box sx={{ mb: '32px' }}>
              <Input
                value={userData.name}
                onChange={onNameChange}
                onBlur={() => checkIsNameValid()}
                label={t('name')}
                placeholder="Name"
                name="name"
                error={!!nameErrorText}
                helperText={nameErrorText}
                disabled={isExist}
              />
            </Box>

            <PhoneInput
              value={phoneNumber}
              onChange={onPhoneChange}
              onBlur={() => checkIsPhoneValid()}
              countryCode={countryCode}
              phoneErrorText={phoneErrorText}
            />
          </Stack>

          <UserPermissions rolesErrorText={rolesErrorText} roleIDs={userData.roles} setRoles={onRolesChange} />
        </Stack>

        <Stack alignItems="end">
          <SaveControl onCancel={onClose} onSave={handleSave} isLoading={isLoading} />
        </Stack>
      </Box>
    </DialogModal>
  );
};
