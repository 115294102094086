import React from 'react';
import { ContextMenuType } from 'shared/models';

export const useContextMenu = () => {
  const [isContextMenu, setIsContextMenu] = React.useState<ContextMenuType | null>(null);

  const openContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();

    setIsContextMenu(
      isContextMenu === null
        ? {
            mouseX: e.clientX + 2,
            mouseY: e.clientY - 6,
          }
        : null
    );
  };
  const closeContextMenu = () => setIsContextMenu(null);

  return { isContextMenu, openContextMenu, closeContextMenu };
};
