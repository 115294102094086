import { Box, LinearProgress, useTheme } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'shared/hooks';
import { selectAuthToken, selectPermissionsLoading } from '../../shared/slices';

interface Props extends PropsWithChildren {
  progress: number;
}

export const PermissionsLoadingScreen: FC<Props> = ({ children, progress }) => {
  const { palette } = useTheme();
  const location = useLocation();
  const token = useAppSelector(selectAuthToken);
  const isLoading = useAppSelector(selectPermissionsLoading);

  const isPermissionLoading = token && isLoading && location.pathname.includes('main');

  return isPermissionLoading ? (
    <Box
      sx={{
        width: '100%',
        height: '100svh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        bgcolor: palette.mode === 'dark' ? 'secondary.dark' : 'info.main',
      }}
    >
      <img src="/images/rcadi-logo.svg" width="15%" alt="logo" />
      <Box width="25%" mt={2}>
        <LinearProgress color="primary" variant="determinate" value={progress} />
      </Box>
    </Box>
  ) : (
    <>{children}</>
  );
};
