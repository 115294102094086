import { FC, useState } from 'react';
import { useAppDispatch, useAppNavigator } from 'shared/hooks';
import { Box, Stack, styled, Typography } from '@mui/material';
import { AuthInput, AuthSwitcher, Button } from 'shared/ui';
import { useLoginValues } from './hooks';
import { setToken } from 'shared/slices';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { axiosBase } from 'shared/axios';
import { handleError, userNotActivatedCb } from '../../shared/lib/errorHandling';
import { getForgotPasswordPath } from '../../shared/lib/login/getForgotPasswordPath';

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.darksome.main,
  fontSize: '12px',
  fontWeight: 400,
  margin: '16px 0 8px 0px',
  textDecoration: 'none',
}));

const activationPendingMessage = `
  Account Activation Pending
  Please check your email inbox to complete the account activation process.
  If you haven't received the activation email, please contact your organization administrator for assistance.
`;

const accountDeactivatedMessage = 'Permission denied.';

export const Login: FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { navigateToHome } = useAppNavigator();

  const [loading, setLoading] = useState(false);
  const [isWrongCredentials, setIsWrongCredentials] = useState(false);
  const [isRemember, setRemember] = useState(false);

  const { loginData, onLoginDataChange, checkIsValid, usernameError, passwordError } = useLoginValues();

  const onSubmit = async () => {
    if (!checkIsValid()) return;

    setLoading(true);
    setIsWrongCredentials(false);

    try {
      const { data } = await axiosBase.post('/Auth/login', loginData);

      dispatch(setToken(data['data'].token));

      localStorage.setItem('token', data['data'].token);
      localStorage.setItem('email', data['data'].email);
      localStorage.setItem('name', data['data'].name);
      localStorage.setItem('userId', data['data'].userId);

      if (isRemember) {
        const rememberedUser = window.btoa(encodeURIComponent(JSON.stringify(loginData)));
        localStorage.setItem('rememberedUser', rememberedUser);
      }

      navigateToHome();
    } catch (err) {
      setIsWrongCredentials(true);

      handleError(err, userNotActivatedCb(activationPendingMessage, accountDeactivatedMessage));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Typography
        sx={{
          color: 'primary.main',
          fontSize: '20px',
          fontWeight: 600,
          mb: '15px',
        }}
      >
        {t('welcome_back')}
      </Typography>

      <Box component="form" sx={{ flexGrow: 1 }}>
        <Text sx={{ ml: '14px' }}>{t('login')}</Text>
        <AuthInput
          value={loginData.username}
          onChange={onLoginDataChange}
          placeholder={t('email_or_phone_number')}
          required
          name="username"
          autoComplete="off"
          error={usernameError || isWrongCredentials}
          helperText={isWrongCredentials ? 'Incorrect user data' : 'Please enter your login'}
        />

        <Text sx={{ ml: '14px' }}>{t('password')}</Text>
        <AuthInput
          value={loginData.password}
          onChange={onLoginDataChange}
          placeholder={t('enter_password')}
          password
          required
          name="password"
          autoComplete="off"
          error={passwordError || isWrongCredentials}
          helperText={isWrongCredentials ? 'Incorrect user data' : 'Please enter your password'}
        />

        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: '5px' }}>
          <Box sx={{ mt: '5px' }}>
            <AuthSwitcher isOn={isRemember} toggle={() => setRemember((prev) => !prev)} label={t('remember_me')} />
          </Box>
          <Link to={getForgotPasswordPath(loginData.username)} style={{ textDecoration: 'none' }}>
            <Text sx={{ color: '#007AFF', cursor: 'pointer' }}>{t('forgot_password')}?</Text>
          </Link>
        </Stack>

        <Box sx={{ height: '40px', my: '23px' }}>
          <Button onClick={onSubmit} filled fullWidth loading={loading}>
            <Typography sx={{ fontWeight: 700 }}>{t('sign_in')}</Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
};
