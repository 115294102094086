import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const CloseIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M22.5512 10.0889L10.2998 22.3403M22.5512 22.3403L10.2998 10.0889"
      stroke={props.stroke ?? '#242731'}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
