import ListItemButton from '@mui/material/ListItemButton';
import { FC } from 'react';
import { Icon } from 'shared/ui';
import { Box, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  icon: string;
  route: string;
  callback?: () => void;
}

export const MenuLink: FC<Props> = ({ title, icon, route, callback }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const handleClick = () => callback?.();

  return (
    <ListItemButton
      sx={{
        px: 0,
        maxHeight: '44px',
        ...(route == location.pathname && { bgcolor: 'primary.dark' }),
        '&:hover': {
          bgcolor: 'primary.dark',
        },
      }}
      onClick={handleClick}
    >
      <Box sx={{ minWidth: '55px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Icon path={icon} />
      </Box>
      <Typography
        noWrap
        sx={{
          fontWeight: 600,
          fontSize: '18px',
          color: 'info.main',
        }}
      >
        {t(title)}
      </Typography>
    </ListItemButton>
  );
};
