import React, { FC } from 'react';
import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

const CustomToolTip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }}>
    {props.children}
  </Tooltip>
))(({ theme }) => ({
  padding: 0,
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.darksome.main,
    fontSize: '14px',
    borderRadius: '8px',
    maxWidth: 500,
    border: '1px solid grey',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.info.main,
    left: '50% !important',
    bottom: '0.5px !important',
    transform: 'translateX(-50%) !important',
    '&:before': {
      border: '1px solid grey',
    },
  },
}));

export const HoverToolTip: FC<TooltipProps> = ({ children, title, ...props }) => {
  return (
    <CustomToolTip title={title} arrow {...props}>
      <span>{children}</span>
    </CustomToolTip>
  );
};
