import { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { CloseIcon, DoneIcon } from 'shared/ui';

interface Props {
  text: string;
  isInvalid: boolean;
}

export const ValidationCheckmark: FC<Props> = ({ text, isInvalid }) => {
  return (
    <Stack direction="row" alignItems="center" sx={{ p: '1px 24px' }}>
      {isInvalid ? (
        <CloseIcon width="32" height="32" viewBox="0 0 32 32" stroke={'red'} />
      ) : (
        <DoneIcon width="24" height="25" viewBox="0 0 24 25" />
      )}

      <Typography
        sx={{
          ml: '4px',
          fontSize: '12px',
          lineHeight: '20px',
          color: isInvalid ? 'error.main' : 'primary.main',
        }}
      >
        {text}
      </Typography>
    </Stack>
  );
};
