import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const FilterIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M8.33455 19.9979C7.87626 19.9979 7.5013 19.623 7.5013 19.1647V9.36569C7.5013 8.92407 7.32632 8.49911 7.00969 8.19081L0.243725 1.42485C-0.0812415 1.09989 -0.0812415 0.57494 0.243725 0.249974C0.402041 0.0916571 0.610353 0 0.83533 0H19.1668C19.625 0 20 0.374961 20 0.833247C20 1.05822 19.9083 1.26653 19.7584 1.42485L12.9924 8.19081C12.6758 8.49911 12.5008 8.92407 12.5008 9.36569V15.8317C12.5008 16.0567 12.4091 16.265 12.2591 16.4233L8.92615 19.7563C8.76784 19.9063 8.55952 19.9979 8.33455 19.9979Z"
      fill="#8C8D92"
    />
  </SvgIcon>
);
