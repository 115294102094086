import { Organization, OrgPathItem } from 'shared/models';

export const getOrgIds = (arr: Organization[]): { id: number; organizationId: string }[] => {
  const idsSet = new Set<{ id: number; organizationId: string }>();

  function collectOrgIds(organizations: Organization[]) {
    for (const org of organizations) {
      idsSet.add({ id: org.id, organizationId: org.organizationId });

      if (org.subOrganizations.length > 0) {
        collectOrgIds(org.subOrganizations);
      }
    }
  }

  collectOrgIds(arr);

  return [...idsSet];
};

export const flatOrgs = (arr: Organization[]): Organization[] => {
  return arr.reduce((acc: Organization[], org: Organization) => {
    acc.push(org);
    if (org.subOrganizations.length > 0) {
      acc.push(...flatOrgs(org.subOrganizations));
    }
    return acc;
  }, []);
};

export const getOrgHierarchyList = (targetOrgId: number, arr: Organization[]): OrgPathItem[] => {
  for (const org of arr) {
    if (org.id === targetOrgId) {
      if (org.subOrganizations) {
        return [{ id: org.id, name: org.organizationName }];
      } else {
        return [{ id: org.id, name: org.organizationName }];
      }
    }

    if (org.subOrganizations) {
      const subPath = getOrgHierarchyList(targetOrgId, org.subOrganizations);
      if (subPath.length > 0) {
        return [{ id: org.id, name: org.organizationName }, ...subPath];
      }
    }
  }

  return [];
};
