import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../models';

export interface UsersState {
  isLoading: boolean;
  orgUsersSynchronizeCount: number;
  users: User[];
}

const initialState: UsersState = {
  isLoading: false,
  orgUsersSynchronizeCount: 0,
  users: [],
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUsers: () => initialState,
    updateUserStatus: (state, action: PayloadAction<{ userId: string; newStatus: number }>) => {
      const { userId, newStatus } = action.payload;
      const userIndex = state.users.findIndex((user) => user.userId === userId);
      if (userIndex !== -1) {
        state.users[userIndex].status = newStatus;
      }
    },
    setUsersLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    updateOrgUsersSynchronizeCount: (state) => {
      state.orgUsersSynchronizeCount = state.orgUsersSynchronizeCount + 1;
    },
    setUsers: (state, action: PayloadAction<User[]>) => {
      state.users = action.payload;
    },
  },
  selectors: {
    selectUsers: (state) => state.users,
    selectUsersLoading: (state) => state.isLoading,
    selectOrgUsersSyncedCount: (state) => state.orgUsersSynchronizeCount,
    selectUsersState: (state) => state,
  },
});

export const { setUsers, setUsersLoading, resetUsers, updateUserStatus, updateOrgUsersSynchronizeCount } =
  usersSlice.actions;

export const { selectUsers, selectOrgUsersSyncedCount, selectUsersLoading, selectUsersState } =
  usersSlice.selectors;

export const usersReducer = usersSlice.reducer;
