import { Box, Stack, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { DialogModal, SaveControl } from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { ArchiveIcon } from '../../shared/ui/icons/ArchiveIcon';

interface Props {
  open: boolean;
  onClose: () => void;
  catalogName: string;
}

export const CatalogArchiveModal: FC<Props> = ({ open, onClose, catalogName }) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async () => {
    setIsLoading(true);

    try {
      console.log('');
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DialogModal
      open={open}
      withCloseButton={false}
      onClose={onClose}
      padding={'48px'}
      sx={{
        '& .MuiBackdrop-root': {
          top: '44px',
        },
        '& .MuiPaper-root': {
          width: '560px',
        },
      }}
    >
      <Stack sx={{ overflow: 'hidden' }}>
        <Stack mb={'12px'} direction={'row'} justifyContent={'space-between'}>
          <Stack direction={'row'} gap={'8px'} alignItems={'center'}>
            <ArchiveIcon />
            <Typography
              sx={{
                color: '#4D4E4C',
                fontFamily: 'Roboto',
                fontSize: '18px',
                fontWeight: 400,
                lineHeight: '130%',
                letterSpacing: '0.18px',
              }}
            >
              {t('Archive Catalog')}
            </Typography>
          </Stack>
        </Stack>
        <Box mb={'24px'}>
          <Typography
            sx={{
              color: '#4D4E4C',
              fontFamily: 'Roboto',
              fontSize: '18px',
              fontWeight: 600,
              lineHeight: '130%',
              letterSpacing: '0.18px',
            }}
          >
            {t(catalogName)}
          </Typography>
        </Box>
        <Box mb={'24px'}>
          <Typography
            sx={{
              color: '#4D4E4C',
              fontFamily: 'Roboto',
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '130%',
              letterSpacing: '0.18px',
            }}
          >
            {`Are you sure you want to archive ${t(catalogName)} catalog?`}
          </Typography>
        </Box>
        <Stack direction="row" justifyContent="flex-end" sx={{ width: '100%' }}>
          <SaveControl btnTexts={['Yes', 'No']} onSave={onSubmit} onCancel={onClose} isLoading={isLoading} />
        </Stack>
      </Stack>
    </DialogModal>
  );
};
