import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React from 'react';

export const PublicIcon: React.FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      d="M9.99984 0.666504C4.84784 0.666504 0.666504 4.84784 0.666504 9.99984C0.666504 15.1518 4.84784 19.3332 9.99984 19.3332C15.1518 19.3332 19.3332 15.1518 19.3332 9.99984C19.3332 4.84784 15.1518 0.666504 9.99984 0.666504ZM9.0665 17.4012C5.37984 16.9438 2.53317 13.8078 2.53317 9.99984C2.53317 9.42117 2.60784 8.8705 2.72917 8.32917L7.19984 12.7998V13.7332C7.19984 14.7598 8.03984 15.5998 9.0665 15.5998V17.4012ZM15.5065 15.0305C15.2638 14.2745 14.5732 13.7332 13.7332 13.7332H12.7998V10.9332C12.7998 10.4198 12.3798 9.99984 11.8665 9.99984H6.2665V8.13317H8.13317C8.6465 8.13317 9.0665 7.71317 9.0665 7.19984V5.33317H10.9332C11.9598 5.33317 12.7998 4.49317 12.7998 3.4665V3.08384C15.5345 4.1945 17.4665 6.87317 17.4665 9.99984C17.4665 11.9412 16.7198 13.7052 15.5065 15.0305Z"
      fill="#8C8D92"
    />
  </SvgIcon>
);
