import { Box, styled } from '@mui/material';

export const ContentBox = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  overflowX: 'hidden',
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.main,
    border: `6px solid ${theme.palette.info.main}`,
    borderRadius: '30px',
  },
  '&::-webkit-scrollbar': {
    width: '16px',
  },
  '&::-webkit-scrollbar-corner': {
    backgroundColor: theme.palette.info.main,
    background: theme.palette.info.main,
  },
}));
