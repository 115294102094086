import { CircularProgress, Stack, Typography, styled } from '@mui/material';
import { FC } from 'react';
import { AvailableGroup } from 'shared/models';
import { HoverToolTip } from 'shared/ui';
import { SquareCheckBox } from 'shared/ui/checkBoxes';

const Text = styled(Typography)(({ theme }) => ({
  padding: '5px 15px',
  color: theme.palette.info.dark,
  borderLeft: '1px solid grey',
  borderColor: theme.palette.info.light,
  height: '100%',
  cursor: 'pointer',
}));

interface Props {
  group: AvailableGroup;
  checked: boolean;
  onToggle: (id: string) => void;
  disabled: boolean;
  itemsAmount: number;
  selectedItemsAmount: number;
  isSelectedForView: boolean;
  onSelectForView: (id: string) => void;
  isLoading: boolean;
}

export const AvailabilityGroup: FC<Props> = ({
  group,
  checked,
  onToggle,
  itemsAmount,
  selectedItemsAmount,
  isSelectedForView,
  onSelectForView,
  isLoading,
}) => {
  const handleToggle = () => onToggle(group.id);
  return (
    <Stack
      direction="row"
      sx={{
        borderBottom: '1px solid grey',
        borderColor: 'info.light',
        height: '33px',
      }}
    >
      <SquareCheckBox checked={checked} onToggle={handleToggle} isSelected={isSelectedForView} />

      <Text
        onClick={() => onSelectForView(group.id)}
        noWrap
        sx={{
          width: '100%',
          color: 'black',
          bgcolor: isSelectedForView ? 'primary.light' : 'info.main',
        }}
      >
        <HoverToolTip title={group.name} placement="top-start">
          <span>{group.name}</span>
        </HoverToolTip>
      </Text>

      <Text
        noWrap
        sx={{
          bgcolor: isSelectedForView ? 'primary.light' : 'info.main',
          color: isSelectedForView ? 'info.main' : 'info.dark',
          textAlign: 'center',
          width: '45%',
          minWidth: '55px',
          // maxWidth: '55px',
          px: '3px',
        }}
      >
        {isLoading ? (
          <CircularProgress size={15} color="primary" sx={{ m: '2px' }} />
        ) : (
          <HoverToolTip title={`${selectedItemsAmount}/${itemsAmount}`} placement="top-start">
            <span>{`${selectedItemsAmount}/${itemsAmount}`}</span>
          </HoverToolTip>
        )}
      </Text>
    </Stack>
  );
};
