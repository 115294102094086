import { FC, useEffect, useRef, useState } from 'react';
import { IconButton, Popover, Stack, TextField, Typography } from '@mui/material';
import { TableProductInfo } from 'shared/models';
import { RtlProvider } from 'app/providers/RtlProvider';
import { useAnchorElement, useAppSelector } from 'shared/hooks';
import { TranslationItem } from './index';
import { selectCatalogLanguages } from 'shared/slices';
import { Icon } from 'shared/ui';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

interface Props {
  item: TableProductInfo;
  onUpdateItem: (val: string) => void;
  selected: string;
  onSelect: (id: string) => void;
  language: string;
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  error: string | null;
}

export const EditTranslation: FC<Props> = ({
  onSelect,
  selected,
  item,
  onUpdateItem,
  language,
  onKeyDown,
  error,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState('');
  const languages = useAppSelector(selectCatalogLanguages);

  const { open, anchorEl, onOpenMenu, onCloseMenu } = useAnchorElement();

  useEffect(() => {
    const stringResources = item.stringResources;

    if (!stringResources) {
      setValue('');
      return;
    }

    const translation = stringResources.find((sr) => sr.languageId === language);

    setValue(translation?.value ?? '');
  }, [language]);

  const isHebrew = languages.find((l) => l.id === language)?.culture === 'he-IL';

  return (
    <TranslationItem
      onClick={() => onSelect(item.id)}
      onKeyDown={onKeyDown}
      tabIndex={0}
      sx={{
        padding: '4px 8px !important',
        minWidth: '360px',
        maxWidth: '360px',
        borderLeft: '1px solid lightgrey',
        ...(selected === item.id && { bgcolor: 'primary.light' }),
        ...(error && { border: '2px solid red', padding: '2px 6px !important' }),
      }}
    >
      <RtlProvider enable={isHebrew}>
        {selected === item.id ? (
          <Stack direction="row">
            <TextField
              fullWidth
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                onUpdateItem(e.target.value);
              }}
              inputRef={inputRef}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  inputRef.current?.blur();
                }
              }}
              sx={{
                input: {
                  color: 'primary.main',
                  fontSize: 16,
                  fontWeight: 400,
                  lineHeight: '16px',
                  letterSpacing: '0.16px',
                  p: 0,
                  ...(isHebrew && { fontFamily: 'Helvetica' }),
                },
                '.MuiOutlinedInput-notchedOutline': { border: 'none' },
              }}
            />

            {!!error && (
              <IconButton onClick={onOpenMenu} sx={{ p: '0px' }}>
                <Icon path="error-help" sx={{ width: '14px' }} />
              </IconButton>
            )}

            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={onCloseMenu}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              sx={{ '& .MuiPaper-root': { borderRadius: '6px' }, mt: 1 }}
            >
              <Stack
                alignItems={'center'}
                sx={{ textAlign: 'center', maxWidth: '230px', color: 'error.main', fontSize: 14, p: 1, pt: 0 }}
              >
                <PlayArrowIcon sx={{ height: '16px', rotate: '90deg', color: 'error.main' }} />

                {error}
              </Stack>
            </Popover>
          </Stack>
        ) : (
          <Typography noWrap sx={{ ...(isHebrew && { fontFamily: 'Helvetica' }) }}>{value}</Typography>
        )}
      </RtlProvider>
    </TranslationItem>
  );
};
